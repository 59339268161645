//import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';



function dateFormat(value){
	let objectDate = new Date(value);
	let day = ("0" + (objectDate.getDate() + 1)).slice(-2);
	let month = ("0" + (objectDate.getMonth() + 1)).slice(-2);
	let year = objectDate.getFullYear();
	
	return  day + "/" + month + "/" + year;	 
	
}



export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
        Header: 'Name',
        Footer: 'Name',
        accessor: 'first_name',
        Cell: ({row}) => (
			<div style={{display: 'flex', alignItems: 'center'}}>
				<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png'  
					 style={{width: '50px', height: '50px', marginRight: '10px', borderRadius: '50%'}} 
					 alt={row.original.first_name} />
				{row.original.first_name}
			</div>
		)
		,
        Filter: ColumnFilter,
    },
	{
		Header : 'Price',
		Footer : 'Price',
		accessor: 'email',
		Filter: ColumnFilter,
	},
	{
		Header : 'Date of  Birth',
		Footer : 'Date of  Birth',
		accessor: 'date_of_birth',
		Cell: ({ value }) => {return dateFormat(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Country',
		Footer : 'Country',
		accessor: 'country',
		Filter: ColumnFilter,
	},
	// {
	// 	Header : 'Phone',
	// 	Footer : 'Phone',
	// 	accessor: 'phone',
	// 	Filter: ColumnFilter,
	// },
	{
		Header: 'Action',
		Footer: 'Action',
		disableFilters: true,
		Cell: ({row}) => (
			<div>
				<button className='btn btn-success py-2 text-uppercase' onClick={() => alert(`Editing row with ID ${row.original.id}`)} style={{marginRight: '10px'}}>
					Buy
				</button>
				<button className='btn btn-danger py-2 text-uppercase' onClick={() => window.confirm(`Are you sure you want to delete row with ID ${row.original.id}?`) && console.log('Delete action here')}>
					Sell
				</button>
			</div>
		)
	}
	
]

export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]