import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button
} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const SupplyList = () => {

    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    // Sample data
    const data = [
        {
            userId: 8,
            inst_id: "CCD-INR",
            quantity: 2345642
        },
        {
            userId: 9,
            inst_id: "CCD-INR",
            quantity: 1
        },
        {
            userId: 10,
            inst_id: "CCD-INR",
            quantity: 9999000.0
        }
    ]



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };


    const filteredData = (filter === '') ? data : data.filter(item =>
        item.userId.toString().includes(filter) ||
        item.inst_id.toString().includes(filter) ||
        item.quantity.toString().includes(filter)
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleSearch = () => {
        console.log('Sell')
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Coinn Supply List</Card.Title>
                    <div className="form-group col-md-1">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select"
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-between mb-4">
                        <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Filter..."
                                    value={filter}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                            <div className="d-flex align-items-center">
                                <Button type="submit" variant="success" onClick={handleSearch}>Sell</Button>
                            </div>
                        </Col>
                    </Row>


                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>User Id</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Inst ID</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Quantity</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(item => (
                                <tr key={item.userId}>
                                    <td>
                                        <strong>{item.userId}</strong>
                                    </td>
                                    <td className="text-center">{item.inst_id}</td>
                                    <td className="text-center">{item.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between ">

                        <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>

                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default SupplyList;
