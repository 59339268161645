// NotificationBlog.js

import React, { useState, useEffect } from "react";
import { useSubscription, gql } from '@apollo/client';
import { getAllAdminNotify } from "../../../apolloClientConnection/apolloClient";

const ADMIN_NOTIFICATION_SUBSCRIPTION = gql`
  subscription AdminNotification {
    adminNotification
  }
`;

const NotificationBlog = ({ classChange }) => {
    const [notifications, setNotifications] = useState([]);
    const [lastKey, setLastKey] = useState(null);

    const { data: subscriptionData } = useSubscription(ADMIN_NOTIFICATION_SUBSCRIPTION);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const limit = 10;
                const response = await getAllAdminNotify(limit, lastKey);
                setNotifications(response.Notifys);
                setLastKey(response.lastEvaluatedKey);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchNotifications();
    }, []);

    useEffect(() => {
        if (subscriptionData && subscriptionData.adminNotification) {
            const newNotification = {
                Notification_message: subscriptionData.adminNotification,
                createdAt: Date.now().toString(), // Use current timestamp as string
                PK: "SUBSCRIPTION#NEW",
                SK: "SUBSCRIPTION#NEW"
            };
            setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
        }
    }, [subscriptionData]);

    return (
        <>
            {notifications.map((notification, index) => (
                <li key={index}>
                    <div className="timeline-panel">
                        <div className={`media me-2 ${classChange}`}><i className="fa fa-bell" /></div>
                        <div className="media-body">
                            <h6 className="mb-1">{notification.Notification_message}</h6>
                            <small className="d-block">{new Date(parseInt(notification.createdAt)).toLocaleString()}</small>
                        </div>
                    </div>
                </li>
            ))}
        </>
    );
};

export default NotificationBlog;
