import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown, Badge } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { fetchUserProfile, getAllComplain, deleteComplainTicket, resolvedComplain } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { formatDateTime1 } from "../UserManagement/apis";

const HelpSupportList = () => {
    const [filter, setFilter] = useState();
    const [perPage, setPerPage] = useState(10);
    const [complain, setComplain] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastKey, setLastKey] = useState(null);
    const [paginationInfo, setPaginationInfo] = useState({
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 10,
        hasNextPage: false
    });
    const navigate = useNavigate();
    const { notifySuccess, notifyWarning, notifyError } = useToast();
    const [loading, setLoading] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [noData, setNoData] = useState(false);

    const fetchComplain = async (pageSize, lastEvaluatedKey) => {
        try {
            setLoading(true); // Start loading
            setNetworkError(false); // Reset network error state

            const cleanLastKey = lastEvaluatedKey ? {
                SK: lastEvaluatedKey.SK,
                PK: lastEvaluatedKey.PK,
                createdAt: lastEvaluatedKey.createdAt,
                type: lastEvaluatedKey.type
            } : null;

            const response = await getAllComplain(pageSize, cleanLastKey);

            if (response.items.length === 0) {
                setNoData(true); // Show no data animation if response has no complain
            } else {
                // Fetch user profiles for each complain
                const updatedComplains = await Promise.all(response.items.map(async (item) => {
                    try {
                        const userProfile = await fetchUserProfile({ PK: item.UserPk, SK: "" });
                        // const getUserProfileData = userProfile ?
                        const profileData = JSON.parse(userProfile.profile ? userProfile.profile : 'N/A')

                        return { ...item, profileData };
                    } catch (error) {
                        console.error('Failed to fetch user profile :', error);
                        return item; // Return item without user profile if fetching fails
                    }
                }));
                setComplain(updatedComplains);
                setLastKey(response.lastEvaluatedKey);
                setPaginationInfo(prev => ({
                    ...prev,
                    totalItems: response.totalItems,
                    totalPages: Math.ceil(response.totalItems / pageSize),
                    hasNextPage: !!response.lastEvaluatedKey,
                }));
            }
        } catch (error) {
            console.error('Error fetching complain:', error);
            setNetworkError(true); // Show network error if there's an error
        } finally {
            setLoading(false); // Stop loading regardless of success or failure
        }
    };

    useEffect(() => {
        fetchComplain(perPage, lastKey);
    }, [filter, currentPage, perPage]);

    const handleNextPage = () => {
        if (paginationInfo.hasNextPage) {
            setCurrentPage(current => current + 1);
            fetchComplain(perPage, lastKey);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(current => current - 1);
            setLastKey(null);
            fetchComplain(perPage, null);
        }
    };

    const goToPage = (number) => {
        setCurrentPage(number);
        setLastKey(null);
        fetchComplain(perPage, null);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        if (event.target.value === '') {
            setNoData(false);
        }
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        setCurrentPage(1); // Reset to the first page when changing perPage
        setLastKey(null); // Reset lastKey to fetch from the beginning
        fetchComplain(selectedOption.value, null); // Fetch data with new perPage value
    };

    const handleDeleteComplain = async (id) => {
        try {
            const deleteResponse = await deleteComplainTicket(id);
            if (deleteResponse === "Complain Successfully deleted") {
                const updatedComplain = complain.filter(complain => complain.TicketId !== id);
                setComplain(updatedComplain);
                notifySuccess('Complain deleted successfully!');
            } else {
                notifyWarning('Complain not found or already deleted!');
            }
        } catch (error) {
            console.error('Error deleting complain:', error);
            notifyError('Error deleting complain. Please try again.');
        }
    };

    const handleResolveComplain = async (ticketId) => {
        try {
            const response = await resolvedComplain(ticketId);
            if (response === "successfull") {
                const updatedComplain = complain.map(comp =>
                    comp.TicketId === ticketId ? { ...comp, isResolveIssue: "true" } : comp
                );
                setComplain(updatedComplain);
                notifySuccess('Complain resolved successfully!');
            } else {
                notifyWarning('Failed to resolve complain!');
            }
        } catch (error) {
            console.error('Error resolving complain:', error);
            notifyError('Error resolving complain. Please try again.');
        }
    };

    const handleSeeComplain = (complain) => {
        let convertStringcomplainData = complain;
        navigate('/help&support-message', { state: { convertStringcomplainData } });
    };

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z" fill="#000000" />
            </g>
        </svg>
    );

    const svg2 = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    const optionValue = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 25, label: '25' },
        { value: 30, label: '30' },
    ];

    const pageRange = Array.from({ length: paginationInfo.totalPages }, (_, i) => i + 1);

    const handleRowClick = (pk, sk) => {
        localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
        navigate("/user-data");
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Complain</Card.Title>
                    <div className="d-flex justify-content-start align-items-center">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select"
                            style={{ width: '200px' }} // Adjust width as necessary
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search complain..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive >
                        <thead>
                            <tr>
                                <th>
                                    <strong>User ID</strong>
                                </th>
                                <th >
                                    <strong>Name</strong>
                                </th>
                                <th  >
                                    <strong>Phone/Email</strong>
                                </th>
                                <th className="text-center" style={{ width: '500px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                    <strong>Description</strong>
                                </th>
                                <th className="text-center" style={{ width: '500px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                    <strong>Title</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Date/Time</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Status</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Action</strong>
                                </th>
                                <th className="text-start d-none">
                                    <strong>ID</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            )}
                            {networkError && (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            )}
                            {noData && (
                                <tr>
                                    <td colSpan="8" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            )}
                            {!loading && !networkError && !noData && (
                                complain.map((complain, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage - 1) * perPage + index + 1}</td>
                                        <td >
                                            {complain.profileData && complain.profileData.full_name ? complain.profileData.full_name : 'Guest'}
                                        </td>
                                        <td >
                                            <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }} onClick={() => handleRowClick(complain.UserPk, complain.profileData.SK)}>
                                                {complain.profileData ? complain.profileData.phone : 'N/A'}
                                                {complain.profileData ? complain.profileData.email && <div>{complain.profileData.email}</div> : ''}
                                            </div>
                                        </td>
                                        <td className="text-center fs-12" style={{ width: '500px', wordWrap: 'break-word', whiteSpace: 'normal' }}>{complain.discription}</td>
                                        <td className="text-center fs-12" style={{ width: '500px', wordWrap: 'break-word', whiteSpace: 'normal' }}>{complain.Title}</td>
                                        <td className="text-center">{formatDateTime1(complain)}</td>
                                        <td className="text-center">{complain.isResolveIssue === "false" ? <button onClick={() => handleResolveComplain(complain.TicketId)} className={'btn btn-danger'}>Open</button> : <Badge bg="success">Resolved</Badge>}</td>
                                        <td className="text-center">
                                            <Dropdown>
                                                <a onClick={() => handleSeeComplain(complain)}>
                                                    <Dropdown.Toggle
                                                        variant={'success'}
                                                        className="light sharp i-false"
                                                    >
                                                        {svg1}
                                                    </Dropdown.Toggle>
                                                </a>
                                                <Dropdown.Toggle
                                                    variant={'danger'}
                                                    className="light sharp i-false ms-3"
                                                    onClick={() => handleDeleteComplain(complain.TicketId)}
                                                >
                                                    {svg2}
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </td>
                                        <td className="text-start d-none">{complain.PK}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span>{complain.length}</span>  complaints</p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li
                                    className={`page-item page-indicator ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <Link
                                        to={"#"}
                                        className="page-link"
                                        onClick={(e) => {
                                            if (currentPage === 1) {
                                                e.preventDefault();
                                            } else {
                                                handlePreviousPage();
                                            }
                                        }}
                                    >
                                        <i
                                            className="fa-solid fa-angle-left"
                                            style={{
                                                color: currentPage === 1 ? "#cccccc" : "inherit",
                                            }}
                                        ></i>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link to={"#"} className="page-link">
                                        {currentPage}
                                    </Link>
                                </li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link" onClick={handleNextPage}>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default HelpSupportList;
