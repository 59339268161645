import React, { useState } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { addHeaderText } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import swal from "sweetalert";

const MobileData = () => {
    const { notifySuccess, notifyWarning, notifyError } = useToast();
    const [heading, setHeading] = useState("");
    const [text, setText] = useState("");
    const [minAmount, setMinAmount] = useState("");
    const [maxAmount, setMaxAmount] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!heading.trim() || !text.trim()) {
            notifyWarning("Please enter both Header and description.");
            return;
        }

        // SweetAlert confirmation dialog
        const willSubmit = await swal({
            title: "Are you sure?",
            text: "Do you want to change the ICO Text?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (willSubmit) {
            try {
                const result = await addHeaderText(heading, text, 'ICO', parseFloat(minAmount), parseFloat(maxAmount));
                if (result.DynamiInput === true) {
                    notifySuccess("ICO text added successfully!");
                    setHeading('');
                    setText('');
                    setMinAmount('');
                    setMaxAmount('');
                } else {
                    notifyError("Failed to add ICO text.");
                }
            } catch (error) {
                notifyError("An error occurred. Please try again.");
            }
        } else {
            notifyWarning("ICO Text change canceled.");
        }
    };

    const handleMinAmountChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
            setMinAmount(value);
        }
    };

    const handleMaxAmountChange = (e)=>{
        const value = e.target.value;
        if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
            setMaxAmount(value);
        }
    }

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Mobile Data</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Heading</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Title"
                                        value={heading}
                                        onChange={(e) => setHeading(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>Minimum Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Set minimum Amount"
                                        value={minAmount}
                                        onChange={handleMinAmountChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>Maximum Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Set maximum Amount"
                                        value={maxAmount}
                                        onChange={handleMaxAmountChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ minHeight: "150px", resize: "vertical" }}
                                        placeholder="Enter Description"
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="success">Save</Button>
                                <Link to='/ico-text'>
                                    <Button className="ms-2" variant="danger">Go Back</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default MobileData;
