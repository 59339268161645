import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo/logoSimplyfy.png";
import { decryptData } from "../../store/cryptoUtils/cryptoUtils";


const LockScreen = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [checkPasswod, setCheckPassword] = useState();
    const [error, setError] = useState("");


    useEffect(() => {
        const storedPassword = localStorage.getItem("lockScreenPassword");
        if (storedPassword) {
            const decryptedPassword = decryptData(storedPassword);
            setCheckPassword(decryptedPassword);
        }
    }, []);



    const submitHandler = (e) => {
        e.preventDefault();
        // Assume the correct password is "1234"
        if (password === checkPasswod) {
            setError("");

            navigate("/welcome");
        } else {
            setError("Incorrect password, please try again.");
        }
    };

    return (
        <div className="authincation" style={{ backgroundColor: "black", height: "100vh" }}>
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form p-4">
                                        <div className="text-center mb-3">
                                            <Link to="/dashboard">
                                                <img src={logo} alt="Logo" />
                                            </Link>
                                        </div>
                                        <h4 className="text-center text-white mb-4">Account Locked</h4>
                                        <form onSubmit={submitHandler}>
                                            <div className="mb-3">
                                                <label className="text-white"><strong>Password</strong></label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            {error && <div className="text-danger mb-3">{error}</div>}
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary btn-block">Unlock</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LockScreen;
