import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Dropdown,

} from "react-bootstrap";

import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchAllUserApprovedByAdmin, getWalletDetails } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { formatDateTime1 } from "./apis";

const optionValue = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" }, // Changed default value to 25
    { value: 300, label: "300" },
];

const ApproveByAdmin = () => {
    // Sample data

    // SVG Icon
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z" fill="#000000" />
            </g>
        </svg>
    );

    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(50);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([])
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [noDataError, setNoDataError] = useState(false);
    const [pageKeys, setPageKeys] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);


    const getData = async (page) => {
        setLoading(true);

        const lastKey = pageKeys[page - 1];
        try {
            const data = await fetchAllUserApprovedByAdmin(perPage, lastKey?.PK, lastKey?.SK, lastKey?.createdAt, lastKey?.type);
            if (data.success) {
                let items = data.data.items || []

                // Fetch wallet details for each user concurrently
                const itemsWithWalletDetails = await Promise.all(items.map(async (item) => {
                    try {
                        const walletData = await getWalletDetails("", "", item.PK); // Assuming PK is the user ID
                        const price = walletData?.getWalletDetails?.remaining_price?.price?.toFixed(2) || "0";
                        return { ...item, walletDetails: price };
                    } catch (walletError) {
                        console.error('Error fetching wallet details for user:', item.PK, walletError);
                        return { ...item, walletDetails: "Error" };
                    }
                }));

                setData(itemsWithWalletDetails);
                setHasMoreData(itemsWithWalletDetails.length > 0);
                const newPageKeys = {
                    ...pageKeys,
                    [page]: data.data.lastEvaluatedKey || null,
                };
                setPageKeys(newPageKeys);
                setNoDataError(items.length === 0);
                setNetworkError(false);
            } else {
                setNoDataError(true);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
            setNetworkError(true);
        }
    };

    useEffect(() => {
        getData(currentPage);
    }, [currentPage, perPage]);

    // const handleFilterChange = (event) => {
    //     setFilter(event.target.value);
    // };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const filteredData = (filter === '') ? data : data.filter(item =>
        item.userId.toString().includes(filter) ||
        item.full_name.toString().includes(filter) ||
        item.mobileNo.includes(filter) ||
        item.inrWalletBalance.includes(filter) ||
        item.dateTime.includes(filter) ||
        item.status.includes(filter) ||
        item.userType.includes(filter)
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        setCurrentPage(1); // Reset to the first page
    };


    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
    };

    const handleRowClick = (PK, SK) => {
        let dataPKSK = {
            PK: PK,
            SK: SK
        }
        // Save data to local storage
        localStorage.setItem('dataPKSK', JSON.stringify(dataPKSK));
        navigate("/user-data", { state: { dataPKSK } });
    };

    const handleNextPage = () => {
        if (hasMoreData && pageKeys[currentPage]) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
        }
    };


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
        }
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Approved By Admin</Card.Title>
                    <div className="form-group col-md-1">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select"
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-between mb-4">
                        <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Filter..."
                                    // value={filter}
                                    // onChange={handleFilterChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control"
                                    placeholderText="Start Date"
                                />
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control ms-2"
                                    placeholderText="End Date"
                                />
                                <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                            </div>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>User Id</strong>
                                </th>
                                <th>
                                    <strong>Name</strong>
                                </th>
                                <th>
                                    <strong>MobileNO/Email</strong>
                                </th>
                                <th>
                                    <strong>Inr Wallet Balance</strong>
                                </th>
                                <th>
                                    <strong>Date/Time</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th> <strong>Action</strong></th>
                                <th style={{ display: "none" }}>PK</th>
                                <th style={{ display: "none" }}>SK</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="9" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : networkError ? (
                                <tr>
                                    <td colSpan="9" className="text-center">
                                        <LottieAnimation
                                            lottieJson={NetworkError}
                                            width={550}
                                            height={400}
                                        />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : noDataError || data.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="text-center">
                                        <LottieAnimation
                                            lottieJson={noDataAnimation}
                                            width={550}
                                            height={400}
                                        />
                                    </td>
                                </tr>
                            ) : (data.map((item, index) => (
                                <tr key={item.userId}>
                                    <td>
                                        <strong>{item.serialNo}</strong>
                                    </td>
                                    <td>{item.full_name}</td>
                                    <td className="text-primary">{item.phone}
                                        {item.email && <div>{item.email}</div>}</td>
                                    <td >₹<span style={{ marginLeft: "5px" }}>{item.walletDetails}</span></td>
                                    <td>{formatDateTime1(item)}</td>
                                    <td>
                                        <span
                                            className={`badge light badge-${item.isKYCPending === true ? "danger" : "success"
                                                }`}
                                        >
                                            {item.isKYCPending === true ? "InActive" : "Active"}
                                        </span>
                                    </td>
                                    <td style={{ display: "none" }}>{item.PK}</td>
                                    <td style={{ display: "none" }}>{item.SK}</td>
                                    <td>
                                        <Dropdown.Toggle
                                            variant={
                                                item.status === "Active" ? "success" : "danger"
                                            }
                                            className="light sharp i-false"
                                            onClick={() => handleRowClick(item.PK, item.SK)}
                                        >
                                            {svg1}
                                        </Dropdown.Toggle>
                                    </td>
                                </tr>
                            ))
                            )}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">
                            Showing <span> 1-4 </span> from <span> 30 </span> data{" "}
                        </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li
                                    className={`page-item page-indicator ${currentPage === 1 ? "disabled" : ""
                                        }`}

                                >
                                    <Link
                                        to={"#"}
                                        className="page-link"
                                        onClick={(e) => {
                                            if (currentPage === 1) {
                                                e.preventDefault();
                                            } else {
                                                handlePreviousPage();
                                            }
                                        }}
                                    >
                                        <i
                                            className="fa-solid fa-angle-left"
                                            style={{
                                                color: currentPage === 1 ? "#cccccc" : "inherit",
                                            }}
                                        ></i>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link to={"#"} className="page-link">
                                        {currentPage}
                                    </Link>
                                </li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link" onClick={handleNextPage}>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </Card.Body>
            </Card>
        </Col>
    );
}

export default ApproveByAdmin;
