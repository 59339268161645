import React, { useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';

const TokenRequest = () => {
    const [solidityCodeConfirmed, setSolidityCodeConfirmed] = useState(null);
    const [sourceCodeDetails, setSourceCodeDetails] = useState('');
    const [superuserPrivileges, setSuperuserPrivileges] = useState('');
    const [hasSolidityCode, setHasSolidityCode] = useState(false);
    const [hasSuperUser, setHasSuperUser] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({
            solidityCodeConfirmed,
            sourceCodeDetails,
            superuserPrivileges,
            // Include other form data as needed
        });

    };

    const handleSolidityCode = (hasSolidityCode) => {
        setHasSolidityCode(hasSolidityCode)
    }

    const handleSuperUser = (hasSuperUser) => {
        setHasSuperUser(hasSuperUser)
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>Security</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md={12}>
                            <Form.Label>Solidity source code</Form.Label>
                            <Card.Text className="m-0 subtitle text-muted mb-1">
                                Is  the Solidity source code for your token finalized (exact code you will deploy to mainnet),
                                uploaded to Etherscan, and verified?
                            </Card.Text>
                            <div>
                                <Button variant="success" className="me-2" onClick={() => handleSolidityCode(true)}>Yes</Button>
                                <Button variant="danger" onClick={() => handleSolidityCode(false)}>No</Button>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mt-3">
                        {hasSolidityCode && (<Form.Group as={Col} md={12}>
                            <Row className="mb-3 mt-3">
                                <Col md={12}>
                                    <Form.Label>Provide the final solidity source code (the exact code that you will deploy on mainnet). Below are a few recommendations to provide us with the finalized source code:</Form.Label>
                                    <Row>
                                        <Col md={12}>
                                            <p className='text-muted'><strong>i.</strong> Link to Etherscan testnet deployment of the token with source code uploaded Dashboard.</p>
                                            <p className='text-muted'><strong>ii.</strong> Link to GitHub that clearly defines exactly which files constitute the token. </p>
                                            <p className='text-muted'><strong>iii.</strong> Manual sharing of contract source code.</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <div className="input-group mb-2">
                                                <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Link"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="input-group mb-2">
                                                <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Link"
                                                />
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row className="mt-4">
                                        <Form.Label>Disclaimer</Form.Label>
                                        <Card.Text className="m-0 subtitle text-muted mb-1">
                                            Listing of your token can be delayed if the code provided does not exactly match the code deployed on mainnet. Coinbase will confirm prior to launch that the code provided in the application is an exact match with the code deployed on mainnet.
                                            Avoid delays by ensuring that the code is identical in the application and mainnet.                                    </Card.Text>
                                    </Row>


                                </Col>
                            </Row>

                        </Form.Group>)}
                    </Row>

                    <Row className="mb-3 mt-3">
                        <Form.Group as={Col} md={12}>
                            <Form.Label>Superuser privileges</Form.Label>
                            <Card.Text className="m-0 subtitle text-muted mb-1">
                                Are there any roles in the asset that provide the holder "superuser" privileges? Such "superuser" privileges let an individual user, often with a single key, affect
                                the state of the entire asset (e.g. token upgrade roles, seize/destroy roles, mint roles).
                            </Card.Text>
                            <div>
                                <Button variant="success" className="me-2" onClick={() => handleSuperUser(true)}>Yes</Button>
                                <Button variant="danger" onClick={() => handleSuperUser(false)}>No</Button>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mt-3">
                        {hasSuperUser && (<Form.Group as={Col} md={12}>
                            <Row className="mb-3 mt-3">
                                <Col md={6}>
                                    <Form.Label>A. Enumerate all superuser privileges, accounts, and roles</Form.Label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Link"
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>B. Are the superuser privileges decentralized?(e.g. multi-sig wallet, governance system)</Form.Label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Link"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-3 mt-3">
                                <Col md={6}>
                                    <Form.Label>C. How many superuser keys are ther and what are the thresholds for executing an action? (e.g. 3-of-5 accounts must vote in the affirmative)</Form.Label>

                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Link"
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>D. Who controls the superuser keys and how are they managed?(<span>e.g. vault in the bank, dedicated hard ware</span>)</Form.Label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Link"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-3 mt-3">
                                <Col md={6}>
                                    <Form.Label>E. How were the superuser keys generated?(<span>e.g. keygen ceremony</span>)</Form.Label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Link"
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>D. Who controls the superuser keys and how are they managed?(<span>e.g. vault in the bank, dedicated hard ware</span>)</Form.Label>
                                    <div className="input-group mb-2">
                                        <div className="input-group-text"><i className="fa-solid fa-link me-2"></i></div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Link"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Form.Group>)}
                    </Row>

                    {/* Submit Button */}
                    <Row className="mt-4">
                        <Col>
                            <Button className='me-2' variant="success" type="submit">
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default TokenRequest;
