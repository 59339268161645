import React from "react";
import { Col, Card, Row } from "react-bootstrap";
import LottieAnimation from "../../../gif/LottieAnimation ";
import DashboardLottie from "../../../gif/UserDashboard.json"; // Ensure the path is correct

const WelcomePage = () => {
  return (
    <div className="welcome-page">
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <Card className="text-center shadow-lg">
            <Card.Body>
              {/* Lottie Animation for Dashboard */}
              <div className="d-none d-md-flex justify-content-center">
                <LottieAnimation
                  lottieJson={DashboardLottie}
                  height={400}
                  width={550}
                />
              </div>

              <div className="d-flex d-md-none justify-content-center">
                <LottieAnimation
                  lottieJson={DashboardLottie}
                  height={250}
                  width={300}
                />
              </div>

              <h1 className="mt-4 display-4 d-none d-md-block">
                Welcome to Admin Panel
              </h1>
              <h1 className="mt-4 h3 d-block d-md-none">
                Welcome to Admin Panel
              </h1>

              <p className="lead">Manage users, settings, and more from this dashboard.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default WelcomePage;
