import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';

const ListingAgreementTab = ({ onNext  }) => {
    const [title, setTitle] = useState('');
    const [signature, setSignature] = useState('');

    // Assuming you have a URL to the listing agreement document
    const listingAgreementUrl = '/path-to-your-listing-agreement.pdf';

    const handleSubmit = (event) => {
        event.preventDefault();
        onNext(); // Call the function passed as prop to navigate to the next tab
    };

    return (
        <>
            <Card.Title>Listing agreement</Card.Title>
            <Card.Text>
                By signing this agreement, you agree that the information you provide is truthful. You agree to abide by the terms of the agreement (including but not limited to the non-disclosure provision, the restrictions on market manipulation, and the obligation not to use any of the information you receive or allow a third party to engage in market manipulation). Please read this document over carefully.
            </Card.Text>
            <Button className="me-2 mt-3 mb-3" variant="success" href={listingAgreementUrl}  download>
                  <span className="btn-icon-start text-success">
                    <i className="fa fa-download color-success" />
                  </span>
                  Download Listing Agreement
                </Button>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    {/* Title Input */}
                    <Form.Group as={Col} md={6} controlId="formHorizontalTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="MR"
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </Form.Group>

                    {/* Signature Input */}
                    <Form.Group as={Col} md={6} controlId="formHorizontalSignature">
                        <Form.Label>Signature</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Atin"
                            value={signature}
                            onChange={e => setSignature(e.target.value)}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Col sm={{ span: 12 }}>
                        <Button type="submit" variant="success">Agree & Continue</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default ListingAgreementTab;
