import React, { useState, useEffect } from "react";
import Select from 'react-select';
import useToast from "../Toaster/Toaster";
import { registerUser, fetchAlTlemplates } from "../../../apolloClientConnection/apolloClient";
import LottieAnimation from "../../../gif/LottieAnimation ";
import dot from "../../../gif/dot.json"

const CreateAdmin = () => {
    const { notifyError, notifySuccess, notifyWarning, notifyInfo } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    // Form state
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        template: '',
        password: '',
        selectedTemplateOption: null
    });

    const initialFormState = {
        name: '',
        username: '',
        template: '',
        password: ''
    };



    useEffect(() => {
        const loadTemplates = async () => {
            try {
                const response = await fetchAlTlemplates();
                console.log(response)
                if (response && response.Getalltamplate) {
                    const templateOptions = response.Getalltamplate.map(template => ({
                        value: template.SK, // Assuming you want to use SK as value
                        label: `${template.tamplate} (${template.Role})`,
                        PK: template.PK, // Keep PK for later use
                        SK: template.SK
                    }));
                    setTemplates(templateOptions);
                } else {
                    notifyWarning('No templates found');
                }
            } catch (error) {
                notifyError(`Error fetching templates: ${error.message}`);
            }
        };

        loadTemplates();
    }, []);

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;

        const newValue = name === 'name'
            ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
            : name === 'username'
                ? value.toLowerCase()
                : value;

        setFormData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };


    const handleTemplateChange = (selectedOption) => {
        // Check if a template is selected and update form data accordingly
        if (selectedOption) {
            setFormData(prevState => ({
                ...prevState,
                template: selectedOption.label, // Use label for displaying in the UI if needed
                templatePK: selectedOption.PK, // Store the PK of the selected template
                templateSK: selectedOption.SK , // Store the SK of the selected template
                selectedTemplateOption: selectedOption || null
            }));
        } else {
            // Reset template-related data if no template is selected
            setFormData(prevState => ({
                ...prevState,
                template: '',
                templatePK: '',
                templateSK: '',
                selectedTemplateOption: ''
            }));
        }
    };




    // Handle form submission
    const handleSubmit = async (e) => {

        e.preventDefault();
        setIsLoading(true);// Start loading animation

        // Check if all fields are filled
        // Validate required fields
        if (!formData.name || !formData.username || !formData.templatePK || !formData.password) {
            notifyWarning('All fields are required.');
            setIsLoading(false); // Stop loading animation if validation fails
            return;
        }

        try {
            const result = await registerUser({
                userName: formData.username,
                fullName: formData.name,
                password: formData.password,
                password2: "", // Assuming this is intentionally left empty for this example
                templatePK: formData.templatePK, // Use the PK from form data
                templateSK: formData.templateSK  // Use the SK from form data
            });

            console.log("Registration success:", result.message);

            // Handle specific messages or conditions based on the result
            if (result.message === null) {
                notifyInfo('Admin already exists!');
            } else if (result.message === "Admin register successfully") {
                notifySuccess('New admin created successfully!');
                // Optionally, clear the form if registration was successful
                setFormData(initialFormState);
            } else {
                // Handle any other messages that might be returned
                notifyInfo(result.message || "Registration completed with unexpected status.");
            }

        } catch (error) {
            console.error("Registration failed:", error.message);
            notifyError(`Registration failed: ${error.message}`);
        }
        setIsLoading(false); // Stop loading animation after handling registration
    };


    const handleClear = () => {
        setFormData(initialFormState);
    };



    return (
        <>
            {isLoading && (
                <div style={{
                    position: 'fixed', // or 'absolute'
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 100,
                    // backgroundColor: 'rgba(255, 255, 255, 0.5)' // Optional: Adds a semi-transparent overlay
                }}>
                    <LottieAnimation lottieJson={dot} width={550} height={400} />
                </div>
            )}

            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add New Admin</h4>
                    </div>
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Admin Name"
                                            autoComplete="off"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Admin Username"
                                            autoComplete="off"
                                            name="username"
                                            value={formData.username}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Template Select</label>
                                        <Select
                                            options={templates}
                                            className="custom-react-select"
                                            value={formData.selectedTemplateOption}                                             onChange={handleTemplateChange}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            autoComplete="new-password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                                <button type="button" className="btn btn-secondary  ms-2" onClick={handleClear}>
                                    Clear
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateAdmin;
