import React, { useState, useEffect } from 'react';
import {Card, Table, Badge } from 'react-bootstrap';
import { fetchTradeOrders, fetchUserProfile } from '../../../apolloClientConnection/apolloClient';
import { formatDateTime } from '../UserManagement/apis';
import Loader from '../Loader/loader';
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { useSubscription, gql } from '@apollo/client';
import {  useNavigate } from 'react-router-dom';
import useToast from '../Toaster/Toaster';


const TRADE_ORDER_UPDATED_SUBSCRIPTION = gql`
  subscription {
    tradeOrderUpdated {
        tradeHistory{
           type
            price
            quantity
            createdAt
            buyerUserId
            sellerUserId
            orderExcutedType
        }
    }
  }
`;

const OrderDetails = () => {
    const [tradeHistory, setTradeHistory] = useState([]);
    const [userProfiles, setUserProfiles] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const { data: newOrderData } = useSubscription(TRADE_ORDER_UPDATED_SUBSCRIPTION);
    const navigate = useNavigate();
    const {notifyWarning}= useToast()


    useEffect(() => {
        fetchOrderHistory(); // Fetch data for a specific coin pair
    }, []);

    useEffect(() => {
        if (newOrderData?.tradeOrderUpdated?.tradeHistory) {
            const orders = newOrderData.tradeOrderUpdated.tradeHistory;
            if (orders) {
                setTradeHistory(orders);
                fetchUserProfiles(orders);
            }
        }
    }, [newOrderData]);

    const fetchOrderHistory = async () => {
        setIsLoading(true);
        setHasError(false);
        try {
            const res = await fetchTradeOrders('CCD', 'INR');

            if (Array.isArray(res.tradeOrders.tradeHistory)) {
                setTradeHistory(res.tradeOrders.tradeHistory);
                fetchUserProfiles(res.tradeOrders.tradeHistory);
            } else {
                setTradeHistory([]);
                setHasError(true);
            }
        } catch (error) {
            console.error('Fetching error:', error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserProfiles = async (orders) => {
        const profiles = { ...userProfiles };
        for (let order of orders) {
            if (!profiles[order.buyerUserId]) {
                try {
                    const buyerProfile = await fetchUserProfile({ PK: order.buyerUserId });
                    if (buyerProfile.success) {
                        profiles[order.buyerUserId] = JSON.parse(buyerProfile.profile);
                    }
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                }
            }
            if (!profiles[order.sellerUserId]) {
                try {
                    const sellerProfile = await fetchUserProfile({ PK: order.sellerUserId });
                    if (sellerProfile.success) {
                        profiles[order.sellerUserId] = JSON.parse(sellerProfile.profile);
                    }
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                }
            }
        }
        setUserProfiles(profiles);
    };

    // const maxLength = tradeHistory.length;

    // const handleRowClick = (pk, sk) => {
    //     console.log('this is user Pk SK data', pk,sk)
    //     localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
    //     navigate("/user-data");
    // };

    const handleRowClick = (pk, sk) => {

        if (!pk || !sk) {
            notifyWarning('User Data Not Present');
            return;
        }
    
        localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
        navigate("/user-data");
    };

    return (
        <Card className="h-auto">
            <Card.Header>
                <Card.Title>Order Details</Card.Title>
            </Card.Header>
            <Card.Body>
                {isLoading ? (
                    <Loader />
                ) : hasError ? (
                    <div className="text-center">
                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                        Connect To SuperAdmin
                    </div>
                ) : (
                    <Table responsive striped bordered>
                        <thead>
                            <tr className='text-center'>
                                <th colSpan="2" className='bg-success fs-3'>BUYER</th>
                                <th colSpan="4" className='fs-3'>Trade History <span className='text-muted'>(Execution)</span></th>
                                <th colSpan="2" className='bg-danger fs-3'>SELLER</th>
                            </tr>
                            <tr className='text-center'>
                                <th>Name</th>
                                <th>Mobile/Phone</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Date/Time</th>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Mobile/Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tradeHistory?.length === 0 ? (
                                <tr>
                                    <td colSpan="12" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        No Data Available
                                    </td>
                                </tr>
                            ) : (
                                tradeHistory?.map((order, index) => (
                                    <tr className='text-center' key={index}>
                                        <td>{userProfiles[order?.buyerUserId]?.full_name || 'Guest'}</td>
                                        <td>
                                            <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }} onClick={() => handleRowClick(userProfiles[order.buyerUserId]?.PK, userProfiles[order.buyerUserId]?.SK)}>
                                                {userProfiles[order?.buyerUserId]?.phone || 'N/A'}
                                                {userProfiles[order?.buyerUserId]?.email && <div>{userProfiles[order?.buyerUserId]?.email}</div>}
                                            </div>
                                        </td>
                                        <>
                                            <td className='text-danger'>{order?.price?.toFixed(2)}</td>
                                            <td className='text-success'>{order?.quantity?.toFixed(2)}</td>
                                            <td>{order?.customDate ? order?.customDate : formatDateTime(order?.createdAt)}</td>
                                            <td className="text-center"><Badge bg={`${tradeHistory[index].orderExcutedType === 'BUY' ? 'success' : 'danger'}`}>{tradeHistory[index]?.orderExcutedType}</Badge></td>
                                        </>
                                        <td>{userProfiles[order.sellerUserId]?.full_name || 'Guest'}</td>
                                        <td>
                                            <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }} onClick={() => handleRowClick(userProfiles[order?.sellerUserId]?.PK, userProfiles[order?.sellerUserId]?.SK)}>
                                                {userProfiles[order.sellerUserId]?.phone || 'N/A'}
                                                {userProfiles[order.sellerUserId]?.email && <div>{userProfiles[order.sellerUserId]?.email}</div>}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                )}
            </Card.Body>
        </Card>
    );
};

export default OrderDetails;
