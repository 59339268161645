import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

const PrepareToSubmitTab = () => {
    const [currentPhase, setCurrentPhase] = useState('launched');
    const phases = ['submission', 'review', 'launched']; // Define the order of phases

    // Function to determine the badge class based on the phase
    const getBadgeClass = (phase) => {
        let baseClass = "timeline-badge ";
        let phaseIndex = phases.indexOf(phase);
        let currentPhaseIndex = phases.indexOf(currentPhase);

        // If the phase is before or at the current phase, apply color
        if (phaseIndex <= currentPhaseIndex) {
            return baseClass + (phase === 'submission' ? "primary" : phase === 'review' ? "warning" : "success");
        }
        // For phases after the current phase, don't apply specific color
        return baseClass + "default"; // Assuming "default" is a class that doesn't apply a specific color
    };

    // Function to determine the heading and completion message based on the currentPhase
    const getCompletionContent = () => {
        switch (currentPhase) {
            case 'submission':
                return {
                    heading: "Submission Received",
                    message: "Your submission has been received. It is currently awaiting review."
                };
            case 'review':
                return {
                    heading: "Review Underway",
                    message: "Your application is currently under review. We appreciate your patience during this process."
                };
            case 'launched':
                return {
                    heading: "Congratulations!",
                    message: "Congratulations! Your application process is now complete. Your asset has been launched on Simplyfy. Stay tuned for further updates!",
                    className: "text-danger"
                };
            default:
                return {
                    heading: "Process Underway",
                    message: "Your application is being processed. Please wait for further updates."
                };
        }
    };

    const completionContent = getCompletionContent();

    return (
        <>
            <Card.Title>Thank for Your Application</Card.Title>
            <Card.Text>
                We're excited to continue on this journey.
            </Card.Text>
            <div className="card-body">
                <PerfectScrollbar
                    id="DZ_W_TimeLine"
                    className="widget-timeline dz-scroll height37 ps ps--active-y"
                >
                    <ul className="timeline">
                        <li>
                            <div className={getBadgeClass('submission')}></div>
                            <Link className="timeline-panel text-muted" to="/widget-basic">
                                <span>10 minutes ago</span>
                                <h6 className="mb-0">
                                    <strong className="text-info">Submission</strong>
                                </h6>
                                <p className="mb-0">
                                    We've received your application.
                                </p>
                            </Link>
                        </li>
                        <li>
                            <div className={getBadgeClass('review')}></div>
                            <Link className="timeline-panel text-muted" to="/widget-basic">
                                <span>20 minutes ago</span>
                                <h6 className="mb-0">
                                    <strong className="text-warning">In review</strong>
                                </h6>
                                <p className="mb-0">
                                    We have started reviewing your application.
                                </p>
                            </Link>
                        </li>
                        <li>
                            <div className={getBadgeClass('launched')}></div>
                            <Link className="timeline-panel text-muted" to="/widget-basic">
                                <h6 className="mb-0">
                                    <strong className="text-success">Launched</strong>
                                </h6>
                                <p className="mb-0">
                                    Your asset has been launched on Simplyfy.
                                </p>
                            </Link>
                        </li>

                    </ul>
                    <li>
                        <div className="timeline-badge success"></div>
                        <div className="timeline-panel text-muted p-2" style={{ borderRadius: '10px' }}>
                            <h6 >
                                <strong className={completionContent.heading === 'Congratulations!' ? 'text-success' : 'text-white'}>{completionContent.heading}</strong>
                            </h6>
                            <p>{completionContent.message}</p>
                        </div>
                    </li>
                </PerfectScrollbar>
            </div>
        </>
    );
};

export default PrepareToSubmitTab;
