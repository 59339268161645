import React, { useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';

const LegalTab = ({ onNext }) => {
    const [hasAssetTrading, setHasAssetTrading] = useState(false);
    const [assetTradingDetails, setAssetTradingDetails] = useState({
        explain: '',
        discounts: '',
        lockUpVesting: '',
        pricing: '',
        useOfProceeds: '',
        paidPromoters: '',
    });
    const [hasLegalAction, setHasLegalAction] = useState(false);
    const [legalActionDetails, setLegalActionDetails] = useState('');
    const [regularStatus, setRegularStatus] = useState('');
    const [securitiesRegistration, setSecuritiesRegistration] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
       
        onNext();
    };

    const handleAssetTradingDetailChange = (field, value) => {
        setAssetTradingDetails(prevDetails => ({
            ...prevDetails,
            [field]: value,
        }));
    };

    return (
        <>
            <Card.Title>Legal</Card.Title>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3 mt-3">
                    <Form.Group as={Col} md={12}>
                        <Form.Label>Asset Trading</Form.Label>
                        <Card.Text className="m-0 subtitle text-muted mb-1">
                            Was a token sale of any sort conducted? Examples include a presale, "SAFT" sale, Initial Coin or Exchange Offering ("ICO" or "IEO"), or sales of assetQuantity
                            instruments convertible into tokens.
                        </Card.Text>
                        <div>
                            <Button variant="success" className="me-2" onClick={() => setHasAssetTrading(true)}>Yes</Button>
                            <Button variant="danger" onClick={() => setHasAssetTrading(false)}>No</Button>
                        </div>
                    </Form.Group>
                </Row>
                {hasAssetTrading && (
                    <Row className="mb-3 mt-3">
                        <Form.Group as={Col} md={12}>
                            <Row className="mb-3 mt-3">
                                <Col md={6}>
                                    <Form.Label>A. Explain (i) when such sale or sales took place, (ii) How Many tokens were sold, (iii) at what price, (iv) how many purchasers participated, and (v) to the extent available, describe the purchasers.</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter Your Text"
                                        value={assetTradingDetails.explain}
                                        onChange={(e) => handleAssetTradingDetailChange('explain', e.target.value)}
                                    />
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Button className="me-2" variant="primary">
                                                <span className="btn-icon-start text-info">
                                                    <i className="fa fa-link color-info" />
                                                </span>
                                                Add Link
                                            </Button>
                                        </Col>
                                        <Col md={6} className="text-end">
                                            <Button variant="success">
                                                <span className="btn-icon-start text-success">
                                                    <i className="fa fa-upload color-success" />
                                                </span>
                                                Upload File
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                hidden
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>B. Indicate whether there were any discounts offered or bonuses implemented.</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter a message to the buyer..."
                                        value={assetTradingDetails.discounts}
                                        onChange={(e) => handleAssetTradingDetailChange('discounts', e.target.value)}
                                    />
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Button className="me-2" variant="primary">
                                                <span className="btn-icon-start text-info">
                                                    <i className="fa fa-link color-info" />
                                                </span>
                                                Add Link
                                            </Button>
                                        </Col>
                                        <Col md={6} className="text-end">
                                            <Button variant="success">
                                                <span className="btn-icon-start text-success">
                                                    <i className="fa fa-upload color-success" />
                                                </span>
                                                Upload File
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                hidden
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3 mt-3">
                                <Col md={6}>
                                    <Form.Label>C. Describe the terms of any lock-up or vesting periods. Indicate if they are time-based, event-based, or have elements of both.</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter any additional information for this transaction (optional)"
                                        value={assetTradingDetails.lockUpVesting}
                                        onChange={(e) => handleAssetTradingDetailChange('lockUpVesting', e.target.value)}
                                    />
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Button className="me-2" variant="primary">
                                                <span className="btn-icon-start text-info">
                                                    <i className="fa fa-link color-info" />
                                                </span>
                                                Add Link
                                            </Button>
                                        </Col>
                                        <Col md={6} className="text-end">
                                            <Button variant="success">
                                                <span className="btn-icon-start text-success">
                                                    <i className="fa fa-upload color-success" />
                                                </span>
                                                Upload File
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                hidden
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>D. Describe (i) how the pricing of tokens was determined, and (ii) whether any token purchase limits existed (maximums or maximums)</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter Description"
                                        value={assetTradingDetails.pricing}
                                        onChange={(e) => handleAssetTradingDetailChange('pricing', e.target.value)}
                                    />
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Button className="me-2" variant="primary">
                                                <span className="btn-icon-start text-info">
                                                    <i className="fa fa-link color-info" />
                                                </span>
                                                Add Link
                                            </Button>
                                        </Col>
                                        <Col md={6} className="text-end">
                                            <Button variant="success">
                                                <span className="btn-icon-start text-success">
                                                    <i className="fa fa-upload color-success" />
                                                </span>
                                                Upload File
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                hidden
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mb-3 mt-3">
                                <Col md={6}>
                                    <Form.Label>E. Describe the intended use of the proceeds and whether the amount raised was equal to, less than, or greater than the amount necessary to complete development of the platform</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter Reason for Inclusion/Exclusion"
                                        value={assetTradingDetails.useOfProceeds}
                                        onChange={(e) => handleAssetTradingDetailChange('useOfProceeds', e.target.value)}
                                    />
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Button className="me-2" variant="primary">
                                                <span className="btn-icon-start text-info">
                                                    <i className="fa fa-link color-info" />
                                                </span>
                                                Add Link
                                            </Button>
                                        </Col>
                                        <Col md={6} className="text-end">
                                            <Button variant="success">
                                                <span className="btn-icon-start text-success">
                                                    <i className="fa fa-upload color-success" />
                                                </span>
                                                Upload File
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                hidden
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>F. Describe whether any paid promoters (i.e., persons paid to promote the sale of tokens for a fee) were used, how they were compensated (e.g., flat fee or percentage of sales) and what services they provided.</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter Comments or Observations"
                                        value={assetTradingDetails.paidPromoters}
                                        onChange={(e) => handleAssetTradingDetailChange('paidPromoters', e.target.value)}
                                    />
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Button className="me-2" variant="primary">
                                                <span className="btn-icon-start text-info">
                                                    <i className="fa fa-link color-info" />
                                                </span>
                                                Add Link
                                            </Button>
                                        </Col>
                                        <Col md={6} className="text-end">
                                            <Button variant="success">
                                                <span className="btn-icon-start text-success">
                                                    <i className="fa fa-upload color-success" />
                                                </span>
                                                Upload File
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                hidden
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                )}

                <Row className="mb-3 mt-3">
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Regular Status</Form.Label>
                        <Card.Text className="m-0 subtitle text-muted mb-1">
                            Provide any third party or internal legal opinion, memorandum or analysis (if available) on the regulatory status of the token/ network, including under U.S. federal, state or foreign Securities laws and other financial product-related laws relating to the token.
                        </Card.Text>
                        <>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Regular Status"
                                value={regularStatus}
                                onChange={(e) => setRegularStatus(e.target.value)}
                                className="mt-3"
                            />
                            <Row className="mt-3">
                                <Col md={6}>
                                    <Button className="me-2" variant="primary">
                                        <span className="btn-icon-start text-info">
                                            <i className="fa fa-link color-info" />
                                        </span>
                                        Add Link
                                    </Button>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button variant="success">
                                        <span className="btn-icon-start text-success">
                                            <i className="fa fa-upload color-success" />
                                        </span>
                                        Upload File
                                    </Button>
                                    <Form.Control
                                        type="file"
                                        hidden
                                    />
                                </Col>
                            </Row>
                        </>
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Securities Registration</Form.Label>
                        <Card.Text className="m-0 subtitle text-muted mb-1">
                            Have you filed any registration statement or securities law-related notices (e.g., Form D) with respect to offers and sales of the token, SAFTS or similar documents? Has an exemption been claimed?
                        </Card.Text>
                        <>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Securities Registration"
                                value={securitiesRegistration}
                                onChange={(e) => setSecuritiesRegistration(e.target.value)}
                                className="mt-3"
                            />
                            <Row className="mt-3">
                                <Col md={6}>
                                    <Button className="me-2" variant="primary">
                                        <span className="btn-icon-start text-info">
                                            <i className="fa fa-link color-info" />
                                        </span>
                                        Add Link
                                    </Button>
                                </Col>
                                <Col md={6} className="text-end">
                                    <Button variant="success">
                                        <span className="btn-icon-start text-success">
                                            <i className="fa fa-upload color-success" />
                                        </span>
                                        Upload File
                                    </Button>
                                    <Form.Control
                                        type="file"
                                        hidden
                                    />
                                </Col>
                            </Row>
                        </>
                    </Form.Group>
                </Row>
                <Row className="mb-3 mt-3">
                    <Form.Group as={Col} md={12}>
                        <Form.Label>Legal Action</Form.Label>
                        <Card.Text className="m-0 subtitle text-muted mb-1">
                            Has the asset, network, organization, or any individual ever been the subject of an investigation or action by a law enforcement or regulatory body?
                        </Card.Text>
                        <div>
                            <Button variant="success" className="me-2" onClick={() => setHasLegalAction(true)}>Yes</Button>
                            <Button variant="danger" onClick={() => setHasLegalAction(false)}>No</Button>
                        </div>
                        {hasLegalAction && (
                            <Form.Group as={Col} md={12}>
                                <>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter Legal Action"
                                    value={legalActionDetails}
                                    onChange={(e) => setLegalActionDetails(e.target.value)}
                                    className="mt-3"
                                />
                                <Row className="mt-3">
                                        <Col md={6}>
                                            <Button className="me-2" variant="primary">
                                                <span className="btn-icon-start text-info">
                                                    <i className="fa fa-link color-info" />
                                                </span>
                                                Add Link
                                            </Button>
                                        </Col>
                                        <Col md={6} className="text-end">
                                            <Button variant="success">
                                                <span className="btn-icon-start text-success">
                                                    <i className="fa fa-upload color-success" />
                                                </span>
                                                Upload File
                                            </Button>
                                            <Form.Control
                                                type="file"
                                                hidden
                                            />
                                        </Col>
                                    </Row>
                                    </>
                            </Form.Group>
                        )}
                    </Form.Group>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Button className='me-2' variant="success" type="submit">
                            Continue
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default LegalTab;
