import React, { useState , useEffect} from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Dropdown,
    Nav
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import UserNav from './UserNav';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { userDepositeList } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import { formatDateTime1 } from "./apis";



// Define options for the "Items Per Page" dropdown
const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

// Define the component for Deposit History
const DepositHistory = () => {
    // State variables
    const [filter, setFilter] = useState(''); // State for filter input
    const [perPage, setPerPage] = useState(25); // State for items per page
    const [startDate, setStartDate] = useState(null); // State for start date
    const [endDate, setEndDate] = useState(null); // State for end date
    const [userPK, setUserPK] = useState('');
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const { notifyError } = useToast();
    const [depositeData, setDepositeData] = useState([]);



    // Sample data for deposit history


    useEffect(() => {
        const UserDocument = localStorage.getItem("UserDataInfoDocument");
        const ParseUserData = JSON.parse(UserDocument || '{}');

        if (ParseUserData.PK) {
            setUserPK(ParseUserData.PK);
        }
    }, []);

    // Handler for filtering input change
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    // Handler for start date change
    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    // Handler for end date change
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    // Filtered data based on search filter


    // SVG icon
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z" fill="#000000" />
            </g>
        </svg>
    );

    // Handler for changing items per page
    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    // Handler for search button click
    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };


    useEffect(() => {
        if (userPK) {
            fetchDepositeHistory(userPK);
        }
    }, [userPK]);

    const fetchDepositeHistory = async (userPK) => {
        setLoading(true);

        try {
            const data = await userDepositeList(userPK);
            if (data && data.userAmountDepositeList) {
                setDepositeData(data.userAmountDepositeList);
            } else {
                setDepositeData([]);
            }
            setHasError(false);
        } catch (error) {
            console.error("Error fetching orders:", error);
            setDepositeData([]);
            notifyError("Failed to fetch data");
            setHasError(true);

        } finally {
            setLoading(false);
        }
    };


    // JSX return
    return (
        <div className="row">
            <div className="col-xl-12">
                <UserNav />
            </div>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Deposit History</Card.Title>
                        {/* Dropdown for items per page */}
                        <div className="form-group mb-3 col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col md={6} className="d-flex align-items-center ">
                                {/* Filter input */}
                                <div >
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="d-flex align-items-center ">
                                {/* Date pickers and search button */}
                                <div className="mr-2">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                </div>
                                <div className="ms-2">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control"
                                        placeholderText="End Date"
                                    />
                                </div>
                                <button className="btn btn-primary ms-2" onClick={handleSearch}>Search</button>
                            </Col>
                        </Row>

                        {/* Table to display deposit history */}
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>User Id</th>
                                    {/* <th>Amount/Vol</th> */}
                                    <th className="text-center">Price</th>
                                    {/* <th>Total</th> */}
                                    <th className="text-center">Date&Time</th>
                                    {/* <th>Buy/Sell</th> */}
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {loading ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : hasError ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : depositeData?.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        </td>
                                    </tr>
                                ) : (depositeData.map((item, index) => (
                                    <tr key={index}>
                                        <td><strong>{index+1}</strong></td>
                                        <td className="text-center">{item.amount}</td>
                                        <td className="text-center">{formatDateTime1(item)}</td>
                                    </tr>
                                )))}
                            </tbody>
                        </Table>
                        {/* Pagination */}
                        <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                            <p className="mb-0">Showing <span> 1-4 </span> from <span> 25 </span> data </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </div>
    );
}

export default DepositHistory;
