import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllCategories, deleteCoupon } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import swal from "sweetalert";
const SERVER_LINK = process.env.REACT_APP_SERVER_LINK


const ListBrand = () => {
    const [filter, setFilter] = useState('');
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [noData, setNoData] = useState(false);

    const { notifySuccess, notifyError } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        setHasError(false);
        setNoData(false);

        fetchAllCategories()
            .then(async (res) => {
                if (res.length === 0) {
                    setNoData(true);
                } else {

                    setBrands(res);
                }
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
                setHasError(true);
                console.log(err);
            });
    }, []);

    const handleActiveToggle = async (pk, sk, currentState) => {
        const newState = !currentState; // Toggle the current state
        swal({
            title: "Are you sure?",
            text: `You are about to ${newState ? "activate" : "deactivate"} this brand.`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willConfirm) => {
                if (willConfirm) {
                    try {
                        const response = await fetch(`${SERVER_LINK}/api/v1/coupon/removeCategory`, {
                            method: "POST", // Assuming a POST request
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                PK: pk,
                                SK: sk,
                                // isLive: newState // Send the new state in the request body
                            })
                        });

                        if (response.ok) {
                            notifySuccess(`Brand ${newState ? "activated" : "deactivated"} successfully!`);

                            // Update the brand state in the list without fetching again
                            setBrands((prevBrands) =>
                                prevBrands.map((brand) =>
                                    brand.PK === pk ? { ...brand, isLive: newState } : brand
                                )
                            );
                        } else {
                            throw new Error('Failed to toggle brand status');
                        }
                    } catch (error) {
                        console.error("Failed to update brand status:", error);
                        notifyError("Failed to update brand status");
                    }
                }
            });
    };


    const handleDeleteBrand = async (item) => {
        try {
            const response = await deleteCoupon(item.PK, item.SK);
            if (response) {
                notifySuccess('Item Deleted Successfully!');
                const newBrands = brands.filter((brand) => brand.PK !== item.PK);
                setBrands(newBrands);
            }
        } catch (error) {
            notifyError('Error deleting category');
        }
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = (filter === '') ? brands : brands.filter(item =>
        item.name.toLowerCase().includes(filter.toLowerCase()) ||
        item.shortcode.toLowerCase().includes(filter.toLowerCase()) ||
        (item.image && item.image.toLowerCase().includes(filter.toLowerCase()))
    );

    const handleEditBrand = (item) => {
        navigate('/add-brand', { state: { item } });
    };

    // SVG Icons
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFFFFF" d="M3,21.5 L3.5,17.5 L14.5,6.5 C14.8978,6.10218 15.4374,5.87868 16,5.87868 C16.2786,5.87868 16.5541,5.93355 16.8111,6.04016 C17.0681,6.14678 17.303,6.30356 17.5,6.5 C17.697,6.69644 17.8538,6.93131 17.9604,7.18832 C18.067,7.44534 18.1219,7.72077 18.1219,7.99935 C18.1219,8.56196 17.8984,9.10155 17.5006,9.49935 L6.5,20.5 L2.5,21 L3,21.5 Z M5.06066,19 L14.0607,10 L17,12.9393 L8,21.9393 L5.06066,19 Z M16.5,9.5 L19.5,6.5 L17,4 L14,7 L16.5,9.5 Z" />
        </svg>
    );

    const svg2 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Brand</Card.Title>
                    <div className="form-group">
                        <div className="col-auto">
                            <Link to="/add-brand">
                                <button type="submit" className="btn btn-success">Add Brand</button>
                            </Link>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Image</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Short Code</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Name</strong>
                                </th>
                                <th className="d-none">
                                    ID
                                </th>
                                <th className="text-center">
                                    Active
                                </th>
                                <th className="text-center">
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="4" className="text-center"><Loader /></td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : noData ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (filteredData?.map(item => (
                                <tr key={item.id}>
                                    <td>
                                        <strong><img src={item.image} alt={item.name} style={{ width: '40px', height: '40px', borderRadius: '20px' }} /></strong>
                                    </td>
                                    <td className="text-center">{item.shortcode}</td>
                                    <td className="text-center">{item.name}</td>
                                    <td className="d-none">{item.id}</td>
                                    <td className="text-center">
                                        <Button
                                            variant={item.isLive ? 'success' : 'secondary'}
                                            onClick={() => handleActiveToggle(item.PK, item.SK, item.isLive)}
                                        >
                                            {item.isLive ? 'On' : 'Off'}
                                        </Button>
                                    </td>
                                    <td className="text-center">
                                        <Dropdown>
                                            <Dropdown.Toggle onClick={() => handleEditBrand(item)} variant={'success'} className="light sharp i-false">
                                                {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Toggle
                                                variant={'danger'}
                                                className="light sharp i-false ms-3"
                                                onClick={() => handleDeleteBrand(item)}
                                            >
                                                {svg2}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span> 1-4 </span> from <span> {filteredData.length} </span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default ListBrand;
