import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ThemeContext from "./context/ThemeContext";
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClientConnection/apolloClient';
import { SubscriptionProvider } from './jsx/components/TestTrading/SubscriptionContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={client}>
    <SubscriptionProvider>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeContext>
              <ToastContainer />
              <App />
          </ThemeContext>
        </BrowserRouter>
      </Provider>
    </SubscriptionProvider>
  </ApolloProvider>
);
reportWebVitals();
