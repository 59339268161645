import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import useToast from '../Toaster/Toaster';
import { createBanners } from '../../../apolloClientConnection/apolloClient';
import Select from 'react-select';
import { getImageLink } from '../StorJ/StorJFunction';


const AddBanner = () => {
  const [cards, setCards] = useState([
    { id: '1', content: 'Card 1', backgroundColor: '#ffffff8c', image: null }
  ]);
  const { notifySuccess, notifyWarning, notifyError } = useToast();

  const [digitalLotionURLs, setDigitalLotionURLs] = useState([]);
  const SERVER_LINK = process.env.REACT_APP_SERVER_LINK
  const key = process.env.REACT_APP_SECRET_PARSE_KEY


  const handleDragEnd = (result) => {
    if (!result.destination) return; // dropped outside the list
    const items = Array.from(cards);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCards(items);
  };

  const handleImageUpload = async (e, cardId) => {
    const file = e.target.files[0];
    if (!file) return;

    const card = cards.find(card => card.id === cardId);
    if (!card.selectedOption || !card.selectedOption.value) {
      notifyWarning('Please select an option before uploading an image.');
      return;
    }

    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        return { ...card, image: URL.createObjectURL(file) };
      }
      return card;
    });
    setCards(updatedCards);

    const formData = new FormData();
    formData.append('ImageUpload', file);

    try {
      const response = await fetch(`${SERVER_LINK}/api/v1/file/ImageUpload`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data) {
        const digitalLotionURL = data?.data
        try {
          const imageStream = await getImageLink(digitalLotionURL);
          if (imageStream) {
            setDigitalLotionURLs(prevURLs => [...prevURLs, { url: imageStream, selectedOption: card.selectedOption, inputURL: card.url }]);
            notifySuccess('Image uploaded successfully!');
          } else {
            notifyWarning(`Failed to upload image: ${data.message}`);
          }
        } catch (error) {
          console.error('Failed to fetch image:', error);
        }
      }

    } catch (error) {
      notifyWarning('Error uploading file!');
    }
  };




  const handleCardClick = (cardId) => {
    const fileInput = document.getElementById(`fileInput_${cardId}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleAddCard = () => {
    const newCardId = (cards.length + 1).toString();
    const newCard = {
      id: newCardId,
      content: `Card ${newCardId}`,
      backgroundColor: '#ffffff8c',
      image: null
    };
    setCards([...cards, newCard]);
  };

  const logDigitalLotionURLs = async () => {

    if (digitalLotionURLs.length === 0) {
      notifyWarning('No URLs to process.');
      return;
    }

    let successCount = 0;
    try {
      for (let item of digitalLotionURLs) {
        const { url, selectedOption } = item;
        if (!selectedOption || !selectedOption.value) {
          console.warn(`No selected option for URL: ${url}`);
          continue;
        }


        if (selectedOption.value === 'Offers') {
          // For "Offers", use the URL as the image and page, and leave the link empty.
          await createBanners(url, selectedOption.value, '');
          successCount++;
        } else if (selectedOption.value === 'link') {
          // For "Link", use the URL from the input field as the link, leave image empty.
          // Assume that the item also includes a 'url' field when 'link' is selected.
          if (item.inputURL) {  // Ensure there is a URL provided for the link
            await createBanners(url, "", item.inputURL);  // The `item.url` should be the link entered by the user.
            successCount++;
          } else {
            console.warn(`No URL provided for link option for card: ${url}`);
          }
        }
      }

      if (successCount === digitalLotionURLs.length) {
        notifySuccess('All banners added successfully!');
      } else {
        notifyWarning(`Some banners (${digitalLotionURLs.length - successCount}) could not be added`);
      }
    } catch (error) {
      console.error('Error creating banners:', error);
      notifyError('Failed to add banners!');
    }
  };




  const handleSelectChange = (selectedOption, cardId) => {
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        if (selectedOption && selectedOption.value === 'link') {
          return { ...card, selectedOption, url: '' };  // Resets URL if "link" is selected
        } else {
          return { ...card, selectedOption };  // Maintains current state for other options
        }
      }
      return card;
    });
    setCards(updatedCards);
  };

  const handleUrlInputChange = (e, cardId) => {
    const { value } = e.target;
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        return { ...card, url: value };
      } else {
        return card;
      }
    });
    setCards(updatedCards);
  };

  const handleReset = () => {
    // Reset cards to initial state
    setCards([
      { id: '1', content: 'Card 1', backgroundColor: '#ffffff8c', image: null, selectedOption: null, url: '' }
    ]);
    // Clear digital lotion URLs
    setDigitalLotionURLs([]);
    // Optionally, clear any other state or perform additional cleanup
    notifySuccess('Reset successful!');
  };


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>Add Banner</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                {cards.map((card, index) => (
                  <Col lg={6} key={card.id}>
                    <Droppable droppableId={card.id}>
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <Draggable draggableId={card.id} index={index}>
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <Card style={{ backgroundColor: card.backgroundColor, height: '300px', borderRadius: '10px', cursor: 'pointer' }} onClick={() => card.selectedOption ? handleCardClick(card.id) : notifyWarning('Please select an option first.')}>
                                  <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 0 }}>
                                    {card.image && (
                                      <img src={card.image} alt={`Image ${card.id}`} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
                                    )}
                                    {!card.image && (
                                      <React.Fragment>
                                        <input type="file" style={{ display: 'none' }} onChange={(e) => card.selectedOption ? handleImageUpload(e, card.id) : notifyWarning('Please select an option first.')} id={`fileInput_${card.id}`} />
                                        <label htmlFor={`fileInput_${card.id}`} style={{ fontSize: '3rem', cursor: 'pointer' }}>Img {index + 1}</label>
                                      </React.Fragment>
                                    )}
                                  </Card.Body>
                                </Card>
                                <Row className='mb-5'>
                                  <Col lg={12}>
                                    <Select
                                      className="custom-react-select mb-2"
                                      value={card.selectedOption}
                                      onChange={(selectedOption) => handleSelectChange(selectedOption, card.id)}
                                      options={[
                                        { value: 'Offers', label: 'Offers' },
                                        { value: 'link', label: 'Link' }
                                      ]}
                                    />
                                    {card.selectedOption && card.selectedOption.value === 'link' && (
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter URL"
                                        value={card.url}
                                        onChange={(e) => handleUrlInputChange(e, card.id)}
                                      />
                                    )}
                                  </Col>

                                </Row>
                              </div>
                            )}
                          </Draggable>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <Button variant="success" className='me-2' onClick={() => logDigitalLotionURLs()}>
                    <span className="btn-icon-start text-success">
                      <i className="fa fa-upload color-success" />
                    </span>
                    Upload
                  </Button>
                  <Button className="me-2" variant="info" onClick={handleAddCard}>
                    <span className="btn-icon-start text-info">
                      <i className="fa fa-plus color-info" />
                    </span>
                    Add Banner Card
                  </Button>
                  <Button className="me-2" variant="primary" onClick={handleReset}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </DragDropContext>
  );
};

export default AddBanner;
