import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import useToast from '../Toaster/Toaster';
import { addTab } from '../../../apolloClientConnection/apolloClient';


const AddTabSubTabs = () => {
    // Set initial state for the main tab name and subtabs list
    const [mainTabName, setMainTabName] = useState('');
    const [subTabs, setSubTabs] = useState([{ id: 1, name: '' }]);
    const { notifySuccess, notifyInfo, notifyError } = useToast();

    // Function to handle adding a new subtab
    const handleAddSubTab = () => {
        const newSubTab = {
            id: subTabs.length + 1,
            name: ''
        };
        setSubTabs([...subTabs, newSubTab]);
    };

    // Function to update the name of a specific subtab
    const handleSubTabNameChange = (id, newName) => {
        const updatedSubTabs = subTabs.map(subTab =>
            subTab.id === id ? { ...subTab, name: newName } : subTab
        );
        setSubTabs(updatedSubTabs);
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        const Tabs = {
            tab: mainTabName,
            subtab: subTabs.map(subTab => subTab.name)
        };


        if (!mainTabName || !subTabs) {
            notifyInfo("Please fill out all fields");
            return;
        }

        addTab(Tabs)
            .then(data => {
                console.log(data);
                notifySuccess("Tabs Added Successfully!")
                handleClear()
            }).catch((error) => {
                console.log(error);
                notifyError('Network Error!');
            })
    };

    // Function to clear all inputs back to initial state
    const handleClear = () => {
        setMainTabName(''); // Reset main tab name
        setSubTabs([{ id: 1, name: '' }]); // Reset subtabs to initial state
    };

    return (
        <Row>
            <Col lg={8}>
                <Card>
                    <Card.Header as="h5">Add Main Tab and Subtabs</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} className="mb-3" controlId="mainTabName">
                                <Form.Label column sm="2">Main Tab Name</Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter main tab name"
                                        value={mainTabName}
                                        onChange={(e) => setMainTabName(e.target.value)}
                                        autoComplete='off'
                                    />
                                </Col>
                            </Form.Group>
                            {subTabs.map((subTab, index) => (
                                <Form.Group as={Row} className="mb-3" key={subTab.id} controlId={`subTabName-${index}`}>
                                    <Form.Label column sm="2">{`Subtab ${index + 1}`}</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="text"
                                            placeholder={`Enter subtab ${index + 1} name`}
                                            value={subTab.name}
                                            onChange={(e) => handleSubTabNameChange(subTab.id, e.target.value)}
                                        />
                                    </Col>
                                </Form.Group>
                            ))}
                            <div className="d-flex justify-content-between">
                                <Button variant="primary" onClick={handleAddSubTab}>Add More Subtabs</Button>
                                <div>
                                    <Button variant="success" type="submit" className="me-2">Submit</Button>
                                    <Button variant="danger" onClick={handleClear}>Clear</Button>
                                </div>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default AddTabSubTabs;
