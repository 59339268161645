import { ApolloClient, InMemoryCache, gql, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
const SERVER_LINK = process.env.REACT_APP_SERVER_LINK
const SUBSCRIPTION_LINK = process.env.REACT_APP_SUBSCRIPTION_LINK

// HTTP link for queries and mutations
const httpLink = new HttpLink({
  uri: `${SERVER_LINK}/graphql`, // Your GraphQL HTTP endpoint
});

// Authentication middleware link
const authLink = setContext((_, { headers }) => {
  // Retrieve the token from storage (localStorage or another method)
  const token = localStorage.getItem('authToken');

  // Add the token to the headers
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});

// Combine authLink with httpLink
const enhancedHttpLink = authLink.concat(httpLink);

// WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(createClient({
  url: `${SUBSCRIPTION_LINK}/graphql`,
  connectionParams: {
    authToken: localStorage.getItem('authToken'), // Assuming you store the token the same way
  },
  reconnect: true,
}));


// Split connections based on operation type
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink, // Operations requiring subscriptions use this link
  enhancedHttpLink  // Other operations (queries and mutations) use this link
);

// Initialize Apollo Client with the split link and in-memory cache
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});


// GraphQL mutation for admin login
const ADMIN_LOGIN_MUTATION = gql`
  mutation AdminLogin($userName: String!, $password: String!, $password_2: String! , $ipAddress: String!, $role: String) {
    Adminlogin(userName: $userName, password: $password, password_2: $password_2, ipAddress: $ipAddress, role: $role) {
      userName
      role
      accessToken
      tamplate
      LogoutTime
      PK
      SK
    }
  }
`;



// GraphQL mutation for adding a trade
const ADD_TRADE_MUTATION = gql`
mutation addTrade($type: String!,$shareName:String!,$coinPairWith: String!, $quantity: Float!, $price: Float!) {
  addTrade(type: $type,shareName:$shareName,coinPairWith:$coinPairWith, quantity: $quantity, price: $price) {
    buyOrders {
      type
      shareName
      coinPairWith
      price
      quantity
      userId
      serialNo
      createdAt
      PK
      SK
      orderId
      placeOrderId
      buyerUserId
      sellerUserId
  }
  tradeHistory {
      type
      shareName
      coinPairWith
      price
      quantity
      userId
      serialNo
      createdAt
      PK
      SK
      orderId
      placeOrderId
      buyerUserId
      sellerUserId
  }
  }
}
`;

// GraphQL query for fetching trade orders
const TRADE_ORDERS_QUERY = gql`
  query tradeOrders($shareName: String!, $coinPairWith: String!, $startedAt: String, $endAt: String) {
    tradeOrders(shareName: $shareName, coinPairWith: $coinPairWith, startedAt: $startedAt, endAt: $endAt ) {
      buyOrders {
        type
        shareName
        coinPairWith
        price
        quantity
        createdAt
        PK
        SK
        orderId
        placeOrderId   
        tradingType
        totalQuantity
        orderType
      }
      sellOrders {
        type
        shareName
        coinPairWith
        price
        quantity
        createdAt
        PK
        SK
        orderId
        placeOrderId
        tradingType
        totalQuantity
        orderType
      }
      tradeHistory {
        type
        shareName
        coinPairWith
        price
        quantity
        createdAt
        PK
        SK
        buyerUserId
        sellerUserId
        orderExcutedType
        totalSellerAmount      
        totalBuyerAmount
        customDate
        orderType
         sellCharges {
                brokarage
                GST
                TDS
            }
            buyCharges {
                brokarage
                GST
                TDS
            }
      }
 
    }
  }
`;

// GraphQL mutation for counting market trade value
const COUNT_MARKET_TRADE_VALUE_MUTATION = gql`
  mutation countMarketTradeValue($changes: String!, $amount: Float!, $tradeType: String!, $shareName: String!, $coinPairWith: String!) {
    countMarketTradeValue(changes: $changes, amount: $amount, tradeType: $tradeType, shareName: $shareName, coinPairWith: $coinPairWith)
  }
`;


const ACTIVE_MARKET_TRADE_MUTATION = gql`
  mutation activateMarketTrade($changes: String!, $tradeType: String!, $amount: Float!, $shareName: String!, $coinPairWith: String!) {
    activateMarketTrade(changes: $changes, tradeType: $tradeType, amount: $amount, shareName: $shareName, coinPairWith: $coinPairWith)
  }
`;


// GraphQL mutation for adding tabs
const ADD_TABS_MUTATION = gql`
  mutation AddTabs($Tabs: TabInput!) {
    AddTabs(Tabs: $Tabs) {
      message
    }
  }
`;


// New GraphQL mutation for adding todos
const ADD_TODO_MUTATION = gql`
  mutation addTodo($text: String!) {
    addTodo(text: $text) {
      id
      text
    }
  }
`;

export const TODO_ADDED_SUBSCRIPTION = gql`
subscription onTodoAdded {
  todoAdded {
    id
    text
  }
}
`;

// Define the Getalltabs query
const GET_ALL_TABS_QUERY = gql`
  query Getalltabs {
    getalltabs {
      PK
      SK
      Tabs
    }
  }
`;

// Define the GetAllAdmin query using gql
const GET_ALL_ADMIN_QUERY = gql`
  query GetAllAdmin {
    GetAllAdmin {
      PK
      SK
      userName
      full_name
    }
  }
`;

const CHANGE_ADMIN_PASSWORD_MUTATION = gql`
  mutation ChangeAdminPassword($PK: String!, $SK: String!, $newPassword: String!) {
    ChangeAdminPassword(PK: $PK, SK: $SK, newPassword: $newPassword)
  }
`;

const ADD_TEMPLATE_MUTATION = gql`
mutation AddTemplate($template: String, $tabs: [SubTabInput!]!, $role: String) {
  AddTamplate(tamplate: $template, Tabs: $tabs, Role: $role) {
    message
  }
}
`;

const REGISTER_USER_MUTATION = gql`
mutation Register($userName: String, $fullName: String, $password: String, $password2: String, $templatePK: String!, $templateSK: String!, $type:String, $role: String) {
  register(
    userName: $userName
    full_name: $fullName
    password: $password
    password_2: $password2
    Tamplate: { PK: $templatePK, SK: $templateSK }
    type: $type
    role: $role
  ) {
    message
  }
}
`;

const GET_ALL_TEMPLATES_QUERY = gql`
  query Getalltamplate {
    Getalltamplate {
      PK
      SK
      tamplate
      Role
    }
  }
`;

const GET_TABS_BY_TEMPLATE_QUERY = gql`
  query GetTabsByTamplate($PK: String, $SK: String) {
    GetTabsByTamplate(PK: $PK, SK: $SK) {
        message
        data {
            PK
            SK
            Tabs
        }
    }
  }
`;

const ADD_CATEGORY_MUTATION = gql`
  mutation AddCategory($name: String!, $shortcode: String!, $description: String!, $image: String!, $PK: String, $SK: String) {
    addCategory(input: { name: $name, shortcode: $shortcode, description: $description, image: $image, PK: $PK, SK: $SK })
  }
`;

// Add this near your other gql query definitions
const GET_ALL_CATEGORIES_QUERY = gql`
  query GetAllCategories {
    getAllCategories {
      PK
      SK
      name
      shortcode
      image
      description
      isLive
    }
  }
`;

const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`;


// Define the GraphQL mutation for adding a coupon
const ADD_OR_UPDATE_COUPON_MUTATION = gql`
  mutation AddOrUpdateCoupon(
    $PK: String,
    $SK: String,
    $category_name: String!,
    $coupon_code: Float!,
    $amount: Float!,
    $isClaimed: Boolean!,
    $image_link: String,
    $CouponCount: Float!,
    $CouponEexpiryTime: String,
    $CouponMRP: Float!,
    $description: String
  ) {
    addCoupon(
      input: {
        PK: $PK,
        SK: $SK,
        category_name: $category_name,
        coupon_code: $coupon_code,
        amount: $amount,
        isClaimed: $isClaimed,
        image_link: $image_link,
        CouponCount: $CouponCount,
        CouponEexpiryTime: $CouponEexpiryTime,
        CouponMRP: $CouponMRP,
        description: $description
      }
    )
  }
`;




const DELETE_COUPON_MUTATION = gql`
    mutation DeleteFromCouponTbl($PK: String!, $SK: String!) {
        deleteFromCouponTbl(PK: $PK, SK: $SK)
    }
`;



export const UPDATE_COUPON_MUTATION = gql`
  mutation UpdateCoupon($id: ID!, $category_name: String, $coupon_code: Float, $amount: Float, $status: Int) {
    updateCoupon(input: { id: $id, category_name: $category_name, coupon_code: $coupon_code, amount: $amount, status: $status }) {
      id
    }
  }
`;

// Define the GetCategorieDetail query
export const GET_CATEGORY_DETAIL_QUERY = gql`
  query GetCategorieDetail($id: ID!) {
    getCategorieDetail(id: $id) {
      id
      name
      shortcode
      description
      image
      created_at
      updated_at
      deleted_at
    }
  }
`;

// Define the GraphQL mutation for updating a category
const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateCategory($ID: ID!, $name: String!, $shortcode: String!, $description: String!, $image: String!) {
    updateCategory(input: { id: $ID, name: $name, shortcode: $shortcode, description: $description, image: $image }) {
      id
      name
      shortcode
      description
      image
      created_at
      updated_at
      deleted_at
    }
  }
`;


const GET_USER_BY_PAGINATION_QUERY = gql`
query GetUserByPagination($limit: Int, $lastKey: LastKeyInput, $SearchObj: SearchInput) {
  GetUserByPagination(limit: $limit, lastKey: $lastKey, SearchObj: $SearchObj) {
    items {
      PK
      SK
      phone
      email
      full_name
      pancard
      aadhar
      updatedAt
      isKYCPending
      accessToken
      Tabs
      customCreatedAt
      createdAt
      type
      serialNo
      isBlocked
    }
    lastEvaluatedKey {
      PK
      SK
      createdAt
      type
    }
  }
}
`;

// Define the GraphQL query for fetching all coupons
const GET_ALL_COUPONS_QUERY = gql`
  query GetAllcoupons($limit: Int, $lastKey: LastKeyInput, ) {
    getAllcoupons(limit: $limit, lastKey: $lastKey) {
      items {
        PK
            SK
            category_name
            coupon_code
            amount
            image_link
            CouponEexpiryTime
            CouponMRP
            description
            isClaimed
            createdAt
            updatedAt
      }
       lastEvaluatedKey {
            SK
            PK
            createdAt
            type
        }
    }
  }
`;



const GET_USER_PROFILE_QUERY = gql`
query GetUserProfile($PK:  String, $SK: String) {
  GetUserProfile(PK: $PK, SK: $SK) {
    data
    message
  }
}
`;

const GET_ALL_USER_APPROVED_BY_ADMIN_QUERY = gql`
  query GetAllUserApprovedByAdmin($limit: Int, $lastKey: LastKeyInputApproved) {
    getAllUserApprovedByAdmin (limit: $limit, lastKey: $lastKey){
      items {
        PK
        SK
        phone
        email
        full_name
        pancard
        aadhar
        updatedAt
        isKYCPending
        accessToken
        Tabs
        customCreatedAt
        createdAt
        serialNo
      }
      lastEvaluatedKey {
        PK
        SK
        createdAt
        type
      }
    }
  }
`;

const GET_ALL_USER_PARTIAL_FILLED_QUERY = gql`
query GetAllUserSemiApproved($limit: Int, $lastKey: LastKeyInput) {
  getAllUserSemiApproved (limit: $limit, lastKey: $lastKey){
      items {
          PK
          SK
          phone
          email
          full_name
          pancard
          aadhar
          updatedAt
          isKYCPending
          accessToken
          Tabs
          customCreatedAt
          createdAt
      }
      lastEvaluatedKey {
        PK
        SK
        createdAt
        type
      }
  }
}
`;

const GET_ALL_USER_AUTO_APPROVED_QUERY = gql`
query GetAllUserAutoApproved($limit: Int, $lastKey: LastKeyInput) {
  getAllUserAutoApproved(limit: $limit, lastKey: $lastKey) {
      items {
          PK
          SK
          phone
          email
          full_name
          pancard
          aadhar
          updatedAt
          isKYCPending
          accessToken
          Tabs
          customCreatedAt
          createdAt
      }
      lastEvaluatedKey {
        PK
        SK
        createdAt
        type
      }
  }
}
`;

// Define the mutation for deleting an item
const DELETE_ITEM_MUTATION = gql`
  mutation DeleteItem($modelName: String, $PK: String!, $SK: String!) {
    DeleteItem(modelName: $modelName, PK: $PK, SK: $SK)
  }
`;


const CREATE_WALLET_MUTATION = gql`
  mutation CreateWallet($input: CoinInput!) {
    createWallet(input: $input) {
      SK
        coinCode
        coinName
        coinPairWith
        lastTradePrice
        coinPrice
        price
        totalSupply
        isLiveTrade
        priceAmount
    }
  }
`;


const ADD_BLOG_POST_MUTATION = gql`
  mutation AddBlogPost($title: String, $description: String, $Image: String) {
    addBlogPost(title: $title, description: $description, Image: $Image) {
      title
      Image
      description
      PK
      SK
    }
  }
`;


const ADD_WEB_BLOG_POST_MUTATION = gql`
  mutation AddBlogPostWeb($title: String, $description: String, $Image: String) {
    addBlogPostWeb(title: $title, description: $description, Image: $Image) {
      title
      Image
      description
      PK
      SK
    }
  }
`;


const GET_ALL_BLOGS_QUERY = gql`
  query GetAllBlogs($limit: Int, $lastKey: LastKeyInput) {
    GetAllBlogs(limit: $limit, lastKey: $lastKey)
  }
`;

const GET_ALL_WEB_BLOGS_QUERY = gql`
  query GetAllWebBlogs($limit: Int, $lastKey: LastKeyInput) {
    GetAllWebBlogs(limit: $limit, lastKey: $lastKey){
     items {
            PK
            SK
            title
            description
            Image
            createdAt
        }
    }
  }
`;

const BLOG_DETAILS_QUERY = gql`
  query BlogDetails($PK: String!, $SK: String!) {
    blogDetails(PK: $PK, SK: $SK) {
      title
      Image
      description
      PK
      SK
    }
  }
`;


// GraphQL mutation for creating banners
const CREATE_BANNERS_MUTATION = gql`
  mutation CreateBanners($image: String!, $page: String!, $link: String!) {
    createBanners(image: $image, page: $page, link: $link) {
      image
      page
      link
    }
  }
`;

const BANNERS_LIST = gql`
  query Banners {
    banners {
      image
      order
      page
      link
      PK
      SK
    }
  }
`;

const HEADER_TEXT = gql`
mutation DynamiInput ($stringInput: String! $discription: String!, $type: String! $maxAmount: Float, $minAmount: Float){
  DynamiInput(StringInput: $stringInput, Discription: $discription, type: $type, maxAmount: $maxAmount, minAmount: $minAmount)
}
`
const HEADER_TEXT_LIST = gql`
query GetDynamicStringList($type: String!) {
  GetDynamicStringList(type: $type){
      StringInput
      Discription
      IsdynamicString
      PK
      SK
      minAmount
      maxAmount
  }
}
`

const UPDATE_QUERY = gql`
mutation UpdateItemByPkandSK($PK: String!, $SK: String!, $updateData: JSON!) {
  UpdateItemByPkandSK(PK: $PK, SK: $SK, updateData: $updateData) {
    data
    message
  }
}
`;


const GET_ALL_COMPLAIN_QUERY = gql`
  query GetAllComplain($limit: Int!, $lastKey: LastKeyInput) {
    GetAllComplain(limit: $limit, lastKey: $lastKey) {
      items {
        PK
        SK
        AdminReply
        TicketId
        UserPk
        Title
        File_link
        isResolveIssue
        updatedAt
        createdAt
        userquery
        discription
      }
      lastEvaluatedKey {
        SK
        PK
        createdAt
        type
      }
    }
  }
`;



const GET_HELP_SUPPORT_CHAT_QUERY = gql`
query Get_help_support_chat($ID: String!) {
  get_help_support_chat(input: { Ticket: $ID })
}
`;


const SOLVED_COMPLAIN_MUTATION = gql`
  mutation Solved_Complain($Ticket: String!) {
    Solved_Complain(input: { Ticket: $Ticket })
  }
`;

const ADD_CHAT_MUTATION = gql`
  mutation ChatNew($ticketId: String!, $text: String!, $chatBy: String!) {
    ChatNew(ticketId: $ticketId, text: $text, chatBy: $chatBy) 
  }
`;

const DELET_COMPLAIN_TICKET_MUTATION = gql`
  mutation DeleteComplainByTicket($ticket: String!) {
    DeleteComplainByTicket(input: { Ticket: $ticket })
  }
`;

// GraphQL mutation for changing password
const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($userName: String, $password: String, $password_2: String, $newPassword: String,  $newPassword_2: String) {
    changePassword(userName: $userName, password: $password, password_2: $password_2, newPassword: $newPassword, newPassword_2: $newPassword_2)
  }
`;

//GraphQL query for getting wallet details
const GET_WALLET_DETAILS_QUERY = gql`
query GetWalletDetails($coinPairWith: String!, $coinCode: String!, $userPK: String) {
  getWalletDetails(coinPairWith: $coinPairWith, coinCode: $coinCode, userPK: $userPK) {
    remaining_coin {
      SK
      coinCode
      coinName
      coinPairWith
      lastTradePrice
      coinPrice
      price
      totalSupply
      isLiveTrade
      priceAmount
      withdrawalPrice
    }
    remaining_price {
      SK
      price
      withdrawalPrice
    }
    pending_price {
      SK
      price
      withdrawalPrice
    }
  }
}
`;



//Query for add charges
const ADD_APP_CHARGES_MUTATION = gql`
  mutation AddAppChargesPlans($brokerageFee: Float!, $TDS: Float!, $GST: Float!) {
    addAppChargesPlans(brokerageFee: $brokerageFee, TDS: $TDS, GST: $GST){
      success
    }
  }
`;

//Query for List Charges
const GET_ALL_CHARGESL_LIST_QUERY = gql`
query AppChargesPlansList {
  appChargesPlansList {
      PK
      SK
      type
      brokerageFee
      TDS
      GST
      createdAt
  }
}
`;

//Query For WithDrawl Request
const GET_USER_WITH_DRAWL_REQUEST = gql`
query UserWithdrawalRequestList ($userPK: String){
  userWithdrawalRequestList (userPK: $userPK){
      SK
      withdrawalRequestType
      PK
      priceAmount
      createdAt
      customCreatedAt
      requestedAt
  }
}
`;

//Query For Complete User WithdrawalRequest
const USER_WITHDRAWL_REQUEST = gql`
  mutation CompleteUserWithdrawalRequest($withdrawalRequestSK: String!, $requestType: String!, $withdrawalRequestPK: String!) {
    completeUserWithdrawalRequest(withdrawalRequestSK: $withdrawalRequestSK, requestType: $requestType, withdrawalRequestPK: $withdrawalRequestPK)
  }
`;



const GET_BANK_DETAILS = gql`
query GetBankAccount($userPK: String, $isAllAccounts: Boolean) {
  getBankAccount(userPK: $userPK, isAllAccounts: $isAllAccounts) {
      client_id
      account_exists
      upi_id
      full_name
      PK
      SK
      account
      ifscCode
      bankName
      ifsc_details {
          ifsc
          bank
          bank_code
          bank_name
          branch
          centre
          district
          state
          city
          address
          contact
      }
  }
}
`

const USER_ORDERS_QUERY = gql`
query UserOrders($shareName: String!, $coinPairWith: String!, $userPK: String!){
  userOrders(shareName: $shareName, coinPairWith: $coinPairWith, userPK: $userPK) {
      buyOrders {
            type
            shareName
            coinPairWith
            price
            quantity
            userId
            serialNo
            createdAt
            PK
            SK
            orderId
            placeOrderId
            buyerUserId
            sellerUserId
            tradingType
            orderType
            beforeDeleteType
            totalQuantity
            quantityPer
            orderExcutedType
            sellCharges {
                brokarage
                GST
                TDS
            }
            buyCharges {
                brokarage
                GST
                TDS
            }
        }
      sellOrders {
            type
            shareName
            coinPairWith
            price
            quantity
            userId
            serialNo
            createdAt
            PK
            SK
            orderId
            placeOrderId
            buyerUserId
            sellerUserId
            tradingType
            orderType
            beforeDeleteType
            quantityPer
            orderExcutedType
            totalQuantity
            buyCharges {
                brokarage
                GST
                TDS
            }
            sellCharges {
                brokarage
                GST
                TDS
            }
        }
        tradeHistory {
            type
            shareName
            coinPairWith
            price
            quantity
            userId
            serialNo
            createdAt
            PK
            SK
            orderId
            placeOrderId
            buyerUserId
            sellerUserId
            tradingType
            orderType
            beforeDeleteType
            quantityPer
            customDate
            orderExcutedType
            totalQuantity
            sellCharges {
                brokarage
                GST
                TDS
            }
            buyCharges {
                brokarage
                GST
                TDS
            }
        }
  }
}
`

const CANCEL_ORDER_QUERY = gql`
query DeleteTradeOrderList ($userPK: String, $forUser: String){
  deleteTradeOrderList(userPK: $userPK, forUser: $forUser){
      type
      shareName
      coinPairWith
      price
      quantity
      userId
      serialNo
      createdAt
      PK
      SK
      orderId
      placeOrderId
      buyerUserId
      sellerUserId
      tradingType
      orderType
      beforeDeleteType
      quantityPer
      brokarage
      totalQuantity
      date
  }
}
`

const ADD_VERSION_QUERY = gql`
mutation AppSetting($AndroidVersion: String, $IOSVersion: String) {
  appSetting(AndroidVersion: $AndroidVersion, IOSVersion: $IOSVersion) {
    AndroidVersion
    IOSVersion
  }
}
`;


const LIST_VERSION_QUERY = gql`
query AppSettingList {
  appSettingList {
    AndroidVersion
    IOSVersion
    WEBVersion
    createdAt
    PK
    isActiveVersion
  }
}
`
const ACTIV_DEACTIVE_VERSION_QUERY = gql`
mutation ActiveDeactiveVersion ($versionPK : String!, $isActiveVersion: Boolean!){
  activeDeactiveVersion(versionPK: $versionPK, isActiveVersion: $isActiveVersion)
}
`

const FREEZ_USER_QUERY = gql`
  mutation FreezUser($PK : String!, $SK: String!) {
    FreezUser(PK: $PK, SK: $SK)
}
`

const ACTIVE_USER_QUERY = gql`
mutation ActiveUser($PK : String!, $SK: String!) {
  ActiveUser(PK: $PK, SK: $SK)
}
`

const INACTIVE_USER_QUERY = gql`
mutation InactiveUser($PK : String!, $SK: String!) {
  InactiveUser(PK: $PK, SK: $SK)
}
`

const USER_AMOUNT_DEPOSITE_LIST_QUERY = gql`
query UserAmountDepositeList($userPK: String) {
  userAmountDepositeList (userPK: $userPK){
      PK
      SK
      type
      amount
      createdAt
      customCreatedAt
  }
}
`


const ADD_ICO_COUPON = gql`
mutation AddICOCoupon($couponName: String!, $couponUsesLimit: String!, $couponExpirydate: String!, $usesLocation: String!, $action: String!, $PK: String, $SK: String) {
    addICOCoupon(couponName: $couponName, couponUsesLimit: $couponUsesLimit, couponExpirydate: $couponExpirydate, usesLocation: $usesLocation, action: $action, PK: $PK, SK: $SK) {
        success
    }
}
`;

const ICO_COUPON_LIST = gql`
query IcoCouponList {
  icoCouponList {
      couponName
      couponUsesLimit
      couponExpirydate
      usesLocation
      action
      createdAt
      updatedAt
      SK
      PK
  }
}
`

const ICO_LISTING = gql`
query GetAllICOAmount {
    GetAllICOAmount {
        PK
        ICO_AMOUNT
        UserId
        phone
        email
        SK
        full_name 
        createdAt
    }
}
`

const SEND_NOTIFICATION_ONE_USER_MUTATION = gql`
  mutation SendNotificationOneUser($messageTitle: String, $messageBody: String, $PK: String, $SK: String) {
    sendNotificationOneUser(
      input: { 
        messageTitle: $messageTitle, 
        messageBody: $messageBody, 
        PK: $PK, 
        SK: $SK 
      }
    )
  }
`;

const DELETE_ITEM_BY_TYPE = gql`
mutation DeleteAllItem($type: String) {
  DeleteAllItem(Type: $type)
}
`


const DELETE_ALL_COUPON_LIST = gql`
mutation DeleteAllCoupons {
  deleteAllCoupons
}
`


const LAST_TRADE_PRICE = gql`
query Last_24Hours_tradePriceCal($shareName: String!, $coinPairWith: String!) {
  last_24Hours_tradePriceCal(shareName: $shareName, coinPairWith: $coinPairWith) {
    lastTradePrice
    totalVolum
    averagePrice
    highestPrice
    lowestPrice
    lastTradeQuantity
    lastTradeTime
  }
}
`
const ADMIN_WALLET_QUERY = gql`
query GetAppWallets {
    getAppWallets {
        SK
        coinCode
        coinPairWith
        lastTradePrice
        coinPrice
        price
        totalSupply
        isLiveTrade
        priceAmount
        withdrawalPrice
        voucherAmount
        tdsAmount
        gstAmount
        brokerageAmount
    }
}
`


const GET_ALL_ADMIN_NOTIFY = gql`
  query GetAllAdminNotify($limit: Int!, $lastKey: LastKeyInput) {
    GetAllAdminNotify(limit: $limit, lastKey: $lastKey) {
      Notifys {
        PK
        SK
        Notification_message
        createdAt
      }
      lastEvaluatedKey {
        SK
        PK
        createdAt
        type
      }
    }
  }
`;

const GET_ALL_REFERRAL = gql`
  query GetAllReferrel {
    getAllReferrel {
      referrals {
        PK
        SK
        name
        RefferalAmount
        RewardType
        description
        IsActive
        createdAt
        updatedAt
      }
    }
  }
`;

const ADD_REFERRAL = gql`
  mutation AddRefferal($input: RefferalInput!) {
    addRefferal(input: $input)
  }
`;


const EXECUTED_BREAK_DATA_QUERY = gql`
query ExecutedOrderId($pendingOrderId: String!) {
    executedOrderId(pendingOrderId: $pendingOrderId) {
        type
        shareName
        coinPairWith
        price
        quantity
        userId
        serialNo
        createdAt
        PK
        SK
        orderId
        placeOrderId
        buyerUserId
        sellerUserId
        tradingType
        orderType
        beforeDeleteType
        quantityPer
        totalQuantity
        brokarage
        GST
        TDS
        orderExecutedType
        orderExcutedType
    }
}
`;

const GET_ALL_USER_AIRDROP = gql`
query GetUserAirDrop ($limit: Int, $lastKey: LastKeyInput, $SearchObj: SearchInput){
    GetUserAirDrop(limit: $limit, lastKey: $lastKey, SearchObj: $SearchObj) {
        items {
            tokenQty
            status
            date
            coinCode
            userName
            receiverMobileNo
            coinName
        }
             lastEvaluatedKey {
            SK
            PK
            createdAt
            type
        }
    }
}
`
const GET_USER_AIRDROP = gql`
query GetAirDropOneUser ($PK: String!){
  GetAirDropOneUser(PK: $PK) {
      senderMobileNo
      senderUserName
      receiverMobileNo
      userName
      tokenQty
      date
      status
      coinName
  }
}
  `


const CREATE_MERCHANT_VOUCHER = gql`
  mutation CreateMerchantVoucher($brandPK: String!, $vouchers: [VoucherInput]!) {
    createMerchantVoucher(brandPK: $brandPK, vouchers: $vouchers)
  }
`;

const GET_ALL_MERCHENT_VOUCHER = gql`
query GetAllMerchatVoucher {
  GetAllMerchatVoucher {
      brandPK
      PK
      SK
      type
      createdAt
      updatedAt
      vouchers {
          amount
          discount
      }
  }
}
`;

const KYC_APPROVED_BY_ADMIN = gql`
mutation KYCApprovedByAdmin ($PK: String!, $SK: String!, $PanNumber: String, $AadharNumber: String){
    KYCApprovedByAdmin(PK: $PK, SK: $SK, PanNumber: $PanNumber, AadharNumber: $AadharNumber) {
        data
        message
    }
}
`
const GET_WEB_COMPLAIN = gql`
query GetAllContactUser ($limit: Float,  $lastKey: LastKeyInput){
    getAllContactUser(limit: $limit,  lastKey: $lastKey) {
        items {
            PK
            SK
            FirstName
            LastName
            Massage
            phone
            email
        }
        lastEvaluatedKey {
            SK
            PK
            createdAt
            type
        }
    }
}
`

const PURCHASED_VOUCHER_LIST = gql`
query PurchasedCouponVoucherList {
    purchasedCouponVoucherList {
        items {
            PK
            SK
            CouponMRP
            CouponEexpiryTime
            updatedAt
            createdAt
            amount
            category_name
            coupon_code
        }
        lastEvaluatedKey {
            SK
            PK
            createdAt
            type
        }
    }
}
`

const ADD_RECHARGE = gql`
mutation AddPriceToWallet($priceAmount: Float!, $customerBankAccountNumber: Float!, $phone: Float) {
    addPriceToWallet(priceAmount: $priceAmount ,customerBankAccountNumber: $customerBankAccountNumber, phone: $phone) {
        success
    }
}
`


const APP_CHARGES_HISTORY = gql`
query AppChargesHistoryOnTrade ($startedAt: String, $endAt: String){
    appChargesHistoryOnTrade (startedAt: $startedAt, endAt: $endAt){
        originalAmount
        tradeOrderPK
        updatedAt
        tradingFee
        SK
        createdAt
        extraAmount
        tdsDeduction
        PK
        netAmount
    }
}
`


const REVERSE_COUPOUN_VOUCHER = gql`
mutation ReverseCouponVoucher ($reverseCouponIds: [ReverseOrdersIdsInput]!){
    reverseCouponVoucher(reverseCouponIds: $reverseCouponIds)
}
`

// Function to handle adding a trade
export const addTrade = async ({ type, coinName, quantity, price }) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_TRADE_MUTATION,
      variables: { type, shareName: coinName, coinPairWith: 'INR', quantity, price },
    });


    return data;
  } catch (error) {
    throw new Error('An error occurred while adding the trade');
  }
};

// Function to handle admin login request
export const login = async (username, password, password1, ipAddress, role) => {
  try {
    const { data } = await client.mutate({
      mutation: ADMIN_LOGIN_MUTATION,
      variables: { userName: username, password, password_2: password1, ipAddress, role },
    });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

// Function to fetch trade orders
export const fetchTradeOrders = async (shareName, coinPairWith, startedAt = null, endAt = null) => {
  try {
    const variables = { shareName, coinPairWith };
    if (startedAt) variables.startedAt = startedAt;
    if (endAt) variables.endAt = endAt;

    const { data } = await client.query({
      query: TRADE_ORDERS_QUERY,
      fetchPolicy: 'network-only',
      variables,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching trade orders');
  }
};

// Function to count market trade value
export const countMarketTradeValue = async (changes, amount, tradeType, shareName, coinPairWith) => {
  try {
    const { data } = await client.mutate({
      mutation: COUNT_MARKET_TRADE_VALUE_MUTATION,
      variables: { changes, amount, tradeType, shareName, coinPairWith },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while counting market trade value');
  }
};

export const activeMarketTradeValue = async (changes, amount, tradeType, shareName, coinPairWith) => {


  try {
    const { data } = await client.mutate({
      mutation: ACTIVE_MARKET_TRADE_MUTATION,
      variables: { changes, amount, tradeType, shareName, coinPairWith },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while active market trade value');
  }
};


export const addTab = async (Tabs) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_TABS_MUTATION,
      variables: {
        Tabs: { tab: Tabs.tab, subtab: Tabs.subtab },
      },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while adding tabs');
  }
};

// New function to add todos
export const addTodo = async (Tabs) => {

  try {
    const { data } = await client.mutate({
      mutation: ADD_TODO_MUTATION,
      variables: { Tabs },
    });
    return data.AddTabs;
  } catch (error) {
    throw new Error('An error occurred while adding the todo');
  }
};

// Function to execute the Getalltabs query
export const fetchAllTabs = async () => {
  try {
    // Use the Apollo Client to execute the query
    const { data } = await client.query({
      query: GET_ALL_TABS_QUERY,
      fetchPolicy: 'network-only',
    });
    // Return the query results
    return data.getalltabs;
  } catch (error) {
    // Handle any errors that occur during the fetch
    throw new Error('An error occurred while fetching all tabs');
  }
};


// Function to fetch all admin data
export const fetchAllAdmins = async () => {
  try {
    // Use the Apollo Client to execute the query
    const { data } = await client.query({
      query: GET_ALL_ADMIN_QUERY,
      fetchPolicy: 'network-only', // Ensures the data is fetched from the server each time
    });
    // Return the query results
    return data.GetAllAdmin;
  } catch (error) {
    // Handle any errors that occur during the fetch
    throw new Error('An error occurred while fetching all admins');
  }
};

// Function to call the mutation
export const changeAdminPassword = async (PK, SK, newPassword) => {
  try {
    const { data } = await client.mutate({
      mutation: CHANGE_ADMIN_PASSWORD_MUTATION,
      variables: { PK, SK, newPassword },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while changing the admin password');
  }
};

export const addTemplate = async (templateName, tabsData, role) => {
  const variables = {
    template: templateName,
    tabs: tabsData,
    role: role
  };


  try {
    const response = await client.mutate({
      mutation: ADD_TEMPLATE_MUTATION,
      variables: variables,
    });
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const registerUser = async ({ userName, fullName, password, password2, templatePK, templateSK }) => {

  let type = 'Admin'
  let role = 'ADMIN'

  try {
    const response = await client.mutate({
      mutation: REGISTER_USER_MUTATION,
      variables: {
        userName,
        fullName,
        password,
        password2,
        templatePK,
        templateSK,
        type,
        role
      },
    });
    return response.data.register;
  } catch (error) {
    throw new Error(error.message);
  }
};


// Function to fetch all templates
export const fetchAlTlemplates = async () => {
  try {
    const { data } = await client.query({
      query: GET_ALL_TEMPLATES_QUERY,
      fetchPolicy: 'network-only', // Ensures the data is fetched from the server each time
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching all templates');
  }
};

export const fetchTabsByTemplate = async (PK, SK) => {
  try {
    const { data } = await client.query({
      query: GET_TABS_BY_TEMPLATE_QUERY,
      variables: { PK, SK },
      fetchPolicy: 'network-only', // Or other fetch policy as per your requirement
    });
    return data.GetTabsByTamplate;
  } catch (error) {
    throw new Error('An error occurred while fetching tabs by template');
  }
};

export const addCategory = async (name, shortcode, description, image, PK = null, SK = null) => {
  try {
    const variables = { name, shortcode, description, image };

    if (PK && SK) {
      variables.PK = PK;
      variables.SK = SK;
    }

    const { data } = await client.mutate({
      mutation: ADD_CATEGORY_MUTATION,
      variables,
    });

    return data.addCategory;
  } catch (error) {
    throw new Error('An error occurred while adding the category');
  }
};




// Function to update a category
export const updateCategory = async (ID, name, shortcode, description, image) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_CATEGORY_MUTATION,
      variables: { ID, name, shortcode, description, image }
    });
    return data.updateCategory;
  } catch (error) {
    throw new Error('An error occurred while updating the category');
  }
};
// Define the function to fetch all categories
export const fetchAllCategories = async () => {
  try {
    const { data } = await client.query({
      query: GET_ALL_CATEGORIES_QUERY,
      fetchPolicy: 'network-only', // Ensures the data is fetched from the server each time
    });
    return data.getAllCategories;
  } catch (error) {
    throw new Error('An error occurred while fetching all categories');
  }
};

// Function to fetch category detail
export const getCategoryDetail = async (id) => {
  try {
    const { data } = await client.query({
      query: GET_CATEGORY_DETAIL_QUERY,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    return data.getCategorieDetail;
  } catch (error) {
    throw new Error('An error occurred while fetching category detail');
  }
};

// Create a function to handle deleting a category
export const deleteCategory = async (id) => {
  try {

    // Execute the mutation using the Apollo client
    const { data } = await client.mutate({
      mutation: DELETE_CATEGORY_MUTATION,
      variables: { id: id },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while deleting the category');
  }
};

// Function to add a coupon
export const addCoupon = async (category_name, coupon_code, amount, isClaimed, image_link = null, CouponCount = null, CouponMRP = null, CouponEexpiryTime = null, description, PK = null, SK = null) => {
  try {
    const variables = { category_name, coupon_code: parseFloat(coupon_code), amount: parseFloat(amount), isClaimed, image_link, CouponCount: parseFloat(CouponCount), CouponEexpiryTime, CouponMRP: parseFloat(CouponMRP), description };
    if (PK && SK) {
      variables.PK = PK;
      variables.SK = SK;
    }

    const { data } = await client.mutate({
      mutation: ADD_OR_UPDATE_COUPON_MUTATION,
      variables,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while adding/updating the coupon');
  }
};


// Function to delete a coupon
export const deleteCoupon = async (PK, SK) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_COUPON_MUTATION,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while deleting the coupon');
  }
};



export const fetchUserByPagination = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null, phone = null, email = null, full_name = null, startDate = null, endDate = null) => {
  let variables = {};

  if (phone || email || startDate || endDate || full_name) {
    variables.SearchObj = {};
    if (phone) {
      variables.SearchObj.phone = phone;
    }
    if (email) {
      variables.SearchObj.email = email;
    }
    if (full_name) {
      variables.SearchObj.full_name = full_name;
    }
    if (startDate) {
      variables.SearchObj.startDate = startDate.toString();
    }
    if (endDate) {
      variables.SearchObj.endDate = endDate.toString();
    }
  } else {
    variables.limit = limit;
  }

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }

  try {
    const { data: responseData, errors } = await client.query({
      query: GET_USER_BY_PAGINATION_QUERY,
      variables,
      fetchPolicy: 'network-only',
    });

    if (errors && errors.some(error => error.message.includes("No record found"))) {
      throw new Error("No record found");
    }

    if (responseData && responseData.GetUserByPagination) {
      return {
        success: true,
        message: "Users fetched successfully!",
        data: responseData.GetUserByPagination
      };
    } else {
      throw new Error("No data available");
    }
  } catch (error) {
    throw error; // Rethrow the error to be handled by the calling function
  }
};

// Function to fetch all coupons
export const getAllCoupons = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null, phone = null, email = null, startDate = null, endDate = null) => {

  let variables = {};

  if (phone || email || startDate || endDate) {
    variables.SearchObj = {};
    if (phone) {
      variables.SearchObj.phone = phone;
    }
    if (email) {
      variables.SearchObj.email = email;
    }
    if (startDate) {
      variables.SearchObj.startDate = startDate.toString();
    }
    if (endDate) {
      variables.SearchObj.endDate = endDate.toString();
    }
  } else {
    variables.limit = limit;
  }

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }


  try {
    const { data } = await client.query({
      query: GET_ALL_COUPONS_QUERY,
      variables,
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching coupons');
  }
};



export const getAirDrop = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null, phone = null, email = null, startDate = null, endDate = null) => {
  let variables = {};

  if (phone || email || startDate || endDate) {
    variables.SearchObj = {};
    if (phone) {
      variables.SearchObj.phone = phone;
    }
    if (email) {
      variables.SearchObj.email = email;
    }
    if (startDate) {
      variables.SearchObj.startDate = startDate.toString();
    }
    if (endDate) {
      variables.SearchObj.endDate = endDate.toString();
    }
  } else {
    variables.limit = limit;
  }

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }

  try {
    const { data } = await client.query({
      query: GET_ALL_USER_AIRDROP,
      variables,
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching Airdrop Data');
  }
};

export const getParticularUserAirDrop = async (PK) => {
  try {
    const { data } = await client.query({
      query: GET_USER_AIRDROP,
      variables: { PK },
    });
    return data.GetAirDropOneUser;
  } catch (error) {
    throw new Error(error);
  }
};




export const fetchUserProfile = async (data) => {
  const { PK, SK } = data;
  // Initialize the variables object based on the presence of PK and SK
  let variables = {};
  if (PK) variables.PK = PK;
  if (SK) variables.SK = SK;

  try {
    const { data } = await client.query({
      query: GET_USER_PROFILE_QUERY,
      variables: variables, // Pass the variables object
      fetchPolicy: 'network-only', // Use 'network-only' to always fetch the latest data
    });

    if (data && data.GetUserProfile) {
      return {
        success: true,
        profile: data.GetUserProfile.data,
        message: data.GetUserProfile.message
      };
    } else {
      return {
        success: false,
        message: 'Failed to fetch user profile. No data available.'
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`
    };
  }
};


export const fetchAllUserApprovedByAdmin = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null) => {
  let variables = { limit };

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      isApprovedby: 'ApprovedByAdmin'
    };
  }

  try {
    const { data } = await client.query({
      query: GET_ALL_USER_APPROVED_BY_ADMIN_QUERY,
      variables,
      fetchPolicy: 'network-only',
    });

    if (data && data.getAllUserApprovedByAdmin) {
      return {
        success: true,
        message: "Users approved by admin fetched successfully!",
        data: data.getAllUserApprovedByAdmin
      };
    } else {
      return {
        success: false,
        message: "No users approved by admin found",
        data: []
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`
    };
  }
};

export const fetchAllPartialFilledData = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null) => {
  let variables = { limit };

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }

  try {
    const { data } = await client.query({
      query: GET_ALL_USER_PARTIAL_FILLED_QUERY,
      variables,
      fetchPolicy: 'network-only', // Ensures the data is fetched from the server each time
    });

    if (data && data.getAllUserSemiApproved) {
      return {
        success: true,
        message: "Users Partial Filled fetched successfully!",
        data: data.getAllUserSemiApproved.items
      };
    } else {
      return {
        success: false,
        message: "No Partial Filled found",
        data: []
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`
    };
  }
};

export const fetchAllAutoApprovedData = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null) => {

  let variables = { limit };

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }

  try {
    const { data } = await client.query({
      query: GET_ALL_USER_AUTO_APPROVED_QUERY,
      variables,
      fetchPolicy: 'network-only', // Ensures the data is fetched from the server each time
    });

    if (data && data.getAllUserAutoApproved) {
      return {
        success: true,
        message: "Users Auto Approved fetched successfully!",
        data: data.getAllUserAutoApproved
      };
    } else {
      return {
        success: false,
        message: "No Auto Approved found",
        data: []
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`
    };
  }
};


// Function to handle deleting an item
export const deleteItem = async (modelName, PK, SK) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_ITEM_MUTATION,
      variables: { modelName, PK, SK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while deleting the item');
  }
};



export const createWallet = async (walletInput) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_WALLET_MUTATION,
      variables: { input: walletInput } // Make sure walletInput is not nested further inside another 'input' key
    });

    if (data && data.createWallet) {

      return {
        success: true,
        message: "Wallet created successfully!",
        wallet: data.createWallet

      };
    } else {
      return {
        success: false,
        message: "Failed to create wallet",
        wallet: null
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`
    };
  }
};

export const addBlogPost = async ({ title, description, Image }) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_BLOG_POST_MUTATION,
      variables: {
        title: title,
        description: description,
        Image: Image
      }
    });

    if (data && data.addBlogPost) {
      return {
        success: true,
        message: "Blog post added successfully",
        blogPost: data.addBlogPost
      };
    } else {
      return {
        success: false,
        message: "Failed to add blog post",
        blogPost: null
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`,
      blogPost: null
    };
  }


};

export const addWebBlogPost = async ({ title, description, Image }) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_WEB_BLOG_POST_MUTATION,
      variables: {
        title: title,
        description: description,
        Image: Image
      }
    });

    if (data && data.addBlogPostWeb) {
      return {
        success: true,
        message: "WEB Blog post added successfully",
        blogPost: data.addBlogPostWeb
      };
    } else {
      return {
        success: false,
        message: "WEB Failed to add blog post",
        blogPost: null
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`,
      blogPost: null
    };
  }


};

export const fetchWEBAllBlogs = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null) => {
  let variables = { limit };

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }

  try {
    const { data } = await client.query({
      query: GET_ALL_WEB_BLOGS_QUERY,
      variables,
      fetchPolicy: 'network-only' // This ensures you are getting fresh data from your server and not using the cache
    });


    if (data && data.GetAllWebBlogs) {
      return {
        success: true,
        blogs: data.GetAllWebBlogs
      };
    } else {
      return {
        success: false,
        message: "No blogs found",
        blogs: []
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`,
      blogs: []
    };
  }
};

export const fetchAllBlogs = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null) => {
  let variables = { limit };

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }

  try {
    const { data } = await client.query({
      query: GET_ALL_BLOGS_QUERY,
      variables,
      fetchPolicy: 'network-only' // This ensures you are getting fresh data from your server and not using the cache
    });

    if (data && data.GetAllBlogs) {
      return {
        success: true,
        blogs: data.GetAllBlogs
      };
    } else {
      return {
        success: false,
        message: "No blogs found",
        blogs: []
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`,
      blogs: []
    };
  }
};




export const fetchBlogDetails = async (PK, SK) => {
  try {
    const { data } = await client.query({
      query: BLOG_DETAILS_QUERY,
      variables: { PK, SK },
      fetchPolicy: 'network-only' // Ensures the latest data is fetched
    });

    if (data && data.blogDetails) {
      return {
        success: true,
        blogDetails: data.blogDetails
      };
    } else {
      return {
        success: false,
        message: "No blog details found",
        blogDetails: null
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`,
      blogDetails: null
    };
  }
};



// Function to handle creating banners
export const createBanners = async (image, page, link) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_BANNERS_MUTATION,
      variables: { image, page, link },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while creating banners');
  }
};

export const fetchBanners = async () => {
  try {
    const { data } = await client.query({
      query: BANNERS_LIST,
      fetchPolicy: 'network-only'  // This ensures the query always goes to the network instead of using the cache
    });
    return data.banners;  // Assuming the data you want is located directly under data.banners
  } catch (error) {
    throw new Error('An error occurred while fetching banners');
  }
};


export const addHeaderText = async (stringInput, discription, type, minAmount, maxAmount) => {
  try {
    const { data } = await client.mutate({
      mutation: HEADER_TEXT,
      variables: { stringInput, discription, type, minAmount, maxAmount }
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while adding header text');
  }
};

export const fetchHeaderText = async (type) => {
  try {
    const { data, errors } = await client.query({
      query: HEADER_TEXT_LIST,
      variables: { type },
      fetchPolicy: 'network-only'  // This ensures the query always goes to the network instead of using the cache
    });

    if (errors) {
      throw new Error(errors[0].message);
    }

    return data;
  } catch (error) {
    if (error == "No record  found") {
      throw new Error(error);
    } else {
      throw new Error('An error occurred while fetching header text');

    }
  }
}



export const dataUpdate = async (PK, SK, isDynamicString) => {

  try {
    // Create an update object
    const updateObject = {
      IsdynamicString: isDynamicString
    };



    // Convert the update object to a JSON string
    const updateData = JSON.stringify(updateObject);

    // Perform the mutation
    const { data } = await client.mutate({
      mutation: UPDATE_QUERY,
      variables: { PK, SK, updateData }
    });

    return data;
  } catch (error) {
    throw new Error('An error occurred while updating the item');
  }
};



export const getAllComplain = async (limit, lastKey) => {
  try {
    const { data } = await client.query({
      query: GET_ALL_COMPLAIN_QUERY,
      variables: { limit, lastKey },
      fetchPolicy: 'network-only',
    });
    return data.GetAllComplain;
  } catch (error) {
    throw new Error(error);
  }
};

export const resolvedComplain = async (Ticket) => {
  try {
    const { data } = await client.mutate({
      mutation: SOLVED_COMPLAIN_MUTATION,
      variables: { Ticket },
    });
    return data.Solved_Complain;
  } catch (error) {
    throw new Error('An error occurred while resolving complain');
  }
};


// Function to fetch help support chat
export const fetchHelpSupportChat = async (ID) => {
  try {
    const { data } = await client.query({
      query: GET_HELP_SUPPORT_CHAT_QUERY,
      variables: { ID },
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching help support chat');
  }
};


export const addChat = async (ticketId, text, chatBy) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_CHAT_MUTATION,
      variables: { ticketId, text, chatBy }
    });
    return data.ChatNew;
  } catch (error) {
    throw new Error('An error occurred while adding the chat');
  }
};



export const deleteComplainTicket = async (id) => {
  try {
    // Execute the mutation using the Apollo client
    const { data } = await client.mutate({
      mutation: DELET_COMPLAIN_TICKET_MUTATION,
      variables: { ticket: id },
    });
    return data.DeleteComplainByTicket;
  } catch (error) {
    throw new Error('An error occurred while deleting the complain');
  }
};


// Function to handle changing password
export const changePassword = async (userName, password, password_2, newPassword, newPassword_2) => {
  try {
    const { data } = await client.mutate({
      mutation: CHANGE_PASSWORD_MUTATION,
      variables: { userName, password, password_2, newPassword, newPassword_2 },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while changing the password');
  }
};

// Function to execute the GetWalletDetails query
export const getWalletDetails = async (coinPairWith, coinCode, PK) => {
  try {
    let variables = { coinPairWith, coinCode };
    let query = GET_WALLET_DETAILS_QUERY;
    if (PK) {
      variables = { ...variables, userPK: PK }; // Ensure you use 'userPK' here
    }
    const { data } = await client.query({
      query,
      variables,
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching wallet details');
  }
};



export const addAppCharges = async (brokerageFee, TDS, GST) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_APP_CHARGES_MUTATION,
      variables: { brokerageFee, TDS, GST },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while adding charges');
  }
};



export const listAppCharges = async () => {
  try {
    const { data } = await client.query({
      query: GET_ALL_CHARGESL_LIST_QUERY,
      fetchPolicy: 'network-only'  // This ensures the query always goes to the network instead of using the cache
    });
    return data.appChargesPlansList;

  } catch (error) {
    throw new Error('An error occurred while fetching charges list');
  }
}


export const listWithdrawlRequest = async (userPK) => {
  let variables = { userPK };

  try {
    const { data } = await client.query({
      query: GET_USER_WITH_DRAWL_REQUEST,
      variables,
      fetchPolicy: 'network-only'  // This ensures the query always goes to the network instead of using the cache
    });
    return data.userWithdrawalRequestList;

  } catch (error) {
    throw new Error('An error occurred while fetching Withdrwal Request');
  }
}


export const withDrawalRequest = async (withdrawalRequestSK, requestType, withdrawalRequestPK) => {
  console.log('data of apolo====',withdrawalRequestPK )
  try {
    const { data } = await client.mutate({
      mutation: USER_WITHDRAWL_REQUEST,
      variables: { withdrawalRequestSK, requestType, withdrawalRequestPK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while adding withdrawal Request');
  }
};



export const banckDetails = async (userPK, isAllAccounts) => {
  try {
    const variables = {
      ...(userPK && { userPK }),
      ...(typeof isAllAccounts !== 'undefined' && { isAllAccounts })
    };

    const { data } = await client.mutate({
      mutation: GET_BANK_DETAILS,
      variables,
    });

    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching bank details');
  }
};


export const userBuySellOrdersData = async (shareName, coinPairWith, userPK) => {
  try {
    const { data } = await client.mutate({
      mutation: USER_ORDERS_QUERY,
      variables: { shareName, coinPairWith, userPK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching user orders details');
  }
};


export const cancelOrder = async (userPK, forUser) => {
  try {
    const { data } = await client.mutate({
      mutation: CANCEL_ORDER_QUERY,
      variables: { userPK, forUser },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching cancel orders details');
  }
};


export const addVersion = async (AndroidVersion, IOSVersion) => {
  try {
    const variables = {};
    if (AndroidVersion) variables.AndroidVersion = AndroidVersion;
    if (IOSVersion) variables.IOSVersion = IOSVersion;

    const { data } = await client.mutate({
      mutation: ADD_VERSION_QUERY,
      variables,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while adding version');
  }
};


export const listVersion = async () => {
  try {
    const { data } = await client.mutate({
      mutation: LIST_VERSION_QUERY,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching version list');
  }
};

export const inActiveUser = async (PK, SK) => {
  try {
    const { data } = await client.mutate({
      mutation: INACTIVE_USER_QUERY,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while changing inactive user');
  }
}

export const activeUser = async (PK, SK) => {

  try {
    const { data } = await client.mutate({
      mutation: ACTIVE_USER_QUERY,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while changing active user');
  }
}

export const freezeUser = async (PK, SK) => {
  try {
    const { data } = await client.mutate({
      mutation: FREEZ_USER_QUERY,
      variables: { PK, SK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while changing freeze user');
  }
}



export const userDepositeList = async (userPK) => {
  try {
    const { data } = await client.mutate({
      mutation: USER_AMOUNT_DEPOSITE_LIST_QUERY,
      variables: { userPK },
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching Deposite List');
  }
}

export const addICOCoupon = async (couponName, couponUsesLimit, couponExpirydate, usesLocation, action, PK, SK) => {

  const variables = { couponName, couponUsesLimit, couponExpirydate, usesLocation, action };
  if (PK) variables.PK = PK;
  if (SK) variables.SK = SK;

  try {
    const { data } = await client.mutate({
      mutation: ADD_ICO_COUPON,
      variables: variables,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while adding ICO Coupon');
  }
};


export const icoCouponList = async () => {
  try {
    const { data } = await client.mutate({
      mutation: ICO_COUPON_LIST,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching ICO Coupon List');
  }
}


export const icoListing = async () => {
  try {
    const { data } = await client.mutate({
      mutation: ICO_LISTING,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching ICO Listing');
  }
}


export const sendNotificationToOneUser = async ({ messageTitle, messageBody, PK, SK }) => {
  try {
    const { data } = await client.mutate({
      mutation: SEND_NOTIFICATION_ONE_USER_MUTATION,
      variables: {
        messageTitle,
        messageBody,
        PK,
        SK
      }
    });
    return data;
  } catch (error) {
    throw new Error('Failed to send notification');
  }
};

export const deletItemByType = async (type) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_ITEM_BY_TYPE,
      variables: { type }
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while delete data');
  }
}

export const deletAllCoupoun = async (type) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_ALL_COUPON_LIST,
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while delete all coupoun');
  }
}


export const lastTradePriceData = async (shareName, coinPairWith) => {
  try {
    const { data } = await client.query({
      query: LAST_TRADE_PRICE,  // Changed from mutation to query
      fetchPolicy: 'network-only',
      variables: { shareName, coinPairWith }
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching last trade price');
  }
}

// Function to fetch Admin Wallet
export const getAdminWallet = async () => {
  try {
    const { data } = await client.query({
      query: ADMIN_WALLET_QUERY,
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching admin wallet');
  }
};



export const getAllAdminNotify = async (limit, lastKey) => {
  try {
    const variables = { limit };
    if (lastKey) {
      variables.lastKey = lastKey;
    }

    const { data } = await client.query({
      query: GET_ALL_ADMIN_NOTIFY,
      variables: variables
    });
    return data.GetAllAdminNotify;
  } catch (error) {
    throw error;
  }
};

export const getReferralList = async () => {
  try {
    const { data } = await client.query({
      query: GET_ALL_REFERRAL,
      fetchPolicy: 'network-only',
    });
    return data.getAllReferrel.referrals;
  } catch (error) {
    throw new Error('An error occurred while fetching Referral List');
  }
};

export const addReferral = async (input) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_REFERRAL,
      variables: { input },
    });
    return data.addRefferal;
  } catch (error) {
    throw new Error('An error occurred while creating Referral');
  }
};

export const getBreakData = async (pendingOrderId) => {
  try {
    const { data } = await client.query({
      query: EXECUTED_BREAK_DATA_QUERY,
      variables: { pendingOrderId },
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching Break List');
  }
};


export async function createMerchantVoucher(brandPK, vouchers) {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_MERCHANT_VOUCHER,
      variables: { brandPK, vouchers, }
    });
    return data.createMerchantVoucher;
  } catch (error) {
    console.error('Error creating merchant voucher:', error);
    throw error;
  }
}

export async function getAllMerchentVoucher() {
  try {
    const { data } = await client.query({
      query: GET_ALL_MERCHENT_VOUCHER,
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    console.error('Error fetching merchant voucher:', error);
    throw error;
  }
}

export async function getAllPurchasedVoucher() {
  try {
    const { data } = await client.query({
      query: PURCHASED_VOUCHER_LIST,
      fetchPolicy: 'network-only',
    });
    return data.purchasedCouponVoucherList;
  } catch (error) {
    console.error('Error fetching purchased voucher:', error);
    throw error;
  }
}


export async function activeVersion(versionPK, isActiveVersion) {
  try {
    const { data } = await client.mutate({
      mutation: ACTIV_DEACTIVE_VERSION_QUERY,
      variables: { versionPK, isActiveVersion, }
    });
    return data;
  } catch (error) {
    console.error('Error update version:', error);
    throw error;
  }
}

export async function kycApprovedByAdmin(PK, SK, PanNumber, AadharNumber) {
  try {
    // Construct the variables object dynamically
    const variables = { PK, SK };

    if (PanNumber) {
      variables.PanNumber = PanNumber;
    }

    if (AadharNumber) {
      variables.AadharNumber = AadharNumber;
    }

    const { data } = await client.mutate({
      mutation: KYC_APPROVED_BY_ADMIN,
      variables: variables, // Pass the dynamically constructed variables
    });

    return data;
  } catch (error) {
    console.error('Error update version:', error);
    throw error;
  }
}


export const getWebComplain = async (limit, lastPK = null, lastSK = null, createdAt = null, type = null) => {


  let variables = { limit };

  if (lastPK !== null && lastSK !== null) {
    variables.lastKey = {
      PK: lastPK,
      SK: lastSK,
      createdAt,
      type
    };
  }

  try {
    const { data } = await client.query({
      query: GET_WEB_COMPLAIN,
      variables,
      fetchPolicy: 'network-only', // Ensures the data is fetched from the server each time
    });

    if (data && data.getAllContactUser) {
      return {
        success: true,
        message: "Users WEB Complain fetched successfully!",
        data: data.getAllContactUser
      };
    } else {
      return {
        success: false,
        message: "No WEB Complain found",
        data: []
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `An error occurred: ${error.message}`
    };
  }
};


export async function addRecharge(priceAmount, customerBankAccountNumber, phone) {
  try {
    const { data } = await client.mutate({
      mutation: ADD_RECHARGE,
      variables: { priceAmount: Number(priceAmount), customerBankAccountNumber, phone: Number(phone) }
    });
    return data;
  } catch (error) {
    console.error('Error update version:', error);
    throw error;
  }
}


export const getAppChargesHistory = async (startedAt, endAt) => {
let variables;

if(startedAt !== null&& endAt !==null){
  variables = {startedAt, endAt}
}

  try {
    const { data } = await client.query({
      query: APP_CHARGES_HISTORY,
      variables,
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (error) {
    throw new Error('An error occurred while fetching charges history');
  }
};

export async function reverseVoucher(reverseCouponIds) {
  try {
    const { data } = await client.mutate({
      mutation: REVERSE_COUPOUN_VOUCHER,
      variables: {reverseCouponIds}
    });
    return data;
  } catch (error) {
    console.error('Error reverse voucher', error);
    throw error;
  }
}




export default client;