import React, { useState, useEffect } from 'react';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import client from '../../../../src/apolloClientConnection/apolloClient'; // Import the Apollo Client instance
import './Chating.css'; // Import the CSS file for styling

// GraphQL mutation for adding a todo
const ADD_TODO_MUTATION = gql`
  mutation addTodo($text: String!) {
    addTodo(text: $text) {
      id
      text
    }
  }
`;

// Subscription for listening to new todos
const TODO_ADDED_SUBSCRIPTION = gql`
  subscription onTodoAdded {
    todoAdded {
      id
      text
    }
  }
`;



const Chating = () => {
  const [todos, setTodos] = useState([]);
  const [todoText, setTodoText] = useState('');
  const { data } = useSubscription(TODO_ADDED_SUBSCRIPTION);

  const [addTodo, { loading: addingTodo }] = useMutation(ADD_TODO_MUTATION, {
    onCompleted: () => setTodoText(''), // Clear input after adding
    client, // Pass the client instance to the mutation
  });

  // Update the local list of todos when a new one is added
  useEffect(() => {
    if (data) {
      setTodos((prevTodos) => [...prevTodos, data.todoAdded]);
    }
  }, [data]);

  const handleAddTodo = () => {
    if (!todoText.trim()) return; // Prevent adding empty todos
    addTodo({
      variables: {
        text: todoText,
      },
    });
  };

  return (
    <Col xs={12}>
      <Card>
        <Card.Body>
          <h2>Todos:</h2>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">Todo:</Form.Label>
            <Col sm="8">
              <Form.Control
                type="text"
                value={todoText}
                onChange={(e) => setTodoText(e.target.value)}
                placeholder="Write a todo"
              />
            </Col>
            <Col sm="2">
              <Button onClick={handleAddTodo} disabled={addingTodo}>
                Add Todo
              </Button>
            </Col>
          </Form.Group>
          <div className="todos-container">
            {todos.map((todo, index) => (
              <p key={index} className="todo-item">{todo.text}</p>
            ))}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default Chating;
