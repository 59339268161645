import React, { useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useToast from "../Toaster/Toaster";
import { deletItemByType } from "../../../apolloClientConnection/apolloClient";

const rowNames = ['BUY', 'SELL', 'ICO', 'TAB', 'APP_SETTING', 'ICO_COUPON', 'USER_DEPOSIT_AMOUNT', 'APP_CHARGES_PLANS', 'USER_ACCOUNT_DETAIL', "USER_WITHDRAWAL_REQUEST", "USER_BUY_AVG_PRICE", 'Admin', "BLOG", "COMMENT", "EXECUTED_ORDER", "DELETED_ORDER", "BANNER_IMAGE", 'EXECUTED', 'Tamplate', 'WALLET', 'USER', "Dynamic_String", "USER_AIRDROP", "COMPLETED_ORDER", "USER_EXECUTED_ORDER", "APP_ACCOUNTING_CHARGES", "USER_BUCKET_EXECUTED_ORDER"];

const options = rowNames.map(name => ({ value: name, label: name }));

const DeleteItems = () => {
    const [selectedOption, setSelectedOption] = useState(null); // State to track the selected option
    const { notifySuccess, notifyError } = useToast();

    const handleDelete = async () => {
        if (selectedOption) {
            try {
                await deletItemByType(selectedOption.value);
                notifySuccess(`Deleted item: ${selectedOption.value}`);
            } catch (error) {
                console.error('Failed to delete item:', error);
                notifyError('Failed to delete item');
            }
        } else {
            notifyError("Please select an item to delete");
        }
    };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    return (
        <>
            <div className="row">
                <div className="col-xxl-6 col-xl-6">
                    <div className="card">
                        <div className="card-header border-0">
                            <h4 className="heading">Delete Items By Type</h4>
                        </div>
                        <div className="card-body pt-0">
                            <Select
                                className="custom-react-select"
                                options={options}
                                onChange={handleChange}
                                isSearchable={false}
                            />
                            <div className="col-auto mt-4">
                                <button type="button" className="btn btn-success" onClick={handleDelete}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteItems;
