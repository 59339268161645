import React, { useState, useEffect } from 'react';
import UserNav from './UserNav';
import { Col, Card, Table, Pagination } from 'react-bootstrap';
import { IoCopy } from "react-icons/io5";
import { getWalletDetails } from '../../../apolloClientConnection/apolloClient';
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import useToast from "../Toaster/Toaster";

const Holding = () => {
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;
    const [visiblePages, setVisiblePages] = useState(5);
    const [userPK, setUserPK] = useState('');
    const [coinData, setCoinData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);
    const { notifyError } = useToast();

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
        setCurrentPage(1); // Reset to first page on filter change
    };

    const copyToClipboard = (text, index) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedIndex(index); // Show copied indicator
            setTimeout(() => setCopiedIndex(null), 2000); // Hide after 2 seconds
        }).catch(err => console.error('Failed to copy: ', err));
    };

    const filteredData = coinData.filter(item =>
        item.coinName.toLowerCase().includes(filter.toLowerCase()) ||
        item.totalSupply.toString().includes(filter) ||
        (item.SK || "No data").includes(filter)
    );

    const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

    const currentData = filteredData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const getPaginationItems = () => {
        let items = [];
        let leftSide = currentPage - Math.floor(visiblePages / 2);
        let rightSide = currentPage + Math.floor(visiblePages / 2);

        if (leftSide < 1) {
            rightSide = visiblePages;
            leftSide = 1;
        }
        if (rightSide > totalPages) {
            leftSide = totalPages - visiblePages + 1;
            rightSide = totalPages;
        }
        if (leftSide < 1) leftSide = 1;

        for (let number = leftSide; number <= rightSide; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (leftSide > 1) {
            items.unshift(<Pagination.Ellipsis key="ellipsis1" onClick={() => setCurrentPage(currentPage - visiblePages)} />);
            items.unshift(
                <Pagination.Item key={1} onClick={() => setCurrentPage(1)}>
                    1
                </Pagination.Item>
            );
        }

        if (rightSide < totalPages) {
            items.push(<Pagination.Ellipsis key="ellipsis2" onClick={() => setCurrentPage(currentPage + visiblePages)} />);
            items.push(
                <Pagination.Item key={totalPages} onClick={() => setCurrentPage(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    useEffect(() => {
        const UserDocument = localStorage.getItem("UserDataInfoDocument");
        const ParseUserData = JSON.parse(UserDocument || '{}');

        if (ParseUserData.PK) {
            setUserPK(ParseUserData.PK);
        }
    }, []);

    useEffect(() => {
        if (userPK) {
            fetchUserCoin(userPK);
        }
    }, [userPK]);

    const fetchUserCoin = async (userPK) => {
        setLoading(true);

        try {
            const data = await getWalletDetails('INR', 'CCD', userPK);
            console.log('Fetched coin balance:', data);
            if (data && data.getWalletDetails.remaining_coin) {
                setCoinData([data.getWalletDetails.remaining_coin]);
            } else {
                setCoinData([]);
            }
            setHasError(false);
        } catch (error) {
            console.error("Error fetching coin balance:", error);
            setCoinData([]);
            notifyError("Failed to fetch data");
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="row">
            <div className="col-xxl-12">
                <UserNav />
            </div>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Holding</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className="mb-3" style={{ width: '200px' }}>
                            <input type="text" className="form-control" placeholder="Filter..." value={filter} onChange={handleFilterChange} />
                        </div>
                        <Table responsive className="header-border verticle-middle">
                            <thead>
                                <tr>
                                    <th scope="col">Coin Name</th>
                                    <th className="text-center" scope="col">Total Supply</th>
                                    <th className="text-center" scope="col">Wallet Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : hasError ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : !hasData || currentData?.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        </td>
                                    </tr>
                                ) : (currentData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.coinName}</td>
                                        <td className="text-center">{(item.totalSupply)?.toFixed(2)}</td>
                                        <td className="text-center">
                                            {"No data"}
                                            <IoCopy style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => copyToClipboard("No data", index)} />
                                            {copiedIndex === index && <span style={{ marginLeft: '5px', color: 'green' }}>Copied!</span>}
                                        </td>
                                    </tr>
                                )))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
                            <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                            {getPaginationItems()}
                            <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                            <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
                        </Pagination>
                    </Card.Body>

                </Card>
            </Col>
        </div>
    );
};

export default Holding;
