import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Table } from "react-bootstrap";
import Select from 'react-select';
import { fetchAlTlemplates, fetchTabsByTemplate } from "../../../apolloClientConnection/apolloClient";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json"
import Loader from "../Loader/loader";

const ListAdminTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const [noData, setNoData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        setLoading(true);
        const response = await fetchAlTlemplates();
        if (response && response.Getalltamplate) {
          setTemplates(response.Getalltamplate);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching templates:", error);
        setLoading(false);
        setNetworkError(true);
      }
    };
    loadTemplates();
  }, []);

  useEffect(() => {
    const loadTabs = async () => {
      if (!selectedTemplate) return;
      try {
        setLoading(true);
        const response = await fetchTabsByTemplate(selectedTemplate.PK, selectedTemplate.SK);
        if (response && response.data && response.data.Tabs) {
          const parsedTabs = JSON.parse(response.data.Tabs);
          setTabs(parsedTabs);
          setNoData(parsedTabs.length === 0);
        } else {
          setTabs([]);
          setNoData(true);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tabs:", error);
        setLoading(false);
        setNetworkError(true);
      }
    };
    loadTabs();
  }, [selectedTemplate]);

  const handleSelectChange = (selectedOption) => {
    const template = templates.find((template) => template.SK === selectedOption.value);
    setSelectedTemplate(template);
  };

  const options = templates.map((template) => ({
    value: template.SK,
    label: template.tamplate,
  }));

  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title>Select Template to View</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && <Loader />}
        {networkError && <LottieAnimation lottieJson={NetworkError} height={400} width={550} />}
        {!loading && !networkError && (
          <>
            <div className="form-group mb-3 col-md-6">
              <label>Template Name</label>
              <Select
                value={options.find((option) => option.value === selectedTemplate?.SK)}
                onChange={handleSelectChange}
                options={options}
                className="custom-react-select"
                isSearchable={false}
              />
            </div>
            {noData && <tr>
                  <td colSpan="4" className="text-center">
                    <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                  </td>
                </tr>}
            {!noData && tabs && tabs.length > 0 && (
              <>
                <h5 className="mt-4">Template Details:</h5>
                <Card className="mt-3">
                  <Card.Header>
                    <Card.Title>{selectedTemplate?.tamplate}</Card.Title>
                  </Card.Header>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Tab Name</th>
                        <th className="text-center">Sub-Tabs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tabs.map((tab, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{tab.tab}</td>
                          <td className="text-center">{tab.subtab.join(", ")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Card.Footer>
                    <Button variant="primary" >
                      Edit
                    </Button>
                  </Card.Footer>
                </Card>
              </>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ListAdminTemplate;

