
import { listAppCharges } from "../../../apolloClientConnection/apolloClient";

async function brokarageData() {
    try {
        const response = await listAppCharges();
        if (response && response.length > 0) {
            const { brokerageFee, TDS, GST } = response[0];
            return { brokerageFee: parseFloat(brokerageFee), TDS: parseFloat(TDS), GST: parseFloat(GST) };
        } else {
            throw new Error('No brokerage data available');
        }
    } catch (error) {
        console.error("Failed to fetch brokerage data:", error);
        throw error;
    }
}

export async function calculateTransactionDetails(transaction, userType) {
    const amount = transaction;
    // const amount =rawAmount; // Ensure amount is a number
    if (isNaN(amount)) {
        console.error('Invalid amount provided:', amount);
        throw new Error("Invalid amount. Must be a numeric value.");
    }

    const { TDS: TDS_RATE, brokerageFee: TRADING_FEE_RATE, GST: GST_RATE } = await brokarageData();

    const tdsDeduction = amount * (TDS_RATE / 100);
    const tradingFee = amount * (TRADING_FEE_RATE / 100);
    const gstOnFee = tradingFee * (GST_RATE / 100);

    let netAmount, extraAmount;
    if (userType === 'SELL') {
        extraAmount = tdsDeduction + tradingFee + gstOnFee;
        netAmount = amount - extraAmount;
    } else if (userType === 'BUY') {
        extraAmount = tradingFee + gstOnFee;
        netAmount = amount + extraAmount;
    } else {
        console.error('Invalid user type:', userType);
        throw new Error("Invalid user type. Must be 'BUY' or 'SELL'.");
    }

    console.log('this is netAmount', netAmount)

    return {
        originalAmount: amount,
        tdsDeduction,
        tradingFee,
        gstOnFee,
        netAmount,
        extraAmount,
        brokerageRate: TRADING_FEE_RATE,
        gstRate: GST_RATE
    };
}
