import React, { useState } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import useToast from "../Toaster/Toaster";
import swal from "sweetalert";

const AddDeposite = () => {
    const [depositeAmount, setDepositeAmount] = useState(''); // State for deposit amount
    const { notifySuccess, notifyWarning, notifyError } = useToast();
    const SERVER_LINK = process.env.REACT_APP_SERVER_LINK; // Ensure this is defined in your environment variables

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get PK from localStorage
        const storedData = JSON.parse(localStorage.getItem("adminPKSK"));
        const adminPK = storedData?.PK;

        if (!depositeAmount.trim()) {
            notifyWarning("Please enter the deposit amount.");
            return;
        }

        if (!adminPK) {
            notifyError("Admin PK is not available.");
            return;
        }

        swal({
            title: "Are you sure?",
            text: "Do you want to submit this deposit?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willSubmit) => {
            if (willSubmit) {
                try {
                    // Call the API to submit deposit data as raw JSON in the body
                    const response = await fetch(`${SERVER_LINK}/api/v1/file/manualdeposite`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json', // Set content type to JSON
                        },
                        body: JSON.stringify({
                            PK: adminPK, // Send PK and amount in the body
                            amount: parseInt(depositeAmount),
                        }),
                    });

                    if (response.ok) {
                        notifySuccess("Deposit added successfully.");
                        setDepositeAmount(''); // Clear the input field
                    } else {
                        notifyError("Failed to add deposit. Please try again.");
                    }
                } catch (error) {
                    console.error('Error:', error);
                    notifyError("An error occurred while adding the deposit.");
                }
            } else {
                notifyWarning("Deposit submission canceled.");
            }
        });
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Add Deposite</Card.Title>
                    <div className="form-group">
                        <div className="col-auto">
                            <Link to="/all-history">
                                <button type="button" className="btn btn-success">History</button>
                            </Link>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Deposite Amount"
                                        value={depositeAmount}
                                        onChange={e => setDepositeAmount(e.target.value)} // Set deposit amount
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="success">Save</Button>
                                <Link to='/all-history'>
                                    <Button className="ms-2" variant="danger">Go Back</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default AddDeposite;
