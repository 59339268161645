import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Dropdown,
} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Select from 'react-select';

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const TokenHolderList = () => {

    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    // Sample data
    const data = [
        {
            userId: 1,
            user_name: "Savita",
            mobile_no: "98765432",
            inst_id: 'CCD-INR',
            quantity: '3.00'
        }
    ];

    // SVG Icon
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z" fill="#000000" />
            </g>
        </svg>
    );



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };


    const filteredData = (filter === '') ? data : data.filter(item =>
        item.userId.toString().includes(filter) ||
        item.user_name.toString().includes(filter) ||
        item.mobile_no.includes(filter) ||
        item.inst_id.includes(filter) ||
        item.quantity.includes(filter) 
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Token Holder List</Card.Title>
                    <div className="form-group col-md-1">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select"
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-between mb-4">
                        <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Filter..."
                                    value={filter}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </Col>
                    </Row>


                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>S.No</strong>
                                </th>
                                <th>
                                    <strong>User Name</strong>
                                </th>
                                <th>
                                    <strong>Mobile No</strong>
                                </th>
                                <th>
                                    <strong>Inst ID</strong>
                                </th>
                                <th>
                                    <strong>Quantity</strong>
                                </th>
                                <th> <strong>Action</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(item => (
                                <tr key={item.userId}>
                                    <td>
                                        <strong>{item.userId}</strong>
                                    </td>
                                    <td>{item.user_name}</td>
                                    <td className="text-primary">{item.mobile_no}</td>
                                    <td>{item.inst_id}</td>
                                    <td>{item.quantity}</td>
                                    <td>
                                        <Dropdown>
                                            <Link to="">
                                                <Dropdown.Toggle
                                                    variant={item.status === 'Active' ? 'success' : 'danger'}
                                                    className="light sharp i-false"
                                                >
                                                    {svg1}
                                                </Dropdown.Toggle>
                                            </Link>
                                            {/* <Dropdown.Menu>
                                                <Dropdown.Item>Edit</Dropdown.Item>
                                                <Dropdown.Item>Delete</Dropdown.Item>
                                            </Dropdown.Menu> */}
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between ">

                        <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>

                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default TokenHolderList;
