import React, { useState } from "react";
import {
    Col,
    Card,
    Table,
    Dropdown,
} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Select from 'react-select';

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
];

const Denominations = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);

    // Proper data
    const data = [
        {
            name: "John Doe",
            email: "john@example.com",
            mob_no: "1234567890",
            amount: "100",
        },
        {
            name: "Jane Smith",
            email: "jane@example.com",
            mob_no: "9876543210",
            amount: "200",
        },
        // Add more data objects as needed
    ];

    // SVG Icon
    const svgDelete = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = (filter === '') ? data : data.filter(item =>
        item.name.toString().includes(filter) ||
        item.email.toString().includes(filter) ||
        item.mob_no.includes(filter) ||
        item.amount.includes(filter)
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Denominations</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Name</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Email</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Mobile No</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Amount</strong>
                                </th>
                                <th className="text-center"> <strong>Action</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(item => (
                                <tr key={item.name}>
                                    <td>
                                        <strong>{item.name}</strong>
                                    </td>
                                    <td className="text-center">{item.email} </td>
                                    <td className="text-center">{item.mob_no}</td>
                                    <td className="text-center"><span>₹</span>{item.amount}</td>
                                    <td className="text-center">
                                        <Dropdown>
                                            <Link>
                                                <Dropdown.Toggle
                                                    variant={'danger'}
                                                    className="light sharp i-false ms-3"
                                                >
                                                    {svgDelete}
                                                </Dropdown.Toggle>
                                            </Link>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>

                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default Denominations;
