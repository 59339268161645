import React, { useState, useEffect } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import { sendNotificationToOneUser } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";

const Notification = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const location = useLocation();
    const { notifySuccess, notifyError, notifyWarning } = useToast();

    const pk = location.state?.dataPKSK?.PK;
    const sk = location.state?.dataPKSK?.SK;

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!title || !description) {
            notifyWarning("Please provide both title and description.");
            return;
        }
        try {
            const response = await sendNotificationToOneUser({ messageTitle: title, messageBody: description, PK: pk, SK: sk });
            if (response) {
                notifySuccess("Notification sent successfully!");
                setTitle('')
                setDescription('')
            }
        } catch (error) {
            notifyError("Failed to send notification.");
        }
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>User Notification</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Title"
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Description"
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" className="me-2" variant="success">
                                    <span className="btn-icon-start text-success">
                                        <i className="fa fa-bell color-danger" />
                                    </span>
                                    Send
                                </Button>
                                <Link to='/all-Users'>
                                    <Button className="ms-2" variant="danger">Go Back</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default Notification;
