import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserNav from "./UserNav";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getParticularUserAirDrop } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
];

const ProfileAirdrop = () => {
    const [filter, setFilter] = useState("");
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [userPK, setUserPK] = useState('');
    const [airdropData, setAirdropData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [noDataError, setNoDataError] = useState(false);

    useEffect(() => {
        const UserDocument = localStorage.getItem("UserDataInfoDocument");
        const ParseUserData = JSON.parse(UserDocument || '{}');
        if (ParseUserData.PK) {
            setUserPK(ParseUserData.PK);
        }
    }, []);

    useEffect(() => {
        if (userPK) {
            fetchAirdrop(userPK);
        }
    }, [userPK]);

    const fetchAirdrop = async (userPK) => {
        setLoading(true);
        try {
            const data = await getParticularUserAirDrop(userPK);
            setAirdropData(data);
            setNoDataError(data.length === 0);
            setNetworkError(false);
        } catch (error) {
            setNetworkError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleSearch = () => {
        // Implement filtering based on selected date range
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };

    return (
        <Row>
            <UserNav />
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Airdrop</Card.Title>
                        <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col md={6} className="d-flex align-items-center">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="d-flex align-items-center">
                                <div className="mr-2">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                </div>
                                <div className="ms-2">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control"
                                        placeholderText="End Date"
                                    />
                                </div>
                                <button className="btn btn-primary ms-2" onClick={handleSearch}>Search</button>
                            </Col>
                        </Row>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>User Id</th>
                                    <th className="text-center">Name</th>
                                    <th className="text-center">MobileNo./Email</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Coin</th>
                                    <th className="text-center">CoinName</th>
                                    <th className="text-center">Date&Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : networkError ? (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            <LottieAnimation
                                                lottieJson={NetworkError}
                                                width={550}
                                                height={400}
                                            />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : noDataError || airdropData.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            <LottieAnimation
                                                lottieJson={noDataAnimation}
                                                width={550}
                                                height={400}
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                    airdropData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="text-center">{item.userName}</td>
                                            <td className="text-center">
                                                <span className="text-primary">{item.receiverMobileNo}</span>
                                            </td>
                                            <td className="text-center">
                                                <Badge bg={item.status === "Success" ? "success" : item.status === "Pending" ? "warning" : "danger"}>
                                                    {item.status}
                                                </Badge>
                                            </td>
                                            <td className="text-center">{item.tokenQty}</td>
                                            <td className="text-center">{item.coinName}</td>
                                            <td className="text-center">{item.date}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                        <div className="table-pagination d-flex align-items-center justify-content-between mb-3">
                            <p className="mb-0">
                                Showing <span>1-{perPage}</span> of <span>{airdropData.length}</span> data
                            </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to="#" className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active">
                                        <Link to="#" className="page-link">1</Link>
                                    </li>
                                    <li className="page-item page-indicator">
                                        <Link to="#" className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ProfileAirdrop;
