import React, { useState, useEffect } from "react";
import { Col, Card, Table } from "react-bootstrap";
import Loader from "../Loader/loader";
import { listAppCharges } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { formatDateTime } from "../../DateFormate/DateFormate";

const ListCharges = () => {
    const [chargeList, setChargeList] = useState([]);
    const { notifyError } = useToast();
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);

    useEffect(() => {
        const fetchChargeList = async () => {
            try {
                setLoading(true);
                const response = await listAppCharges();
                if (response && response.length > 0) {
                    setChargeList(response);
                    setHasData(true);
                } else {
                    setHasData(false);
                }
                setHasError(false);
            } catch (error) {
                console.error("Failed to fetch charges data: ", error);
                notifyError("Failed to fetch charges data");
                setHasError(true);
                setHasData(false);
            } finally {
                setLoading(false);
            }
        };

        fetchChargeList();
    }, []);

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Brokerage Charges List</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th><strong>S.No</strong></th>
                                <th className="text-center"><strong>Brokerage</strong></th>
                                <th className="text-center"><strong>TDS</strong></th>
                                <th className="text-center"><strong>GST</strong></th>
                                <th className="text-center"><strong>Date/Time</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : !hasData || chargeList.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (chargeList.map((item, index) => (
                                <tr key={item.PK}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td className="text-center">{item.brokerageFee}</td>
                                    <td className="text-center">{item.TDS}</td>
                                    <td className="text-center">{item.GST}</td>
                                    <td className="text-center">
                                        {formatDateTime(item.createdAt ? item.createdAt : "Time Not Fetch")}
                                        {index === 0 && <span className="live-indicator" style={{ marginLeft: '8px' }}></span>}
                                    </td>
                                </tr>
                            )))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ListCharges;
