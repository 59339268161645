// Adjusted imports to match your file structure
import IntroTab from './IntroTab/IntroTab';
// import ListingAgreementTab from './ListingAgreementTab/ListingAgreementTab';
import ListingAgreementTab from './ListingAgreementTab/ListingAgreementTab '
import TokenAddressTab from './TokenAddress/TokenAddress';
import AddAssetTab from './AddAssetTab/AddAssetTab';
import ComplianceTab from './ComplianceTab/ComplianceTab';
import LegalTab from './LegalTab/LegalTab';
import SecurityTab from './SecurityTab/SecurityTab';
import PrepareToSubmitTab from './PrepareToSubmitTab/PrepareToSubmitTab';

export const Dashboard1Data = [
    { name: "Intro", icon: "home", component: IntroTab, nextTab: "Listing Agreement" },
    { name: "Listing Agreement", icon: "file-contract", component: ListingAgreementTab, nextTab: "Token Address" },
    { name: "Token Address", icon: "wallet", component: TokenAddressTab, nextTab: "Asset" },
    { name: "Asset", icon: "plus-square", component: AddAssetTab, nextTab: "Compliance" },
    { name: "Compliance", icon: "gavel", component: ComplianceTab, nextTab: "Legal" },
    { name: "Legal", icon: "balance-scale", component: LegalTab, nextTab: "Security" },
    { name: "Security", icon: "shield-alt", component: SecurityTab, nextTab: "Prepare to Submit" },
    { name: "Prepare to Submit", icon: "tasks", component: PrepareToSubmitTab, nextTab: null } // No nextTab for the last item
];
