import React, { useState, useEffect } from "react";
import { Col, Card, Table, Badge, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { icoCouponList, deleteItem } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json"


const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
];

const ICOCouponList = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    const [coupons, setCoupons] = useState([]);
    const { notifyError, notifySuccess, notifyWarning } = useToast();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [noData, setNoData] = useState(false);
    const navigate = useNavigate();

    const fetchCoupons = async () => {
        setLoading(true);
        setError(false);
        setNoData(false);
        try {
            const response = await icoCouponList();
            if (response.icoCouponList.length === 0) {
                setNoData(true);
            } else {
                setCoupons(response.icoCouponList);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching ICO coupon list:", error);
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCoupons();
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = (filter === '') ? coupons : coupons.filter(item =>
        item.couponName.toString().includes(filter) ||
        item.couponUsesLimit.toString().includes(filter) ||
        item.usesLocation.includes(filter) ||
        item.action.includes(filter)
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );

    const handleEdit = (coupon) => {
        navigate('/ico-coupon', { state: { coupon } });
    };

    const handleDelete = async (coupon) => {
        try {
            await deleteItem(coupon.PK, coupon.SK);
            notifySuccess("Coupon deleted successfully!");
            setCoupons(prevCoupons => prevCoupons.filter(item => item.PK !== coupon.PK));
        } catch (error) {
            notifyError("Error deleting coupon!");
        }
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>ICO Coupon List</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Coupon Name</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Coupon Limit</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Expiry Date</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Location</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Status</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="6" className="text-center"><Loader /></td>
                                </tr>
                            ) : error ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : noData ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={400} />
                                        <p>No data available</p>
                                    </td>
                                </tr>
                            ) : filteredData.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <strong>{item.couponName}</strong>
                                    </td>
                                    <td className="text-center">{item.couponUsesLimit}</td>
                                    <td className="text-center">{item.couponExpirydate}</td>
                                    <td className="text-center">{item.usesLocation}</td>
                                    <td className="text-center">
                                        <Badge bg={
                                            item.action === 'Start' ? 'success' :
                                                item.action === 'Stop' ? 'danger' :
                                                    item.action === 'Pause' ? 'warning' : 'secondary'
                                        }>
                                            {item.action}
                                        </Badge>
                                    </td>
                                    <td className="text-center">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="success"
                                                className="light sharp i-false"
                                            >
                                                {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleEdit(item)}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDelete(item)}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagination d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span> 1-4 </span> from <span> {filteredData.length} </span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ICOCouponList;
