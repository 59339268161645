import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Dropdown, Badge } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchUserProfile, fetchTradeOrders } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { formatDateTime } from "../UserManagement/apis";

const Orders = () => {
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filter, setFilter] = useState('');
    const [data, setData] = useState([]);
    const [userProfiles, setUserProfiles] = useState({});
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();
    const [coinPair, setCoinPair] = useState('CCD-INR'); // Default value

    const selctCoinOption = [
        { value: 'CCD-INR', label: 'CCD-INR' },
        { value: 'CCC-INR', label: 'CCC-INR' },
        // Add more options as needed
    ];

    useEffect(() => {
        fetchOrderHistory(coinPair);
    }, [coinPair]);

    const fetchOrderHistory = async (coinPair) => {
        setIsLoading(true);
        setHasError(false);
        try {
            const res = await fetchTradeOrders(coinPair.split('-')[0], coinPair.split('-')[1]);
            if (Array.isArray(res.tradeOrders.tradeHistory)) {
                setData(res.tradeOrders.tradeHistory);
                // Fetch user profiles
                res.tradeOrders.tradeHistory.forEach(order => {
                    let data = {
                        PK: order.buyerUserId,
                        SK: `Login#${order.buyerUserId}`
                    }
                    fetchUserProfile(data).then(profileRes => {
                        if (profileRes.success) {
                            const userData = JSON.parse(profileRes.profile);


                            setUserProfiles(prev => ({ ...prev, [order.PK]: userData }));
                        }
                    });
                });
                res.tradeOrders.tradeHistory.forEach(order => {
                    let data = {
                        PK: order.PK,
                        SK: order.SK
                    }
                    fetchUserProfile(data).then(profileRes => {
                        if (profileRes.success) {
                            const userData = JSON.parse(profileRes.profile);

                            setUserData(prev => ({ ...prev, [order.PK]: userData }));
                        }
                    });
                });
                setIsLoading(false);
            } else {
                console.error('Received data is not an array:', res.tradeOrders.tradeHistory);
                setData([]);
                setHasError(true);
            }
        }
        catch (error) {
            console.error('Fetching error:', error);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCoinChange = selectedOption => {
        setCoinPair(selectedOption?.value);
    };



    const handlePerPageChange = selectedOption => {
        setPerPage(selectedOption?.value);
    };

    const handleStartDateChange = date => setStartDate(date);
    const handleEndDateChange = date => setEndDate(date);
    const handleFilterChange = event => setFilter(event.target.value);
    const handleSearch = () => {
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
        // Implement additional filtering logic here
    };



    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z" fill="#000000" />
            </g>
        </svg>
    );






    const handleRowClick = (PK, SK) => {
        let dataPKSK = {
            PK: PK,
            SK: SK
        }
        // Save data to local storage
        localStorage.setItem('dataPKSK', JSON.stringify(dataPKSK));
        navigate("/user-data");
    };



    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Orders Tab</Card.Title>
                        <Select
                            options={[
                                { value: 10, label: '10' },
                                { value: 20, label: '20' },
                                { value: 25, label: '25' },
                                { value: 30, label: '30' }
                            ]}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select col-md-1"
                        />
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div>
                                    <Select
                                        className="custom-react-select ms-2"
                                        placeholder='Select Coin...'
                                        options={selctCoinOption}
                                        value={selctCoinOption.find(option => option.value === coinPair)}
                                        onChange={handleCoinChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                                <div className="d-flex align-items-center">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control ms-2"
                                        placeholderText="End Date"
                                    />
                                    <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                                </div>
                            </Col>
                        </Row>
                        <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Pair</th>
                                    <th>Order ID</th>
                                    <th>Vol</th>
                                    <th>Price</th>
                                    <th colSpan="2">Buyer Charges</th>
                                    <th colSpan="3">Seller Charges</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                <tr>
                                    {/* Sub-headers for charges */}
                                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                                    <th>GST</th>
                                    <th>Brokrage</th>
                                    <th>TDS</th>
                                    <th>GST</th>
                                    <th>Brokrage</th>
                                    <th></th><th></th><th></th><th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {isLoading ? (
                                    // Ensure the Loader spans all the columns of the table
                                    <tr>
                                        <td colSpan="16" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : hasError ? (
                                    // Handling error state, assuming error message is appropriate for display
                                    <tr>
                                        <td colSpan="16" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : data.length > 0 ? (
                                    // Mapping through the data only if it exists and is not loading
                                    data.map((order, index) => {
                                        const userOrderIdData = userData[order.PK] || {};
                                        const profile = userProfiles[order.PK] || {};
                                        // const orderType = extractOrderType(order.SK);


                                        return (
                                            <tr key={index}>
                                                <th>{index + 1}</th>
                                                <td>{profile.full_name || 'Guest'}</td>
                                                <td onClick={() => handleRowClick(profile.PK, profile.SK)}>
                                                    <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }}>
                                                        {profile.phone || 'N/A'}
                                                        {profile.email && <div>{profile.email}</div>}
                                                    </div>
                                                </td>
                                                <td>{order.coinPairWith || 'N/A'}</td>
                                                <td>{userOrderIdData.orderId}</td>
                                                <td>{order.quantity ? Number(order.quantity).toFixed(2) : '0'}</td>
                                                <td>₹ {order.price ? Number(order.price).toFixed(2) : '0'}</td>
                                                <td className="color-primary">₹ {order.buyCharges.GST ? Number(order.buyCharges.GST).toFixed(2) : '0'}</td>
                                                <td className="color-primary">₹ {order.buyCharges.brokarage ? Number(order.buyCharges.brokarage).toFixed(2) : '0'}</td>
                                                <td className="color-primary">₹ {order.sellCharges.TDS ? Number(order.sellCharges.TDS).toFixed(2) : '0'}</td>
                                                <td className="color-primary">₹ {order.sellCharges.GST ? Number(order.sellCharges.GST).toFixed(2) : '0'}</td>
                                                <td className="color-primary">₹ {order.sellCharges.brokarage ? Number(order.sellCharges.brokarage).toFixed(2) : '0'}</td>
                                                <td>{order?.customDate ? order?.customDate : formatDateTime(order.createdAt)}</td>
                                                {/* <td>
                                                    <Badge bg={order.orderExecutedType === 'BUY' ? 'success' : 'danger'}>{order.orderExecutedType || "SELL"}</Badge>
                                                </td> */}
                                                <td>{order.orderType}</td>
                                                <td>
                                                    <Badge bg={order.orderExcutedType === 'BUY' ? 'success' : 'danger'}>{order.orderExcutedType || "SELL"}</Badge>
                                                </td>
                                                <td>
                                                    <Dropdown>
                                                        <Link to="/orders-Tab">
                                                            <Dropdown.Toggle
                                                                variant={'success'}
                                                                className="light sharp i-false"
                                                            >
                                                                {svg1}
                                                            </Dropdown.Toggle>
                                                        </Link>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    // If there are no errors but the data array is empty
                                    <tr>
                                        <td colSpan="16" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        </td>
                                    </tr>
                                )}
                            </tbody>


                        </Table>
                        <div className="table-pagenation d-flex align-items-center justify-content-between ">
                            <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>

                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Orders;
