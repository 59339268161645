import React, { useState, useEffect } from "react";
import { Col, Card, Table, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { listVersion, activeVersion } from "../../../apolloClientConnection/apolloClient";
import { formatDateTime } from "../UserManagement/apis";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import swal from "sweetalert";
import useToast from "../Toaster/Toaster";

const VersionList = () => {
    const [versionData, setVersionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);
    const { notifySuccess, notifyWarning, notifyError } = useToast();

    const fetchVersionList = async () => {
        setLoading(true);
        setHasError(false);
        setHasData(true);
        try {
            const data = await listVersion();
            if (data.appSettingList && data.appSettingList.length > 0) {
                setVersionData(data.appSettingList);
            } else {
                setHasData(false);
            }
        } catch (error) {
            console.error("Failed to fetch version list:", error);
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleActiveToggle = async (versionPK, currentState) => {
        const newState = !currentState;
        swal({
            title: "Are you sure?",
            text: `You are about to ${newState ? "activate" : "deactivate"} this version.`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willConfirm) => {
            if (willConfirm) {
                try {
                    await activeVersion(versionPK, newState);
                    notifySuccess(`Version ${newState ? "activated" : "deactivated"} successfully!`);
                    
                    // Update the versionData state without fetching again
                    setVersionData((prevData) =>
                        prevData.map((item) =>
                            item.PK === versionPK ? { ...item, isActiveVersion: newState } : item
                        )
                    );
                } catch (error) {
                    console.error("Failed to update version status:", error);
                    notifyError("Failed to update version status");
                }
            }
        });
    };

    useEffect(() => {
        fetchVersionList();
    }, []);

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Version List</Card.Title>
                    <div className="form-group">
                        <div className="col-auto">
                            <Link to="/version">
                                <button type="button" className="btn btn-success">Add Version</button>
                            </Link>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {loading ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ) : hasError ? (
                        <div className="text-center">
                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                            <p>Connect To SuperAdmin</p>
                        </div>
                    ) : !hasData || versionData.length === 0 ? (
                        <div className="text-center">
                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                        </div>
                    ) : (
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="width80">
                                        <strong>S.No</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Android Version</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>IOS Version</strong>
                                    </th>
                                    <th className="text-center">
                                        Active
                                    </th>
                                    <th className="text-center">
                                        <strong>Date/Time</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {versionData.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <strong>{index + 1}</strong>
                                        </td>
                                        <td className="text-center"><strong>{item?.AndroidVersion || 'No Version'}</strong></td>
                                        <td className="text-center"><strong>{item?.IOSVersion || 'No Version'}</strong></td>
                                        <td className="text-center">
                                            <Button
                                                variant={item.isActiveVersion ? 'success' : 'secondary'}
                                                onClick={() => handleActiveToggle(item.PK, item.isActiveVersion)}
                                            >
                                                {item.isActiveVersion ? 'On' : 'Off'}
                                            </Button>
                                        </td>
                                        <td className="text-center">
                                            <strong>{formatDateTime(item.createdAt)}</strong>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Card.Body>
            </Card>
        </Col>
    );
};

export default VersionList;
