import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { listWithdrawlRequest, withDrawalRequest, fetchUserProfile, banckDetails } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json"
import { formatDateTime1 , formatDateTime} from "../UserManagement/apis";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
]

const WithDraw = () => {
    const [disabledRows, setDisabledRows] = useState({});
    const [filter, setFilter] = useState("");
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);
    const { notifyError, notifySuccess } = useToast();
    const [withdrawalData, setWithdrawalData] = useState([])
    const navigate = useNavigate();


    const handleAction = async (action, id, sk, pk) => {
        setDisabledRows((prevState) => ({
            ...prevState,
            [id]: true,
        }));


        try {
            let response = await withDrawalRequest(sk, action, pk);

            if (response) {
                // Notify success first
                notifySuccess(`User Withdrawal Amount ${action}`);

                // Update only the status of the specific withdrawal in the local state
                setWithdrawalData(prevData => prevData.map(item => {
                    if (item.SK === sk) {
                        return { ...item, status: action === "COMPLETED" ? "COMPLETED" : "CANCELED" };
                    }
                    return item;
                }));

                setDisabledRows((prevState) => ({
                    ...prevState,
                    [id]: false,
                }));
            }

        } catch (error) {
            console.error("Error updating status: ", error);
            notifyError("Error updating status");
        }
    };

    const renderDropdownItem = (action, id, sk, status, pk) => (
        <Dropdown.Item
            key={action}
            onClick={() => handleAction(action, id, sk, pk)}
            disabled={status !== "PENDING"}
        >
            {action}
        </Dropdown.Item>
    );

    const renderDropdownItems = (id, sk, status, pk) => {
        const actions = ["CANCELED", "COMPLETED"];
        if (status === "PENDING") {
            return actions.map(action => renderDropdownItem(action, id, sk, status, pk));
        } else {
            return null;
        }
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const withdrawals = await listWithdrawlRequest();

            if (withdrawals && withdrawals.length > 0) {
                const dataPromises = withdrawals.map(async (item) => {
                    const [profile, bankDetails] = await Promise.all([
                        fetchUserProfile({ PK: item.PK, SK: '' }),
                        banckDetails(item.PK)
                    ]);
                    let userProfileData = JSON.parse(profile.profile);
                    let bankAccount = bankDetails.getBankAccount[0];


                    return {
                        ...item,
                        fullName: bankAccount ? bankAccount.full_name : "No Data",
                        account: bankAccount ? bankAccount.account : "No Data",
                        bankName: bankAccount && bankAccount.ifsc_details ? bankAccount.ifsc_details.bank : bankAccount?.bankName,
                        phone: bankAccount && bankAccount.ifsc_details ? bankAccount.ifsc_details.contact : "No Data",
                        ifsc: bankAccount && bankAccount.ifsc_details ? bankAccount.ifsc_details.ifsc : bankAccount?.ifscCode,
                        amount: item?.priceAmount,
                        status: item?.withdrawalRequestType,
                        SK: item?.SK,
                        userData: userProfileData
                    };
                });
                const updatedData = await Promise.all(dataPromises);
                

                setWithdrawalData(updatedData);
            } else {

                setWithdrawalData([]);
            }
            setHasError(false);
        } catch (error) {
            console.error("Failed to fetch data: ", error);
            notifyError("Failed to fetch data");
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredData = useMemo(() =>
        withdrawalData.filter((item) =>
            Object.values(item).some(
                (value) =>
                    typeof value === "string" &&
                    value.toLowerCase().includes(filter.toLowerCase())
            )
        ), [withdrawalData, filter]);

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleSearch = () => {
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleRowClick = (pk, sk) => {
        localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
        navigate("/user-data");
    };


    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Withdraw</Card.Title>
                        <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                                <div className="d-flex align-items-center">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control ms-2"
                                        placeholderText="End Date"
                                    />
                                    <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                                </div>
                            </Col>
                        </Row>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>ID</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Name</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Phone/Email</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Bank Name</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>A/C No.</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>IFSC</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Amount</strong>
                                    </th>
                                    <th>
                                        <strong>Status</strong>
                                    </th>
                                    <th>
                                        <strong>Requested Time</strong>
                                    </th>
                                    <th>
                                        <strong>Response Time</strong>
                                    </th>
                                    <th>Approve/Reject</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="11" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : hasError ? (
                                    <tr>
                                        <td colSpan="11" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : !hasData || filteredData.length === 0 ? (
                                    <tr>
                                        <td colSpan="11" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        </td>
                                    </tr>
                                ) : (filteredData.map((item, index) => (
                                    <tr key={item.id}>
                                        <td><strong>{index + 1}</strong></td>
                                        <td className="text-center">{item.fullName}</td>
                                        {/* <td className="text-center">{item.phone === null ? 'No Data' : item.phone}</td> */}
                                        <td className="text-center">
                                            <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }} onClick={() => handleRowClick(item.PK, item.userData.SK)}>
                                                {item.userData ? item.userData.phone : 'N/A'}
                                                {item.userData ? item.userData.email && <div>{item.userData.email}</div> : ''}
                                            </div>
                                        </td>
                                        <td className="text-center">{item?.bankName ? item?.bankName : 'No Data'}</td>
                                        <td className="text-center">{item.account}</td>
                                        <td className="text-center">{item.ifsc ? item.ifsc : 'No Data'}</td>
                                        <td className="text-center"><span className="me-2">{item.priceAmount}</span></td>
                                        <td>
                                            <span className={`badge light badge-${item.status === "PENDING" ? "warning" : item.status === "CANCELED" ? "danger" : "success"}`}>
                                                {item.status}
                                            </span>
                                        </td>
                                        <td>{formatDateTime1(item)}</td>
                                        <td>{formatDateTime(item.createdAt)}</td>
                                        <td className="text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="success"
                                                    className={`light sharp i-false ${item.status !== "PENDING" ? 'disabled-toggle' : ''}`}
                                                    disabled={item.status !== "PENDING"}
                                                >
                                                    {item.status === "PENDING" ?
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                            </g>
                                                        </svg>
                                                        :
                                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                            <line x1="4" y1="9" x2="20" y2="15" stroke="currentColor" strokeWidth="2" />
                                                        </svg>
                                                    }
                                                </Dropdown.Toggle>
                                                {item.status === "PENDING" && (
                                                    <Dropdown.Menu>
                                                        {renderDropdownItems(item.id, item.SK, item.status, item.PK)}
                                                    </Dropdown.Menu>
                                                )}
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )))}
                            </tbody>
                        </Table>
                        <div className="table-pagination d-flex align-items-center justify-content-between">
                            <p className="mb-0">
                                Showing <span> 1-3 </span> from <span> {withdrawalData.length} </span> data
                            </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to="#" className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to="#" className="page-link">1</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to="#" className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default WithDraw;
