import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-bootstrap";
import useToast from '../../Toaster/Toaster';
import Loader from "../../Loader/loader";
import LottieAnimation from "../../../../gif/LottieAnimation ";
import NetworkError from "../../../../gif/networkError.json";
import noDataAnimation from "../../../../gif/Data Error.json";
import { cancelOrder } from "../../../../apolloClientConnection/apolloClient";


const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]


const CancelOrder = () => {
    const [filter, setFilter] = useState(''); // State to keep track of the filter text
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [orderDataModalShow, setOrderDataModalShow] = useState(false);
    const [userPK, setUserPK] = useState('');
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null); // State variable to store the selected order data
    const { notifyError } = useToast();

    useEffect(() => {
        const UserDocument = localStorage.getItem("UserDataInfoDocument");
        const ParseUserData = JSON.parse(UserDocument || '{}');

        if (ParseUserData.PK) {
            setUserPK(ParseUserData.PK);
        }
    }, []);




    useEffect(() => {
        if (userPK) {
            fetchUserBuySell(userPK);
        }
    }, [userPK]);

    const fetchUserBuySell = async (userPK) => {
        setLoading(true);

        try {
            const data = await cancelOrder(userPK, 'YES');
            if (data && data.deleteTradeOrderList) {
                setOrders(data.deleteTradeOrderList);
            } else {
                setOrders([]);
            }
            setHasError(false);
        } catch (error) {
            console.error("Error fetching orders:", error);
            setOrders([]);
            notifyError("Failed to fetch data");
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const filteredOrders = orders.filter(order =>
        Object.values(order).some(
            value =>
                typeof value === "string" &&
                value.toLowerCase().includes(filter.toLowerCase())
        )
    ).sort((a, b) => b.createdAt - a.createdAt);


    // Update the displayed data when the filter changes
    const handleFilterChange = (e) => {
        const filterText = e.target.value;
        setFilter(filterText);
        // setData(filterData(filterText));
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };

    const handleRowClick = (order) => {
        setSelectedOrder(order);
        setOrderDataModalShow(true);
    };

    function formatDateTime(timestamp) {
        const date = new Date(Number(timestamp));
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <>
            <div className="table-responsive dataTabletrade ">
                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-3 mt-3" style={{ maxWidth: '100%' }}>
                    {/* Filter Input on the Left */}
                    <div style={{ width: '200px', flex: '0 0 auto' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    {/* Date Pickers and Download Button on the Right */}
                    <div className="d-flex justify-content-between justify-content-lg-end align-items-center mt-3 mt-lg-0">
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control"
                                    placeholderText="Start Date"
                                />
                            </div>
                            <div className="ms-2">
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control"
                                    placeholderText="End Date"
                                />
                            </div>
                            <button className="btn btn-primary ms-2 me-3" onClick={handleSearch}>Search</button>
                        </div>
                        <Link to={"#"} className="btn btn-primary light btn-rounded me-3 mb-3 mb-lg-0"><i className="las la-download scale5 me-2"></i>Download</Link>
                    </div>
                </div>


                <div id="futureorder_wrapper" className="dataTables_wrapper no-footer">
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Pair</th>
                                <th>OrderID</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Total</th>
                                <th>Date</th>
                                <th>Order Type</th>
                                <th><span className="text-success">Buy</span>/<span className="text-danger">Sell</span></th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="12" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="12" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : !hasData || filteredOrders?.length === 0 ? (
                                <tr>
                                    <td colSpan="12" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (filteredOrders?.map((order, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td>{order.shareName}</td>
                                    <td>{order.coinPairWith}</td>
                                    <td className="color-primary">{order.orderId}</td>
                                    <td className="color-primary">{order.quantity}</td>
                                    <td className="color-primary">₹ {order.price}</td>
                                    <td className="color-primary">₹ {order.quantity * order.price}</td>
                                    <td>{order?.date ? order?.date : formatDateTime(order.createdAt)}</td>
                                    <td className="color-primary">{order.tradingType}</td>
                                    <td>
                                        <span className={`badge light badge-${order.beforeDeleteType === "BUY" ? "success" : "danger"}`}>
                                            {order.beforeDeleteType}
                                        </span>
                                    </td>
                                    <td className="color-primary">
                                        <td className={`text-${order.totalQuantity === order.quantity ? 'danger' : 'warning'}`}>
                                            {order.totalQuantity === order.quantity ? (
                                            'Cancelled'
                                        ) : (
                                            <>
                                                Partially<br />Cancelled
                                            </>
                                        )}
                                        </td>
                                    </td>

                                </tr>
                            )))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        {/* <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p> */}
                        <div className="form-group mb-3 col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>

                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CancelOrder;
