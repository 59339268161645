import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { fetchUserByPagination, banckDetails, addRecharge } from "../../../apolloClientConnection/apolloClient";
import Swal from "sweetalert2";

const Recharge = () => {
    const [inputValue, setInputValue] = useState("");
    const [amount, setAmount] = useState("");
    const [userData, setUserData] = useState(null); // State to store fetched user data
    const [accountNumber, setAccountNumber] = useState(""); // State to store the account number
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to control submit button
    const [showNoAccountMessage, setShowNoAccountMessage] = useState(false); // State to show/hide the no account message

    useEffect(() => {
        // Enable the submit button only if all inputs are filled
        if (inputValue && amount && (accountNumber || showNoAccountMessage)) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [inputValue, amount, accountNumber, showNoAccountMessage]);

    const handleInputChange = async (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Clear user data and account number if input is cleared
        if (value.trim() === "") {
            setUserData(null);
            setAccountNumber("");
            setShowNoAccountMessage(false); // Reset the no account message
            return;
        }

        // Determine if the input is a phone number or an email
        const isPhoneNumber = /^\d+$/.test(value) && value.length >= 10;
        const isEmail = value.includes("@");

        if (isPhoneNumber || isEmail) {
            try {
                const response = await fetchUserByPagination(
                    null,
                    null,
                    null,
                    null,
                    null,
                    isPhoneNumber ? value : null,
                    isEmail ? value : null
                );
                if (response.success && response.data.items.length > 0) {
                    const user = response.data.items[0];
                    setUserData(user); // Store the first user item data

                    // Call the bankDetails API with the user's PK
                    const bankDetailsResponse = await banckDetails(user.PK, false);

                    // Check if bank details exist and extract the account number
                    if (bankDetailsResponse && bankDetailsResponse.getBankAccount.length > 0) {
                        const bankAccount = bankDetailsResponse.getBankAccount[0];
                        setAccountNumber(bankAccount.account || ""); // Set the account number if it exists
                        setShowNoAccountMessage(false); // Hide the no account message if the account exists
                    } else {
                        setAccountNumber(""); // Clear the account number if no data is found
                        setShowNoAccountMessage(true); // Show the no account message if no account is found
                    }
                } else {
                    setUserData(null); // Clear user data if no data is found
                    setAccountNumber(""); // Clear the account number as well
                    setShowNoAccountMessage(false); // Hide the no account message
                }
            } catch (error) {
                console.error("Error fetching user or bank data:", error.message);
                setUserData(null); // Clear user data on error
                setAccountNumber(""); // Clear the account number on error
                setShowNoAccountMessage(false); // Hide the no account message on error
            }
        }
    };

    const handleRecharge = async () => {
        // Show confirmation before proceeding with the recharge
        const confirmation = await Swal.fire({
            title: 'Are you sure?',
            text: `You are about to recharge ${amount} to account: ${accountNumber}. This action cannot be undone.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, recharge it!',
            cancelButtonText: 'Cancel'
        });

        if (confirmation.isConfirmed) {
            try {
                const response = await addRecharge(amount, accountNumber, inputValue);


                if (response.addPriceToWallet.success == true) {
                    Swal.fire({
                        icon: "success",
                        title: "Recharge Successful",
                        text: `Recharge of ${amount} was successful for account: ${accountNumber}`,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Recharge Failed",
                        text: response.message || "An error occurred during the recharge process.",
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Recharge Failed",
                    text: error.message || "An error occurred during the recharge process.",
                });
            }
        }
    };

    return (
        <Row>
            <Col md={6}>
                <Card className="mb-4">
                    <Card.Header>
                        <Card.Title>Recharge</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formInput">
                                <Form.Label>Enter Phone Number or Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter phone number or email"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                />
                            </Form.Group>

                            {/* Conditionally render the account number input or show the no account message */}
                            {accountNumber ? (
                                <Form.Group className="mb-3" controlId="formAccountNumber">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account number"
                                        value={accountNumber}
                                        onChange={(e) => setAccountNumber(e.target.value)}
                                        autoComplete="off"
                                    />
                                </Form.Group>
                            ) : (
                                showNoAccountMessage && (
                                    <p style={{ color: "red" }}>No bank account added in this account</p>
                                )
                            )}

                            <Form.Group className="mb-3" controlId="formAmount">
                                <Form.Label>Enter Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    autoComplete="off"
                                />
                            </Form.Group>

                            {/* Button aligned to the right */}
                            <div className="d-flex justify-content-end">
                                <Button
                                    variant="success"
                                    onClick={handleRecharge}
                                    disabled={isSubmitDisabled} // Disable the button if inputs are not filled
                                >
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>

            {/* Conditionally render the user information card when data is available */}
            {userData && (
                <Col md={6}>
                    <Card className="mb-4">
                        <Card.Header>
                            <Card.Title>User Information</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={4}>
                                    <strong>Name:</strong>
                                </Col>
                                <Col xs={8}>
                                    {userData.full_name || "Guest"}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={4}>
                                    <strong>Email:</strong>
                                </Col>
                                <Col xs={8}>
                                    {userData.email || "N/A"}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col xs={4}>
                                    <strong>Phone:</strong>
                                </Col>
                                <Col xs={8}>
                                    {userData.phone || "N/A"}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </Row>
    );
};

export default Recharge;
