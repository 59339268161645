import React, { useState } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';

const ListOfRole = () => {
    // Sample data
    const [data, setData] = useState([
        {
            sno: 1,
            rollName: "Admin",
            tabs: ["Dashboard", "User Management", "Orders"]
        },
        {
            sno: 2,
            rollName: "Moderator",
            tabs: ["Dashboard", "Orders"]
        },
        // Add more role data as needed
    ]);

    const [filter, setFilter] = useState('');

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = (filter === '') ? data : data.filter(item =>
        item.rollName.toLowerCase().includes(filter.toLowerCase())
    );

    // SVG Icon
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.04,5.21 L18.79,8.96 L20.71,7.04 Z" fill="#000000" />
        </g>
    </svg>
    );

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>List of Roles</Card.Title>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    <strong>S.No</strong>
                                </th>
                                <th>
                                    <strong>Role Name</strong>
                                </th>
                                <th>
                                    <strong>Tab</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Edit</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td rowSpan={item.tabs.length}>{item.sno}</td>
                                        <td rowSpan={item.tabs.length}>{item.rollName}</td>
                                        <td >{item.tabs[0]}</td>
                                        <td className="text-center" rowSpan={item.tabs.length}>
                                            <Dropdown>
                                                <Link to="/add-role">
                                                    <Dropdown.Toggle
                                                        variant={item.status === 'Active' ? 'success' : 'danger'}
                                                        className="light sharp i-false"
                                                    >
                                                        {svg1}
                                                    </Dropdown.Toggle>
                                                </Link>
                                                {/* <Dropdown.Menu>
                                                    <Dropdown.Item>Edit</Dropdown.Item>
                                                    <Dropdown.Item>Delete</Dropdown.Item>
                                                </Dropdown.Menu> */}
                                            </Dropdown>
                                        </td>
                                    </tr>
                                    {item.tabs.slice(1).map((tab, tabIndex) => (
                                        <tr  key={tabIndex}>
                                            <td>{tab}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default ListOfRole;
