import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const optionValue = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 25, label: '25' }, // Changed default value to 25
  { value: 30, label: '30' },
]

const tabDataBlog = [
  { Date: "01/01/2024 09:18AM", CoinName: "BTC", Naration: "INR Deposit", BUY: "120", SELL: "80", Balance: "40" },
  { Date: "12/02/2001 07:36AM", CoinName: "CCD", Naration: "INR Withdrawal", BUY: "90", SELL: "30", Balance: "0" },
];

const International = () => {
  const [filter, setFilter] = useState(''); // State to keep track of the filter text
  const [data, setData] = useState(tabDataBlog); // Use the initial data as the default state
  const [perPage, setPerPage] = useState(25);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  // Function to filter data based on the filter text
  const filterData = (filterText) => {
    if (!filterText) {
      return tabDataBlog; // If no filter is applied, return the original data
    }

    return tabDataBlog.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(filterText.toLowerCase())
      )
    );
  };


  // Update the displayed data when the filter changes
  const handleFilterChange = (e) => {
    const filterText = e.target.value;
    setFilter(filterText);
    setData(filterData(filterText));
  };

  const handlePerPageChange = (selectedOption) => {
    setPerPage(selectedOption.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearch = () => {
    // Implement filtering based on selected date range
    // You can use startDate and endDate states to filter the data
    // console.log("Start Date:", startDate);
    // console.log("End Date:", endDate);
  };

  return (
    <>
      <div className="table-responsive dataTabletrade ">
      <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-3 mt-3" style={{ maxWidth: '100%' }}>
    {/* Filter Input on the Left */}
    <div style={{ width: '200px', flex: '0 0 auto' }}>
        <input
            type="text"
            className="form-control"
            placeholder="Filter..."
            value={filter}
            onChange={handleFilterChange}
        />
    </div>

    {/* Date Pickers and Download Button on the Right */}
    <div className="d-flex justify-content-between justify-content-lg-end align-items-center mt-3 mt-lg-0">
        <div className="d-flex align-items-center">
            <div className="mr-2">
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    className="form-control"
                    placeholderText="Start Date"
                />
            </div>
            <div className="ms-2">
                <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    className="form-control"
                    placeholderText="End Date"
                />
            </div>
            <button className="btn btn-primary ms-2 me-3" onClick={handleSearch}>Search</button>
        </div>
        <Link to={"#"} className="btn btn-primary light btn-rounded me-3 mb-3 mb-lg-0"><i className="las la-download scale5 me-2"></i>Download</Link>
    </div>
</div>


        <div id="futureorder_wrapper" className="dataTables_wrapper no-footer">
          <table id="example" className="table display dataTable no-footer" style={{ minWidth: "845px" }}>
            <thead>
              <tr>
                <th>Date/Time</th>
                <th>Coin Name</th>
                <th>Narration</th>
                <th className='text-success'>BUY</th>
                <th className='text-danger'>SELL</th>
                <th className="text-end">Balance</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.Date}</td>
                  <td>{item.CoinName}</td>
                  <td>
                    <Badge bg={item.Naration === "INR Deposit" ? "success" : "danger"}>
                      {item.Naration}
                    </Badge>
                  </td>
                  <td className='text-success'>{item.BUY}</td>
                  <td className='text-danger'>{item.SELL}</td>
                  <td className="text-end">{item.Balance}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
            {/* <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p> */}
            <div className="form-group mb-3 col-md-1">
              <Select
                options={optionValue}
                value={{ value: perPage, label: perPage.toString() }}
                onChange={handlePerPageChange}
                className="custom-react-select"
              />
            </div>
            <nav>
              <ul className="pagination pagination-gutter pagination-primary no-bg">
                <li className="page-item page-indicator">
                  <Link to={"#"} className="page-link">
                    <i className="fa-solid fa-angle-left"></i>
                  </Link>
                </li>
                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>

                <li className="page-item page-indicator">
                  <Link to={"#"} className="page-link">
                    <i className="fa-solid fa-angle-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default International;
