import {
  runLogoutTimer,
  saveTokenInLocalStorage
} from "../../services/AuthService";

import { login } from "../../apolloClientConnection/apolloClient"
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";



export function Logout(navigate) {
  localStorage.removeItem("authToken");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("tabData");
  localStorage.removeItem("dataPKSK");
  localStorage.removeItem("templateData");
  localStorage.removeItem("adminPKSK");
  localStorage.removeItem("allUsersData");
  localStorage.clear();

  if (typeof navigate === 'function') { // Correct check using typeof
    navigate("/main");
  } else {
    console.error("navigate is not a function");
  }

  return {
    type: LOGOUT_ACTION,
  };
}


function saveTokenLocalStorage(data) {
  // Save data to local storage
  localStorage.setItem('authToken', data);
}

export function loginAction(username, password, password1, navigate, notifySuccess, notifyError, notifyWarning, startLoading, stopLoading, ipAddress) {
  startLoading();
  return (dispatch) => {
    login(username, password, password1, ipAddress, 'ADMIN')
      .then((response) => {
        stopLoading()
        if (response.Adminlogin.userName == username) {
          let jsonData = {
            code: 200,
            idToken: response.Adminlogin.accessToken,
            expiresIn: response.Adminlogin.LogoutTime,
            user: "SuperAdmin"
          }
          saveTokenInLocalStorage(jsonData);
          runLogoutTimer(dispatch, 5 * 60 * 1000, navigate);
          dispatch(loginConfirmedAction(jsonData));

          if (response?.Adminlogin?.role === 'ADMIN' || 'SUPER_ADMIN') {
            localStorage.setItem("lockScreenPassword", password);
            localStorage.setItem("adminPKSK", JSON.stringify({ PK: response?.Adminlogin?.PK, SK: response?.Adminlogin?.SK }));
            saveTokenLocalStorage(response?.Adminlogin?.accessToken)
            notifySuccess('Login Successfull !');
            let templateData = response?.Adminlogin?.tamplate
            localStorage.setItem("templateData", JSON.stringify(templateData));
            navigate("/welcome");
          }
        } else if (response?.Adminlogin?.userName === null) {

          notifyWarning('Invalid username!');
        }
        else {
          notifyError(response.data.message + ' !');
        }
      })
      .catch((error) => {
        stopLoading()
        if (error.message.includes('Invalid Password')) {
          notifyWarning('Invalid Password!');
        } else if (error.message.includes('Acc Not found')) {
          notifyWarning('Invalid username!');
        }
        else if (error.message.includes('Account Not found')) {
          notifyWarning('Account Not found');
        }
        else if (error.message.includes('Failed to authenticate password')) {
          notifyWarning('Invalid Password!');
        } else {
          notifyError('Network Error!')
        }
      });
  };
}


export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}



export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
