import React, { useEffect, useState, useRef } from 'react';
import { Col, Card, Modal, Image } from "react-bootstrap";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageSeparator
} from "@chatscope/chat-ui-kit-react";
import { fetchUserProfile, fetchHelpSupportChat, addChat } from '../../../apolloClientConnection/apolloClient';
import { FaDownload } from 'react-icons/fa';
import profile1 from "./../../../images/profile/pic1.jpg";
import { useSubscription, gql } from '@apollo/client';
import DOMPurify from 'dompurify';
import { BsArrowLeft } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { getHelpSupportImage } from '../StorJ/StorJFunction';

const LIVE_CHAT_SUBSCRIPTION = gql`
  subscription ChatAdded {
    chatAdded {
      id
      ticketId
      text
      chatBy
      createdAt
    }
  }
`;

const HelpSupportMessage = () => {
  const [messages, setMessages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const location = useLocation();
  const userMessage = useRef(null);
  const fetchedData = useRef(false); // Use a ref to track whether data has been fetched
  const { data: newChatMessage } = useSubscription(LIVE_CHAT_SUBSCRIPTION);
  const navigate = useNavigate();

  useEffect(() => {
    if (newChatMessage) {
      const newTicketId = newChatMessage.chatAdded.ticketId;
      if (userMessage.current && userMessage.current.TicketId === newTicketId) {
        const parsedMessage = subscriptionParseMessage(newChatMessage.chatAdded, messages.length);
        setMessages(prevMessages => [...prevMessages, parsedMessage]);
      }
    }
  }, [newChatMessage]);

  useEffect(() => {
    if (location.state && location.state.convertStringcomplainData) {
      userMessage.current = location.state.convertStringcomplainData;
      fetchData(userMessage.current);
    }
  }, [location.state]);

  const subscriptionParseMessage = (message, index) => {
    const { text, chatBy, createdAt } = message;
    const direction = chatBy === 'USER' ? 'incoming' : 'outgoing';
    const date = new Date(createdAt);

    return {
      id: `${direction}-${index}`,
      text,
      timestamp: date.getTime(), // Using timestamp as a number for sorting
      date: date.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' }),
      direction,
    };
  };

  const parseMessage = (message, index, direction) => {
    const [text, timestamp] = message.split('@@');
    const date = new Date(timestamp);
    return {
      id: `${direction}-${index}`,
      text,
      timestamp: date.getTime(), // Using timestamp as a number for sorting
      date: date.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' }),
      direction,
    };
  };

  const fetchData = async (userMessage) => {
    if (fetchedData.current) {
      return; // Prevents re-fetching
    }

    try {
      if (userMessage) {
        const distractlink = await getHelpSupportImage(userMessage.File_link);

        const fileExtension = userMessage.File_link.split('.').pop().toLowerCase();
        let messageContent;

        if (['jpg', 'jpeg', 'png', 'heic', 'bmp'].includes(fileExtension)) {
          messageContent = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={distractlink}
                alt="User Image"
                style={{ maxWidth: "100%", maxHeight: "200px", cursor: 'pointer' }}
                onClick={() => handleImageClick(distractlink)}
              />
              <a href={distractlink} download>
                <FaDownload style={{
                  marginLeft: '20px',
                  color: 'black',
                  fontSize: '25px',
                  backgroundColor: '#c6e3fa',
                  borderRadius: '50%',
                  padding: '5px',
                }} />
              </a>
            </div>
          );
        } else if (['pdf', 'zip'].includes(fileExtension)) {
          messageContent = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXjAs3Dxo8W1hFytLhe2v6A-MTXcI-4Er1nqZCjatv4Q&s'
                style={{ maxWidth: "100%", maxHeight: "200px", cursor: 'pointer' }}
                onClick={() => handleImageClick('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXjAs3Dxo8W1hFytLhe2v6A-MTXcI-4Er1nqZCjatv4Q&s')}
              />
              <a href={distractlink} download>
                <FaDownload style={{
                  marginLeft: '20px',
                  color: 'black',
                  fontSize: '25px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '50%',
                  padding: '5px',
                }} />
              </a>
            </div>
          );
        } else {
          messageContent = distractlink;
        }
        setFileContent(messageContent);
        try {
          const userProfile = await fetchUserProfile({ PK: userMessage.UserPk, SK: "" });
          setUserData(JSON.parse(userProfile?.profile));
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
        }

        try {
          const fetchChat = await fetchHelpSupportChat(userMessage.TicketId);
          const chatData = JSON.parse(fetchChat.get_help_support_chat)[0];

          const userMessages = chatData.userquery.map((msg, index) => parseMessage(msg, index, 'incoming'));
          const adminMessages = chatData.AdminReply.map((msg, index) => parseMessage(msg, index, 'outgoing'));

          setMessages([...userMessages, ...adminMessages]);

          fetchedData.current = true; // Data has been successfully fetched
        } catch (error) {
          console.error('Failed to fetch chat data:', error);
        }
      }
    } catch (error) {
      console.error('Failed to fetch link or chat:', error);
    }
  };

  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImageUrl(null);
  };

  const handleAdminMessageInput = async (text) => {
    try {
      // Sanitize the input to remove any HTML tags and inline styles
      const sanitizedText = DOMPurify.sanitize(text, { ALLOWED_TAGS: [], ALLOWED_ATTRS: [] });

      const now = new Date();
      const newMessage = {
        id: `admin-${messages.length}`,
        text: sanitizedText,
        timestamp: now.getTime(),
        date: now.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' }),
        direction: "outgoing"
      };

      // Add the chat to the server
      await addChat(userMessage.current.TicketId, sanitizedText, "");
      // Update local state
      // setMessages(prevMessages => [...prevMessages, newMessage]);
    } catch (error) {
      console.error("Failed to add chat:", error);
    }
  };

  const sortedMessages = messages.sort((a, b) => a.timestamp - b.timestamp);

  return (
    <Col lg={12}>
      <Card>
        <Card.Header>
          <div className="d-flex align-items-center">
            <div onClick={() => navigate('/help&support-list')} className="cursor-pointer">
              <div className="back-icon-wrapper">
                <BsArrowLeft className="back-icon" />
              </div>
            </div>
            <Card.Title className="mb-0 ms-3">
              {userData && userData?.full_name ? userData?.full_name : "Guest"}
            </Card.Title>
          </div>
        </Card.Header>
        <div style={{ position: "relative", height: "500px" }}>
          <MainContainer>
            <ChatContainer >
              <MessageList>
                {fileContent && (
                  <div className="mt-2 mb-2 ">
                    {fileContent}
                  </div>
                )}
                {sortedMessages.map((msg, index, array) => {
                  const showDate = index === 0 || msg.date !== array[index - 1].date;
                  return (
                    <React.Fragment key={msg.id}>
                      {showDate && <MessageSeparator content={msg.date} />}
                      <Message
                        key={msg.id}
                        model={{
                          message: msg.text,
                          direction: msg.direction,
                          sentTime: msg.timestamp,
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </MessageList>
              <MessageInput placeholder="Type message here" onSend={handleAdminMessageInput} />
            </ChatContainer>
          </MainContainer>
        </div>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          <img src={modalImageUrl} alt="Large View" style={{ width: '100%' }} />
        </Modal.Body>
        <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default HelpSupportMessage;
