import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Badge
} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const data = [
    {
        name: 'Sarita',
        grossAmount: '4945.64',
        tdsDeducted: '494.56',
        panNo: 'ABCDE1234F',
        dob: '1990-01-01',
        aadharNo: '123456789012',
        mNo: '9876543210',
        email: 'sarita@gmail.com',
        address: '123, Lotus Road, Mumbai',
    },
    {
        name: 'Babita',
        grossAmount: '3535.64',
        tdsDeducted: '353.56',
        panNo: 'BCDEF1234G',
        dob: '1992-02-02',
        aadharNo: '234567890123',
        mNo: '8765432109',
        email: 'babita@gmail.com',
        address: '456, Rose Street, New Delhi',
    },
];


const TDSDeduction = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };


    const handleEndDateChange = (date) => {
        setEndDate(date);
    };


    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };


    return (
        <div className="row">
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>TDS Deduction</Card.Title>
                        <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                                <div className="d-flex align-items-center">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control ms-2"
                                        placeholderText="End Date"
                                    />
                                    <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                                </div>
                            </Col>
                        </Row>
                        <Table responsive bordered className="header-border ">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th className="text-center">Deductee/Employee Name</th>
                                    <th className="text-center">Gross Amount</th>
                                    <th className="text-center">TDS deducted</th>
                                    <th className="text-center">Pan No.</th>
                                    <th className="text-center">DOB</th>
                                    <th className="text-center">Aadhar No</th>
                                    <th className="text-center">M.No</th>
                                    <th className="text-center">Email id</th>
                                    <th className="text-end">Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>  {/* S.No based on index */}
                                        <td className="text-center">{item.name}</td>
                                        <td className="text-center">{item.grossAmount}</td>
                                        <td className="text-center">{item.tdsDeducted}</td>
                                        <td className="text-center">{item.panNo}</td>
                                        <td className="text-center">{item.dob}</td>
                                        <td className="text-center">{item.aadharNo}</td>
                                        <td className="text-center">{item.mNo}</td>
                                        <td className="text-center">{item.email}</td>
                                        <td className="text-end">{item.address}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </Table>
                        <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                            <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                    <li className="page-item "><Link to={"#"} className="page-link">2</Link></li>
                                    <li className="page-item"><Link to={"#"} className="page-link">3</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </Card.Body>
                </Card>
            </Col>
        </div>
    )
}

export default TDSDeduction