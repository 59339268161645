import React, { useState, useEffect } from "react";
import UserNav from './UserNav';
import { banckDetails } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";

const BankDetails = () => {
    const [userPK, setUserPK] = useState('');
    const [bankDetailsArray, setBankDetailsArray] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [virtualAccountDetails, setVertualAccountDetails] = useState('');
    const { notifyWarning, notifySuccess, notifyError } = useToast();

    const [disabledStates, setDisabledStates] = useState({
        userAccountDetails: true,
        virtualAccountDetails: true,
    });

    const toggleEdit = (cardKey) => {
        setDisabledStates(prevState => ({
            ...prevState,
            [cardKey]: !prevState[cardKey],
        }));
    };

    useEffect(() => {
        const UserDocument = localStorage.getItem("UserDataInfoDocument");
        const ParseUserData = JSON.parse(UserDocument || '{}');

        console.log('this is account number',ParseUserData )
       const virtualAcNo = ParseUserData?.virtualAccountNo ? ParseUserData?.virtualAccountNo :ParseUserData?.virtualAccountNumber
        setVertualAccountDetails(virtualAcNo)
        if (ParseUserData.PK) {
            setUserPK(ParseUserData.PK);
        }
    }, []);

    useEffect(() => {
        if (userPK) {
            fetchBankData(userPK);
        }
    }, [userPK]);

    const fetchBankData = async (userPK) => {
        try {
            const data = await banckDetails(userPK);
            if (data && data.getBankAccount) {
                setBankDetailsArray(data.getBankAccount);
                if (data.getBankAccount.length > 0) {
                    notifySuccess("Bank details fetched successfully.");
                } else {
                    notifyWarning("No bank details found.");
                }
            }
        } catch (error) {
            console.error('Error fetching bank details:', error);
            notifyError("Error fetching bank details.");
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div className="row">
            <div className="col-xl-12">
                <UserNav />
            </div>
            <div className="col-xl-6">
                <div className="card h-auto">
                    <div className="card-header">
                        <h4 className="card-title">User Bank Account Details</h4>
                        {bankDetailsArray.length > 1 && (
                            <ul className="nav nav-tabs">
                                {bankDetailsArray.map((_, index) => (
                                    <li className="nav-item" key={index}>
                                        <button
                                            className={`nav-link ${activeTab === index ? 'active' : ''}`}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="card-body">
                        {bankDetailsArray.length > 0 && (
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                            name="full_name"
                                            value={bankDetailsArray[activeTab]?.full_name || ''}
                                            disabled={disabledStates.userAccountDetails}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">A/C No</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Account Number"
                                            name="account"
                                            value={bankDetailsArray[activeTab]?.account || ''}
                                            disabled={disabledStates.userAccountDetails}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">IFSC Code</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="IFSC Code"
                                            name="ifscCode"
                                            value={
                                                bankDetailsArray[activeTab]?.ifsc_details?.ifsc ||
                                                bankDetailsArray[activeTab]?.ifscCode ||
                                                ''
                                            }
                                            disabled={disabledStates.userAccountDetails}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">Bank Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Bank Name"
                                            name="bankName"
                                            value={
                                                bankDetailsArray[activeTab]?.ifsc_details?.bank_name ||
                                                bankDetailsArray[activeTab]?.bankName ||
                                                ''
                                            }
                                            disabled={disabledStates.userAccountDetails}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">Branch</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Branch"
                                            name="branch"
                                            value={
                                                bankDetailsArray[activeTab]?.ifsc_details?.branch ||
                                                bankDetailsArray[activeTab]?.branch ||
                                                ''
                                            }
                                            disabled={disabledStates.userAccountDetails}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">A/C Address</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Accounts Address"
                                            name="address"
                                            value={
                                                bankDetailsArray[activeTab]?.ifsc_details?.address||
                                                bankDetailsArray[activeTab]?.address                                                ||
                                                ''
                                            }
                                            disabled={disabledStates.userAccountDetails}
                                        />
                                    </div>
                                </div>
                                {/* <div className="mb-3 row">
                                    <label className="col-sm-3 col-form-label">Status</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Status"
                                            value={bankDetailsArray[activeTab]?.account_exists == null ? 'Active' : 'Inactive'}
                                            disabled={disabledStates.userAccountDetails}
                                        />
                                    </div>
                                </div> */}
                                <div className="mb-3 row">
                                    <div className="col-sm-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => toggleEdit('userAccountDetails')}>
                                            {disabledStates.userAccountDetails ? 'Edit' : 'Cancel'}
                                        </button>
                                    </div>
                                    <div className="col-sm-9">
                                        <button
                                            type="submit"
                                            className="btn btn-success"
                                            onClick={() => toggleEdit('userAccountDetails')}
                                            disabled={disabledStates.userAccountDetails}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="card h-auto">
                    <div className="card-header">
                        <h4 className="card-title">Assigned Virtual Bank Account</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        disabled={disabledStates.virtualAccountDetails}
                                        value={"Simplyfy"} 
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">A/C No</label>
                                <div className="col-sm-9">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Account Number"
                                        disabled={disabledStates.virtualAccountDetails}
                                        value={virtualAccountDetails}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">IFSC Code</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="IFSC Code"
                                        disabled={disabledStates.virtualAccountDetails}
                                        value={"UBIN0996335"}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">Bank Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Bank Name"
                                        disabled={disabledStates.virtualAccountDetails}
                                        value={"UNION BANK OF INDIA"}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-sm-3 col-form-label">Branch</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Branch"
                                        disabled={disabledStates.virtualAccountDetails}
                                        value={"FCS BANGALORE, JELLITTA TOWERS, KARNATAKA"}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BankDetails;
