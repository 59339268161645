import React, { useState, useEffect } from 'react';
import { addTrade } from '../../../apolloClientConnection/apolloClient';
import { calculateTransactionDetails } from './utils';

import useToast from '../Toaster/Toaster';

const LimitOrder = ({ currentTab = 'BUY', onTradeSuccess, coinName }) => {

	const [price, setPrice] = useState('');
	const [amount, setAmount] = useState('');
	const [total, setTotal] = useState('');
	const { notifySuccess, notifyInfo, notifyError, notifyWarning } = useToast();
	const [transactionDetails, setTransactionDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');


	const handlePriceChange = (e) => {
		setPrice(e.target.value);
	}

	const handleQuantityChange = (e) => {
		setAmount(e.target.value);
	}

	useEffect(() => {
		if (!price || !amount) {
			setTotal('');
			setTransactionDetails(null);
			return;
		}

		const priceNum = parseFloat(price);
		const amountNum = parseFloat(amount);

		if (!isNaN(priceNum) && !isNaN(amountNum)) {
			const totalValue = (priceNum * amountNum).toFixed(2);

			setTotal(totalValue);
			setLoading(true);
			calculateTransactionDetails(parseFloat(totalValue), currentTab)
				.then(details => {
					setTransactionDetails(details);
					setLoading(false);
					setError('');


				})
				.catch(err => {
					setError('Failed to calculate transaction details');
					setLoading(false);
				});
		}
	}, [price, amount, currentTab]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!amount || !price) {
			notifyInfo('Please enter both quantity and price to proceed.');
			return;
		}
	
		if (Number(total) < 50) {
			notifyWarning('The total must be at least 50 to proceed.');
			return;
		}

		addTrade({ type: currentTab, coinName, quantity: Number(amount), price: Number(price) })
			.then(data => {
				if (data) {
					onTradeSuccess(coinName);
					notifySuccess('Order Placed!');
					setPrice('');
					setAmount('');
					setTotal('');
					setTransactionDetails(null);
				}
			})
			.catch(error => {
				notifyError('Failed to place order: ' + error.message);
			});
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div className="sell-blance">
					<label className="form-label text-primary">Price</label>
					{/* <div className="form-label blance"><span>BALANCE:</span><p>$3,123.9</p></div> */}
					<div className="input-group">
						<input type="text" min="0" step="0.01" className="form-control" placeholder="0.00" value={price} onChange={handlePriceChange} />
						<span className="input-group-text">INR</span>
					</div>
				</div>
				<div className="sell-blance">
					<label className="form-label text-primary">Quantity</label>
					<div className="input-group">
						<input type="text" min="0" className="form-control" placeholder="Amount" value={amount} onChange={handleQuantityChange} />
						<span className="input-group-text">BTC</span>
					</div>
				</div>
				<div className="sell-blance">
					<label className="form-label text-primary">Total</label>
					<div className="input-group">
						<input
							type="text"
							className="form-control"
							placeholder="Total"
							value={total}
							onChange={e => setTotal(e.target.value)}
							readOnly
						/>
						<span className="input-group-text">INR</span>
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-center mt-4">
					{loading ? <p>Loading...</p> : transactionDetails && (
						<div className="d-flex justify-content-center align-items-center">
							<p className="text-bold me-2">
								Fee: {transactionDetails?.brokerageRate?.toFixed(2)}% <span className="text-muted">|</span>
							</p>
							<p className="text-bold me-2">
								GST: {transactionDetails?.gstRate?.toFixed(2)}% <span className="text-muted">|</span>
							</p>
							{currentTab === 'SELL' && (
								<p className="text-bold me-2">
									TDS: {transactionDetails?.tdsDeduction?.toFixed(2)}% <span className="text-muted">|</span>
								</p>
							)}
							<p className="text-bold">
								Net Amount: {transactionDetails?.netAmount} INR
							</p>
						</div>
					)}
					{error && <p className="error text-bold text-danger">{error}</p>}
				</div>

				<div className="text-center">
					<button type="submit" className={`btn btn-${currentTab === 'BUY'? 'success': 'danger'} w-75 fw-bold`} style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
						{currentTab}
					</button>
				</div>

			</form>
		</>
	)
}
export default LimitOrder;