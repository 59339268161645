import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Card, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { getAirDrop } from "../../../apolloClientConnection/apolloClient";
import { debounce } from "../UserManagement/debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";

const optionValue = [
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' }, // Changed default value to 25
    { value: 300, label: '300' },
    { value: 5000, label: '5000' },
]


const Airdrop = () => {
    const [perPage, setPerPage] = useState(50);
    const [data, setData] = useState([])
    const [pageKeys, setPageKeys] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [networkError, setNetworkError] = useState(false);
    const [noDataError, setNoDataError] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);


    useEffect(() => {
        getAirdropData(currentPage)
    }, [currentPage, perPage])


    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        setCurrentPage(1);
        getAirdropData(1);
    };


    const getAirdropData = async (page, phone = null, email = null, startDate = null, endDate = null) => {
        setLoading(true);
        const lastKey = pageKeys[page - 1];

        try {
            const data = await getAirDrop(perPage, lastKey?.PK, lastKey?.SK, lastKey?.createdAt, lastKey?.type, phone, email, startDate ? formatTimestamp(startDate) : null, endDate ? formatTimestamp(endDate) : null)
            const userData = data?.GetUserAirDrop?.items
            setData(userData)
            const newPageKeys = {
                ...pageKeys,
                [page]: data?.GetUserAirDrop?.lastEvaluatedKey || null,
            };

            setPageKeys(newPageKeys);
            setNoDataError(data.length === 0);
            setNetworkError(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error.message.includes("No record  found") || error.message.includes("No data  available")) {
                setNoDataError(true);
                setData([]);
            } else if (error.message.includes("Network error")) {
                setNetworkError(true);

            } else {
                setNetworkError(true);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {

        getAirdropData(1, filter ? determineSearchType(filter).phone : null, filter ? determineSearchType(filter).email : null, startDate, endDate);
    };


    const debouncedFetchUsers = useCallback(debounce((filterValue) => {
        const { phone, email } = determineSearchType(filterValue);
        getAirdropData(currentPage, phone, email, startDate, endDate);
    }, 500), [currentPage, startDate, endDate]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        debouncedFetchUsers(event.target.value);
    };

    const formatTimestamp = (timestamp) => {
        return new Date(timestamp).getTime(); // Convert to timestamp
    };

    const determineSearchType = (filter) => {
        const phoneRegex = /^[0-9]+$/;
        if (phoneRegex.test(filter)) {
            return { phone: filter, email: null };
        } else {
            return { phone: null, email: filter };
        }
    };

    const handleNextPage = () => {
        if (hasMoreData && pageKeys[currentPage]) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
        }
    };



    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Airdrop</Card.Title>
                        {/* <div className="form-group col-md-1"> */}
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select me-3"
                        />
                        {/* </div> */}
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={6}
                                className="d-flex align-items-center justify-content-lg-end"
                            >
                                <div className="d-flex align-items-center">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control ms-2"
                                        placeholderText="End Date"
                                    />
                                    <button className="btn btn-primary ms-3" onClick={handleSearch}>
                                        Search
                                    </button>
                                </div>
                            </Col>
                        </Row>

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>User Id</th>
                                    <th className="text-center">Name</th>
                                    <th className="text-center">MobileNo./Email</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">Coin</th>
                                    <th className="text-center">CoinName</th>
                                    <th className="text-center">Date&Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : networkError ? (
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            <LottieAnimation
                                                lottieJson={NetworkError}
                                                width={550}
                                                height={400}
                                            />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : noDataError || data.length === 0 ? (
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            <LottieAnimation
                                                lottieJson={noDataAnimation}
                                                width={550}
                                                height={400}
                                            />
                                        </td>
                                    </tr>
                                ) : (data?.length > 0 ? (
                                    data?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{(currentPage - 1) * perPage + index + 1}</td>
                                            <td className="text-center">{item?.userName}</td>
                                            <td className="text-center">
                                                <span className="text-primary">{item?.receiverMobileNo}</span>
                                            </td>
                                            <td className="text-center">
                                                <Badge bg={item?.status === "Success" ? "success" : item?.status === "Pending" ? "warning" : "danger"}>{item?.status}</Badge>
                                            </td>
                                            <td className="text-center">{item?.tokenQty}</td>
                                            <td className="text-center">{item?.coinName}</td>
                                            <td className="text-center">{item?.date}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">No data found</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                            <p className="mb-0">
                                Showing <span> 1-4 </span> from <span> 30 </span> data{" "}
                            </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li
                                        className={`page-item page-indicator ${currentPage === 1 ? "disabled" : ""
                                            }`}
                                    >
                                        <Link
                                            to={"#"}
                                            className="page-link"
                                            onClick={(e) => {
                                                if (currentPage === 1) {
                                                    e.preventDefault();
                                                } else {
                                                    handlePreviousPage();
                                                }
                                            }}
                                        >
                                            <i
                                                className="fa-solid fa-angle-left"
                                                style={{
                                                    color: currentPage === 1 ? "#cccccc" : "inherit",
                                                }}
                                            ></i>
                                        </Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to={"#"} className="page-link">
                                            {currentPage}
                                        </Link>
                                    </li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link" onClick={handleNextPage}>
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default Airdrop;
