import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useToast from "../Toaster/Toaster";
import { fetchAllAdmins } from "../../../apolloClientConnection/apolloClient";

const rowNames = ["Dashboard", "Coin Listing", "User Management", "Orders", "Withdraw", "Commission or Fee", "Airdrop"];

const subTabsContent = {
    "Dashboard": ["Sub-Tab 1A", "Sub-Tab 2A"],
    "Coin Listing": ["Add/Edit New Coins", "List Own Coins", "List Market Coins"],
    "User Management": ["Auto Approved", "Partial Filled", "Approved By Admin", "All Users"],
    "Orders": [],
    "Withdraw": [],
    "Commission or Fee": ["Add Tax", "List Tax"],
    "Airdrop": []
};

const ToggleRow = ({ label, isActive, onToggle }) => (
    <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>{label}</h4>
        <button style={{ marginRight: '16px' }} className={`btn ${isActive ? 'btn-success' : 'btn-secondary'}`} onClick={onToggle}>
            {isActive ? 'On' : 'Off'}
        </button>
    </div>
);

const Control = () => {
    const [toggles, setToggles] = useState(Array(rowNames.length).fill(false));
    const [selectedAdmin, setSelectedAdmin] = useState(null); // State to track the selected admin
    const [adminsOptions, setAdminsOptions] = useState([]); // Options for Select component
    const [subTabToggles, setSubTabToggles] = useState({});
    const [selectedTab, setSelectedTab] = useState(null);
    const { notifySuccess, notifyError } = useToast();

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                const admins = await fetchAllAdmins();
                const options = admins.map(admin => ({
                    value: admin.PK.trim(),
                    label: admin.userName,
                    SK: admin.SK.trim()
                }));
                setAdminsOptions(options);
                if(options.length > 0) {
                    setSelectedAdmin(options[0]); // Default to the first admin
                }
            } catch (error) {
                notifyError("Failed to fetch admin data");
            }
        };
        fetchAdminData();
    }, []);

    const handleToggle = index => {
        const newToggles = [...toggles];
        newToggles[index] = !newToggles[index];
        setToggles(newToggles);
        if (newToggles[index]) {
            setSelectedTab(rowNames[index]);
        } else if (selectedTab === rowNames[index]) {
            setSelectedTab(null);
        }
    };

    const handleSubTabToggle = subTab => {
        setSubTabToggles(prevState => ({
            ...prevState,
            [subTab]: !prevState[subTab]
        }));
    };

    const handleAdminSelectionChange = selectedOption => {
        setSelectedAdmin(selectedOption);
    };

    const handleToAdminPanel = () => {
        const tabsData = rowNames.reduce((acc, name, index) => {
            if (toggles[index]) {
                const activeSubTabs = subTabsContent[name].filter(subTab => subTabToggles[subTab]);
                if (activeSubTabs.length > 0) {
                    acc[name] = activeSubTabs;
                }
            }
            return acc;
        }, {});
        notifySuccess('Successful admin panel setup!');
        console.log('Selected Admin:', selectedAdmin ? selectedAdmin.label : 'No Admin Selected');
        console.log('Tabs and Sub-Tabs:', JSON.stringify(tabsData, null, 2));
    };

    return (
        <>
            <div className="row">
                <div className="col-xxl-6 col-xl-6">
                    <div className="card">
                        <div className="card-header border-0">
                            <h4 className="heading">Admin User</h4>
                        </div>
                        <div className="card-body pt-0">
                            <Select
                                options={adminsOptions}
                                className="custom-react-select"
                                onChange={handleAdminSelectionChange}
                                isSearchable={false}
                                getOptionLabel={(option) => option.label} // Specifies how to display the label
                                getOptionValue={(option) => option.value} // Specifies how to get the value
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-6 col-xl-6">
                    <div className="card">
                        <div className="card-header border-0">
                            <h4 className="heading">Select Tabs</h4>
                        </div>
                        <div className="card-body pt-0">
                            {rowNames.map((name, index) => (
                                <ToggleRow
                                    key={index}
                                    label={name}
                                    isActive={toggles[index]}
                                    onToggle={() => handleToggle(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="col-xxl-6 col-xl-6">
                    <div className="card">
                        <div className="card-header border-0">
                            <h4 className="heading">Select Sub-Tabs of {selectedTab}</h4>
                        </div>
                        <div className="card-body pt-0">
                            {selectedTab && subTabsContent[selectedTab].length > 0 ? (
                                subTabsContent[selectedTab].map((subTab, index) => (
                                    <ToggleRow
                                        key={index}
                                        label={subTab}
                                        isActive={subTabToggles[subTab] || false}
                                        onToggle={() => handleSubTabToggle(subTab)}
                                    />
                                ))
                            ) : (
                                <p className="text-center">No sub-tabs available{selectedTab ? ` for ${selectedTab}` : ''}</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-xxl-6 col-xl-6">
                    <div className="card">
                        <div className="card-header border-0">
                            <h4 className="heading">Confirm Admin Panel</h4>
                        </div>
                        <div className="card-body pt-0">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h5 className="mb-0">Click to confirm</h5>
                                </div>
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-success" onClick={handleToAdminPanel}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
               
                </div>

            </div>
        </>
    );
};

export default Control;
