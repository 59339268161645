import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Card, Table, Dropdown, Row, Button } from "react-bootstrap";
import { formatDateTime1 } from "./apis"; // Adjust the path as necessary
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { fetchUserByPagination, getWalletDetails } from "../../../apolloClientConnection/apolloClient";
import { debounce } from "./debounce";
import * as XLSX from "xlsx";

const optionValue = [
  { value: 15, label: "15" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 300, label: "300" },
  { value: 500, label: "500" },
  { value: 1000, label: "1000" },
];

const AllUsers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [userdata, setUserdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [perPage, setPerPage] = useState(15);
  const [networkError, setNetworkError] = useState(false);
  const [noDataError, setNoDataError] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [pageKeys, setPageKeys] = useState({});
  const [searchByEmail, setSearchByEmail] = useState(false);
  const navigate = useNavigate();
  // const initialFetchRef = useRef(true);


  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).getTime(); // Convert to timestamp
  };

  const fetchUsers = async (page, phone = null, email = null, full_name = null, startDate = null, endDate = null) => {
    setLoading(true);

    const lastKey = pageKeys[page - 1];

    try {
      const data = await fetchUserByPagination(perPage, lastKey?.PK, lastKey?.SK, lastKey?.createdAt, lastKey?.type, phone, email, full_name, startDate ? formatTimestamp(startDate) : null, endDate ? formatTimestamp(endDate) : null);

      if (data.success) {
        const items = data.data.items || [];
        const itemsWithWalletDetails = await Promise.all(items.map(async (item) => {
          try {
            const walletData = await getWalletDetails("", "", item.PK);
            const price = walletData?.getWalletDetails?.remaining_price?.price?.toFixed(2) || "0";
            return { ...item, walletDetails: price };
          } catch (walletError) {
            console.error('Error fetching wallet details for user:', item.PK, walletError);
            return { ...item, walletDetails: "Error" };
          }
        }));

        setUserdata(itemsWithWalletDetails);
        setHasMoreData(itemsWithWalletDetails.length > 0);
        const newPageKeys = {
          ...pageKeys,
          [page]: data.data.lastEvaluatedKey || null,
        };
        setPageKeys(newPageKeys);
        setNoDataError(itemsWithWalletDetails.length === 0);
        setNetworkError(false);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.message.includes("No record  found") || error.message.includes("No data  available")) {
        setNoDataError(true);
        setUserdata([]);
      } else if (error.message.includes("Network error")) {
        setNetworkError(true);

      } else {
        setNetworkError(true);

      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage, perPage]); // Add perPage to the dependency array


  // useEffect(() => {
  //   const storedData = localStorage.getItem("allUsersData");
  //   if (storedData) {
  //     console.log('this is storeData', storedData)
  //     const data = JSON.parse(storedData);
  //     setUserdata(data.items);
  //     setPageKeys(data.pageKeys);
  //     setHasMoreData(data.hasMoreData);
  //     setCurrentPage(data.currentPage);
  //     setPerPage(data.perPage); // Set perPage from localStorage
  //     setLoading(false);

  //   } else {
  //     fetchUsers(currentPage);
  //   }
  // }, []);

  // // Store data in localStorage when userdata changes
  // useEffect(() => {
  //   localStorage.setItem(
  //     "allUsersData",
  //     JSON.stringify({
  //       items: userdata,
  //       pageKeys: pageKeys,
  //       hasMoreData: hasMoreData,
  //       currentPage: currentPage,
  //       perPage: perPage // Store perPage in localStorage
  //     })
  //   );
  // }, [userdata, pageKeys, hasMoreData, currentPage, perPage]); // Add perPage to the dependency array


  const determineSearchType = (filter) => {
    const phoneRegex = /^[0-9]+$/;
    if (phoneRegex.test(filter)) {
      return { phone: filter, email: null, full_name: null };
    } else if (searchByEmail) {
      return { phone: null, email: filter, full_name: null };
    } else {
      return { phone: null, email: null, full_name: filter.toUpperCase() };
    }
  };

  const debouncedFetchUsers = useCallback(debounce((filterValue) => {
    const { phone, email, full_name } = determineSearchType(filterValue);
    fetchUsers(currentPage, phone, email, full_name, startDate, endDate);
  }, 500), [currentPage, startDate, endDate, searchByEmail]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    debouncedFetchUsers(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearch = () => {
    fetchUsers(1, filter ? determineSearchType(filter).phone : null, filter ? determineSearchType(filter).email : null, filter ? determineSearchType(filter).full_name : null, startDate, endDate);
  };

  const handlePerPageChange = (selectedOption) => {
    setPerPage(selectedOption.value);
    setCurrentPage(1);
    fetchUsers(1);
  };

  const handleNextPage = () => {
    if (hasMoreData && pageKeys[currentPage]) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  const handleRowClick = (PK, SK) => {
    let dataPKSK = {
      PK: PK,
      SK: SK
    };
    localStorage.setItem('dataPKSK', JSON.stringify(dataPKSK));
    navigate("/user-data");
  };

  const handleMessageClick = (PK, SK) => {
    let dataPKSK = {
      PK: PK,
      SK: SK
    };
    navigate("/notification", { state: { dataPKSK } });
  };

  const svg1 = (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z"
          fill="#000000"
        />
      </g>
    </svg>
  );

  const svg2 = (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#FFFFFF"
    >
      <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5S10.5 3.17 10.5 4v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-6 1c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </svg>
  );

  const downloadExcel = () => {

    const showDateTime = (updatedAt) => {
      const date = new Date(Number(updatedAt)); // Convert to number if necessary
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const formattedDate = `${month}-${day}-${year}`;

      let hours = date.getHours();
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      const formattedTime = `${('0' + hours).slice(-2)}:${minutes} ${ampm}`;

      return { date: formattedDate, time: formattedTime };

    };

    const dataToDownload = userdata.map(user => {
      const { date, time } = showDateTime(user.updatedAt);
      return {
        Name: user.full_name || 'Guest',
        MobileNo: user.phone,
        Email: user.email || 'No data',
        WalletBalance: user.walletDetails,
        Date: date,
        Time: time,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

    // Set column widths
    const wscols = [
      { wch: 20 }, // Name
      { wch: 15 }, // MobileNo
      { wch: 25 }, // Email
      { wch: 15 }, // WalletBalance
      { wch: 15 }, // Date
      { wch: 15 }, // Time
    ];
    worksheet['!cols'] = wscols;

    XLSX.writeFile(workbook, "UsersData.xlsx");
  };

  return (
    <Col lg={12}>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>All Users</Card.Title>
          <div className="d-flex align-items-center">
            <Select
              options={optionValue}
              value={{ value: perPage, label: perPage.toString() }}
              onChange={handlePerPageChange}
              className="custom-react-select me-3"
            />
            <Button className="me-2" variant="success" onClick={downloadExcel}>
              <span className="btn-icon-start text-success">
                <i className="fa fa-file color-success" />
              </span>
              Download
            </Button>
          </div>
        </Card.Header>

        <Card.Body>
          <Row className="justify-content-between mb-4">
            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter..."
                  value={filter}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="form-check custom-checkbox checkbox-success ms-3"> {/* Adjusted margin here */}
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheckBox3"
                  checked={searchByEmail}
                  onChange={() => setSearchByEmail(!searchByEmail)}
                />
                <label
                  className="form-check-label"
                  htmlFor="customCheckBox3"
                >
                  Search By Email
                </label>
              </div>
            </Col>
            <Col
              lg={6}
              className="d-flex align-items-center justify-content-lg-end"
            >
              <div className="d-flex align-items-center">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  className="form-control"
                  placeholderText="Start Date"
                  dateFormat="dd/MM/yy"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  className="form-control ms-2"
                  placeholderText="End Date"
                  dateFormat="dd/MM/yy"
                />
                <button className="btn btn-primary ms-3" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </Col>
          </Row>

          <Table responsive>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Name</th>
                <th>MobileNO/Email</th>
                <th>Inr Wallet Balance</th>
                <th>Date/Time</th>
                <th>Status</th>
                <th>Action</th>
                <th style={{ display: "none" }}>PK</th>
                <th style={{ display: "none" }}>SK</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <Loader />
                  </td>
                </tr>
              ) : networkError ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <LottieAnimation
                      lottieJson={NetworkError}
                      width={550}
                      height={400}
                    />
                    Connect To SuperAdmin
                  </td>
                </tr>
              ) : noDataError || userdata.length === 0 ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <LottieAnimation
                      lottieJson={noDataAnimation}
                      width={550}
                      height={400}
                    />
                  </td>
                </tr>
              ) : (
                userdata.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <strong>{item?.serialNo}</strong>
                      {/* <strong>{index+1}</strong> */}

                    </td>
                    <td className="fw-bold">{item.full_name ? item.full_name : 'Guest'}</td>
                    <td style={{ display: "none" }}>{item.PK}</td>
                    <td style={{ display: "none" }}>{item.SK}</td>
                    <td className="text-primary fw-bold">
                      {item.phone}
                      {item.email && <div>{item.email}</div>}
                    </td>
                    <td className="fw-bold">₹<span className="mx-2">{item.walletDetails}</span></td>
                    <td className="fw-bold">{formatDateTime1(item)}</td>
                    <td>
                      <span
                        className={`badge light badge-${
                          item.isBlocked 
                            ? "danger" 
                            : item.isKYCPending === true 
                              ? "danger" 
                              : "success"
                        }`}
                      >
                        {
                          item.isBlocked
                            ? "Blocked"
                            : item.isKYCPending
                              ? "InActive"
                              : "Active"
                        }
                      </span>
                    </td>
                    <td>
                      <Dropdown.Toggle
                        variant={
                          item.status === "Active" ? "success" : "danger"
                        }
                        className="light sharp i-false"
                        onClick={() => handleRowClick(item.PK, item.SK)}
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Toggle
                        variant="success"
                        className=" sharp i-false mx-2"
                        onClick={() => handleMessageClick(item.PK, item.SK)}
                      >
                        {svg2}
                      </Dropdown.Toggle>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
            <p className="mb-0">
              Showing <span> 1-4 </span> from <span> 30 </span> data{" "}
            </p>
            <nav>
              <ul className="pagination pagination-gutter pagination-primary no-bg">
                <li
                  className={`page-item page-indicator ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <Link
                    to={"#"}
                    className="page-link"
                    onClick={(e) => {
                      if (currentPage === 1) {
                        e.preventDefault();
                      } else {
                        handlePreviousPage();
                      }
                    }}
                  >
                    <i
                      className="fa-solid fa-angle-left"
                      style={{
                        color: currentPage === 1 ? "#cccccc" : "inherit",
                      }}
                    ></i>
                  </Link>
                </li>
                <li className="page-item">
                  <Link to={"#"} className="page-link">
                    {currentPage}
                  </Link>
                </li>
                <li className="page-item page-indicator">
                  <Link to={"#"} className="page-link" onClick={handleNextPage}>
                    <i className="fa-solid fa-angle-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default AllUsers;
