import React, { useState, useEffect } from "react";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import { FaWallet } from 'react-icons/fa'; // Wallet icon from react-icons
import Loader from "../Loader/loader";
import useToast from "../Toaster/Toaster";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { Link } from "react-router-dom";

const DepositeAllHistory = () => {
    const [depositHistory, setDepositHistory] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0); // State for wallet balance
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false); // New state for network error
    const { notifyError } = useToast();
    const SERVER_LINK = process.env.REACT_APP_SERVER_LINK; // Ensure this is defined in your environment variables

    useEffect(() => {
        // Fetch deposit history
        const fetchHistory = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${SERVER_LINK}/api/v1/file/getAllDepositeData`, {
                    method: 'GET',
                });

                if (response.ok) {
                    const result = await response.json(); // Parse the JSON response
                    if (result && result.data) {
                        // Combine Debit and Credit data into one array and add a transactionType field
                        const combinedHistory = [
                            ...(result.data.Debit?.data || []).map(item => ({ ...item, transactionType: "DEBIT" })),
                            ...(result.data.Credit?.data || []).map(item => ({ ...item, transactionType: "CREDIT" }))
                        ];

                        // Sort by updatedAt field in descending order (newest first)
                        combinedHistory.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

                        setDepositHistory(combinedHistory); // Set sorted history
                    } else {
                        notifyError("No deposit history available");
                    }
                } else {
                    notifyError("Failed to fetch deposit history");
                }
                setHasError(false);
            } catch (error) {
                console.error("Failed to fetch deposit history: ", error);
                notifyError("Failed to fetch deposit history");
                setHasError(true);
            } finally {
                setLoading(false);
            }
        };

        // Fetch wallet balance
        const fetchWalletBalance = async () => {
            try {
                const response = await fetch(`${SERVER_LINK}/api/v1/file/getadminbankamount`, {
                    method: 'GET',
                });

                if (response.ok) {
                    const result = await response.json();
                    if (result && result.data && typeof result.data.amount === 'number') {
                        setWalletBalance(result.data.amount); // Using amount from response
                    } else {
                        notifyError("Failed to fetch wallet balance");
                    }
                } else {
                    notifyError("Failed to fetch wallet balance");
                }
            } catch (error) {
                console.error("Failed to fetch wallet balance: ", error);
                notifyError("Failed to fetch wallet balance");
            }
        };

        fetchHistory();
        fetchWalletBalance(); // Fetch wallet balance when component mounts
    }, []);

    return (
        <Col lg={12}>
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>All History</Card.Title>
                    <div className="d-flex align-items-center">

                        <FaWallet style={{ fontSize: "24px", marginRight: "10px" }} /> {/* Wallet icon */}
                        <span className="fw-bold me-5 fs-5">₹ {walletBalance.toFixed(2)}</span>
                        {/* <Button className="me-2" variant="success" >
                            <span className="btn-icon-start text-success">
                                <i className="fa fa-file color-success" />
                            </span>
                            Download
                        </Button> */}
                        <Link to="/add-deposite">
                            <Button className="me-2" variant="success" >
                                Add Deposite
                            </Button>
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th><strong>S.No</strong></th>
                                <th><strong>Transaction ID</strong></th>
                                <th><strong>Amount</strong></th>
                                <th><strong>Status</strong></th>
                                <th><strong>Transaction Type</strong></th>
                                <th><strong>Updated At</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : depositHistory.length === 0 ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (
                                depositHistory.map((record, index) => (
                                    <tr key={record.SK}>
                                        <td>{index + 1}</td>
                                        <td>{record.transactionId || record.orderId}</td>
                                        <td>{record.amount || record.priceAmount}</td>
                                        <td>
                                            <Badge bg={`${record.transactionType === "DEBIT" ? 'danger' : 'success'}`}>
                                                {record.transactionType === "DEBIT" ? 'COMPLETED' : 'APPROVED'}
                                            </Badge>
                                        </td>
                                        <td>
                                            <span className={`badge light badge-${record.transactionType === "DEBIT" ? "warning" : "success"}`}>
                                                {record.transactionType}
                                            </span>
                                        </td>
                                        <td>{new Date(record.updatedAt).toLocaleString()}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default DepositeAllHistory;
