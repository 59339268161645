import React, { useState } from 'react';
import { Form, Button, Card, Col, Row } from 'react-bootstrap';
import Select from 'react-select';

const ComplianceTab = ({ onNext }) => {
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState(null); // This should be an object { value, label }
    const [city, setCity] = useState(null); // This should be an object { value, label }
    const [zipCode, setZipCode] = useState('');
    const [isTrading, setIsTrading] = useState(false);
    const [tradingDetails, setTradingDetails] = useState('');
    const [hasGovConnection, setHasGovConnection] = useState(false);
    const [govConnectionDetails, setGovConnectionDetails] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({
            companyName,
            address,
            country: country ? country.value : '',
            city: city ? city.value : '',
            zipCode,
            isTrading,
            tradingDetails: isTrading ? tradingDetails : 'N/A', // Log trading details if applicable
            hasGovConnection,
            govConnectionDetails: hasGovConnection ? govConnectionDetails : 'N/A', // Log government connection details if applicable
        });
        onNext(); // Continue to the next step
    };

    const CountryOption = [
        { value: 'US', label: 'United States' },
        { value: 'CA', label: 'Canada' },
        // Add more countries as needed
    ];

    const CityOption = [
        { value: 'NY', label: 'New York' },
        { value: 'TO', label: 'Toronto' },
        // Add more cities based on selected country if necessary
    ];

    const handleTradingChange = (isTrading) => {
        setIsTrading(isTrading);
        if (!isTrading) {
            setTradingDetails(''); // Clear trading details if "No" is selected
        }
    };

    const handleGovermentConnection = (hasGovConnection) => {
        setHasGovConnection(hasGovConnection);
        if (!hasGovConnection) {
            setGovConnectionDetails(''); // Clear trading details if "No" is selected
        }
    };


    return (
        <>
            <Card.Title>Compliance</Card.Title>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3 mt-3">
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Provide the full name of your company.</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Enter Company Name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                        <Form.Label>Provide the company address of incorporation.</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Enter company address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </Col>
                    </Form.Group>
                </Row>

                <Row className="mb-3 mt-3">
                    <Form.Group as={Col} controlId="country" md={4}>
                        <Form.Label>Country</Form.Label>
                        <Select
                            options={CountryOption}
                            value={country}
                            onChange={setCountry}
                            className="custom-react-select"
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="city" md={4}>
                        <Form.Label>City</Form.Label>
                        <Select
                            options={CityOption}
                            value={city}
                            onChange={setCity}
                            className="custom-react-select"
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="zipCode" md={4}>
                        <Form.Label>ZIP Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter ZIP code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                        />
                    </Form.Group>
                </Row>

                <Row className="mb-3 mt-3">
                    <Form.Group as={Col} md={6}>
                        <Form.Label>Asset Trading</Form.Label>
                        <Card.Text className="m-0 subtitle text-muted mb-1">
                            Does your company or any related entity participate in the asset markets, either directly or indirectly on your behalf
                            (<code>e.g., proprietary trading, market making</code>)?
                        </Card.Text>
                        <div>
                            <Button variant="success" className="me-2" onClick={() => handleTradingChange(true)}>Yes</Button>
                            <Button variant="danger" onClick={() => handleTradingChange(false)}>No</Button>
                        </div>
                        {isTrading && (
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter trading details"
                                value={tradingDetails}
                                onChange={(e) => setTradingDetails(e.target.value)}
                                className="mt-3"
                            />
                        )}
                    </Form.Group>

                    <Form.Group as={Col} md={6}>
                        <Form.Label>Government Connection</Form.Label>
                        <Card.Text className="m-0 subtitle text-muted mb-1">
                            Has your company or project received any sponsorship, assistance, or
                            financial contribution from any government entity or political figure?
                        </Card.Text>
                        <div>
                            <Button variant="success" className="me-2" onClick={() => handleGovermentConnection(true)}>Yes</Button>
                            <Button variant="danger" onClick={() => handleGovermentConnection(false)}>No</Button>
                        </div>
                        {hasGovConnection && (
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter government connection details"
                                value={govConnectionDetails}
                                onChange={(e) => setGovConnectionDetails(e.target.value)}
                                className="mt-3"
                            />
                        )}
                    </Form.Group>
                </Row>

                <Button variant="success" type="submit">
                    Continue
                </Button>
            </Form>
        </>
    );
};

export default ComplianceTab;
