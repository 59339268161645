import React, { useState, useEffect, useRef } from 'react';
import { Col, Card, Table, Row } from "react-bootstrap";
import useToast from "../Toaster/Toaster";
import { getAdminWallet, getAppChargesHistory, fetchUserProfile } from '../../../apolloClientConnection/apolloClient';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../UserManagement/apis';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const GSTWallet = () => {
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { notifyWarning } = useToast();
  const navigate = useNavigate();


  const fetchBalance = async () => {
    try {
      const { getAppWallets } = await getAdminWallet();
      const wallet = getAppWallets.find(wallet => wallet.SK === "APP#REMAINING#PRICE_AMOUNT");
      if (wallet) {
        setBalance(wallet.gstAmount || 0); // Set balance to 0 if price is null
      } else {
        notifyWarning('Failed to fetch GST wallet balance');
      }
    } catch (error) {
      console.error('Error fetching GST wallet balance:', error);
      notifyWarning('Failed to fetch GST wallet balance');
    }
  };

  const fetchTransactions = async (startDate, endDate) => {
    try {
      const data = await getAppChargesHistory(startDate, endDate);
      if (data.appChargesHistoryOnTrade) {
        const updatedComplains = await Promise.all(data.appChargesHistoryOnTrade.map(async (item) => {
          try {
            const userProfile = await fetchUserProfile({ PK: item.PK, SK: "" });
            // const getUserProfileData = userProfile ?
            const profileData = JSON.parse(userProfile.profile ? userProfile.profile : 'N/A')

            return { ...item, profileData };
          } catch (error) {
            console.error('Failed to fetch user profile :', error);
            return item; // Return item without user profile if fetching fails
          }
        }));
        setTransactions(updatedComplains);
      } else {
        notifyWarning('Failed to fetch transaction history');
      }
    } catch (error) {
      notifyWarning('Failed to fetch transaction histor');
    }
  };

  useEffect(() => {
    fetchBalance();
    fetchTransactions();
  }, []); // Empty dependency array ensures this effect runs only once

  const handleRowClick = (pk, sk) => {
    if (pk || sk === undefined) {
      return notifyWarning('ID not present')
    }
    localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
    navigate("/user-data");
  };


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  const handleSearch = () => {
    fetchTransactions(startDate, endDate);
  };


  return (
    <Col lg={12}>
      <Card className="mb-3" style={{ maxWidth: '300px' }}>
        <Card.Header>
          <Card.Title>GST Wallet Balance</Card.Title>
        </Card.Header>
        <Card.Body>
          <h4><span style={{ fontWeight: 'bold' }}>₹</span> <span style={{ fontWeight: 'bold' }}>{balance.toFixed(2)}</span></h4>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>Transaction History</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-between mb-4">
            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter..."
                />
              </div>
            </Col>
            <Col
              lg={6}
              className="d-flex align-items-center justify-content-lg-end"
            >
              <div className="d-flex align-items-center">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  className="form-control"
                  placeholderText="Start Date"
                  dateFormat="dd/MM/yy"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  className="form-control ms-2"
                  placeholderText="End Date"
                  dateFormat="dd/MM/yy"
                />
                <button className="btn btn-primary ms-3" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </Col>
          </Row>
          <Table responsive hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email/Phone</th>
                <th>Date</th>
                <th>Amount (₹)</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((data, index) => (
                <tr key={index}>
                  <td>{data?.profileData ? data?.profileData?.full_name : 'Guest'}</td>
                  <td >
                    <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }} onClick={() => handleRowClick(data?.PK, data?.profileData?.SK)}>
                      {data.profileData ? data.profileData.phone : 'N/A'}
                      {data.profileData ? data.profileData.email && <div>{data.profileData.email}</div> : ''}
                    </div>
                  </td>
                  <td>{formatDateTime(data.createdAt)}</td>
                  <td><span style={{ fontWeight: 'bold' }}>₹</span> <span style={{ fontWeight: 'bold' }}>0</span></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default GSTWallet;
