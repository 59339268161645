import React, { useState, useEffect } from 'react';
import { Col, Card, Table, Dropdown, Row, Button } from "react-bootstrap";
import { useApolloClient } from '@apollo/client';
import { Link } from 'react-router-dom';
import { getAllMerchentVoucher } from '../../../apolloClientConnection/apolloClient';
import { formatDateTime } from '../UserManagement/apis';

const FlexibleVoucherList = () => {
    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [noData, setNoData] = useState(false);
    const client = useApolloClient();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setNetworkError(false);
            setNoData(false);
            try {
                const data = await getAllMerchentVoucher(client);
                setVouchers(data.GetAllMerchatVoucher);
                if (data.GetAllMerchatVoucher.length === 0) {
                    setNoData(true);
                }
            } catch (error) {
                setNetworkError(true);
                console.error('Error fetching merchant vouchers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [client]);

    return (
        <Card>
            <Card.Header>
                <Card.Title>Flexibal Voucher</Card.Title>
                <div className="d-flex justify-content-start align-items-center">
                    <Link to="/flexible-voucher">
                        <Button variant="success" className="me-2">Add Voucher</Button>
                    </Link>
                </div>
            </Card.Header>
            <Card.Body>

                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Brand</th>
                            <th>Vouchers</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <tr>
                                <td colSpan="7" className="text-center">Loading...</td>
                            </tr>
                        )}
                        {networkError && (
                            <tr>
                                <td colSpan="7" className="text-center">Network Error. Please try again later.</td>
                            </tr>
                        )}
                        {noData && (
                            <tr>
                                <td colSpan="7" className="text-center">No Data Available</td>
                            </tr>
                        )}
                        {!loading && !networkError && !noData && vouchers.map((voucher, index) => (
                            <tr key={voucher.PK}>
                                <td>{index + 1}</td>
                                <td>{voucher.brandPK}</td>
                                <td>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Amount</th>
                                                <th>Discount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {voucher.vouchers.map((v, i) => (
                                                <tr key={i}>
                                                    <td>{v.amount}</td>
                                                    <td>{v.discount} %</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </td>
                                <td>{formatDateTime(voucher.createdAt)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default FlexibleVoucherList;
