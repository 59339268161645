import React, { useState, useEffect } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import useToast from "../Toaster/Toaster";
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { fetchAllCategories, addCoupon } from "../../../apolloClientConnection/apolloClient";
import DatePicker from "react-datepicker";

const AddCoupons = () => {
  const [coupon_code, setCouponCode] = useState('');
  const [amount, setAmount] = useState('');
  const [brand, setBrand] = useState(null);
  const [brands, setBrands] = useState([]);
  const [isClaimed, setIsClaimed] = useState(null);
  const [pk, setPK] = useState(null);
  const [sk, setSK] = useState(null);
  const [imageLink, setImageLink] = useState('');
  const [description, setDescription] = useState('');
  const [couponCount, setCouponCount] = useState('');
  const [couponMRP, setCouponMRP] = useState('');
  const { notifySuccess, notifyWarning } = useToast();
  const [endDate, setEndDate] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.coupon) {
      const { coupon } = location.state;
      console.log('this is all coupon', coupon)
      setPK(coupon?.PK);
      setSK(coupon?.SK);
      setCouponCode(coupon?.coupon_code.toString());
      setAmount(coupon?.amount.toString());
      setBrand({ value: coupon?.category_name, label: coupon?.category_name });
      setIsClaimed(coupon?.isClaimed);
      setImageLink(coupon?.image_link);
      setDescription(coupon?.description)
      setCouponCount(coupon?.CouponCount?.toString());
      setCouponMRP(coupon?.CouponMRP?.toString());
      // setEndDate(coupon?.CouponEexpiryTime);
    }
  }, [location.state]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categories = await fetchAllCategories();
        const formattedBrands = categories.map(cat => ({
          value: cat.shortcode,
          label: cat.name,
          image: cat.image,
          description: cat.description
        }));
        setBrands(formattedBrands);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        notifyWarning('Failed to fetch brands.');
      }
    };
    loadCategories();
  }, []);

  const clearForm = () => {
    setCouponCode('');
    setAmount('');
    setBrand(null);
    setIsClaimed(null);
    setPK(null);
    setSK(null);
    setImageLink('');
    setDescription('')
    setCouponCount('');
    setCouponMRP('');
    setEndDate('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!coupon_code || !amount || !brand || isClaimed === null) {
      notifyWarning('All fields are required!');
      return;
    }

    console.log('this is proper date', endDate)

    const expiryTimestamp = new Date(endDate).getTime();
    const convertedTime = expiryTimestamp.toString()
    try {
      await addCoupon(
        brand.value,
        coupon_code,
        amount,
        isClaimed,
        imageLink,
        couponCount,
        couponMRP,
        convertedTime,
        description,
        pk,
        sk
      );
      notifySuccess('Coupon saved successfully!');
      clearForm();
    } catch (error) {
      console.error('Error handling coupon:', error);
      notifyWarning('Failed to handle coupon. Please try again.');
    }
  };

  const handleCouponCodeChange = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, '');
    setCouponCode(sanitizedValue);
  };

  const handleAmountChange = (e) => {
    const sanitizedValue = e.target.value.replace(/\D/g, '');
    setAmount(sanitizedValue);
  };

  const handleIsClaimedChange = (selectedOption) => {
    setIsClaimed(selectedOption.value);
  };

  const handleCouponCount = (e) => {
    setCouponCount(e.target.value);
  };

  const handleCouponMRP = (e) => {
    setCouponMRP(e.target.value);
  };

  

  const handleBrandChange = (selectedBrand) => {
    setBrand(selectedBrand);
    setImageLink(selectedBrand.image);
    setDescription(selectedBrand.description)
  };

  const statuses = [
    { value: true, label: 'Claimed' },
    { value: false, label: 'Unclaimed' }
  ];

  return (
    <Col lg={12}>
      <Card>
        <Card.Header>
          <Card.Title>Coupon Information</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col lg={6} md={12}>
                <Form.Group>
                  <Form.Label>Coupon Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter coupon code"
                    value={coupon_code}
                    onChange={handleCouponCodeChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={12}>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={handleAmountChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6} md={12}>
                <Form.Group>
                  <Form.Label>Brand</Form.Label>
                  <Select
                    options={brands}
                    className="custom-react-select"
                    value={brand}
                    onChange={handleBrandChange}
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={12}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Select
                    options={statuses}
                    className="custom-react-select"
                    value={statuses.find(option => option.value === isClaimed)}
                    onChange={handleIsClaimedChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6} md={12}>
                <Form.Group>
                  <Form.Label>Coupon Count</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Coupon Count"
                    value={couponCount}
                    onChange={handleCouponCount}
                  />
                </Form.Group>
              </Col>

              <Col lg={6} md={12}>
                <Form.Group>
                  <Form.Label>Coupon MRP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter MRP"
                    value={couponMRP}
                    onChange={handleCouponMRP}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={6} md={12}>
                <Form.Group>
                  <Form.Label>Coupon Expiry Time</Form.Label>
                  <div>
                    <DatePicker
                      selected={endDate} onChange={(date) => setEndDate(date)}
                      placeholderText="Select Expiry Time"
                      className="form-control"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit" variant="success">Save</Button>
                <Link to='/list-coupons'>
                  <Button className="ms-2" variant="danger">Go Back</Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default AddCoupons;
