import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import Loader from "../Loader/loader";
import { fetchAllAdmins, deleteItem } from "../../../apolloClientConnection/apolloClient"; // Import deleteItem function
import useToast from "../Toaster/Toaster";
import { Link, useNavigate } from "react-router-dom";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json"

const AdminList = () => {
  const [adminList, setAdminList] = useState([]);
  const [filter, setFilter] = useState(""); // State to hold the filter value
  const { notifyError } = useToast();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false); // New state for network error
  const [hasData, setHasData] = useState(true); // New state to track if data is available
  const navigate = useNavigate();


  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        setLoading(true);
        const response = await fetchAllAdmins();
        if (response && response.length > 0) {
          console.log('this is response', response)
          setAdminList(response);
          setHasData(true); // Data is present
        } else {
          setHasData(false); // No data
        }
        setHasError(false); // No error if fetch is successful
      } catch (error) {
        console.error("Failed to fetch admin data: ", error);
        notifyError("Failed to fetch admin data");
        setHasError(true); // Set error state
        setHasData(false); // Assume no data in case of error
      } finally {
        setLoading(false); // Ensure loading is set to false regardless of outcome
      }
    };

    fetchAdminData();
  }, []);


  const handleFilterChange = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  // Corrected filtered list based on the filter state
  const filteredAdminList = adminList.filter(admin => {
    const fullNameLower = admin.full_name ? admin.full_name.toLowerCase() : '';
    const userNameLower = admin.userName ? admin.userName.toLowerCase() : '';
    return fullNameLower.includes(filter) || userNameLower.includes(filter);
  });

  const handleRowClick = (admin) => {
    navigate('/admin-password', { state: { admin } });
  };

  const handleDeleteAdmin = async (PK, SK) => {
    try {
      await deleteItem('NewUser',PK, SK); // Call the deleteItem function with the admin's PK
      const updatedAdminList = adminList.filter(item => item.PK !== PK); // Filter out the deleted admin from the admin list
      setAdminList(updatedAdminList); // Update the admin list state
    } catch (error) {
      notifyError("Failed to delete admin");
    }
  };

  
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill="#FFFFFF" d="M3,21.5 L3.5,17.5 L14.5,6.5 C14.8978,6.10218 15.4374,5.87868 16,5.87868 C16.2786,5.87868 16.5541,5.93355 16.8111,6.04016 C17.0681,6.14678 17.303,6.30356 17.5,6.5 C17.697,6.69644 17.8538,6.93131 17.9604,7.18832 C18.067,7.44534 18.1219,7.72077 18.1219,7.99935 C18.1219,8.56196 17.8984,9.10155 17.5006,9.49935 L6.5,20.5 L2.5,21 L3,21.5 Z M5.06066,19 L14.0607,10 L17,12.9393 L8,21.9393 L5.06066,19 Z M16.5,9.5 L19.5,6.5 L17,4 L14,7 L16.5,9.5 Z" />
    </svg>
);

const svg2 = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
    </svg>
);

  return (
    <Col lg={12}>
      <Card>
        <Card.Header>
          <Card.Title>List of Admin</Card.Title>
          <div className="mb-3" style={{ width: "300px", float: "right" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Filter by name or username..."
              value={filter}
              onChange={handleFilterChange}
            />
          </div>
        </Card.Header>
        <Card.Body>

          <Table responsive>
            <thead>
              <tr>
                <th><strong>S.No</strong></th>
                <th><strong>Full Name</strong></th>
                <th><strong>Username</strong></th>
                <th className="text-center"><strong>Change Password</strong></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Loader />
              ) : hasError ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                    Connect To SuperAdmin
                  </td>
                </tr>
              ) : !hasData || filteredAdminList.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                  </td>
                </tr>
              ) : (filteredAdminList.map((admin, index) => (
                <tr key={admin.PK}>
                  <td>{index + 1}</td>
                  <td>{admin.full_name}</td>
                  <td>{admin.userName}</td>
                  <td className="text-center">
                    <Dropdown>
                      <Dropdown.Toggle onClick={() => handleRowClick(admin)} variant={'success'} className="light sharp i-false">
                        {/* Pass handleRowClick function for change password */}
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Toggle
                        variant={'danger'}
                        className="light sharp i-false ms-3"
                        onClick={() => handleDeleteAdmin(admin.PK, admin.SK)} // Pass PK and SK to handleDeleteAdmin function
                      >
                        {/* Pass handleDeleteAdmin function for delete */}
                        {svg2}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </td>
                </tr>
              )))}
            </tbody>
          </Table>

          <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
            <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>

            <nav>
              <ul className="pagination pagination-gutter pagination-primary no-bg">
                <li className="page-item page-indicator">
                  <Link to={"#"} className="page-link">
                    <i className="fa-solid fa-angle-left"></i>
                  </Link>
                </li>
                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                <li className="page-item page-indicator">
                  <Link to={"#"} className="page-link">
                    <i className="fa-solid fa-angle-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default AdminList;
