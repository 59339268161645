import React, { useState } from 'react';
import { Row, Col, Card, Tab, Nav, Button } from 'react-bootstrap'; // Ensure Button is imported
import { Dashboard1Data } from './Dashboard1Data/DashboardData1';

const Dashboard1 = () => {
    const [activeKey, setActiveKey] = useState(Dashboard1Data[0].name);

    // Function to navigate to the next tab
    const navigateToNextTab = () => {
        const currentIndex = Dashboard1Data.findIndex(tab => tab.name === activeKey);
        const nextTab = Dashboard1Data[currentIndex + 1];
        if (nextTab) {
            setActiveKey(nextTab.name);
        }
    };

    // Function to navigate to the previous tab
    const navigateToPreviousTab = () => {
        const currentIndex = Dashboard1Data.findIndex(tab => tab.name === activeKey);
        const previousTab = Dashboard1Data[currentIndex - 1];
        if (previousTab) {
            setActiveKey(previousTab.name);
        }
    };

    // Check if the current tab is not the first tab
    const showBackButton = Dashboard1Data.findIndex(tab => tab.name === activeKey) > 0;

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Asset Submission</Card.Title>

                    </Card.Header>
                    <Card.Body>

                        <Tab.Container activeKey={activeKey.toLowerCase()}>

                            <Nav as="ul" className="nav-tabs">
                                {Dashboard1Data.map((tab, index) => (
                                    <Nav.Item as="li" key={index}>
                                        <Nav.Link eventKey={tab.name.toLowerCase()}>
                                            <i className={`la la-${tab.icon} me-2`}></i>
                                            {tab.name}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            {showBackButton && (
                                
                                 <Button className="me-2 float-end mt-2" variant="success light"  onClick={navigateToPreviousTab} >
                                 <i className="la la-arrow-left me-2 "></i><strong>Back</strong>
                               </Button>
                            )}

                            <Tab.Content className="pt-4">
                                {Dashboard1Data.map((tab, index) => (
                                    <Tab.Pane eventKey={tab.name.toLowerCase()} key={index}>
                                        {React.createElement(tab.component, {
                                            key: index,
                                            onNext: navigateToNextTab, // Pass the navigation function as a prop
                                        })}
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default Dashboard1;
