import React, { useState, useEffect } from "react";
// import { fetchAdminData } from "./Api";
import useToast from "../Toaster/Toaster";
import { changePassword } from "../../../apolloClientConnection/apolloClient";


const SuperAdminPassword = () => {
  const [password1, setPassword1] = useState("");
  const [confirmPassword1, setConfirmPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [confirmPassword2, setConfirmPassword2] = useState("");
  const [OldPaaword1, SetOldPaaword1] = useState("");
  const [OldPaaword2, SetOldPaaword2] = useState("");
  const [superAdminData, setSuperAdminData] = useState('Super');
  const { notifyError, notifySuccess, notifyWarning, notifyInfo } = useToast();

  const FetchingAdminData = async () => {
    // Filter items to include only those where item.role is 'Admin'
    // const items = await fetchAdminData();
    const items = 'Manas';
    const AdminItems = items.data.items;
    const adminFilteredItems = AdminItems.filter(
      (item) => item.role === "SUPER_ADMIN"
    );

    const transformedItems = adminFilteredItems[0].userName;

    setSuperAdminData(transformedItems);
  };

  const ChangePassword = async (userName, password, password_2, newPassword, newPassword_2) => {
    try {
      const resp = await changePassword(userName, password, password_2, newPassword, newPassword_2);
      console.log("resp", resp);
      if (resp.changePassword == 'password changes  successfully') {
        notifySuccess("Change Password successful!");
      } else {
        notifyError("Invalid Password!");  // Assumes non-success is an error
      }
    } catch (error) {
      notifyError("Failed to change password. Please try again.");  // Notify user of the error
    }
  };
  

  useEffect(() => {
    // FetchingAdminData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
 

    // Check if all the fields are filled
    if (!password1 || !confirmPassword1 || !password2 || !confirmPassword2 || !OldPaaword1 || !OldPaaword2) {
      notifyWarning("All fields are required");
      return;
    }

    // Check if only one password is filled
    if ((password1 && !password2) || (!password1 && password2)) {
      notifyWarning("Both Password 1 and Password 2 are required.");
      return;
    }

    if (password1 !== confirmPassword1 || password2 !== confirmPassword2) {
      notifyWarning("Passwords do not match.");
      return;
    }

    ChangePassword(superAdminData ,OldPaaword1,OldPaaword2,password1,password2)

    // Reset all states
    setPassword1("");
    setConfirmPassword1("");
    setPassword2("");
    setConfirmPassword2("");
    SetOldPaaword1("")
    SetOldPaaword2("")
  ; // Success message disappears after 5 seconds
  };

  const handleClear = () => {
    // Clear all input fields
    SetOldPaaword1("")
    SetOldPaaword2("")
    setPassword1("");
    setConfirmPassword1("");
    setPassword2("");
    setConfirmPassword2("");
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Change Super Admin Passwords</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Super Admin Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={superAdminData}
                      onChange={(e) => setSuperAdminData(e.target.value)}
                      autoComplete="off"
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Old Password 1</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Old Password 1"
                      value={OldPaaword1}
                      onChange={(e) => SetOldPaaword1(e.target.value)}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Old Password 2</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Old Password 2"
                      value={OldPaaword2}

                      onChange={(e) => SetOldPaaword2(e.target.value)}
                    />
                  </div>
                </div>
                {/* Password 1 and Confirm Password 1 inputs */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Password 1</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password 1"
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Confirm Password 1</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm New Password 1"
                      value={confirmPassword1}

                      onChange={(e) => setConfirmPassword1(e.target.value)}
                    />
                  </div>
                  {/* Password 2 and Confirm Password 2 inputs */}
                  <div className="form-group mb-3 col-md-6">
                    <label>Password 2</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password 2"
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Confirm Password 2</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm New Password 2"
                      value={confirmPassword2}
                      onChange={(e) => setConfirmPassword2(e.target.value)}
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary me-2">
                  Change Passwords
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClear}
                >
                  Clear
                </button>
              </form>
            </div>
          </div>      
        </div>
      </div>
    </>
  );
};

export default SuperAdminPassword;
