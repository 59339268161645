// TokenAddressTab.js
import React, { useState } from 'react';
import { Card, Button, Row, Col, InputGroup, FormControl } from "react-bootstrap";

const TokenAddressTab = ({ onNext }) => {
    const [tokenAddress, setTokenAddress] = useState('');

    const handleContinue = () => {
        onNext()
    };

    const handleAddressChange = (event) => {
        setTokenAddress(event.target.value);
    };

    return (
        <>
            <Card.Title>Token Address</Card.Title>
            <Card.Text>Please Deposit token on</Card.Text>
            <Col xl={6} className='mt-1'>
                <InputGroup className="mb-3">
                    <FormControl
                        value={tokenAddress}
                        aria-label="Token address"
                        onChange={handleAddressChange}
                        placeholder='Enter Your Token Address'
                    />
                </InputGroup>
            </Col>

            <Row className="mt-4">
                <Col>
                    <Button className='me-2' variant="success" type="submit" onClick={handleContinue}>
                        Continue
                    </Button>
                    
                </Col>
            </Row>
        </>
    );
};

export default TokenAddressTab;
