import React, { useState } from "react";
import useToast from "../Toaster/Toaster";
import LottieAnimation from "../../../gif/LottieAnimation ";
import dot from "../../../gif/dot.json";
import { useNavigate } from "react-router-dom";
const SERVER_LINK = process.env.REACT_APP_SERVER_LINK;

// Email Validation Regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Phone Number Validation Regex (US/Intl, adjust as needed)
const phoneRegex = /^[0-9]{10,15}$/;

const MerchantCompany = () => {
    const { notifyError, notifySuccess, notifyWarning } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Form state for phone, email, password, image, and company name
    const [formData, setFormData] = useState({
        phone: '',
        email: '',
        password: '',
        image: null,
        companyName: '' // Added company name field
    });

    const initialFormState = {
        phone: '',
        email: '',
        password: '',
        image: null,
        companyName: ''
    };

    // Handle input change
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image' && files.length > 0) {
            setFormData(prevState => ({
                ...prevState,
                [name]: files[0] // Store file object
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading animation

        const { phone, email, password, image, companyName } = formData;

        // Validate at least one of phone or email is provided
        if (!phone && !email) {
            notifyWarning('Please provide either a phone number or an email.');
            setIsLoading(false);
            return;
        }

        // Validate required fields
        if (!password) {
            notifyWarning('Password is required.');
            setIsLoading(false);
            return;
        }

        if (!companyName) {
            notifyWarning('Company Name is required.');
            setIsLoading(false);
            return;
        }

        // Validate email or phone input
        let payload;
        if (email) {
            if (!emailRegex.test(email)) {
                notifyWarning('Please enter a valid email.');
                setIsLoading(false);
                return;
            }
            payload = { email, password, companyName };
        } else if (phone) {
            if (!phoneRegex.test(phone)) {
                notifyWarning('Please enter a valid phone number.');
                setIsLoading(false);
                return;
            }
            payload = { phone: Number(phone), password, companyName };
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('companyName', companyName);
            if (email) formDataToSend.append('email', email);
            if (phone) formDataToSend.append('phone', phone);
            formDataToSend.append('password', password);
            if (image) formDataToSend.append('ComapnyLogo', image);

            const response = await fetch(`${SERVER_LINK}/api/v1/admin/CompanyCreateUser`, {
                method: 'POST',
                body: formDataToSend // Send data as multipart form data
            });

            const result = await response.json();

            if (response.ok) {
                notifySuccess('User created successfully.');
                setFormData(initialFormState);
            } else {
                notifyError(result.message || 'Failed to create user.');
            }
        } catch (error) {
            notifyError('Error occurred during the request.');
        }

        setIsLoading(false); // Stop loading animation after request
    };

    const handleBack = () => {
        navigate('/merchent-company');
    };

    return (
        <>
            {isLoading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 100,
                }}>
                    <LottieAnimation lottieJson={dot} width={550} height={400} />
                </div>
            )}

            <div className="col-xl-12 col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Add New Company</h4>
                    </div>
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Company Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Company Name"
                                            autoComplete="off"
                                            name="companyName"
                                            value={formData.companyName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Phone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Company Phone No."
                                            autoComplete="off"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Company Email"
                                            autoComplete="off"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            autoComplete="new-password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label>Image Upload</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="image"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-success">
                                    Submit
                                </button>
                                <button type="button" className="btn btn-secondary ms-2" onClick={handleBack}>
                                    Back
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MerchantCompany;
