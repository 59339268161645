import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import Loader from "../Loader/loader";

const URL = process.env.REACT_APP_SERVER_LINK

const ClientWallet = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedRadio, setSelectedRadio] = useState("null"); // Default to null for all
    const [limit, setLimit] = useState(""); // State for the limit
    const [dataToDownload, setDataToDownload] = useState([]);
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [error, setError] = useState(""); // State for error messages
    const [isImporting, setIsImporting] = useState(false); // State to manage the button disabled state
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        // Automatically fetch total count and set limit when the component loads
        const fetchTotalCount = async () => {
            try {
                const response = await fetch(`${URL}/api/v1/counter/item-count?type=USER`);
                if (response.ok) {
                    const result = await response.json();
                    setLimit(result.data); // Set the limit based on the total count
                } else {
                    setError("Failed to fetch total count");
                }
            } catch (error) {
                setError("An error occurred while fetching total count.");
            } finally {
                setIsLoading(false); // Hide loader
            }
        };

        fetchTotalCount();
    }, []);


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleOptionChange = (e) => {
        setError(""); // Clear previous errors
        setSelectedOption(e.target.value);
    };

    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.id);
    };

    const handleLimitChange = (e) => {
        // Allow only positive numbers
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setLimit(value);
        }
    };

    const getPriceQuery = () => {
        switch (selectedRadio) {
            case "option1":
                return { gt: 0 };
            case "option2":
                return { ge: 0 };
            case "option3":
                return { le: 0 };
            case "option4":
                return { lt: 0 };
            case "option6":
                return { eq: 0 };
            default:
                return null;
        }
    };

    const downloadExcel = () => {
        const dataForDownload = dataToDownload.map(user => {
            return {
                Name: user?.user?.full_name || 'Guest',
                MobileNo: user?.user?.phone || 'N/A',
                Email: user?.user?.email || 'N/A',
                Pancard: user?.user?.pancard || 'N/A',
                Aadhar: user?.user?.aadhar || 'N/A',
                DOB: user?.user?.DateOfBirth || 'N/A',
                Address: user?.user?.address || 'N/A',
                WalletBalance: user?.priceWallet?.price,
                'CCD Token Balance': user?.coinWallet?.totalSupply.toString() || 'N/A',
            };
        });


        const worksheet = XLSX.utils.json_to_sheet(dataForDownload);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

        // Set column widths
        const wscols = [
            { wch: 20 }, // Name
            { wch: 15 }, // MobileNo
            { wch: 25 }, // Email
            { wch: 25 }, // Pancard
            { wch: 25 }, // Aadhar
            { wch: 25 }, // DOB
            { wch: 25 }, // Address
            { wch: 15 }, // WalletBalance
            { wch: 20 }, // CCDTokenBalance
        ];
        worksheet['!cols'] = wscols;

        XLSX.writeFile(workbook, "WalletData.xlsx");
    };

    const handleSearch = async () => {
        setIsImporting(true)
        setIsDataAvailable(false);
        setError(""); // Clear previous errors

        // Check if an option is selected
        if (!selectedOption) {
            setIsImporting(false);
            setError("Please select an option.");
            return;
        }

        const url = `https://simplyfynft.com/api/v1/wallet-excel/${selectedOption}?limit=${limit}`;

        const requestBody = selectedRadio !== "option5" ? { priceQuery: getPriceQuery() } : null

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                ...(requestBody && { body: JSON.stringify(requestBody) }),
            });

            if (response.ok) {
                const result = await response.json();

                // Set data for download
                setDataToDownload(result.data);
                setIsDataAvailable(true);
            } else {
                console.error("API Request Failed:", response.status);
                setError("API Request Failed");
                setIsDataAvailable(false);
            }
        } catch (error) {
            console.error("API Call Error:", error);
            setIsDataAvailable(false);
        } finally {
            setIsImporting(false);
        }
    };

    return (
        <Col md={5}>
            <Card>
           
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>Download Excel File</Card.Title>
                    <div className="d-flex align-items-center">
                       <p>Total {limit} User</p>
                    </div>
                </Card.Header>
                <Card.Body>
                    {isLoading && (
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Loader /> {/* Replace with your loader component */}
                        </div>
                    )}
                    <Form>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Select an Option</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="form-control"
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                    >
                                        <option value="" disabled hidden>
                                            Select an Option
                                        </option>
                                        <option value="walletData">User Wallets</option>
                                        {/* <option value="tokenHolding">User Token Holdings</option> */}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            {/* <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Limit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Limit"
                                        value={limit}
                                        onChange={handleLimitChange}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>

                        <Row className="mt-4">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Check
                                        type="radio"
                                        label="Greater than 0"
                                        name="priceQueryOptions"
                                        id="option1"
                                        className="mb-2"
                                        onChange={handleRadioChange}
                                        checked={selectedRadio === "option1"}
                                    />

                                    {/* <Form.Check
                                        type="radio"
                                        label="Greater than or equal to 0"
                                        name="priceQueryOptions"
                                        id="option2"
                                        className="mb-2"
                                        onChange={handleRadioChange}
                                        checked={selectedRadio === "option2"}
                                    /> */}
                                    <Form.Check
                                        type="radio"
                                        label="Equal to 0"
                                        name="priceQueryOptions"
                                        id="option6"
                                        className="mb-2"
                                        onChange={handleRadioChange}
                                        checked={selectedRadio === "option6"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    {/* <Form.Check
                                        type="radio"
                                        label="Less than or equal to 0"
                                        name="priceQueryOptions"
                                        id="option3"
                                        className="mb-2"
                                        onChange={handleRadioChange}
                                        checked={selectedRadio === "option3"}
                                    /> */}
                                    <Form.Check
                                        type="radio"
                                        label="Less than 0"
                                        name="priceQueryOptions"
                                        id="option4"
                                        className="mb-2"
                                        onChange={handleRadioChange}
                                        checked={selectedRadio === "option4"}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="All"
                                        name="priceQueryOptions"
                                        id="option5"
                                        className="mb-2"
                                        onChange={handleRadioChange}
                                        checked={selectedRadio === "option5"}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* Conditionally render date pickers */}
                        {selectedOption !== "walletData" && selectedOption !== "tokenHolding" && (
                            <div className="d-flex align-items-center mt-4">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control"
                                    placeholderText="Start Date"
                                    dateFormat="dd/MM/yy"
                                />
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control ms-2"
                                    placeholderText="End Date"
                                    dateFormat="dd/MM/yy"
                                />
                            </div>
                        )}

                        <Row className="mt-4">
                            <Col>
                                <Button type="button"
                                    variant="success"
                                    onClick={handleSearch}
                                    disabled={isImporting}>


                                    {isImporting ? 'Importing...' : (
                                        <>
                                            <span className="btn-icon-start text-success">
                                                <i className="fa fa-paper-plane color-success" />
                                            </span>
                                            Send Request
                                        </>
                                    )}

                                </Button>
                                <Button
                                    variant="danger"
                                    className="ms-2"
                                    disabled={!isDataAvailable}
                                    onClick={downloadExcel}
                                >
                                    <span className="btn-icon-start text-danger">
                                        <i className="fa fa-download color-danger" />
                                    </span>
                                    Download
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ClientWallet;
