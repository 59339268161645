import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";




export function formatError(errorResponse) {
  switch (errorResponse) {
    case "Account Not Found":
      //return 'Email already exists';
      swal("Oops", "Username Does not exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Username not found", "error", { button: "Try Again!" });
      break;
    case "Invalid Password":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}


export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(new Date().getTime() + 300 * 1000);
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, navigate) {
  let logoutTimer;
  let lockScreenTimer;
  let debounceTimer;
  const inactivityTime = 10 * 60 * 1000; // 10 minutes in milliseconds
  const logoutTime = 5 * 60 * 1000; // Additional 5 minutes in milliseconds after the lock screen

  const navigateToLockScreen = () => {
    if (typeof navigate === 'function') {
      navigate('/AdminLogin');
      // Set the logout timer when the lock screen is navigated to
      logoutTimer = setTimeout(logout, logoutTime);
    } else {
      console.error("navigate is not a function");
    }
  };

  const logout = () => {
    dispatch(Logout(navigate));
  };

  const debounceReset = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(resetTimer, 100); // 500ms debounce
  };

  const resetTimer = () => {
    clearTimeout(logoutTimer); // Clear the existing logout timer
    clearTimeout(lockScreenTimer); // Clear the existing lock screen timer
    lockScreenTimer = setTimeout(navigateToLockScreen, inactivityTime);
    // Do not set the logout timer here as it will be set when navigating to the lock screen
  };

  // Attach debounceReset to user activity events
  const events = ["mousemove", "mousedown", "click", "touchstart", "keypress", "scroll"];
  const handleEvent = (event) => {
    debounceReset();
  };

  events.forEach(event => {
    window.addEventListener(event, handleEvent, { passive: true });
  });

  // Set the initial lock screen timer
  resetTimer(); // Initialize the timers

  // Cleanup function to remove event listeners and clear the timers
  return () => {
    clearTimeout(logoutTimer);
    clearTimeout(lockScreenTimer);
    clearTimeout(debounceTimer);
    events.forEach(event => {
      window.removeEventListener(event, handleEvent);
    });
  };
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  const tokenDetails = JSON.parse(tokenDetailsString);
  const expireDate = new Date(tokenDetails.expireDate);
  const todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const cleanup = runLogoutTimer(dispatch, navigate);

  // Ensure cleanup is called appropriately
  return cleanup;
}
