import React, { useState, useEffect } from "react";
import { Form,  Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { addICOCoupon } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import CreatableSelect from "react-select/creatable";

const ICOCoupon = () => {
    const [couponUsesLimit, setCouponUsesLimit] = useState('');
    const [couponExpiryDate, setCouponExpiryDate] = useState('');
    const [usesLocation, setUsesLocation] = useState('');
    const [action, setAction] = useState('');
    const [generatedCoupon, setGeneratedCoupon] = useState(''); // New state for generated coupon
    const [PK, setPK] = useState(''); // New state for PK
    const [SK, setSK] = useState(''); // New state for SK
    const { notifyError, notifySuccess, notifyWarning } = useToast();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.coupon) {
            const { couponName, couponUsesLimit, couponExpirydate, usesLocation, action, PK, SK } = location.state.coupon;
            setGeneratedCoupon(couponName);
            setCouponUsesLimit(couponUsesLimit);
            setCouponExpiryDate(couponExpirydate);
            setUsesLocation(usesLocation);
            setAction(action);
            setPK(PK);
            setSK(SK);
        }
    }, [location.state]);

    const handleClear = () => {
        setCouponUsesLimit('');
        setCouponExpiryDate('');
        setUsesLocation('');
        setAction('');
        setGeneratedCoupon('');
        setPK('');
        setSK('');
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form from submitting traditionally
        // Check if any field is empty
        if (!couponUsesLimit || !couponExpiryDate || !usesLocation || !action || !generatedCoupon) {
            notifyWarning("Please fill in all fields before submitting!");
            return; // Stop the form submission if any field is empty
        }

        // Check if the generated coupon is 10 characters long
        if (generatedCoupon.length !== 10) {
            notifyWarning("Coupon code must be 10 characters long!");
            return;
        }

        try {
            const response = await addICOCoupon(
                generatedCoupon,
                couponUsesLimit,
                couponExpiryDate,
                usesLocation,
                action,
                PK || undefined, // Only send PK if it has a value
                SK || undefined  // Only send SK if it has a value
            );
            if (response.addICOCoupon.success === true) {
                if (PK && SK) {
                    notifySuccess("Coupon updated successfully!");
                } else {
                    notifySuccess("Coupon added successfully!");
                }
                handleClear();
            }
        } catch (error) {
            notifyError("Error adding coupon!");
        }
    };

    const handleSelectChange = (selectedOption) => {
        setCouponUsesLimit(selectedOption.value);
    };

    const generateCouponCode = () => {
        const prefix = "SIMP_";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let result = prefix;
        for (let i = 0; i < 5; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setGeneratedCoupon(result);
    };

    const handleCouponChange = (e) => {
        const value = e.target.value.toUpperCase();
        if (value.length <= 10) {
            setGeneratedCoupon(value);
        }
    };

    return (
        <div className="col-xl-12 col-lg-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Add ICO Coupon</h4>
                </div>
                <div className="card-body">
                    <Form onSubmit={handleSubmit}>
                        <div className="row">
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>Coupon Name</Form.Label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Click to Generate Coupon..."
                                        value={generatedCoupon}
                                        onChange={handleCouponChange}
                                    />
                                    <Button variant="success" type="button" onClick={generateCouponCode}>
                                        Generate Coupon
                                    </Button>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>Coupon Uses Limit</Form.Label>
                                <CreatableSelect
                                    className="custom-react-select"
                                    isSearchable={true}
                                    value={{ value: couponUsesLimit, label: couponUsesLimit }} // Set the initial value
                                    onChange={handleSelectChange}
                                    options={[
                                        { value: 'INFINITE', label: 'Infinite' },
                                        { value: '100', label: '100' },
                                        { value: '200', label: '200' },
                                        { value: '500', label: '500' },
                                        { value: '1000', label: '1000' },
                                        { value: '2000', label: '2000' },
                                    ]}
                                />
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>Expire Date of Coupon</Form.Label>
                                <Form.Control
                                    type="date"
                                    id="expireDate"
                                    name="date"
                                    value={couponExpiryDate}
                                    onChange={e => setCouponExpiryDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>Location</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Location"
                                    value={usesLocation}
                                    onChange={e => setUsesLocation(e.target.value)}
                                />
                            </Form.Group>
                        </div>
                        <div className="row">
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>Action</Form.Label>
                                <div>
                                    <Form.Check
                                        type="radio"
                                        label="Pause"
                                        name="status"
                                        value="Pause"
                                        checked={action === 'Pause'}
                                        onChange={e => setAction(e.target.value)}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Start"
                                        name="status"
                                        value="Start"
                                        checked={action === 'Start'}
                                        onChange={e => setAction(e.target.value)}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Stop"
                                        name="status"
                                        value="Stop"
                                        checked={action === 'Stop'}
                                        onChange={e => setAction(e.target.value)}
                                        inline
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <Button type="submit" variant="success">Submit</Button>
                        <Button type="button" className="btn btn-secondary mx-2" onClick={() => handleClear()}>Clear</Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ICOCoupon;
