import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditTaxModel = ({ show, onHide, taxItem, onSave }) => {
    const [taxCalculation, setTaxCalculation] = useState(taxItem?.taxCalculation || '');
    const [percentage, setPercentage] = useState(taxItem?.percentage || '');

    const handleSubmit = (event) => {
        event.preventDefault();
        onSave({ ...taxItem, taxCalculation, percentage });
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Tax</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="taxCalculation">
                        <Form.Label>Tax Calculation</Form.Label>
                        <Form.Control
                            type="text"
                            value={taxCalculation}
                            onChange={(e) => setTaxCalculation(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="percentage">
                        <Form.Label>Percentage</Form.Label>
                        <Form.Control
                            type="text"
                            value={percentage}
                            onChange={(e) => setPercentage(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditTaxModel;
