import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
// import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';

import ScrollToTop from "./layouts/ScrollToTop";

//TestTrading
import TestTrading from "./components/TestTrading/TestTrading";

// Dashboard
import Dashboard1 from "./components/Dashboard/Dashboard1";

//Welcome Page
import WelcomePage from "./components/Welcome/WelcomePage";


//Coin Listing
import ListMarketCoin from "./components/CoinListing/ListMarketCoinTable/ListMarketCoin";
import AddEditNewCoin from "./components/CoinListing/AddEditNewCoins";
import ListOwnCoin from "./components/CoinListing/ListOwnCoins";

// User Management
import AutoApproved from "./components/UserManagement/AutoApproved";
import PartialFilled from "./components/UserManagement/PartialFilled";
import ApproveByAdmin from "./components/UserManagement/ApproveByAdmin";
import UserData from "./components/UserManagement/UserData";
import KYCDocument from "./components/UserManagement/KYCDocument";
import BankDetails from "./components/UserManagement/BankDetails";
import DepositHistory from "./components/UserManagement/DepositHistory";
import TXNhistory from "./components/UserManagement/TXNhistory";
import Holding from "./components/UserManagement/Holding";
import WithDrawProfile from "./components/UserManagement/WithDrawProfile";
import ProfileAirdrop from "./components/UserManagement/ProfileAirdrop";
import WalletAddress from "./components/UserManagement/WalletAddress";

import AllUsers from "./components/UserManagement/AllUsers";

//Control
import Control from "./components/Control/Control";

//Orders
import Orders from "./components/Orders/Orders";
import OrdersTab from "./components/Orders/OrdersTab";

//WithDraw
import WithDraw from "./components/Withdraw/WithDraw";

//CommissionOrFee
import AddTax from "./components/CommissionOrFee/AddTax";
import ListTax from "./components/CommissionOrFee/ListTax";

//Airdrop
import Airdrop from "./components/Airdrop/Airdrop";

//Template
import AddAdminTemplate from "./components/Template/AddAdminTemplate";
import ListAdminTemplate from "./components/Template/ListAdminTemplate";

//Create Admin
import CreateAdmin from "./components/CreateAdmin/CreateAdmin";
import AdminList from "./components/CreateAdmin/AdminList";

//Role
import AddRole from "./components/Role/AddRole";
import ListOfRole from "./components/Role/ListOfRole";

//Change Password
import AdminPassword from "./components/ChangePassword/AdminPassword";
import SuperAdminPassword from "./components/ChangePassword/SuperAdminPassword";

//Control Tabs
import AddTabSubTabs from "./components/ControlTabs/AddTabsSubTabs";
import ListTabsSubTabs from "./components/ControlTabs/ListTabsSubTabs";

//Coin Supply
import SupplyList from "./components/CoinSupply/SupplyList";
import AddSupply from "./components/CoinSupply/AddSupply";

//Order History
import OrderHistory from './components/OrderHistory/OrderHistory';

//Token Holder List
import TokenHolderList from "./components/TokenHolderList/TokenHolderList";

//TransactionHistory
import TransactionHistory from "./components/TransactionHistory/TransactionHistory";

//Token Request
import TokenRequest from "./components/TokenRequest/TokenRequest";


//import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";


//demochat
import Chat from "./components/Chat/chating";

//Brand

import AddBrand from "./components/Brand/AddBrand";
import ListBrand from "./components/Brand/ListBrand";

//Denominations
import Denominations from "./components/Denominations/Denominations";

//Coupons
import AddCoupons from "./components/Coupons/AddCoupons";
import ListCoupons from "./components/Coupons/ListCoupons";
import FlexibleVoucher from "./components/Coupons/FlexibleVoucher";
import FlexibleVoucherList from "./components/Coupons/FlexibleVoucherList";
import UserVoucherList from "./components/Coupons/UserVoucherList";

//Add Blog
import AddBlog from "./components/MobileApp/AddBlog";
import BlogList from "./components/MobileApp/BlogList";

//Banners
import AddBanner from "./components/Banners/AddBanner";
import BannerList from "./components/Banners/BannerList";

import HomeTextHeader from "./components/MobileApp/HomeTextHeader";
import ListHeaderText from "./components/MobileApp/ListHeaderText";

//Help&Support
import HelpSupportList from "./components/HelpSupport/HelpSupportList";
import HelpSupportMessage from "./components/HelpSupport/HelpSupportMessage";

//App Charges
import AddCharges from "./components/AppCharges/AddCharges";
import ListCharges from "./components/AppCharges/ListCharges";

//Version
import Version from "./components/MobileApp/Version";
import VersionList from "./components/MobileApp/VersionList";

//Maintenance
import Maintenance from "./components/MobileApp/Maintenance";


//ICO
import MobileData from "./components/ICO/MobileData";
import MobileDataList from "./components/ICO/MobileDataList";
import ICOList from "./components/ICO/ICOLsting";
import ICOCoupon from "./components/ICO/ICOCoupon";
import ICOCouponList from "./components/ICO/ICOCouponList";

//Notification
import Notification from "./components/MobileApp/Notification";

//DeleteItems
import DeleteItems from "./components/DeleteItems/DeleteItems";

//TestCoin
import INRWallet from "./components/AdminWallet/INRWallet";
import BrokerageWallet from "./components/AdminWallet/BrokerageWallet";
import GSTWallet from "./components/AdminWallet/GSTWallet";
import TDSWallet from "./components/AdminWallet/TDSWallet";
import CCDTokenWallet from "./components/AdminWallet/CCDTokenWallet";
import TDSDeduction from "./components/AdminWallet/TDSDeduction";

//All Orders
import OpenOrder from "./components/Order/OpenOrder";
import AllCancelled from './components/Order/AllCancelledOrders'
import ExecutedOrder from "./components/Order/ExecutedOrder";

//Reffral
import ReferralList from "./components/Referral/ReferralList";
import CreateReferralProgram from "./components/Referral/CreateReferral";

//UserOrders
import UserOrders from "./components/UserManagement/userOrders";

//Order Details
import OrderDetails from "./components/OrderDetails/OrderDetails";

//Bank Details
import AllBankDetails from "./components/AllUserBankDetails/AllBankDetails";

// Desktop
import ContactUser from "./components/WEBContent/WEBContactUser";
import WEBBlog from "./components/WEBContent/WEBBlog";
import WEBBlogList from "./components/WEBContent/WEBBlogList";

//Recharge
import Recharge from "./components/Recharge/Recharge";

//AdminChangePassword
import Password from "./components/AdminPassword/Password";

//ClientWallet
import ClientWallet from "./components/ClientWallet/ClientWallet";

//Deposite File
import DepositeFile from "./components/DepositFile/DepositeFile";
import DepositeFileHistory from "./components/DepositFile/DepositeFileHistory";
import DepositeAllHistory from "./components/DepositFile/DepositeAllHistory";
import AddDeposite from "./components/DepositFile/AddDeposite";

//Merchant Company
import MerchantCompany from "./components/MerchantUser/MerchantCompany";
import MerchentCompanyList from "./components/MerchantUser/MerchantCompanyList";
import RejectedHistory from "./components/DepositFile/RejectedHistory";

//Simplyfy Shark
import SharkAllUser from "./components/SimplyfyShark/SharkAllUser";



const Markup = () => {
  const allroutes = [

    //Welocme Page 
    { url: 'welcome', component: <WelcomePage/> },
    //TestTrading
    { url: 'test-trading', component: <TestTrading /> },

    /// Dashboard

    { url: 'dashboard-1', component: <Dashboard1 /> },


    //Coin Marketing

    { url: "add-Coin", component: <AddEditNewCoin /> },
    { url: "List-Coin", component: <ListOwnCoin /> },
    { url: "listMarketCoin", component: <ListMarketCoin /> },

    //Usees Management
    { url: "auto-approved", component: <AutoApproved /> },
    { url: "partial-filled", component: <PartialFilled /> },
    { url: "approved-by-admin", component: <ApproveByAdmin /> },
    { url: "user-data", component: <UserData /> },
    { url: "kYC-document", component: <KYCDocument /> },
    { url: "bank-details", component: <BankDetails /> },
    { url: "deposit-history", component: <DepositHistory /> },
    { url: "tXN-history", component: <TXNhistory /> },
    { url: "holding", component: <Holding /> },
    { url: "withDraw-profile", component: <WithDrawProfile /> },
    { url: "profile-airdrop", component: <ProfileAirdrop /> },
    { url: "all-Users", component: <AllUsers />, },
    { url: "wallet-address", component: <WalletAddress /> },

    //Control
    { url: "control", component: <Control /> },

    //Orders
    { url: "orders", component: <Orders /> },
    { url: "orders-Tab", component: <OrdersTab /> },

    //WithDraw
    { url: "with-draw", component: <WithDraw /> },

    //CommissionOrFees
    { url: "add-tax", component: <AddTax /> },
    { url: "list-tax", component: <ListTax /> },

    //Airdrop
    { url: "airdrop", component: <Airdrop /> },

    //Template
    { url: "admin-template", component: <AddAdminTemplate /> },
    { url: "list-template", component: <ListAdminTemplate /> },

    //Role
    { url: "add-role", component: <AddRole /> },
    { url: "list-role", component: <ListOfRole /> },

    //Create Admin
    { url: "create-admin", component: <CreateAdmin /> },
    { url: "admin-list", component: <AdminList /> },

    //Change Password
    { url: "admin-password", component: <AdminPassword /> },
    { url: "superAdmin-password", component: <SuperAdminPassword /> },

    //Control Tabs
    { url: "add-Tab", component: <AddTabSubTabs /> },
    { url: "listTabs-Subtabs", component: <ListTabsSubTabs /> },

    //CoinSupply
    { url: "add-supply", component: <AddSupply /> },
    { url: "supply-list", component: <SupplyList /> },

    //Transaction History
    { url: "transaction-history", component: <TransactionHistory /> },

    //Order History
    { url: "order-history", component: <OrderHistory /> },

    //Token Holder List
    { url: "token-holder", component: <TokenHolderList /> },

    //Token Request

    { url: 'token-request', component: <TokenRequest /> },





    // /// Apps
    // { url: "app-profile", component: <AppProfile /> },
    // { url: "edit-profile", component: <EditProfile /> },
    // { url: "email-compose", component: <Compose /> },
    // { url: "email-inbox", component: <Inbox /> },
    // { url: "email-read", component: <Read /> },
    // { url: "app-calender", component: <Calendar /> },
    // { url: "post-details", component: <PostDetails /> },

    { url: 'chat-message', component: <Chat /> },

    //Brand
    { url: 'list-brand', component: <ListBrand /> },
    { url: 'add-brand', component: <AddBrand /> },

    //Denominations
    { url: 'denomination', component: <Denominations /> },

    //Coupons
    { url: 'list-coupons', component: <ListCoupons /> },
    { url: 'add-coupons', component: <AddCoupons /> },
    { url: 'flexible-voucher', component: <FlexibleVoucher /> },
    { url: 'flexible-list', component: <FlexibleVoucherList /> },
    { url: 'userVoucher-list', component: <UserVoucherList /> },


    //Add Blog
    { url: 'add-blog', component: <AddBlog /> },
    { url: 'blog-list', component: <BlogList /> },

    //Banners
    { url: 'add-banner', component: <AddBanner /> },
    { url: 'banner-list', component: <BannerList /> },

    //home page header
    { url: 'home-header', component: <HomeTextHeader /> },
    { url: 'list-header', component: <ListHeaderText /> },

    //Notification
    { url: 'notification', component: <Notification /> },

    //Help&Support
    { url: 'help&support-message', component: <HelpSupportMessage /> },
    { url: 'help&support-list', component: <HelpSupportList /> },

    //App Charges
    { url: 'add-charges', component: <AddCharges /> },
    { url: 'list-charges', component: <ListCharges /> },

    //Version
    { url: 'version', component: <Version /> },
    { url: 'version-list', component: <VersionList /> },

    //Maintenance
    { url: 'maintenance', component: <Maintenance /> },

    //ICO
    { url: 'mobile-data', component: <MobileData /> },
    { url: 'ico-listing', component: <ICOList/> },
    { url: 'ico-text', component: <MobileDataList /> },
    { url: 'ico-coupon', component: <ICOCoupon /> },
    { url: 'ico-list', component: <ICOCouponList /> },

    //Delete Items
    { url: 'delete-items', component: <DeleteItems /> },

    //Testing Coin Data

    //Admin Wallet
    { url: 'inr-wallet', component: <INRWallet /> },
    { url: 'brokerage-wallet', component: <BrokerageWallet /> },
    { url: 'gst-wallet', component: <GSTWallet /> },
    { url: 'tds-wallet', component: <TDSWallet /> },
    { url: 'token-wallet', component: <CCDTokenWallet /> },
    { url: 'tDS-deduction', component: <TDSDeduction /> },

    //All Orders
    { url: 'open-order', component: <OpenOrder /> },
    { url: 'cancelled-orders', component: <AllCancelled /> },
    { url: 'executed-orders', component: <ExecutedOrder /> },

    //Referral
    { url: 'referral-list', component: <ReferralList /> },
    { url: 'create-referral', component: <CreateReferralProgram /> },

    //User Orders
    { url: 'user-orders', component: <UserOrders /> },

    //Order Details
    { url: 'order-detail', component: <OrderDetails /> },

    // All Bankl Details
    { url: 'all-bankDetails', component: <AllBankDetails /> },
    
    { url: 'contact-user', component: <ContactUser /> },
    { url: 'web-blog', component: <WEBBlog/> },
    { url: 'web-bloglist', component: <WEBBlogList/> },

    //Recharge
    { url: 'recharge', component: <Recharge/> },

    //AdminChangePassword
    { url: 'password', component: <Password/> },

    //ClientWallet
    { url: 'client-wallet', component: <ClientWallet/> },

    //Deposite File
    { url: 'deposite-file', component: <DepositeFile/> },
    { url: 'deposite-history', component: <DepositeFileHistory/> },
    { url: 'all-history', component: <DepositeAllHistory/> },
    { url: 'add-deposite', component: <AddDeposite/> },
    { url: 'rejected-history', component: <RejectedHistory/> },

    
    //Merchent Company
    { url: 'addmerchent-company', component: <MerchantCompany/> },
    { url: 'merchent-company', component: <MerchentCompanyList/> },

    //Simplyfy Shark
    { url: 'simplyfy-shark', component: <SharkAllUser/> },

  ];

  return (
    <>
      <Routes>
        <Route path="page-lock-screen" element={<LockScreen />} />
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
