import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { deleteCoupon } from "../../../apolloClientConnection/apolloClient";
import Select from "react-select";
const SERVER_LINK = process.env.REACT_APP_SERVER_LINK;

const optionValue = [
    { value: 15, label: "15" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
];


const SharkAllUser = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(15); // Default limit set to 10
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [noData, setNoData] = useState(false);

    const { notifySuccess, notifyError } = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        setHasError(false);
        setNoData(false);

        fetch(`${SERVER_LINK}/api/v1/file/GetSharkData`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async (res) => {
                const result = await res.json();
                if (res.ok && result.data && result.data.data.length > 0) {
                    setBrands(result.data.data); // Accessing nested data
                } else if (result.data && result.data.data.length === 0) {
                    setNoData(true);
                } else {
                    setHasError(true);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setHasError(true);
                console.log(err);
            });
    }, [perPage]);

    const handleDeleteBrand = async (item) => {
        try {
            const response = await deleteCoupon(item.PK, item.SK);
            if (response) {
                notifySuccess('Item Deleted Successfully!');
                const newBrands = brands.filter((brand) => brand.PK !== item.PK);
                setBrands(newBrands);
            }
        } catch (error) {
            notifyError('Error deleting category');
        }
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = ()=>{
    
    }

    const handleEditBrand = (item) => {
        navigate('/addmerchent-company', { state: { item } });
    };

    // SVG Icons
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFFFFF" d="M3,21.5 L3.5,17.5 L14.5,6.5 C14.8978,6.10218 15.4374,5.87868 16,5.87868 C16.2786,5.87868 16.5541,5.93355 16.8111,6.04016 C17.0681,6.14678 17.303,6.30356 17.5,6.5 C17.697,6.69644 17.8538,6.93131 17.9604,7.18832 C18.067,7.44534 18.1219,7.72077 18.1219,7.99935 C18.1219,8.56196 17.8984,9.10155 17.5006,9.49935 L6.5,20.5 L2.5,21 L3,21.5 Z M5.06066,19 L14.0607,10 L17,12.9393 L8,21.9393 L5.06066,19 Z M16.5,9.5 L19.5,6.5 L17,4 L14,7 L16.5,9.5 Z" />
        </svg>
    );

    const svg2 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>User List</Card.Title>
                    <div className="d-flex align-items-center">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select me-3"
                        />
                    </div>
                </Card.Header>


                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                            <th >
                                    <strong>S.No</strong>
                                </th>
                                <th >
                                    <strong>Name</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Phone / Email</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Nationality</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Gender</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Marrige Status</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Qualification</strong>
                                </th> 
                                <th className="text-center">
                                    <strong>Address</strong>
                                </th> 
                                <th className="d-none">
                                    ID
                                </th>
                                {/* <th className="text-center">
                                    <strong>Action</strong>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr>
                                    <td colSpan="4" className="text-center"><Loader /></td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : noData ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (brands?.map((item, index)=> (
                                <tr key={item.PK}>
                                    <td>{index+1}</td>
                                    <td>{item.full_name}</td>
                                    <td className="text-primary text-center fw-bold">
                                        {item.phone}
                                        {item.email && <div>{item.email}</div>}
                                    </td>
                                    <td className="text-center">{item.Nationality}</td>
                                    <td className="text-center">{item.Gender}</td>
                                    <td className="text-center">{item.marrigeStatus}</td>
                                    <td className="text-center">{item.qualifications}</td>
                                    <td className="text-center">{item.Address}</td>
                                    <td className="d-none">{item.SK}</td>
                                    {/* <td className="text-center">
                                        <Dropdown>
                                            <Dropdown.Toggle onClick={() => handleEditBrand(item)} variant={'success'} className="light sharp i-false">
                                                {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Toggle
                                                variant={'danger'}
                                                className="light sharp i-false ms-3"
                                                onClick={() => handleDeleteBrand(item)}
                                            >
                                                {svg2}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </td> */}
                                </tr>
                            )))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span> 1-4 </span> from <span> {filteredData.length} </span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default SharkAllUser;
