import React, { useState, useRef, useEffect } from 'react';
import { Button } from "react-bootstrap";
import useToast from '../Toaster/Toaster';
import { createWallet } from '../../../apolloClientConnection/apolloClient';

const AddEditNewCoin = () => {
    const [status, setStatus] = useState('off');
    const [coinCode, setCoinCode] = useState('');
    const [coinPair, setCoinPair] = useState('');
    const [coinName, setCoinName] = useState('');
    const [decimal, setDecimal] = useState('');
    const [coinPrice, setCoinPrice] = useState('');
    const coinPriceRef = useRef(null);
    const [coinSupply, setCoinSupply] = useState('');
    const [coinNameError, setCoinNameError] = useState(false);
    const [coinCodeError, setCoinCodeError] = useState(false);
    const [coinPairError, setCoinPairError] = useState(false);
    const [decimalError, setDecimalError] = useState(false);
    const [coinPriceError, setCoinPriceError] = useState(false);
    const [coinSupplyError, setCoinSupplyError] = useState(false);
    const { notifySuccess, notifyError, notifyInfo } = useToast();

    const handleOnCoinSupply = (e) => {
        setCoinSupplyError(false)
        // Remove any characters that are not digits or decimal points
        let num = e.target.value.replace(/[^0-9.]/g, '');
        // Format the number as Indian Rupees without the currency symbol
        let formattedValue = new Intl.NumberFormat('en-IN', {
            maximumFractionDigits: 2, // Adjust accordingly for desired decimal places
        }).format(num);
        setCoinSupply(formattedValue);
    };

    const handleDecimalChange = (e) => {
        setDecimalError(false)
        const newDecimal = e.target.value;
        if (newDecimal === '' || (Number.isInteger(+newDecimal) && +newDecimal >= 0 && +newDecimal <= 18)) {
            setDecimal(newDecimal);
        }else if(newDecimal == ''){
            setCoinPrice('')
        }
    };

    const handleCoinPriceChange = (e) => {
        setCoinPriceError(false)
        setCoinPriceError(false)
        let newPrice = e.target.value;
        if (/^(\d+\.?\d*?)?$/.test(newPrice)) {
            setCoinPrice(newPrice);
        }
    };

    const formatPriceWithZeros = (price, decimals) => {
        if (!price.includes('.')) {
            price += '.';
        }
        const [integerPart, decimalPart] = price.split('.');
        const paddedDecimalPart = (decimalPart || '').padEnd(decimals, '0');
        return `${integerPart}.${paddedDecimalPart}`;
    };

    // Focus and set cursor position to start on input focus
    const handleCoinPriceFocus = () => {
        setTimeout(() => {
            if (coinPriceRef.current) {
                coinPriceRef.current.setSelectionRange(0, 0);
            }
        }, 1); // A slight delay to ensure the cursor position is set after the input value is updated
    };



    const handleCoinCodeChange = (event) => {
        setCoinCodeError(false);
        const uppercaseInput = event.target.value.toUpperCase();
        const filteredInput = uppercaseInput.replace(/[0-9]/g, ''); // Remove numbers
        setCoinCode(filteredInput.substring(0, 5)); // Take only the first 5 characters
    };
    
    const handleCoinPairChange = (event) => {
        setCoinPairError(false);
        const uppercaseInput = event.target.value.toUpperCase();
        const filteredInput = uppercaseInput.replace(/[0-9]/g, ''); // Remove numbers
        setCoinPair(filteredInput);
    };
    
    const handleCoinNameChange = (event) => {
        setCoinNameError(false)
        const capitalizeWords = event.target.value.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        setCoinName(capitalizeWords);
    };

    const toggleStatus = () => {
        setStatus(currentStatus => currentStatus === 'off' ? 'is live' : 'off');
    };

    const handleClear = () => {
        setCoinName('');
        setCoinCode('');
        setCoinPair('')
        setDecimal('');
        setCoinPrice('');
        setCoinSupply('');
        setCoinNameError(false);
        setCoinCodeError(false);
        setCoinPairError(false)
        setDecimalError(false)
        setCoinPriceError(false)
        setCoinSupplyError(false)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Reset validation states
        setCoinNameError(false);
        setCoinCodeError(false);
        setCoinPairError(false);
        setDecimalError(false);
        setCoinPriceError(false);
        setCoinSupplyError(false);

        let isValid = true;

        // Check if all fields are empty
        if (!coinName || !coinCode || !coinPair || !decimal || !coinPrice || !coinSupply) {
            notifyInfo('Please fill in all the inputs!');
            if (!coinName) setCoinNameError(true);
            if (!coinCode) setCoinCodeError(true);
            if (!coinPair) setCoinPairError(true);
            if (!decimal) setDecimalError(true);
            if (!coinPrice) setCoinPriceError(true);
            if (!coinSupply) setCoinSupplyError(true);
            isValid = false;
        }

        if (!isValid) {
            // Prevent form submission if validation fails
            return;
        }

        // Prepare the wallet input object for mutation
        const walletDetails = {
            coinCode: coinCode,
            coinName: coinName,
            coinPairWith: coinPair,
            isLiveTrade: status === 'is live',
            totalSupply: parseInt(coinSupply.replace(/,/g, ''), 10),
            coinPrice: parseFloat(coinPrice)
          };


        try {
            const result = await createWallet(walletDetails); // Make sure to pass an object with 'input' key
            if (result.success) {
              notifySuccess('Coin added successfully!');
            //   console.log('Coin added:', result.wallet);
            } else {
              notifyError('Failed to add coin. ' + result.message);
            }
          } catch (error) {
            notifyError('An error occurred while adding the coin: ' + error.message);
          }

        handleClear();
    };





    return (
        <>
            <div className="row">

                <div className="col-lg-12">
                    <div className="card">
                        {/* <div className="card-header">
                            <h4 className="card-title">Add New Coin</h4>
                        </div> */}
                        <div className="card-body">
                            <div className="form-validation">
                                <form
                                    className="form-valide"
                                    action="#"
                                    method="post"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3 row">
                                                <label
                                                    className="col-lg-4 col-form-label"
                                                    htmlFor="val-username"
                                                >
                                                    Coin Name
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-lg-6">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${coinNameError ? "is-invalid" : ""}`}
                                                        placeholder="Enter Your Coin Name"
                                                        onChange={handleCoinNameChange}
                                                        value={coinName}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 row">
                                                <label
                                                    className="col-lg-4 col-form-label"
                                                // htmlFor="val-email"
                                                >
                                                    Coin Code<span className="text-danger">*</span>
                                                    <br/>
                                                   <span style={{color: '#828690', fontSize: '13px'}}>Three Latters Only</span> 
                                                </label>
                                                <div className="col-lg-6">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${coinCodeError ? "is-invalid" : ""}`}
                                                        placeholder="Enter Your Coin Code"
                                                        value={coinCode}
                                                        onChange={handleCoinCodeChange}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 row">
                                                <label
                                                    className="col-lg-4 col-form-label"
                                                // htmlFor="val-email"
                                                >
                                                    Coin Pair<span className="text-danger">*</span>
                                                </label>
                                                <div className="col-lg-6">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${coinPairError ? "is-invalid" : ""}`}
                                                        placeholder="Enter Coin Pair"
                                                        value={coinPair}
                                                        onChange={handleCoinPairChange}
                                                        required
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3 row">
                                                <label
                                                    className="col-lg-4 col-form-label"
                                                    htmlFor="val-skill"
                                                >
                                                    No. of Decimal<span className="text-danger">*</span>

                                                </label>
                                                <div className="col-lg-6">
                                                    <input
                                                        type="number"
                                                        className={`form-control ${decimalError ? "is-invalid" : ""}`}
                                                        placeholder="0-18"
                                                        value={decimal}
                                                        onChange={handleDecimalChange}
                                                        min="0"
                                                        max="18"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 row">
                                                <label
                                                    className="col-lg-4 col-form-label"
                                                    htmlFor="val-website"
                                                >
                                                    Coin Price<span className="text-danger">*</span>

                                                </label>
                                                <div className="col-lg-6">
                                                    <input
                                                        type="text"
                                                        ref={coinPriceRef}
                                                        className={`form-control ${coinPriceError ? "is-invalid" : ""}`}
                                                        placeholder="Please Enter Coin Price"
                                                        value={decimal ? formatPriceWithZeros(coinPrice, parseInt(decimal, 10)) : coinPrice}
                                                        onChange={handleCoinPriceChange}
                                                        onFocus={handleCoinPriceFocus}
                                                        onBlur={() => setCoinPrice(formatPriceWithZeros(coinPrice, parseInt(decimal, 10)))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 row">
                                                <label
                                                    className="col-lg-4 col-form-label"
                                                    htmlFor="val-phoneus"
                                                >
                                                    Coin Supply<span className="text-danger">*</span>

                                                </label>
                                                <div className="col-lg-6">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${coinSupplyError ? "is-invalid" : ""}`}
                                                        placeholder="Please Enter Coin Supply"
                                                        value={coinSupply}
                                                        onInput={handleOnCoinSupply}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-group mb-3 row">
                                        <div className="col d-flex align-items-center">
                                            <div className="form-check custom-checkbox checkbox-success me-4"> {/* Adjusted margin here */}
                                                <input
                                                    type="checkbox"
                                                    defaultChecked
                                                    className="form-check-input"
                                                    id="customCheckBox3"
                                                    required
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox3"
                                                >
                                                    Is this Coin Tradeable
                                                </label>
                                            </div>
                                            <Button
                                                variant={status === 'is live' ? "success" : "danger"}
                                                onClick={toggleStatus}
                                                className="me-3"
                                            >
                                                <span className="btn-icon-start" style={{ color: status === 'is live' ? 'green' : 'red' }}>
                                                    <i className="fas fa-exchange-alt" />
                                                </span>
                                                {status === 'is live' ? 'Go Live' : 'Off'}
                                            </Button>
                                            <span className="text-white" style={{ fontSize: "14px" }}>Make this coin live</span>
                                        </div>
                                    </div>
                                    <div className='form-group mb-3 row'>
                                        <div className="d-flex justify-content-start">
                                            <button type="submit" className="btn btn-primary me-2" onClick={handleSubmit}>
                                                Submit
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleClear}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEditNewCoin