import React, { useState, useEffect } from "react";
import { Col, Card, Table, Badge } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { fetchTradeOrders, fetchUserProfile } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { useSubscription, gql } from '@apollo/client';
import { formatDateTime1 } from "../UserManagement/apis";

const TRADE_ORDER_UPDATED_SUBSCRIPTION = gql`
  subscription {
    tradeOrderUpdated {
        buyOrders {
            type
            price
            quantity
            createdAt
            coinPairWith
            PK
            SK
            orderId
            placeOrderId
            buyerUserId
            sellerUserId
            tradingType
            orderType
            beforeDeleteType
            quantityPer
            totalQuantity
            orderExecutedType
            orderExcutedType
            totalBuyerAmount
            totalSellerAmount
        }
        sellOrders {
            type
            price
            quantity
            createdAt
            coinPairWith
            PK
            SK
            orderId
            placeOrderId
            buyerUserId
            sellerUserId
            tradingType
            orderType
            beforeDeleteType
            quantityPer
            totalQuantity
            orderExecutedType
            orderExcutedType
            totalBuyerAmount
            totalSellerAmount
        }
    }
  }
`;

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
];

const OpenOrder = () => {
    const [perPage, setPerPage] = useState(25);
    const [orders, setOrders] = useState([]);
    const [userProfiles, setUserProfiles] = useState({});
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);
    const navigate = useNavigate();
    const { data: newOrderData } = useSubscription(TRADE_ORDER_UPDATED_SUBSCRIPTION);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const res = await fetchTradeOrders('CCD', 'INR');
                if (res.tradeOrders) {
                    const orders = [...res.tradeOrders.buyOrders, ...res.tradeOrders.sellOrders];
                    const completedOrders = await fetchUserProfiles(orders);
                    setOrders(completedOrders);
                    setHasData(completedOrders.length > 0);
                    setLoading(false);
                } else {
                    setHasData(false);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
                setHasError(true);
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    useEffect(() => {
        if (newOrderData?.tradeOrderUpdated) {
            const updatedOrders = [...newOrderData.tradeOrderUpdated.buyOrders, ...newOrderData.tradeOrderUpdated.sellOrders];
            fetchUserProfiles(updatedOrders).then(completedOrders => {
                setOrders(prevOrders => mergeOrders(prevOrders, completedOrders));
            });
        }
    }, [newOrderData]);

    const fetchUserProfiles = async (orders) => {
        const profileRequests = orders.map(async (order) => {
            const buyerProfile = order.PK ? await fetchUserProfile({ PK: order.PK }) : null;
            const sellerProfile = order.PK ? await fetchUserProfile({ PK: order.PK }) : null;


            return {
                ...order,
                buyerProfile: buyerProfile?.success ? JSON.parse(buyerProfile.profile) : null,
                sellerProfile: sellerProfile?.success ? JSON.parse(sellerProfile.profile) : null
            };
        });

        const completedOrders = await Promise.all(profileRequests);

        console.log('this is completedorder', completedOrders)

        completedOrders.forEach(order => {
            if (order.buyerProfile) {
                setUserProfiles(prev => ({
                    ...prev,
                    [order.buyerUserId]: order.buyerProfile
                }));
            }
            if (order.sellerProfile) {
                setUserProfiles(prev => ({
                    ...prev,
                    [order.sellerUserId]: order.sellerProfile
                }));
            }
        });

        return completedOrders;
    };

    const mergeOrders = (currentOrders, newOrders) => {
        const orderMap = new Map();
        currentOrders.forEach(order => orderMap.set(order.orderId, order));
        newOrders.forEach(order => orderMap.set(order.orderId, order));
        return Array.from(orderMap.values());
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleRowClick = (pk, sk) => {
        localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
        navigate("/user-data");
    };

    // Correctly sort orders by creation time using the numeric timestamp
    const sortedOrders = orders.sort((a, b) => b.createdAt - a.createdAt);

    return (
        <div className="row">
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Open Orders</Card.Title>
                        <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive className="header-border">
                            <thead>
                                <tr className="text-center">
                                    <th>Name</th>
                                    <th>Mobile/Phone</th>
                                    <th>Pair</th>
                                    <th>Order ID</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Date/Time</th>
                                    <th>Order Type</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="10" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : hasError ? (
                                    <tr>
                                        <td colSpan="10" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : !hasData || sortedOrders.length === 0 ? (
                                    <tr>
                                        <td colSpan="10" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                            No Data Available
                                        </td>
                                    </tr>
                                ) : (
                                    sortedOrders.map((order, index) => (
                                        <tr key={index} className="text-center">
                                            <td>{order.type === 'BUY' ? (order.buyerProfile.full_name || 'Guest') : (order.sellerProfile.full_name || 'Guest')}</td>
                                            <td onClick={() => handleRowClick(order.PK, order.type === 'BUY' ? order.buyerProfile.SK : order.sellerProfile.SK)}>
                                                <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }}>
                                                    {userProfiles[order.buyerUserId]?.phone || 'N/A'}
                                                    {order.type === 'BUY'
                                                        ? (order.buyerProfile.email && <div>{order.buyerProfile.email}</div>)
                                                        : (order.sellerProfile.email && <div>{order.sellerProfile.email}</div>)
                                                    }
                                                </div>
                                            </td>
                                            <td>{order.coinPairWith}</td>
                                            <td>{order.orderId}</td>
                                            <td className="text-success">{(order.totalQuantity) - (order.quantity)}/{order.totalQuantity}</td>
                                            <td className="text-danger">{order.price}</td>
                                            <td>{formatDateTime1(order)}</td>
                                            <td className={`text-${order.orderType === 'OPEN_ORDER' ? 'success' : 'warning'}`}>{order.orderType}</td>
                                            <td>{order.tradingType}</td>
                                            <td>
                                                <Badge bg={order.type === 'BUY' ? 'success' : 'danger'}>
                                                    {order.type}
                                                </Badge>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </div>
    );
};

export default OpenOrder;
