import React, { useState } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Maintenance = () => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [lottieLink, setLottieLink] = useState(''); // This stores the original link
    const [modifiedLottieLink, setModifiedLottieLink] = useState(''); // This stores the modified link
    const [startDate, setStartDate] = useState(null);
    const [linkError, setLinkError] = useState('');

    const lottieLinkRegex = /^https:\/\/link\.storjshare\.io\/s\/.+/;

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleLottieLinkChange = (e) => {
        const link = e.target.value;
        setLottieLink(link); // Store the original link
        // Validate the input against the regex
        if (lottieLinkRegex.test(link)) {
            // If the link is valid, replace '/s/' with '/raw/'
            const updatedLink = link.replace('/s/', '/raw/');
            setModifiedLottieLink(updatedLink); // Update the modified link
            setLinkError('');
        } else {
            setModifiedLottieLink(''); // Clear modified link if invalid
            setLinkError('Invalid Lottie link format.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate using the original link
        if (!lottieLinkRegex.test(lottieLink)) {
            setLinkError('Please provide a valid Lottie link.');
            return;
        }
        // Log the modified link (with '/raw/')
        console.log('Title:', title);
        console.log('Message:', message);
        console.log('Lottie Link:', modifiedLottieLink);
        console.log('Start Date:', startDate);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Maintenance</Card.Title>
                </Card.Header>

                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Your Message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Lottie Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Link Here"
                                        value={lottieLink} // Show the original link in the input field
                                        onChange={handleLottieLinkChange}
                                        isInvalid={!!linkError}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {linkError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Expected Time</Form.Label>
                                    <div className="d-flex align-items-center">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartDateChange}
                                            className="form-control"
                                            placeholderText="Select Date"
                                            dateFormat="dd/MM/yy"
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="success">Save</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default Maintenance;
