import React, { useState } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { addVersion } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import swal from "sweetalert";

const Version = () => {
    const [androidVersion, setAndroidVersion] = useState('');
    const [iosVersion, setIosVersion] = useState('');
    const { notifySuccess, notifyWarning, notifyError } = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!androidVersion.trim() && !iosVersion.trim()) {
            notifyWarning("Please enter at least one version.");
            return;
        }
        swal({
            title: "Are you sure?",
            text: "Do you want to change the version?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willSubmit) => {
            if (willSubmit) {
                const variables = {};
                if (androidVersion.trim()) {
                    variables.AndroidVersion = androidVersion;
                }
                if (iosVersion.trim()) {
                    variables.IOSVersion = iosVersion;
                }

                try {
                    const response = await addVersion(variables.AndroidVersion, variables.IOSVersion);
                    if (response.appSetting) {
                        notifySuccess("Version added successfully.");
                        setAndroidVersion('');
                        setIosVersion('');
                    } else {
                        notifyError("Failed to add version. Please try again.");
                    }
                } catch (error) {
                    console.error('Error:', error);
                    notifyError("An error occurred while adding version");
                }
            } else {
                notifyWarning("Version change canceled.");
            }
        });
    }

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>App Version</Card.Title>
                    <div className="form-group">
                        <div className="col-auto">
                            <Link to="/version-list">
                                <button type="button" className="btn btn-success">Version List</button>
                            </Link>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Android Version</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Android Version"
                                        value={androidVersion}
                                        onChange={e => setAndroidVersion(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>IOS Version</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter IOS Version"
                                        value={iosVersion}
                                        onChange={e => setIosVersion(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="success">Save</Button>
                                <Link to='/version-list'>
                                    <Button className="ms-2" variant="danger">Go Back</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default Version;
