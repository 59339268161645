import React, { useEffect, useState, useRef } from 'react';
import { Tab, Nav, Badge} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Domestic from './TXN/Domestic';
import International from './TXN/International';
import UserNav from "./UserNav";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
]

const tabDataBlog = [
    { Date: "01/01/2024 09:18AM", Naration: "INR Deposit", Debit: "120", Credit: "80", Balance: "40" },
    { Date: "15/05/2021 09:36AM", Naration: "INR Withdrawal", Debit: "150", Credit: "50", Balance: "100" },
];

const TXNhistory = () => {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const sort = 6;
    const activePag = useRef(0);
    const [test, setTest] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        setData(document.querySelectorAll("#future_wrapper tbody tr"));
    }, [test]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };

    const filteredData = tabDataBlog.filter(item => {
        // Convert all fields to string and lower case for case insensitive comparison
        const itemData = `${item.Date.toLowerCase()} ${item.Naration.toLowerCase()} ${item.Debit.toString()} ${item.Credit.toString()} ${item.Balance.toString()}`;
        return itemData.includes(filter);
    });

    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove("d-none");
            } else {
                data[i].classList.add("d-none");
            }
        }
    };


    // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
    // paggination
    let paggination = Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1);

    // Active paggination & chage data
    const onClick = (i) => {
        activePag.current = i;
        chageData(activePag.current * sort, (activePag.current + 1) * sort);
        setTest(i);
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };


    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <UserNav />
                </div>
            </div>
            <div className="row">

                <div className="col-xl-12">
                    <div className="card">
                        <Tab.Container defaultActiveKey="All">
                            <div className="card-header border-0 pb-2 flex-wrap">
                                <h4 className="heading">TXN History</h4>
                                <>
                                    <Nav className="order nav nav-tabs">
                                        <Nav.Link as="button" eventKey="All" type="button">INR</Nav.Link>
                                        <Nav.Link as="button" eventKey="Order" type="button">Domestic</Nav.Link>
                                        <Nav.Link as="button" eventKey="Trade" type="button">International</Nav.Link>
                                    </Nav>
                                </>

                            </div>
                            <div className="card-body pt-0 pb-0">

                                <Tab.Content >
                                    <Tab.Pane eventKey="All">
                                        <div className="table-responsive dataTabletrade ">
                                            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-3 mt-3" style={{ maxWidth: '100%' }}>
                                                {/* Filter Input on the Left */}
                                                <div style={{ width: '200px', flex: '0 0 auto' }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Filter..."
                                                        value={filter}
                                                        onChange={handleFilterChange}
                                                    />
                                                </div>

                                                {/* Date Pickers and Download Button on the Right */}
                                                <div className="d-flex justify-content-between justify-content-lg-end align-items-center mt-3 mt-lg-0">
                                                    <div className="d-flex align-items-center">
                                                        <div className="mr-2">
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={handleStartDateChange}
                                                                className="form-control"
                                                                placeholderText="Start Date"
                                                            />
                                                        </div>
                                                        <div className="ms-2">
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={handleEndDateChange}
                                                                className="form-control"
                                                                placeholderText="End Date"
                                                            />
                                                        </div>
                                                        <button className="btn btn-primary ms-2 me-3" onClick={handleSearch}>Search</button>
                                                    </div>
                                                    <Link to={"#"} className="btn btn-primary light btn-rounded me-3 mb-3 mb-lg-0"><i className="las la-download scale5 me-2"></i>Download</Link>
                                                </div>
                                            </div>


                                            <div id="future_wrapper" className="dataTables_wrapper no-footer">
                                                <table id="example" className="table display dataTable no-footer" style={{ minWidth: "845px" }}>
                                                    <thead>

                                                        <tr>
                                                            <th>Date/Time</th>
                                                            <th>Narration</th>
                                                            <th>Debit</th>
                                                            <th>Credit</th>
                                                            <th className="text-end">Balance</th>

                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {filteredData.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.Date}</td>
                                                                <td>
                                                                    <Badge bg={item.Naration === "INR Deposit" ? "success" : "danger"}>
                                                                        {item.Naration}
                                                                    </Badge>

                                                                </td>
                                                                <td>{item.Debit}</td>
                                                                <td>{item.Credit}</td>
                                                                <td className="text-end">{item.Balance}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                                    {/* <div className="dataTables_info">
                                                        Showing {activePag.current * sort + 1} to{" "}
                                                        {data.length > (activePag.current + 1) * sort
                                                            ? (activePag.current + 1) * sort
                                                            : data.length}{" "}
                                                        of {data.length} entries
                                                    </div> */}
                                                    <div className="form-group  col-md-1" >
                                                        <Select
                                                            options={optionValue}
                                                            value={{ value: perPage, label: perPage.toString() }}
                                                            onChange={handlePerPageChange}
                                                            className="custom-react-select"
                                                        />
                                                    </div>
                                                    <div
                                                        className="dataTables_paginate paging_simple_numbers mb-0"
                                                        id="application-tbl1_paginate"
                                                    >
                                                        <Link
                                                            className="paginate_button previous "
                                                            to="/tXN-history"
                                                            onClick={() =>
                                                                activePag.current > 0 &&
                                                                onClick(activePag.current - 1)
                                                            }
                                                        >
                                                            <i className="fa fa-angle-double-left" ></i>
                                                        </Link>
                                                        <span>
                                                            {paggination.map((number, i) => (
                                                                <Link
                                                                    key={i}
                                                                    to="/tXN-history"
                                                                    className={`paginate_button  ${activePag.current === i ? "current" : ""
                                                                        } `}
                                                                    onClick={() => onClick(i)}
                                                                >
                                                                    {number}
                                                                </Link>
                                                            ))}
                                                        </span>

                                                        <Link
                                                            className="paginate_button next"
                                                            to="/tXN-history"
                                                            onClick={() =>
                                                                activePag.current + 1 < paggination.length &&
                                                                onClick(activePag.current + 1)
                                                            }
                                                        >
                                                            <i className="fa fa-angle-double-right" ></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Order">
                                        <Domestic />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Trade">
                                        <International />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TXNhistory;