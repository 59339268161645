// Loader.js
import React from 'react';
import {
    PulseLoader
} from 'react-spinners';

// Accepting a `color` prop
const Loader1 = ({ loading, color = '#fffc6a' }) => (
    <div >
        <PulseLoader
 loading={loading} size={2} color={color} />
    </div>
);

export default Loader1;
