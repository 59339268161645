import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import useToast from "../Toaster/Toaster";
import { fetchAllTabs } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/noData.json";

const ListTabsSubTabs = () => {
  const { notifySuccess, notifyError } = useToast();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const tabsData = await fetchAllTabs();
      console.log('here is list of tabdata',tabsData)
      if (tabsData && tabsData.length > 0) {
        const parsedData = tabsData.map((tab, index) => {
          let tabInfo = { mainTab: '', subTabs: [] };
          try {
            // Directly parse the Tabs string as JSON
            tabInfo = JSON.parse(tab.Tabs);
          } catch (error) {
            console.error(`Error parsing JSON for tab ${tab.PK}:`, tab.Tabs, error);
          }
          return {
            ...tab,
            sno: index + 1,
            tabName: tabInfo.tab || 'Unknown Tab',
            subTabs: tabInfo.subtab || [],
          };
        });
        setData(parsedData);
      } else {
        setData([]);
      }
    } catch (error) {
      setHasError(true);
      notifyError("Failed to fetch tabs");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(()=>{
    fetchData()
  },[])
  

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item.tabName.toLowerCase().includes(filter.toLowerCase())
  );

    // SVG Icon
    const svg1 = (
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M3,17.25 L3,21 L6.75,21 L17.81,9.94 L14.06,6.19 L3,17.25 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.04,5.21 L18.79,8.96 L20.71,7.04 Z"
            fill="#000000"
          />
        </g>
      </svg>
    );
  

  return (
    <Col lg={12}>
      <Card>
        <Card.Header>
          <Card.Title>List of Tabs/Subtabs</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="mb-3" style={{ width: "200px" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Filter..."
              value={filter}
              onChange={handleFilterChange}
            />
          </div>

          <Table responsive>
            <thead>
              <tr>
                <th><strong>S.No</strong></th>
                <th><strong>Tab Name</strong></th>
                <th style={{ width: "300px" }}><strong>Sub Tab Name</strong></th>
                <th className="text-center"><strong>Edit</strong></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="4" className="text-center"><Loader /></td>
                </tr>
              ) : hasError ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                    Connect To SuperAdmin
                  </td>
                </tr>
              ) : data.length === 0 || filteredData.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center">
                    <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                    No Data
                  </td>
                </tr>
              ) : (
                filteredData.map((item, index) => (
                  <React.Fragment key={index}>
                  <tr>
                    <td rowSpan={item.subTabs.length}>{item.sno}</td>
                    <td rowSpan={item.subTabs.length}>{item.tabName}</td>
                    <td>{item.subTabs[0]}</td>
                    <td className="text-center" rowSpan={item.subTabs.length}>
                      <Dropdown>
                        {/* <Link to="/addTab-subtabs"> */}
                          <Dropdown.Toggle
                            variant={
                              item.status === "Active" ? "success" : "danger"
                            }
                            className="light sharp i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                        {/* </Link> */}

                      </Dropdown>
                    </td>
                  </tr>
                  {item.subTabs.slice(1).map((tab, tabIndex) => (
                    <tr key={tabIndex}>
                      <td>{tab}</td>
                    </tr>
                  ))}
                </React.Fragment>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ListTabsSubTabs;
