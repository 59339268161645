import React, { useState } from "react";
import { Col, Card, Row, Button, Form } from "react-bootstrap";
import { AiOutlineCloudUpload } from "react-icons/ai"; // Importing the icon from react-icons
import useToast from "../Toaster/Toaster";
import { Link } from "react-router-dom";

const DepositeFile = () => {
    const { notifyError, notifySuccess, notifyWarning, notifyInfo } = useToast();
    const [selectedFile, setSelectedFile] = useState(null);
    const [dragOver, setDragOver] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [uploading, setUploading] = useState(false);
    const SERVER_LINK = process.env.REACT_APP_SERVER_LINK

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setErrorMessage('');
    };

    // Handle file drag-and-drop
    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);
        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
        setErrorMessage('');
    };

    // Function to handle file upload
    const handleUpload = async () => {
        if (selectedFile) {
            setUploading(true);
            const formData = new FormData();
            formData.append("Import_Deposite", selectedFile);

            try {
                const response = await fetch(`${SERVER_LINK}/api/v1/file/ImportDepostireByXlsxFile`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    setErrorMessage('File uploaded successfully');
                } else {
                    setErrorMessage('Error uploading file');
                }
            } catch (error) {
                console.error(error);
                setErrorMessage('Error uploading file');
            } finally {
                setUploading(false);
            }
        } else {
            setErrorMessage("Please select a file to upload.");
        }
    };
    return (
        <Row className="justify-content-center">
            <Col md={6}>
                <Card>
                    <Card.Body
                        className={`p-4 text-center  ${dragOver ? "border-primary" : "border-secondary"}`}
                        style={{
                            border: "2px dashed",
                            borderRadius: "10px",
                            backgroundColor: dragOver ? "#f0f8ff" : "#f9f9f9",
                        }}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <AiOutlineCloudUpload size={50} className="text-muted mb-3" />
                        <p style={{ color: "black", fontSize: '1rem' }}>Choose a file or drag & drop it here</p>
                        <p className="text-muted">
                            PDF format only, up to 50MB
                        </p>
                        <Button variant="outline-primary" className="mt-3">
                            <label
                                htmlFor="fileInput"
                                style={{ cursor: "pointer", margin: 0 }}
                            >
                                Browse File
                            </label>
                        </Button>
                        <Form.Control
                            type="file"
                            id="fileInput"
                            // accept="application/pdf"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                        />
                        {selectedFile && <p className="mt-2" style={{ color: "black", fontSize: '1rem' }}>Selected file: {selectedFile.name}</p>}
                        {errorMessage && (
                            <p className="text-danger mt-2">{errorMessage}</p>
                        )}
                    </Card.Body>
                    <Card.Footer >
                        <Col>
                            <Link to='/deposite-history'>
                                <Button variant="danger">Go Back</Button>
                            </Link>
                            <Button variant="success" className="ms-2" onClick={handleUpload} disabled={uploading}>
                                {uploading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </Col>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    );
};

export default DepositeFile;
