import React from 'react';
import Lottie from 'react-lottie';

const LottieAnimation = ({lottieJson, width, height}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: lottieJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return <Lottie options={defaultOptions} height={height} width={width} /> ;
};

export default LottieAnimation;
