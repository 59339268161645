import React from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button
} from "react-bootstrap";

import { Link } from 'react-router-dom';

const OrdersTab = () => {
    // Sample data
    const data = [
        {
            buyerID: 1,
            sellerOrderId: 654,
            volume: "1.5 CCD",
            buyerPrice: "234",
            sellerPrice: "6768"

        },
    ];




    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Orders Tab</Card.Title>
                    </Card.Header>
                    <Card.Body>


                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Buyer Id</strong>
                                    </th>
                                    <th>
                                        <strong>Seller Order Id</strong>
                                    </th>
                                    <th>
                                        <strong>Volume</strong>
                                    </th>
                                    <th className="text-center text-success">
                                        <strong>Buyer Price</strong>
                                    </th>
                                    <th className="text-center text-danger">
                                        <strong>Seller Price</strong>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {data.map(item => (
                                    <tr key={item.buyerID}>
                                        <td>
                                            <strong>{item.buyerID}</strong>
                                        </td>
                                        <td>{item.sellerOrderId} </td>
                                        <td>{item.volume}</td>
                                        <td className="text-center text-success">{item.buyerPrice}</td>
                                        <td className="text-center text-danger">{item.sellerPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={12}>
                <Card>
                    <Card.Body>
                        <Row>
                            {/* Left column for Token Pair data */}
                            <Col md={6}>
                                <h4 className="text-white mb-3">Token Pair</h4>
                                <Col>
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>User ID:</strong></Col>
                                        <Col xs={6} className="text-muted">12345</Col>
                                    </Row>
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>Name:</strong></Col>
                                        <Col xs={6} className="text-muted">John Doe</Col>
                                    </Row>
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>Order Placed On:</strong></Col>
                                        <Col xs={6} className="text-muted">01-01-2024 09:18AM</Col>
                                    </Row>
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>Order Executed On:</strong></Col>
                                        <Col xs={6} className="text-muted">01-01-2024 09:18AM</Col>
                                    </Row>
                                </Col>

                            </Col>

                            {/* Right column for BUY data */}
                            <Col md={6} >
                                <h4 className=" mb-3 "><span className="text-success">BUY</span></h4>
                                <Col >
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>Order Type:</strong></Col>
                                        <Col xs={6} className="text-info">Market</Col>
                                    </Row>
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>Status:</strong></Col>
                                        <Col xs={6} > <span className={`label label-warning`}>Pending</span></Col>
                                    </Row>
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>Price/Average:</strong></Col>
                                        <Col xs={6} className="text-primary">₹<span className="me-1"></span>300</Col>
                                    </Row>
                                    <Row className="text-white mb-2">
                                        <Col xs={6}><strong>Volume:</strong></Col>
                                        <Col xs={6} className="text-secondary">1.5 CCD</Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                        <Table hover className="mt-4">
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Date/Time</strong>
                                    </th>
                                    <th>
                                        <strong>Completed Volume</strong>
                                    </th>

                                    <th>
                                        <strong>Price</strong>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01-01-2024 09:18AM</td>
                                    <td>Data 2</td>
                                    <td >Data 3</td>
                                </tr>

                                {/* Add more rows as needed */}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            <Link to='/orders'>
                                <Button variant="danger">Go Back</Button>
                            </Link>
                            <Button href="/path/to/your/download/file.csv"  className="mx-2" download type="submit" variant="success" >Download Data</Button>

                            {/* <a href="/path/to/your/download/file.csv" download className="btn btn-primary mx-2">
                                Download Data
                            </a> */}
                        </div>
                    </Card.Body>
                </Card>
            </Col>


        </Row>

    );
}

export default OrdersTab;
