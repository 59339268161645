import React, { useState } from 'react';
import { Form, Button, Card, Col, Row } from 'react-bootstrap';
import Select from 'react-select';

const AddAssetTab = ({ onNext }) => {
    // State for form inputs
    const [network, setNetwork] = useState(null);
    const [contractAddress, setContractAddress] = useState('');
    const [tokenStandard, setTokenStandard] = useState(null);
    const [assetShortName, setAssetShortName] = useState('');
    const [tokenName, setTokenName] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [assetQuantity, setAssetQuantity] = useState('');
    const [ticker, setTicker] = useState('');
    // Assuming you're not immediately using the file, so not storing in state
    // If you plan to upload it, you'll need to handle it differently

    // Handler for form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({
            network: network?.value,
            contractAddress,
            tokenStandard: tokenStandard?.value,
            assetShortName,
            tokenName,
            walletAddress,
            assetQuantity,
            ticker,
            // Add file handling here if needed
        });
        onNext();
    };

    const NetworkOption = [
        { value: 'Polygon', label: 'Polygon' },
        { value: 'Ethereum', label: 'Ethereum' },
    ];

    const TokenStandard = [
        { value: 'ERC-20', label: 'ERC-20' }
    ];

    return (
        <Form onSubmit={handleSubmit}>
            <Card.Title>Add an Existing Asset</Card.Title>
            <Row className="mb-3 mt-3">
                <Form.Group as={Col} md={6}>
                    <Form.Label>What network is your token built on?</Form.Label>
                    <Select
                        options={NetworkOption}
                        className="custom-react-select"
                        value={network}
                        onChange={setNetwork}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                    <Form.Label>Token standard - What standard do you want to use?</Form.Label>
                    <Select
                        options={TokenStandard}
                        className="custom-react-select"
                        value={tokenStandard}
                        onChange={setTokenStandard}
                    />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md={12}>
                    <Form.Label>Contract Address</Form.Label>
                    <Card.Text className="m-0 subtitle text-muted mb-1">
                        This will help us run a manual security review.
                    </Card.Text>
                    <Form.Control
                        type="text"
                        placeholder="Enter contract address"
                        value={contractAddress}
                        onChange={(e) => setContractAddress(e.target.value)}
                    />
                </Form.Group>
            </Row>

            {/* Additional fields */}
            <Row className="mb-3">
                <Form.Group as={Col} md={6}>
                    <Form.Label>Asset short name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Asset short name"
                        value={assetShortName}
                        onChange={(e) => setAssetShortName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                    <Form.Label>What is the name of the token?</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Token Name"
                        value={tokenName}
                        onChange={(e) => setTokenName(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md={6}>
                    <Form.Label>Wallet address</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter wallet address"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                    <Form.Label>Asset Quantity</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Asset Quantity"
                        value={assetQuantity}
                        onChange={(e) => setAssetQuantity(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} md={6}>
                    <Form.Label>What ticker is associated with your asset? Must be between 2-5 letters.</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Your Ticker Here..."
                        value={ticker}
                        onChange={(e) => setTicker(e.target.value)}
                    />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                    <Form.Label >
                        Asset icon (recommended)
                    </Form.Label>
                    <Card.Text className="m-0 subtitle text-muted mb-1">
                        Upload an icon File should be a PNG no longer than 2MB, with dimensions of at least 512px x 512px.
                    </Card.Text>
                    <Form.Control
                        type="file"
                        placeholder="Enter Your Ticker Here..."
                    // className="form-control form-control-lg"
                    />
                </Form.Group>
            </Row>

            <Button variant="success" type="submit">
                Continue
            </Button>
        </Form>
    );
};

export default AddAssetTab;
