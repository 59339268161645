import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Dropdown } from 'react-bootstrap';
import { getReferralList } from '../../../apolloClientConnection/apolloClient';

const ReferralList = () => {
    const [referralPrograms, setReferralPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFFFFF" d="M3,21.5 L3.5,17.5 L14.5,6.5 C14.8978,6.10218 15.4374,5.87868 16,5.87868 C16.2786,5.87868 16.5541,5.93355 16.8111,6.04016 C17.0681,6.14678 17.303,6.30356 17.5,6.5 C17.697,6.69644 17.8538,6.93131 17.9604,7.18832 C18.067,7.44534 18.1219,7.72077 18.1219,7.99935 C18.1219,8.56196 17.8984,9.10155 17.5006,9.49935 L6.5,20.5 L2.5,21 L3,21.5 Z M5.06066,19 L14.0607,10 L17,12.9393 L8,21.9393 L5.06066,19 Z M16.5,9.5 L19.5,6.5 L17,4 L14,7 L16.5,9.5 Z" />
        </svg>
    );

    const svg2 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    useEffect(() => {
        const fetchReferralData = async () => {
            try {
                const data = await getReferralList();
                setReferralPrograms(data);
                setLoading(false);
            } catch (error) {
                setError('Failed to Fetch Referral List');
                setLoading(false);
            }
        };

        fetchReferralData();
    }, []);

   

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Referral Program List</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Reward Amount</th>
                                    <th>Reward Type</th>
                                    <th>Status</th>
                                    <th className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referralPrograms.map((program, index) => (
                                    <tr key={program.PK}>
                                        <td>{index + 1}</td>
                                        <td>{program.name}</td>
                                        <td>{program.RefferalAmount}</td>
                                        <td>{program.RewardType}</td>
                                        <td>
                                            <span className={`badge light badge-${program.IsActive === true ? "success" : "danger"}`}>
                                                {program.IsActive === true ? 'Active' : 'Inactive'}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <Dropdown>
                                                <Dropdown.Toggle variant={'success'} className="light sharp i-false">
                                                    {svg1}
                                                </Dropdown.Toggle>
                                                <Dropdown.Toggle variant={'danger'} className="light sharp i-false ms-3">
                                                    {svg2}
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default ReferralList;
