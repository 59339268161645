import React, { useEffect, useState, useRef } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import useToast from "../Toaster/Toaster";
import { Link, useLocation } from 'react-router-dom';
import { addCategory } from "../../../apolloClientConnection/apolloClient";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import quill styles
import { getImageLink } from "../StorJ/StorJFunction";

const AddBrand = () => {
    const [name, setName] = useState('');
    const [shortcode, setShortCode] = useState('');
    const [description, setDescription] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [imageURL, setImageURL] = useState('');
    const { notifySuccess, notifyWarning } = useToast();
    const [brandID, setBrandID] = useState(null);
    const location = useLocation();
    const [inputKey, setInputKey] = useState(Date.now());
    const quillRef = React.useRef(null);
    const SERVER_LINK = process.env.REACT_APP_SERVER_LINK
    

    useEffect(() => {
        if (location.state && location.state.item) {
            const { PK, SK, name, shortcode, description, image } = location?.state?.item;
            setBrandID({ PK, SK });
            setName(name);
            setShortCode(shortcode);
            setDescription(description);
            setImageURL(image);
        }
    }, [location?.state]);

    useEffect(() => {
        return () => {
            setBrandID(null);
        };
    }, []);

    const handleClear = () => {
        setName('');
        setShortCode('');
        setDescription('');
        setImagePath('');
        setImageURL('');
        setInputKey(Date.now());
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('ImageUpload', file);

            try {
                const response = await fetch(`${SERVER_LINK}/api/v1/file/ImageUpload`, {
                  method: 'POST',
                  body: formData,
                });
                const data = await response.json();
                if (data) {
                  const digitalLotionURL = data?.data
                  try {
                    const imageStream = await getImageLink(digitalLotionURL);
                    if (imageStream) {
                      setImageURL(imageStream);
                      notifySuccess('Image uploaded successfully!');
                    } else {
                      notifyWarning(`Failed to upload image: ${data?.message}`);
                    }
                  } catch (error) {
                    console.error('Failed to fetch image:', error);
                  }
                }
          
              } catch (error) {
                notifyWarning('Error uploading file!');
              }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!name || !shortcode) {
            notifyWarning('Name and Short Code are required!');
            return;
        }

        try {
            const response = await addCategory(
                name,
                shortcode,
                description,
                imageURL,
                brandID?.PK, // PK
                brandID?.SK  // SK
            );
            if (response) {
                notifySuccess(brandID ? 'Brand Updated Successfully!' : 'Brand Added Successfully!');
                handleClear();
            }
        } catch (error) {
            console.error('Error:', error);
            notifyWarning('Failed to perform action!');
        }
    };

    // Define a module to set custom toolbar options
    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }],  // dropdown with defaults from theme
            [{ 'align': [] }],
            ['clean']  // remove formatting button
        ],
    };


    useEffect(() => {
        // Use quillRef.current to interact with the editor instance directly
        const editor = quillRef.current.getEditor(); // Access the Quill editor instance
    }, []);

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Brand Information</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Brand Name"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Short Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Short Code"
                                        value={shortcode}
                                        onChange={e => setShortCode(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label>Term & Condition</Form.Label>
                                    <ReactQuill
                                        ref={quillRef}
                                        theme="snow"
                                        value={description}
                                        onChange={setDescription}  // Correctly handle change
                                        modules={modules}
                                        placeholder="Enter Terms & Conditions"
                                        className="custom-quill"
                                    />

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label>Image Upload</Form.Label>
                                    <Form.Control
                                        type="file"
                                        key={inputKey}
                                        onChange={handleImageUpload}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="success">Save</Button>
                                <Link to='/list-brand'>
                                    <Button className="ms-2" variant="danger">Go Back</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default AddBrand;
