import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import UserNav from "./UserNav";
import "react-datepicker/dist/react-datepicker.css";
import ExecutedOrders from './Orders/ExecutedOrders';
import BuyOrders from './Orders/BuyOrders';
import SellOrders from './Orders/SellOrders';
import CancelOrder from './Orders/CancelOrder';

const UserOrders = () => {
    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <UserNav />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <Tab.Container defaultActiveKey="Buy">
                            <div className="card-header border-0 pb-2 flex-wrap">
                                <h4 className="heading">Order History</h4>
                                <>
                                    <Nav className="order nav nav-tabs">
                                        <Nav.Link
                                            as="button"
                                            eventKey="Buy"
                                            type="button"
                                            className='text-success'
                                        >
                                            BUY
                                        </Nav.Link>
                                        <Nav.Link
                                            as="button"
                                            eventKey="Sell"
                                            type="button"
                                            className='text-danger'
                                        >
                                            SELL
                                        </Nav.Link>
                                        <Nav.Link as="button" eventKey="Executed" type="button" className='text-success'>Completed</Nav.Link>
                                        <Nav.Link as="button" eventKey="Cancelled" type="button" className='text-warning'>CANCEL</Nav.Link>
                                        {/* <Nav.Link as="button" eventKey="PartiallyCompleted" type="button" >Partial Completed</Nav.Link> */}
                                        {/* <Nav.Link as="button" eventKey="PartiallyCancelled" type="button" >Partial Cancelled</Nav.Link> */}
                                        {/* <Nav.Link as="button" eventKey="AllOrderHistory" type="button" >All Order History</Nav.Link> */}
                                    </Nav>
                                </>
                            </div>
                            <div className="card-body pt-0 pb-0">
                                <Tab.Content >
                                    <Tab.Pane eventKey="Buy">
                                        <BuyOrders />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Sell">
                                        <SellOrders />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Executed">
                                        <ExecutedOrders />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Cancelled">
                                        <CancelOrder />
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="PartiallyCompleted">
                                        <PartiallyCompleted />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="PartiallyCancelled">
                                        <PartiallyCancelled />
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserOrders;
