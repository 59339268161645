import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs'; // Import the back icon from react-icons

const UserNav = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Navigation items array for easier mapping
    const navItems = [
        { path: "/user-data", label: "Personal Info" },
        { path: "/kYC-document", label: "KYC Document" },
        { path: "/bank-details", label: "Bank Details" },
        { path: "/deposit-history", label: "Deposit History" },
        { path: "/tXN-history", label: "TXN History" },
        { path: "/holding", label: "Holding" },
        { path: "/user-orders", label: "Order Data" },
        { path: "/withDraw-profile", label: "Withdraw" },
        { path: "/profile-airdrop", label: "Airdrop" },
        { path: "/wallet-address", label: "Wallet Address" },
        // Add other navigation items here
    ];


    return (
        <Navbar bg="secondary" expand="md" className="mb-3 custom-navbar">
            <Container fluid style={{ padding: '5px' }}>
                <div className="d-flex justify-content-between">
                    <Navbar.Brand className="d-md-none">User Data</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'white' }} />
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" activeKey={location.pathname}>
                        <Nav.Link onClick={() => navigate('/all-Users')} className="user-nav">
                            <div className="back-icon-wrapper">
                                <BsArrowLeft className="back-icon" />
                            </div>
                        </Nav.Link>
                        {navItems.map((item, index) => (
                            <Nav.Link
                                className="user-nav"
                                as={Link}
                                to={item.path}
                                key={index}
                                eventKey={item.path}
                            >
                                {item.label}
                            </Nav.Link>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default UserNav;
