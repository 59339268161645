import React, { useState } from 'react';
import { countMarketTradeValue, activeMarketTradeValue } from '../../../apolloClientConnection/apolloClient';
import useToast from '../Toaster/Toaster';
import { calculateTransactionDetails } from './utils';

const MarketOrder = ({ currentTab = 'BUY' }) => {
    const [quantity, setQuantity] = useState('');
    const [total, setTotal] = useState('');
    const { notifySuccess, notifyInfo, notifyError, notifyWarning } = useToast();
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleQuantityChange = async (e) => {
        let value = e.target.value;
        if (value.match(/^\d*\.?\d{0,2}$/)) {
            setQuantity(value);
            if (value.trim() !== '') {
                setLoading(true);
                try {
                    const data = await countMarketTradeValue('COIN', Number(value), currentTab, "CCD", "INR");
                    setTotal(data.countMarketTradeValue.toString());
                    const details = await calculateTransactionDetails(data.countMarketTradeValue, currentTab);
                    setTransactionDetails(details);
                    setError('')
                } catch (error) {
                    console.error('Error retrieving market trade value:', error);
                    setError('Failed to calculate transaction details.');
                }
                setLoading(false);
            } else {
                setTotal('');
                setTransactionDetails(null);
            }
        }
    };

    const handleTotalChange = async (e) => {
        let value = e.target.value;

        if (value.match(/^\d*\.?\d{0,2}$/)) {
            setTotal(value);
            if (value.trim() !== '') {
                setLoading(true);
                try {
                    const data = await countMarketTradeValue('INR', Number(value), currentTab, "CCD", "INR");
                    setQuantity(data.countMarketTradeValue.toString());
                    const details = await calculateTransactionDetails(value, currentTab);
                    setTransactionDetails(details);
                    setError('')
                } catch (error) {
                    console.error('Error retrieving market trade value:', error);
                    setError('Failed to calculate transaction details.');
                }
                setLoading(false);
            } else {
                setQuantity('');
                setTransactionDetails(null);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (quantity === '' && total === '') {
            notifyWarning('Please enter either quantity or total before placing an order.');
            return;
        }

            if (Number(total) < 50) {
                notifyWarning('The total must be at least 50 to proceed.');
                return;
            }


        setLoading(true);
        try {
            const amount = quantity !== '' ? Number(quantity) : Number(total);
            const changes = quantity !== '' ? 'COIN' : 'INR';

            const data = await activeMarketTradeValue(changes, amount, currentTab, "CCD", "INR");
            if (data) {
                notifySuccess('Order Placed!');
                setTotal('');
                setQuantity('');
                setTransactionDetails(null);
            } else {
                notifyInfo('Something went wrong!');
            }
        } catch (error) {
            console.error('Error activating market trade:', error);
            notifyError(error.message);
        }
        setLoading(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="sell-blance">
                    <label className="form-label text-primary">Quantity</label>
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Quantity" value={quantity} onChange={handleQuantityChange} />
                        <span className="input-group-text">COIN</span>
                    </div>
                </div>
                <div className="sell-blance">
                    <label className="form-label text-primary">Total</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Total"
                            value={total}
                            onChange={handleTotalChange}
                        />
                        <span className="input-group-text">INR</span>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                    {loading ? <p>Loading...</p> : transactionDetails && (
                        <div className="d-flex justify-content-center align-items-center">
                            <p className="text-bold me-2">
                                Fee: {transactionDetails?.brokerageRate?.toFixed(2)}% <span className="text-muted">|</span>
                            </p>
                            <p className="text-bold me-2">
                                GST: {transactionDetails?.gstRate?.toFixed(2)}% <span className="text-muted">|</span>
                            </p>
                            {currentTab === 'SELL' && (
                                <p className="text-bold me-2">
                                    TDS: {transactionDetails?.tdsDeduction?.toFixed(2)}% <span className="text-muted">|</span>
                                </p>
                            )}
                            <p className="text-bold">
                                Net Amount: {transactionDetails?.netAmount} INR
                            </p>
                        </div>
                    )}
                    {error && <p className="error text-bold text-danger">{error}</p>}
                </div>

                <div className="text-center">
                    <button type="submit" className={`btn btn-${currentTab === 'BUY'? 'success': 'danger'} w-75 fw-bold`} style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        {currentTab}
                    </button>
                </div>
            </form>
        </>
    );
}

export default MarketOrder;
