import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";

const AddTax = ({ isEditing = false, taxData = {}, onSave }) => {
    const [taxCalculation, setTaxCalculation] = useState('');
    const [percentage, setPercentage] = useState('');
    const [taxCalculationError, setTaxCalculationError] = useState(false);
    const [percentageError, setPercentageError] = useState(false);


    const handlePercentageChange = (e) => {
        const value = e.target.value.replace('%', '');
        setPercentage(value);
    };


    // Effect hook to prefill data when editing
    useEffect(() => {
        if (isEditing) {
            setTaxCalculation(taxData.taxCalculation || '');
            setPercentage(taxData.percentage || '');
        }
    }, [isEditing, taxData]);

    const handleClear = () => {
        setTaxCalculation('');
        setPercentage('');
        setTaxCalculationError(false);
        setPercentageError(false);
    };

    const validateInput = () => {
        let isValid = true;
        if (!taxCalculation) {
            setTaxCalculationError(true);
            isValid = false;
        }
        if (!percentage) {
            setPercentageError(true);
            isValid = false;
        }
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Reset validation states
        setTaxCalculationError(false);
        setPercentageError(false);

        if (!validateInput()) {
            // Prevent form submission if validation fails
            return;
        }

        // Prepare data for submission
        const formData = {
            taxCalculation,
            percentage,
        };

        console.log(formData);

        // Call onSave prop function to handle data submission
        onSave(formData);

        // Optionally clear form or provide feedback
        handleClear();
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-8">
                    <div className="card">
                        <div className="card-body">
                            <form
                                className="form-valide"
                                onSubmit={handleSubmit}
                            >
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3 row">
                                            <label
                                                className="col-lg-4 col-form-label"
                                                htmlFor="val-username"
                                            >
                                                Tax Calculation
                                            </label>
                                            <div className="col-lg-8">
                                                <input
                                                    type="text"
                                                    className={`form-control ${taxCalculationError ? "is-invalid" : ""}`}
                                                    placeholder="Enter Tax Calculation"
                                                    value={taxCalculation}
                                                    onChange={(e) => setTaxCalculation(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3 row">
                                            <label
                                                className="col-lg-4 col-form-label"
                                            >
                                                Percentage
                                            </label>
                                            <div className="col-lg-8">
                                                <input
                                                    type="number"
                                                    className={`form-control ${percentageError ? "is-invalid" : ""}`}
                                                    placeholder="Enter Percentage"
                                                    value={`${percentage}%`}
                                                    onChange={handlePercentageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group mb-3 row'>
                                    <div className="d-flex justify-content-start">
                                        <button type="submit" className="btn btn-primary me-2">
                                            {isEditing ? 'Update' : 'Submit'}
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={handleClear}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddTax;
