import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { fetchHeaderText, deleteItem, dataUpdate } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/noData.json";

const ListHeaderText = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { notifySuccess, notifyError, notifyWarning } = useToast();
    const [filter, setFilter] = useState('');

    const handleActionClick = async (PK, SK, isActive) => {
        try {
            // If the item is already active, there's no need to do anything
            if (isActive) return;

            // Optimistically update the state
            setData(prevData => prevData.map(item => ({
                ...item,
                IsdynamicString: item.PK === PK ? true : false  // Only the clicked item is "On"
            })));

            // Prepare the updates
            const updates = data.map(item => {
                if (item.IsdynamicString && item.PK !== PK) {
                    return dataUpdate(item.PK, item.SK, false);  // Set other items to "Off"
                }
                return Promise.resolve();
            });

            // Wait for all updates to complete
            await Promise.all(updates);

            // Finally, update the clicked item to "On"
            const updateResponse = await dataUpdate(PK, SK, true);
            if (updateResponse) {
                notifySuccess('Update Successful!');
            }
        } catch (error) {
            notifyError('Error updating item');
            console.error('Error updating item:', error);
        }
    };

    const handleDeleteHeaderText = async (PK, SK) => {
        const item = data.find(d => d.PK === PK && d.SK === SK);
        if (item && item.IsdynamicString) {
            notifyWarning('Cannot delete item because it is currently active.');
            return;
        }

        try {
            const response = await deleteItem('NewUser',PK, SK);
            if (response) {
                notifySuccess('Banner Deleted Successfully!');
                setData(prevData => prevData.filter(data => data.PK !== PK || data.SK !== SK));
            }
        } catch (error) {
            notifyError('Error deleting banner');
            console.error('Error deleting banner:', error);
        }
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setError(false);
            try {
                const response = await fetchHeaderText('Dynamic_String');
                setData(response.GetDynamicStringList);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(true);
            }
        }
        fetchData();
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data.filter(item =>
        item.StringInput.toLowerCase().includes(filter.toLowerCase()) ||
        (item.IsdynamicString !== null && item.IsdynamicString.toString() === filter)
    );

    const svg2 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Header List Text</Card.Title>
                    <div className="col-auto">
                        <Link to="/home-header">
                            <button type="submit" className="btn btn-success">Add Header Text</button>
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th><strong>S.no</strong></th>
                                <th className="text-center"><strong>Text</strong></th>
                                <th className="text-center"><strong>Description</strong></th>
                                <th className="text-center"><strong>Active</strong></th>
                                <th className="text-center"><strong>Action</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="5" className="text-center"><Loader /></td>
                                </tr>
                            ) : error ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : data.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <LottieAnimation lottie={noDataAnimation} height={400} width={400} />
                                        <p>No data available</p>
                                    </td>
                                </tr>
                            ) : filteredData.map((item, index) => (
                                <tr key={item.PK}>
                                    <td>{index + 1}</td>
                                    <td className="text-center">{item.StringInput}</td>
                                    <td className="text-center">{item.Discription}</td>
                                    <td className="text-center">
                                        <button
                                            className={`btn btn-${item.IsdynamicString ? 'success' : 'secondary'}`}
                                            onClick={() => handleActionClick(item.PK, item.SK, item.IsdynamicString)}
                                        >
                                            {item.IsdynamicString ? 'On' : 'Off'}
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <Dropdown>
                                            <Dropdown.Toggle variant={'danger'} className="light sharp i-false ms-3" onClick={() => handleDeleteHeaderText(item.PK, item.SK)}>
                                                {svg2}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ListHeaderText;
