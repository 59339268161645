// Utility function to format the date/time
export const formatDateTime = (timestamp) => {
    if (!timestamp) return "Time Not Available";
    const date = new Date(timestamp);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };
    return date.toLocaleDateString(undefined, options);
};