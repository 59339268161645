import React, {useState} from "react";



const Footer = () => {
	const [heartActive, setHeartActive] = useState(true);




	var d = new Date();
	return (
		<div className="footer out-footer">
			<div className="copyright">
				<p>
					<a href="https://simplyfy.trade" target="_blank"  rel="noreferrer">
						Simplyfy
					</a>
					<span
                              className={`heart ${
                                heartActive ? "" : "heart-blast"
                              }`}
                              onClick={() => setHeartActive(!heartActive)}
                            ></span>
					{d.getFullYear()}
				</p>
			</div>
		</div>
	);
};

export default Footer;
