// IntroTab.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';

const IntroTab = ({ onNext }) => {
    return (
        <>
            <Card.Title>Submitting your asset for review</Card.Title>
            <Card.Text>
                Thank you for choosing to list your asset on Simplify. We're excited to hear from you.
                We encourage you to review the help articles on this page to prepare for the listing process.
            </Card.Text>
            <Card.Text>
                This process will walk you through the application, from signing our listing agreement to providing general information on your asset along with details for our compliance, legal, and security teams to review.
            </Card.Text>
            <Button variant="success" className="mt-2" onClick={onNext}>
                Get Started
            </Button>
        </>
    );
};

export default IntroTab;
