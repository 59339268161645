import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import LimitOrder from "./LimitOrder";
import { Nav, Tab } from 'react-bootstrap';
import { fetchTradeOrders, getWalletDetails, lastTradePriceData } from '../../../apolloClientConnection/apolloClient';
import MarketOrder from "./MarketOrder";
import { useSubscription, gql } from '@apollo/client';
import Select from 'react-select';
import useToast from "../Toaster/Toaster";


const TRADE_ORDER_UPDATED_SUBSCRIPTION = gql`
  subscription {
    tradeOrderUpdated {
        buyOrders{
            type
            price
            quantity
            }
            sellOrders{
            type
            price
            quantity
            }
            tradeHistory{
            type
            price
            quantity
            createdAt
            }
    }
  }
`;

// Utility function to format date
const formatTime = (isoDateString) => {
    // Convert string to number if it's a timestamp in string format
    const timestamp = Number(isoDateString);
    // Create a new Date object using the timestamp
    const date = new Date(timestamp);
    // Use toLocaleTimeString for the main part of the time
    const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    // Extract milliseconds
    const milliseconds = date.getMilliseconds();
    // Pad milliseconds to ensure three digits
    const paddedMilliseconds = milliseconds.toString().padStart(3, '0');
    // Combine the time string with milliseconds
    return `${timeString}.${paddedMilliseconds}`;
};

const coinOptions = [
    { label: 'CCD', value: 'CCD' },
    { label: 'BTC', value: 'BTC' },
    { label: 'ETH', value: 'ETH' }
];

const TestTrading = () => {
    const [currentTab, setCurrentTab] = useState('BUY');
    const [tradeHistory, setTradeHistory] = useState([]);
    const [buyOrders, setBuyOrders] = useState([]);
    const [sellOrders, setSellOrders] = useState([]);
    const [remainingPrice, setRemainingPrice] = useState()
    const [pendingPrice, setPendingPrice] = useState()
    const [remainingCoin, setRemainingCoin] = useState()
    const [selectedCoin, setSelectedCoin] = useState(coinOptions[0].value);
    const [coinName, setCoinName] = useState()
    const { data: newOrderData } = useSubscription(TRADE_ORDER_UPDATED_SUBSCRIPTION);
    const { notifyError } = useToast();
    const [lastTradePricingData, setLastTradePricingData] = useState()
    const [averagePriceData, setaveragePricingData] = useState()


    const lastTradeData = async (coin) => {
        try {
            const lastData = await lastTradePriceData(coin, 'INR')
            const value = lastData?.last_24Hours_tradePriceCal?.lastTradePrice
            const averagData = lastData?.last_24Hours_tradePriceCal?.averagePrice
            setLastTradePricingData(value)
            setaveragePricingData(averagData)
        } catch (error) {
            console.error('this is error', error)
        }
    }


    useEffect(() => {
        // Fetch trade orders when the component mounts
        fetchTradeOrdersData(selectedCoin);
        walletData(selectedCoin)
        setCoinName(selectedCoin)
        lastTradeData(selectedCoin)
    }, [selectedCoin]);


    useEffect(() => {
        if (newOrderData?.tradeOrderUpdated) {
            lastTradeData(selectedCoin);
            const orders = newOrderData.tradeOrderUpdated;
            if (orders) {
                setTradeHistory(orders.tradeHistory);
                setBuyOrders(orders.buyOrders);
                setSellOrders(orders.sellOrders);
            }
        }
    }, [newOrderData, selectedCoin]);


    const fetchTradeOrdersData = (coin) => {
        fetchTradeOrders(coin, 'INR')
            .then(data => {
                console.log(data)
                setTradeHistory(data?.tradeOrders?.tradeHistory);
                setBuyOrders(data?.tradeOrders?.buyOrders);
                setSellOrders(data?.tradeOrders?.sellOrders);
            })
            .catch(error => console.error("Failed to fetch trade orders", error));
    };


    const handleCoinChange = (option) => {
        setSelectedCoin(option.value);
    };

    const walletData = async (coinName) => {
        try {
            const data = await getWalletDetails('INR',coinName);
            let pendingPrice = data?.getWalletDetails?.pending_price?.price || 0;
            let remainingPrice = data?.getWalletDetails?.remaining_price?.price || 0;
            let remainingCoin = data?.getWalletDetails?.remaining_coin?.totalSupply || 0;
            setPendingPrice(pendingPrice);
            setRemainingPrice(remainingPrice);
            setRemainingCoin(remainingCoin);
        } catch (error) {
            notifyError("Failed to fetch wallet details. Please try again later.");
        }
    };


    return (
        <Row>
            <div className="col-xl-7">
                <div className="row">
                    <div className="col-xl-12">

                        <div className="card h-auto ">
                            <div className="card-header py-2">
                                <h2 className="heading">Select Coin</h2>
                            </div>
                            <div className="card-body pt-0 pb-0 px-2">

                                <Select
                                    options={coinOptions}
                                    value={coinOptions.find(option => option.value === selectedCoin)}
                                    onChange={handleCoinChange}
                                    className="custom-react-select p-2"
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card h-auto">
                            <div className="card-body px-0 pt-1">
                                <Tab.Container defaultActiveKey={currentTab} onSelect={(k) => setCurrentTab(k)}>
                                    <div>
                                        <div className="buy-sell">
                                            <Nav className="nav nav-tabs" eventKey="nav-tab2" role="tablist">
                                                <Nav.Link as="button" className="nav-link" eventKey="BUY" type="button">buy</Nav.Link>
                                                <Nav.Link as="button" className="nav-link" eventKey="SELL" type="button">sell</Nav.Link>
                                            </Nav>
                                        </div>
                                    </div>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="BUY">
                                            <Tab.Container defaultActiveKey="Navbuylimit">
                                                <div className="limit-sell">
                                                    <Nav className="nav nav-tabs" role="tablist">
                                                        <Nav.Link as="button" eventKey="Navbuylimit">Limit Order</Nav.Link>
                                                        <Nav.Link as="button" eventKey="Navbuymarket">Market Order</Nav.Link>
                                                    </Nav>
                                                </div>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Navbuylimit">
                                                        <div className="sell-element">
                                                            <LimitOrder onTradeSuccess={walletData} coinName={coinName} />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="Navbuymarket">
                                                        <div className="sell-element">

                                                            <MarketOrder />
                                                        </div>

                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="SELL">
                                            <Tab.Container defaultActiveKey="Navselllimit">
                                                <div className="limit-sell">
                                                    <Nav className="nav nav-tabs" role="tablist">
                                                        <Nav.Link as="button" eventKey="Navselllimit">Limit Order</Nav.Link>
                                                        <Nav.Link as="button" eventKey="Navsellmarket">Market Order</Nav.Link>
                                                    </Nav>
                                                </div>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Navselllimit">
                                                        <div className="sell-element">
                                                            <LimitOrder currentTab={currentTab} onTradeSuccess={walletData} coinName={coinName} />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="Navsellmarket">
                                                        <div className="sell-element">
                                                            <MarketOrder currentTab={currentTab} />
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header py-2">
                                <h2 className="heading">Order Book</h2>
                            </div>
                            <div className="card-body pt-0 pb-3 px-2">
                                <div>
                                    <div>
                                        <div className="list-row-head">
                                            <span className="text-white">Quantity</span>
                                            <span className="text-end text-white">BUY PRICE</span>
                                            <span className="text-white">SELL PRICE</span>
                                            <span className="text-end text-white">Quantity</span>
                                        </div>
                                        <div className="list-table danger">
                                            {/* Assuming buyOrders and sellOrders are of equal length or padded appropriately */}
                                            {buyOrders?.map((buyOrder, index) => {
                                                const sellOrder = sellOrders[index] || {};
                                                return (
                                                    <div className="list-row" key={index}>
                                                        <span>{parseFloat(buyOrder?.quantity)?.toFixed(2)}</span>
                                                        <span className="text-end">{parseFloat(buyOrder?.price)?.toFixed(2)}</span>
                                                        <span>{sellOrder.price ? parseFloat(sellOrder.price)?.toFixed(2) : ""}</span>
                                                        <span className="text-end text-danger">{sellOrder?.quantity ? parseFloat(sellOrder?.quantity)?.toFixed(2) : ""}</span>
                                                        <div className="bg-layer"></div>
                                                    </div>
                                                );
                                            })}
                                            {/* Optionally handle any additional sell orders if sellOrders.length > buyOrders.length */}
                                            {sellOrders?.slice(buyOrders.length).map((sellOrder, index) => (
                                                <div className="list-row" key={`sell-${index}`}>
                                                    <span></span> {/* Empty for alignment */}
                                                    <span></span> {/* Empty for alignment */}
                                                    <span>{parseFloat(sellOrder?.price)?.toFixed(2)}</span>
                                                    <span className="text-end">{parseFloat(sellOrder?.quantity)?.toFixed(2)}</span>
                                                    <div className="bg-layer"></div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-xl-5 ">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="card p-2" >
                            <div className="card-body pt-0 pb-3 px-3">
                                <div>
                                    <h4>Remaining Price</h4>
                                </div>
                                <div>
                                    <strong>₹<span className="px-2">{(remainingPrice)?.toFixed(2)}</span></strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card p-2" >
                            <div className="card-body pt-0 pb-3 px-2">
                                <div>
                                    <h4>Pending Price</h4>
                                </div>
                                <div>
                                    <strong>₹<span className="px-2">{(pendingPrice)?.toFixed(2)}</span> </strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="card p-2" >
                            <div className="card-body pt-0 pb-3 px-2">
                                <div>
                                    <h4>Coin Balance</h4>
                                </div>
                                <div>
                                    <strong>₹<span className="px-2">{(remainingCoin)?.toFixed(2)}</span> </strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="card p-2" >
                            <div className="card-body pt-0 pb-3 px-2">
                                <div>
                                    <h4>LTP <small className="text-muted">(Last Trade Price)</small></h4>
                                </div>
                                <div>
                                    <strong>₹<span className="px-2">{lastTradePricingData}</span> & <span className="px-2">{((lastTradePricingData*averagePriceData)%100).toFixed(2)}%</span> </strong>
                                </div>
                            </div>
                        </div>
                    </div>

                   


                    <div className="col-xl-12">
                        <div className="card h-auto" >
                            <div className="card-header py-2">
                                <h2 className="heading">Trade History</h2>
                            </div>
                            <div className="card-body pt-0 pb-3 px-2">
                                <div className="list-row-head">
                                    <span className="text-white text-center">Price</span>
                                    <span className="text-white text-center">Quantity</span>
                                    <span className="text-center text-white">Time</span>
                                </div>
                                <div className="list-table danger">
                                    {tradeHistory?.map((order, index) => (
                                        <div className="list-row" key={index}>
                                            <span className="text-center">{(order?.price)?.toFixed(2)}</span>
                                            <span className="text-center">{(order?.quantity)?.toFixed(2)}</span>
                                            <span className="text-center">{formatTime(order?.createdAt)}</span>
                                            <div className="bg-layer"></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    )
}

export default TestTrading;