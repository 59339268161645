// In BannerList component
import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { fetchBanners, deleteItem } from "../../../apolloClientConnection/apolloClient";

const BannerList = () => {
    const [filter, setFilter] = useState('');
    const [banners, setBanners] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);


    const { notifySuccess, notifyError , notifyWarning} = useToast();

    useEffect(() => {
        setIsLoading(true);
        setHasError(false);

        fetchBanners()
            .then(async (res) => {
                setBanners(res);
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
                setHasError(true);
                console.error(err);
            });
    }, []);

    const handleDeleteBanner = async (PK, SK) => {
        try {
            const response = await deleteItem('NewUser' ,PK, SK);
            if (response.DeleteItem === true) {
                notifySuccess('Banner Deleted Successfully!');
                setBanners(prevBanners => prevBanners.filter(banner => banner.PK !== PK || banner.SK !== SK));
            }else{
                notifyWarning('Failed to delete the banner. Please try again.');

            }
        } catch (error) {
            notifyError('Error deleting banner');
        }
    };



    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = banners.filter(item =>
        (item.name && item.name.toLowerCase().includes(filter)) ||
        (item.page && item.page.toLowerCase().includes(filter)) ||
        (item.link && item.link.toLowerCase().includes(filter))
    );
    
    const svg2 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Banner List</Card.Title>
                    <div className="form-group ">
                        <div className="col-auto">
                            <Link to="/add-banner">
                                <button type="submit" className="btn btn-success">Add Banner</button>
                            </Link>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Image</strong>
                                </th>
                                <th className="text-end">
                                    <strong>Page</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Link</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? (
                                    <tr>
                                        <td colSpan="4" className="text-center"><Loader /></td>
                                    </tr>
                                ) : !hasData || filteredData?.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        </td>
                                    </tr>
                                ) : hasError ? (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : (filteredData.map(item => (
                                    <tr key={item.PK}>
                                        <td>
                                            <strong><img src={item.image} alt={item.name} style={{ width: '90px', height: '90px', borderRadius: '5px' }} /></strong>
                                        </td>
                                        <td className="text-end">{item.page ? item.page: 'No Page'}</td>
                                        <td className="text-center">{item.link ? item.link: 'No Link'}</td>
                                        <td className="text-center">
                                            <Dropdown>
                                                <Dropdown.Toggle variant={'danger'} className="light sharp i-false ms-3" onClick={() => handleDeleteBanner(item.PK, item.SK)}>
                                                    {svg2}
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col >
    );
}

export default BannerList;
