import React, { useState } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import useToast from "../Toaster/Toaster";

const AddSupply = () => {
    // State variables for each input field
    const [contractAddress, setContractAddress] = useState('');
    const [assetShortName, setAssetShortName] = useState('');
    const [tokenName, setTokenName] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [coin, setCoin] = useState('');
    const [tokenQuantity, setTokenQuantity] = useState('');
    const { notifySuccess, notifyError, notifyWarning } = useToast();

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        if(!contractAddress|| !assetShortName || !tokenName || !assetShortName ||  !coin || tokenQuantity === ''){
            notifyWarning('All Field are required!')
            return;
        }


        // Console log all input field values
        console.log({
            ContractAddress: contractAddress,
            AssetShortName: assetShortName,
            TokenName: tokenName,
            WalletAddress: walletAddress,
            Coin: coin,
            TokenQuantity: tokenQuantity,
        });
        notifySuccess(`Added Token Successfully!`)

    }

    return (
        <Col lg={12}>
            
            <Card>
                <Card.Header>
                    <Card.Title>Add Supply</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mt-3 mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Contract address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter contract address"
                                        value={contractAddress}
                                        onChange={e => setContractAddress(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Asset short name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Asset Name"
                                        value={assetShortName}
                                        onChange={e => setAssetShortName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Token Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Token Name"
                                        value={tokenName}
                                        onChange={e => setTokenName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Wallet Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter wallet address"
                                        value={walletAddress}
                                        onChange={e => setWalletAddress(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Coin</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Coin"
                                        value={coin}
                                        onChange={e => setCoin(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Token Quantity</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Type Here"
                                        value={tokenQuantity}
                                        onChange={e => setTokenQuantity(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4 ">
                            <Col>
                                <Button type="submit" variant="success">Add Token</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default AddSupply;
