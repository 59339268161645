import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { fetchHeaderText, deleteItem, dataUpdate } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/noData.json";

const MobileDataList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { notifySuccess, notifyError, notifyInfo } = useToast();
    const [filter, setFilter] = useState('');


    const handleActionClick = async (PK, SK, isActive) => {
        try {
            // If the item is already active, there's no need to do anything
            if (isActive) return;

            // Update all items to "Off" first
            const updates = data.map(item => {
                if (item.IsdynamicString && item.PK !== PK) {
                    return dataUpdate(item.PK, item.SK, false);  // Set other items to "Off"
                }
                return Promise.resolve();
            });

            // Wait for all updates to complete
            await Promise.all(updates);

            // Then set the clicked item to "On"
            const updateResponse = await dataUpdate(PK, SK, true);
            if (updateResponse) {
                notifySuccess('Update Successful!');

                // Update local state to reflect these changes
                setData(data.map(item => ({
                    ...item,
                    IsdynamicString: item.PK === PK ? true : false  // Only the clicked item is "On"
                })));
            }
        } catch (error) {
            notifyError('Error updating item');
            console.error('Error update item:', error);
        }
    };


    const svg2 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    const handleDeleteHeaderText = async (PK, SK) => {
        // First, find the item to check its active status
        const item = data.find(d => d.PK === PK && d.SK === SK);
        
        // Check if the item's IsdynamicString is true (meaning it's "On")
        if (item && item.IsdynamicString) {
            notifyInfo('Cannot delete item because it is currently active.');
            return; // Stop the deletion process if the item is active
        }
    
        try {
            const response = await deleteItem('NewUser',PK, SK);
            if (response) {
                notifySuccess('ICO Text Deleted Successfully!');
                setData(prevData => prevData.filter(data => data.PK !== PK || data.SK !== SK));
            }
        } catch (error) {
            notifyError('Error deleting ICO Text');
            console.error('Error deleting ICO Text:', error);
        }
    };
    
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setError(false);
            try {
                const response = await fetchHeaderText('ICO');
                console.log('this is response of header text', response)
                setData(response.GetDynamicStringList);
                setLoading(false);
            } catch (error) {
                // console.log('this is error',error.message)
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(true);
            }
        }
        fetchData();
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = data.filter(item =>
        item.StringInput.toLowerCase().includes(filter.toLowerCase()) ||
        (item.IsdynamicString !== null && item.IsdynamicString.toString() === filter)
    );





    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>ICO Text List</Card.Title>
                    <div className="col-auto">
                        <Link to="/mobile-data">
                            <button type="submit" className="btn btn-success">Add ICO Text</button>
                        </Link>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th><strong>S.no</strong></th>
                                <th className="text-center"><strong>Text</strong></th>
                                <th className="text-center"><strong>Description</strong></th>
                                <th className="text-center"><strong>Min Amount</strong></th>
                                <th className="text-center"><strong>Max Amount</strong></th>
                                <th className="text-center"><strong>Active</strong></th>
                                <th className="text-center"><strong>Action</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="6" className="text-center"><Loader /></td>
                                </tr>
                            ) : error ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : data.length === 0 ? (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <LottieAnimation lottie={noDataAnimation} height={400} width={400} />
                                        <p>No data available</p>
                                    </td>
                                </tr>
                            ) : filteredData.map((item, index) => (
                                <tr key={item.PK}>
                                    <td>{index + 1}</td>
                                    <td className="text-center">{item.Discription}</td>
                                    <td className="text-center">{item.StringInput}</td>
                                    <td className="text-center">{item.minAmount}</td>
                                    <td className="text-center">{item.maxAmount}</td>
                                    <td className="text-center">
                                        <button
                                            className={`btn btn-${item.IsdynamicString ? 'success' : 'secondary'}`}
                                            onClick={() => handleActionClick(item.PK, item.SK, item.IsdynamicString)}
                                        >
                                            {item.IsdynamicString ? 'On' : 'Off'}
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <Dropdown>
                                            <Dropdown.Toggle variant={'danger'} className="light sharp i-false ms-3" onClick={() => handleDeleteHeaderText(item.PK, item.SK)}>
                                                {svg2}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default MobileDataList;
