import React, { useState, useEffect } from "react";
import Select from "react-select";
import { fetchAllAdmins, changeAdminPassword } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import { useLocation } from 'react-router-dom';

const AdminPassword = () => {
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminOptions, setAdminOptions] = useState([]);
  const { notifyError, notifySuccess, notifyWarning } = useToast();
  const location = useLocation(); // Get location object
  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const admins = await fetchAllAdmins();
        const options = admins.map(admin => ({
          value: admin.PK.trim(),
          label: admin.userName,
          SK: admin.SK.trim()
        }));
        setAdminOptions(options);

        // Set selected admin from navigation state if available
        if (location.state && location.state.admin) {
          const { admin } = location.state;
          const selectedOption = options.find(option => option.value === admin.PK.trim());
          setSelectedAdmin(selectedOption);
        }
      } catch (error) {
        notifyError("Failed to fetch admin data");
      }
    };

    fetchAdminData();
  }, [location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedAdmin) {
      notifyWarning("Please select an admin.");
      return;
    }

    if (password !== confirmPassword) {
      notifyWarning("Passwords do not match!");
      return;
    }

    if (!password || !confirmPassword) {
      notifyWarning("Please fill in all fields.");
      return;
    }

    try {

      // Call the changeAdminPassword function with PK, SK, and the new password
      await changeAdminPassword(selectedAdmin.value, selectedAdmin.SK, password);
      notifySuccess("Password changed successfully!");
      // Reset form
      setSelectedAdmin(null);
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      notifyError("Failed to change password");
    }
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Change Admin Password</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Select Admin</label>
                    <Select
                      options={adminOptions}
                      className="custom-react-select"
                      value={selectedAdmin}
                      onChange={setSelectedAdmin}
                      isSearchable={true}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Confirm New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-primary me-2">
                  Change Password
                </button>
                <button type="button" className="btn btn-secondary" onClick={() => {
                  setSelectedAdmin(null);
                  setPassword("");
                  setConfirmPassword("");
                }}>
                  Clear
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPassword;
