import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Dropdown,

} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const TransactionHistory = () => {
    // Sample data
    const data = [
        {
            name: "CCD-INR",
            id: 5865,
            buyer_id: 'ORD100000003513',
            seller_id: 'ORD100000003499',
            buyer_amount: '4.250000000000',
            seller_amount: '4.250000000000',
            date_time: '01-01-2024 09:18AM',
            quantity: '25.600000001421'
        },
    ];



    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const filteredData = (filter === '') ? data : data.filter(item =>
        item.name.toString().includes(filter) ||
        item.id.toString().includes(filter) ||
        item.buyer_id.includes(filter) ||
        item.seller_id.includes(filter) ||
        item.buyer_amount.includes(filter) ||
        item.seller_amount.includes(filter) ||
        item.date_time.includes(filter) ||
        item.quantity.includes(filter) 
    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        console.log(selectedOption.value)
    };

    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Transaction History List</Card.Title>
                    <div className="form-group col-md-1">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select"
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-between mb-4">
                        <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Filter..."
                                    value={filter}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control"
                                    placeholderText="Start Date"
                                />
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control ms-2"
                                    placeholderText="End Date"
                                />
                                <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                            </div>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Name</strong>
                                </th>
                                <th className="text-center">
                                    <strong>ID</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Buyer ID</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Seller ID</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Buyer Amount(INR)</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Seller Amount(INR)</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Date/Time</strong>
                                </th>
                                <th className="text-end"> <strong>Quantity</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(item => (
                                <tr key={item.name}>
                                    <td>
                                        <strong>{item.name}</strong>
                                    </td>
                                    <td className="text-center">{item.id}</td>
                                    <td className="text-center">{item.buyer_id}</td>
                                    <td className="text-center">{item.seller_id}</td>
                                    <td className="text-center">{item.buyer_amount}</td>
                                    <td className="text-center">
                                      {item.seller_amount}
                                    </td>
                                    <td className="text-center">{item.date_time}</td>
                                    <td className="text-end">{item.quantity}</td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between">

                        <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>

                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </Card.Body>
            </Card>
        </Col>
    );
}

export default TransactionHistory;
