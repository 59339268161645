import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';


const AddTaxModel = ({ show, onHide, onSave }) => {
    const [taxCalculation, setTaxCalculation] = useState('');
    const [percentage, setPercentage] = useState('');

    const handleSubmit = () => {
        // Here, you would usually send the data to the server or update the parent component state
        onSave({ taxCalculation, percentage });
        // Reset form
        setTaxCalculation('');
        setPercentage('');
        // Close modal
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Tax</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="taxCalculation">
                        <Form.Label>Tax Calculation</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter tax calculation"
                            value={taxCalculation}
                            onChange={(e) => setTaxCalculation(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="percentage">
                        <Form.Label>Percentage</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter percentage"
                            value={percentage}
                            onChange={(e) => setPercentage(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddTaxModel;
