import React, { useState, useEffect, useRef } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import Select from 'react-select';
import useToast from '../Toaster/Toaster';
import { createMerchantVoucher, fetchAllCategories } from "../../../apolloClientConnection/apolloClient";
import { Link } from "react-router-dom";

const FlexibleVoucher = () => {
  const [rows, setRows] = useState([{ amount: "", discount: "" }]);
  const [timeouts, setTimeouts] = useState([]);
  const { notifySuccess, notifyWarning, notifyError } = useToast();
  const discountRefs = useRef([]);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null);

  useEffect(() => {
    discountRefs.current = discountRefs.current.slice(0, rows.length);
  }, [rows]);

  const handleAddRow = () => {
    setRows([...rows, { amount: "", discount: "" }]);
  };

  const handleRemoveRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };

  const handleChange = (index, field, value) => {
    const regex = /^\d*\.?\d*$/; // Regex to allow only numbers and decimals

    if (field === "discount" && value.endsWith("%")) {
      value = value.slice(0, -1);
    }

    if (!regex.test(value)) {
      return;
    }

    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);

    if ((field === "amount" || field === "discount") && index > 0) {
      clearTimeout(timeouts[index]);
      const timeoutId = setTimeout(() => {
        const previousValue = parseFloat(newRows[index - 1][field]);
        if (value !== "" && previousValue >= parseFloat(value)) {
          notifyWarning(`${field === "amount" ? "Amount" : "Discount"} must be greater than the previous row's value.`);
          newRows[index][field] = "";
          setRows(newRows);
        } else if (field === "discount" && !value.includes('%')) {
          newRows[index][field] = value + "%";
          setRows(newRows);
        }
      }, 1000);
      const newTimeouts = [...timeouts];
      newTimeouts[index] = timeoutId;
      setTimeouts(newTimeouts);
    }
  };

  const handleBlur = (index, field) => {
    if (field === "discount" && !rows[index][field].includes('%')) {
      const newRows = [...rows];
      newRows[index][field] = rows[index][field] + "%";
      setRows(newRows);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const vouchers = rows.map(row => ({
        amount: parseFloat(row.amount),
        discount: parseFloat(row.discount)
      }));
      
      if (!brand) {
        notifyWarning('Please select a brand.');
        return;
      }

      const data = await createMerchantVoucher(brand.PK, vouchers);
      if (data) {
        notifySuccess('Merchant voucher created successfully!');
        // Clear the form
        setRows([{ amount: "", discount: "" }]);
        setBrand(null);
      } else {
        notifyError('Failed to create merchant voucher.');
      }
      
    } catch (error) {
      console.error('Error creating merchant voucher:', error);
      notifyError('Failed to create merchant voucher.');
    }
  };


  const handleBrandChange = (selectedBrand) => {
    setBrand(selectedBrand);
  };

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const categories = await fetchAllCategories();
        const formattedBrands = categories.map(cat => ({
          value: cat.shortcode,
          label: cat.name,
          PK: cat.PK
        }));
        setBrands(formattedBrands);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        notifyWarning('Failed to fetch brands.');
      }
    };
    loadCategories();
  }, []);

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Col lg={4} md={12}>
              <Form.Group>
                <Form.Label>Brand</Form.Label>
                <Select
                  options={brands}
                  className="custom-react-select"
                  value={brand}
                  onChange={handleBrandChange}
                />
              </Form.Group>
            </Col>
          </Row>
          {rows.map((row, index) => (
            <div key={index}>
              <Row className="mb-3">
                <Col md={4}>
                  <Form.Group controlId={`formAmount${index}`}>
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="text"
                      value={row.amount}
                      onChange={(e) => handleChange(index, "amount", e.target.value)}
                      placeholder="Enter Amount"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId={`formDiscount${index}`}>
                    <Form.Label>Discount</Form.Label>
                    <Form.Control
                      type="text"
                      value={row.discount}
                      onChange={(e) => handleChange(index, "discount", e.target.value)}
                      onBlur={() => handleBlur(index, "discount")}
                      placeholder="Enter Discount"
                    />
                  </Form.Group>
                </Col>
                {index > 0 && (
                  <Col md={2} className="d-flex align-items-end">
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveRow(index)}
                    >
                      Remove
                    </Button>
                  </Col>
                )}
              </Row>
              {index === rows.length - 1 && (
                <Row className="mb-3 mt-4">
                  <Col md={{ span: 4, offset: 7 }} className="text-right">
                    <div
                      className="text-primary fw-bold"
                      onClick={handleAddRow}
                      style={{ cursor: 'pointer' }}
                    >
                      + Add More
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          ))}
          <Col className="mt-3">
            <Button variant="success" type="submit" className="me-3">
              Submit
            </Button>
            <Link to="/flexible-list">
            <Button variant="danger" type="submit" className="me-3">
              Go Back
            </Button>
            </Link>
          </Col>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FlexibleVoucher;
