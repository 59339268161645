
import CryptoJS from "crypto-js";

const key = process.env.REACT_APP_SECRET_PARSE_KEY

export const formatDateTime = (updatedAt) => {
    const date = new Date(Number(updatedAt)); // Convert to number if necessary
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const formattedDate = `${day}-${month}-${year}`;

    let hours = date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${('0' + hours).slice(-2)}:${minutes} ${ampm}`;

    return `${formattedDate} / ${formattedTime}`;
};


export const formatDateTime1 = (item) => {
    if (item.customCreatedAt) {
        return item.customCreatedAt;
    }
    // Check if requestedAt exists and is not null or undefined, otherwise fallback to createdAt
    const timestamp = item.requestedAt ? item.requestedAt : item.createdAt;

    // If both requestedAt and createdAt are missing, return a placeholder or 'N/A'
    if (!timestamp) {
        return 'N/A';
    }
    const date = new Date(Number(timestamp));
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year

    const formattedDate = `${day}-${month}-${year}`;

    let hours = date.getHours();
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedTime = `${('0' + hours).slice(-2)}:${minutes} ${ampm}`;

    return `${formattedDate} / ${formattedTime}`;
};

export const decrypter = (value) => {
    const decryptedBytes = CryptoJS.AES.decrypt(value, key);
    const DecryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return DecryptedValue
}