import React, { useState, useEffect } from 'react';
import { Col, Card, Form, Row, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import quill styles
import { addBlogPost } from '../../../apolloClientConnection/apolloClient';
import useToast from '../Toaster/Toaster';
import { useLocation } from 'react-router-dom';
import { fetchBlogDetails } from '../../../apolloClientConnection/apolloClient';
import { getImageLink } from '../StorJ/StorJFunction';


const AddBlog = () => {
    // State for form fields
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { notifySuccess, notifyWarning, notifyError } = useToast();
    const [imageURL, setImageURL] = useState('');
    const location = useLocation();
    const { blogDetails } = location.state || {}; // Default to an empty object if state is undefined
    const [inputKey, setInputKey] = useState(Date.now());
    const SERVER_LINK = process.env.REACT_APP_SERVER_LINK


    const handleClear = () => {
        setDescription('')
        setTitle('')
        setInputKey(Date.now());
    }


    useEffect(() => {
        if (blogDetails && blogDetails.PK && blogDetails.SK) {
            fetchBlogDetails(blogDetails.PK, blogDetails.SK)
                .then(response => {
                    if (response.success && response.blogDetails) {
                        const blog = response.blogDetails; // Assuming the first item is what you want
                        setTitle(blog.title);
                        setDescription(blog.description);
                        setImageURL(blog.Image);
                        console.log('Fetched blog details:', response);
                    } else {
                        notifyError('Failed to fetch blog details or no details found.');
                    }
                })
                .catch(err => {
                    console.error('Error fetching blog details:', err);
                    notifyError('Error fetching blog details!');
                });
        }
    }, [blogDetails]);


    // Handle form submit
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!title || !description || !imageURL) {
            notifyWarning('Please fill all the fields!')

            return;  // Or handle validation visually for the user
        }

        try {
            const response = await addBlogPost({ title, description, Image: imageURL });
            if (response.success) {
                console.log('Blog post added successfully:', response.blogPost);
                notifySuccess('Add Blog successfully!');
                // Reset form or notify user
                handleClear()
            } else {
                console.error('Failed to add blog post:', response.message);
                notifyWarning('Failed to add blog post!')
            }
        } catch (error) {
            console.error('Error adding blog post:', error);
            notifyError('Error adding blog post!')
        }
    };


    // Define a module to set custom toolbar options
    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            [{ 'color': [] }, { 'background': [] }],  // dropdown with defaults from theme
            [{ 'align': [] }],
            ['clean']  // remove formatting button
        ],
    };

    // ... the rest of your component

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            // Ensure the key matches the expected field name "ImageUpload" as per your Postman setup
            formData.append('ImageUpload', file);
            

            try {
                const response = await fetch(`${SERVER_LINK}/api/v1/file/ImageUpload`, {
                  method: 'POST',
                  body: formData,
                });
                const data = await response.json();
                if (data) {
                  const digitalLotionURL = data?.data
                  try {
                    const imageStream = await getImageLink(digitalLotionURL);
                    if (imageStream) {
                      setImageURL(imageStream);
                      notifySuccess('Image uploaded successfully!');
                    } else {
                      notifyWarning(`Failed to upload image: ${data.message}`);
                    }
                  } catch (error) {
                    console.error('Failed to fetch image:', error);
                  }
                }
          
              } catch (error) {
                notifyWarning('Error uploading file!');
              }
        }
    };


    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Add Blog</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Image Upload</Form.Label>
                                    <Form.Control
                                        type="file"
                                        key={inputKey}
                                        onChange={handleImageUpload}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={setDescription}
                                        modules={modules}
                                        placeholder="Enter Description"
                                        className="custom-quill"
                                    />

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="success" type="submit">
                                    <span className="btn-icon-start text-success"  >
                                    <i className="fa fa-share-alt color-secondary" />
                                    </span>
                                    Send
                                </Button>
                               
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default AddBlog;