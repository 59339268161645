import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { icoListing } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import useToast from "../Toaster/Toaster";
import { formatDateTime } from "../UserManagement/apis";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/noData.json";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const ICOListing = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [icoData, setIcoData] = useState([]);
    const { notifyError, notifySuccess } = useToast();
    const [error, setError] = useState(false);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const fetchIcoData = async () => {
        setLoading(true);
        try {
            const response = await icoListing();
            const GetAllICOAmount = response.GetAllICOAmount;
            setIcoData(GetAllICOAmount);
            setError(false);
        } catch (error) {
            console.error("Failed to fetch ICO data: ", error);
            notifyError("Failed to fetch ICO data");
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchIcoData();
    }, []);

    const filteredData = useMemo(() =>
        icoData.filter(item =>
            item.PK.toLowerCase().includes(filter.toLowerCase()) ||
            item.UserId.toLowerCase().includes(filter.toLowerCase()) ||
            item.phone.toString().includes(filter) ||
            item.email.toLowerCase().includes(filter)
        ), [icoData, filter]);

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleSearch = () => {
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
    };

    const svg1 = (
        <svg
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z"
                    fill="#000000"
                />
            </g>
        </svg>
    );


    const handleRowClick = (PK, SK) => {
        // let dataPKSK = {
        //   PK: PK,
        //   SK: SK
        // };
        // localStorage.setItem('dataPKSK', JSON.stringify(dataPKSK));
        // navigate("/user-data");
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>User ICO List</Card.Title>
                    <div className="form-group col-md-1">
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select"
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-between mb-4">
                        <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Filter..."
                                    value={filter}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </Col>
                        <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control"
                                    placeholderText="Start Date"
                                />
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control ms-2"
                                    placeholderText="End Date"
                                />
                                <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                            </div>
                        </Col>
                    </Row>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th><strong>Name</strong></th>
                                <th className="text-center"><strong>Mobile/Email</strong></th>
                                <th className="text-center"><strong>Amount</strong></th>
                                <th className="text-center"><strong>Date/Time</strong></th>
                                <th ><strong>Action</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="5" className="text-center"><Loader /></td>
                                </tr>
                            ) : error ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : filteredData.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        <LottieAnimation lottie={noDataAnimation} height={400} width={400} />
                                        <p>No data available</p>
                                    </td>
                                </tr>
                            ) : (
                                filteredData.slice(0, perPage).map((item, index) => (
                                    <tr key={index}>
                                        <td><strong>{item.full_name}</strong></td>
                                        {/* <td className="text-center text-blue">{item.phone}<br />{item.email}</td> */}
                                        <td className="text-primary text-center fw-bold" onClick={handleRowClick(item.UserId)}>
                                            {item.phone}
                                            {item.email && <div>{item.email}</div>}
                                        </td>
                                        <td className="text-center">{item.ICO_AMOUNT}</td>
                                        <td className="text-center">{formatDateTime(item.createdAt)}</td>
                                        <td>
                                            <Dropdown.Toggle
                                                variant={
                                                    item.status === "Active" ? "success" : "danger"
                                                }
                                                className="light sharp i-false"
                                            >
                                                {svg1}
                                            </Dropdown.Toggle>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>

                    <div className="table-pagination d-flex align-items-center justify-content-between">
                        <p className="mb-0">Showing <span>1-{Math.min(perPage, filteredData.length)}</span> from <span>{filteredData.length}</span> data</p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to="#" className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to="#" className="page-link">1</Link></li>
                                <li className="page-item page-indicator">
                                    <Link to="#" className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default ICOListing;
