import React, { useState, useEffect, useRef } from 'react';
import { Col, Card, Table, Row } from "react-bootstrap";
import useToast from "../Toaster/Toaster";
import { getAdminWallet } from '../../../apolloClientConnection/apolloClient';
// import { useSubscriptionData } from '../TestTrading/SubscriptionContext';

// Sample transaction data
const sampleTransactions = [
  { id: 1, date: '2024-06-01', type: 'Deposit', amount: 5000 },
  { id: 2, date: '2024-06-02', type: 'Withdrawal', amount: 2000 },
  { id: 3, date: '2024-06-03', type: 'Deposit', amount: 3000 },
];

const CCDTokenWallet = () => {
  const [balance, setBalance] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [coinPrice, setCoinPrice] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const { notifyWarning } = useToast();
  // const { setUpdateCallback } = useSubscriptionData();
  // const isCallbackSet = useRef(false);

  const fetchBalance = async () => {
    try {
      const { getAppWallets } = await getAdminWallet();
      const wallet = getAppWallets.find(wallet => wallet.SK === "WALLET#RESERVED#COIN#CCD#PAIR_WITH_INR");
      if (wallet) {
        setBalance(wallet.price || 0); // Set balance to 0 if price is null
        setTotalSupply(wallet.totalSupply || 0); // Set totalSupply to 0 if null
        setCoinPrice(wallet.coinPrice || 0); // Set coinPrice to 0 if null
      } else {
        notifyWarning('Failed to fetch CCD token wallet balance');
      }
    } catch (error) {
      console.error('Error fetching CCD token wallet balance:', error);
      notifyWarning('Failed to fetch CCD token wallet balance');
    }
  };

  useEffect(() => {
    fetchBalance();
    setTransactions(sampleTransactions); // Fetch transactions from API or use sample data

    // Set the update callback only once
    // if (!isCallbackSet.current) {
    //   setUpdateCallback(fetchBalance);
    //   isCallbackSet.current = true;
    // }
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <Col lg={12}>
      <Row>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header>
              <Card.Title>CCD Token Wallet Balance</Card.Title>
            </Card.Header>
            <Card.Body>
              <h4><span style={{ fontWeight: 'bold' }}>₹</span> <span style={{ fontWeight: 'bold' }}>{balance.toFixed(2)}</span></h4>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header>
              <Card.Title>Total Supply</Card.Title>
            </Card.Header>
            <Card.Body>
              <h4><span style={{ fontWeight: 'bold' }}>{totalSupply.toLocaleString()}</span></h4>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header>
              <Card.Title>Coin Price</Card.Title>
            </Card.Header>
            <Card.Body>
              <h4><span style={{ fontWeight: 'bold' }}>₹</span> <span style={{ fontWeight: 'bold' }}>{coinPrice.toFixed(2)}</span></h4>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card>
        <Card.Header>
          <Card.Title>Transaction History</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Amount (₹)</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map(txn => (
                <tr key={txn.id}>
                  <td>{txn.date}</td>
                  <td>{txn.type}</td>
                  <td><span style={{ fontWeight: 'bold' }}>₹</span> <span style={{ fontWeight: 'bold' }}>{txn.amount}</span></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CCDTokenWallet;
