import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { banckDetails, fetchUserProfile } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
];

const AllBankDetails = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [noData, setNoData] = useState(false);
    const navigate = useNavigate();



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await banckDetails("", true);
                if (response && response.getBankAccount) {
                    const bankAccounts = response.getBankAccount;
    
                    // Extract user IDs from all SK values
                    const userPKs = bankAccounts.map(account => {
                        let skParts = account.SK.split('#');
                        return `${skParts[1]}#${skParts[2]}`; // Extract the user ID part
                    });
    
                    // Fetch profile data for each userPK
                    const fetchProfiles = async (userPKs) => {
                        return Promise.all(userPKs.map(async userPK => {
                            try {
                                const profileData = await fetchUserProfile({ PK: userPK, SK: '' });
                                let userData = profileData?.profile ? JSON.parse(profileData.profile): 'N/A'
                                return userData;
                            } catch (error) {
                                console.error(`Error fetching profile data for user ${userPK}:`, error);
                                return null; // Return null in case of an error
                            }
                        }));
                    };
    
                    const profiles = await fetchProfiles(userPKs);
    
                    // Combine bank account data with profile data
                    const combinedData = bankAccounts.map((account, index) => ({
                        ...account,
                        userProfile: profiles[index]
                    }));
    
                    setData(combinedData);
                    setNoData(combinedData.length === 0);
                } else {
                    setNoData(true);
                }
                setLoading(false);
            } catch (error) {
                setNetworkError(true);
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    

    
    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
    };

    // const filteredData = data.filter(item =>
    //     item.full_name?.toLowerCase().includes(filter.toLowerCase()) ||
    //     item.account?.toLowerCase().includes(filter.toLowerCase()) ||
    //     item.ifsc_details?.ifsc?.toLowerCase().includes(filter.toLowerCase()) ||
    //     item.ifsc_details?.bank_name?.toLowerCase().includes(filter.toLowerCase())
    // );

    const handleRowClick = (pk, sk) => {
        localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
        navigate("/user-data");
    };

    return (
        <div className="row">
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>All User Bank Details</Card.Title>
                        <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                                <div className="d-flex align-items-center">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control ms-2"
                                        placeholderText="End Date"
                                    />
                                    <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                                </div>
                            </Col>
                        </Row>
                        <Table responsive className="header-border">
                            <thead>
                                <tr>
                                    <th>S.NO</th>
                                    <th className="text-center">Name</th>
                                    <th className="text-center">Phone/Email</th>
                                    <th className="text-center">Account No</th>
                                    <th className="text-center">IFSC</th>
                                    <th className="text-center">Bank Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                )}
                                {networkError && (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            <p>Connect To SuperAdmin</p>
                                        </td>
                                    </tr>
                                )}
                                {noData && !loading && !networkError && (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        </td>
                                    </tr>
                                )}
                                {!loading && !networkError && !noData && data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className="text-center">{item.full_name || ''}</td>
                                        <td className="text-center">
                                            <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }} onClick={() => handleRowClick(item.userProfile.PK, item.userProfile.SK)}>
                                                {item?.userProfile?.phone ? item.userProfile.phone : 'N/A'}
                                                {item.userProfile ? item.userProfile.email && <div>{item.userProfile.email}</div> : 'N/A'}
                                            </div>
                                        </td>
                                        <td className="text-center">{item.account || ''}</td>
                                        <td className="text-center">{item.ifsc_details?.ifsc || item.ifscCode || ''}</td>
                                        <td className="text-center">{item.ifsc_details?.bank_name || item.bankName || ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="table-pagination d-flex align-items-center justify-content-between mb-3">
                            <p className="mb-0">Showing <span> 1-4 </span> from <span> {data.length} </span> data </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                    <li className="page-item "><Link to={"#"} className="page-link">2</Link></li>
                                    <li className="page-item"><Link to={"#"} className="page-link">3</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </div>
    );
};

export default AllBankDetails;
