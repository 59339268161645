import React, { useEffect, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Card,
    Table,
} from 'react-bootstrap';
import { getBreakData } from '../../../apolloClientConnection/apolloClient';

const OrderDataModal = ({ show, onHide, order }) => {
    const [breakData, setBreakData] = useState(null);

    useEffect(() => {
        if (show && order && order.placeOrderId) {
            (async () => {
                try {
                    const data = await getBreakData(order.placeOrderId);
                    console.log('Fetched break data:', data);
                    setBreakData(data);
                } catch (error) {
                    console.error('Error fetching break data:', error);
                }
            })();
        }
    }, [show, order]);

    if (!order) {
        return null; // Return null if order is not provided
    }

    const data = [
        {
            buyerID: order.orderId || "N/A",
            sellerOrderId: order.PK.replace("USER", "").replace("EXECUTED", ""),
            quantity: `${order.quantity}`,
            buyerPrice: order.price,
            sellerPrice: "N/A" // Adjust this based on available data
        },
    ];

    const formatDateTime = (timestamp) => {
        const date = new Date(Number(timestamp));
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <Modal show={show} onHide={onHide} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Order</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#000' }}>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th><strong>Buyer Id</strong></th>
                                            <th><strong>Seller Order Id</strong></th>
                                            <th className='text-center'><strong>Quantity</strong></th>
                                            <th className="text-center text-success"><strong>Buyer Price</strong></th>
                                            <th className="text-center text-danger"><strong>Seller Price</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(item => (
                                            <tr key={item.buyerID}>
                                                <td><strong>{item.buyerID}</strong></td>
                                                <td>{item.sellerOrderId}</td>
                                                <td className='text-center'>{item.quantity}</td>
                                                <td className="text-center text-success">{item.buyerPrice}</td>
                                                <td className="text-center text-danger">{item.sellerPrice}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <h4 className="text-white mb-3">Token Pair</h4>
                                        <Col>
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>User ID:</strong></Col>
                                                <Col xs={6} className="text-muted">{order.userId || "N/A"}</Col>
                                            </Row>
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>Name:</strong></Col>
                                                <Col xs={6} className="text-muted">{order.shareName}</Col>
                                            </Row>
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>Order Placed On:</strong></Col>
                                                <Col xs={6} className="text-muted">{formatDateTime(order.createdAt)}</Col>
                                            </Row>
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>Order Executed On:</strong></Col>
                                                <Col xs={6} className="text-muted">{formatDateTime(order.createdAt)}</Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                    <Col md={6}>
                                        <h4 className=" mb-3 "><span className={`text-${order.orderExecutedType === "BUY" ? "success" : order.orderExecutedType === "SELL" ? "danger" : "warning"}`}>{order.orderExecutedType || 'EXECUTED'}</span></h4>
                                        <Col >
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>Order Type:</strong></Col>
                                                <Col xs={6} className="text-info">{order.orderType || 'OPEN_ORDER'}</Col>
                                            </Row>
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>Status:</strong></Col>
                                                <Col xs={6}><span className={`badge light badge-${order.type === "BUY" ? "success" : order.type === "SELL" ? "danger" : "warning"}`}>{order.type}</span></Col>
                                            </Row>
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>Avg Price:</strong></Col>
                                                <Col xs={6} className="text-primary"><span className=''>₹</span>{order.price}</Col>
                                            </Row>
                                            <Row className="text-white mb-2">
                                                <Col xs={6}><strong>Quantity:</strong></Col>
                                                <Col xs={6} className="text-secondary">{order.quantity} {order.shareName}</Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                </Row>
                                <Table hover className="mt-4">
                                    <thead>
                                        <tr>
                                            <th><strong>Date/Time</strong></th>
                                            <th className='text-center'><strong>Quantity</strong></th>
                                            <th><strong>Price</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {breakData && breakData.executedOrderId.map((item, index) => (
                                            <tr key={index}>
                                                <td>{formatDateTime(item.createdAt)}</td>
                                                <td className='text-center'>{item.quantity}</td>
                                                <td>₹ {item.price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-end mt-3">
                                    <a href="/path/to/your/download/file.csv" download className="btn btn-primary">
                                        Download Data
                                    </a>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderDataModal;
