import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSubscription, gql } from '@apollo/client';

const TRADE_ORDER_UPDATED_SUBSCRIPTION = gql`
  subscription {
    tradeOrderUpdated {
      buyOrders {
        type
        price
        quantity
      }
      sellOrders {
        type
        price
        quantity
      }
      tradeHistory {
        type
        price
        quantity
        createdAt
      }
    }
  }
`;

const SubscriptionContext = createContext();

export const useSubscriptionData = () => {
  return useContext(SubscriptionContext);
};

export const SubscriptionProvider = ({ children }) => {
  const [onTradeOrderUpdate, setOnTradeOrderUpdate] = useState(null);

  const { data: newOrderData } = useSubscription(TRADE_ORDER_UPDATED_SUBSCRIPTION);

  useEffect(() => {
    if (newOrderData?.tradeOrderUpdated) {
      if (onTradeOrderUpdate) {
        onTradeOrderUpdate();
      }
    }
  }, [newOrderData, onTradeOrderUpdate]);

  const setUpdateCallback = (callback) => {
    setOnTradeOrderUpdate(() => callback);
  };

  return (
    <SubscriptionContext.Provider value={{ setUpdateCallback }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
