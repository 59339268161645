import React, { useState, useEffect } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import useToast from "../Toaster/Toaster";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { fetchAllCategories, addCoupon } from "../../../apolloClientConnection/apolloClient";
import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";

const statuses = [
    { value: true, label: 'Claimed' },
    { value: false, label: 'Unclaimed' }
];

const CouponsCard = () => {
    const [couponCode, setCouponCode] = useState('');
    const [amount, setAmount] = useState('');
    const [brand, setBrand] = useState(null);
    const [brands, setBrands] = useState([]); // State to hold brand data
    const [status, setStatus] = useState(null);
    const [file, setFile] = useState(null); // State to hold the selected file
    const [isImporting, setIsImporting] = useState(false); // State to manage the button disabled state
    const { notifySuccess, notifyWarning, notifyInfo } = useToast();
    const [endDate, setEndDate] = useState('');
    const [couponCount, setCouponCount] = useState('');
    const [couponMRP, setCouponMRP] = useState('');



    useEffect(() => {
        const loadBrands = async () => {
            try {
                const fetchedBrands = await fetchAllCategories(); // This function should fetch brand data
                const brandOptions = fetchedBrands.map(brand => ({
                    value: brand.shortcode, // Assuming 'shortcode' is a unique identifier
                    label: brand.name,
                    image: brand.image,
                    description: brand.description,
                }));
                setBrands(brandOptions);
            } catch (error) {
                console.error('Failed to fetch brands:', error);
                notifyWarning('Failed to load brands');
            }
        };

        loadBrands();
    }, []);

    const clearForm = () => {
        setCouponCode('');
        setAmount('');
        setBrand(null);
        setStatus(null);
        setFile(null);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file || !brand || !status) {
            notifyWarning('File, Brand, and Status are required!');
            return;
        }

        setIsImporting(true);
        notifyInfo('Coupon import started...');

        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            const expiryTimestamp = new Date(endDate).getTime();
            const convertedTime = expiryTimestamp.toString()

            try {
                for (const row of jsonData) {
                    const { coupon_code, amount, couponMRP } = row;
                    const discountedAmount = parseFloat(amount) * 0.75;

                    // console.log('this is type of code ===', coupon_code, amount, couponMRP)

                    
                    await addCoupon(
                        brand.value,
                        parseFloat(coupon_code),
                        parseFloat(amount),
                        status.value,
                        brand.image,
                        couponCount,
                        discountedAmount,
                        convertedTime,
                        brand.description,
                    );
                }
                clearForm();
                notifySuccess('Coupons Imported Successfully!');
            } catch (error) {
                console.error('Failed to add coupons:', error);
                notifyWarning('Failed to import coupons');
            } finally {
                setIsImporting(false);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     if (!file || !brand || !status) {
    //         notifyWarning('File, Brand, and Status are required!');
    //         return;
    //     }

    //     setIsImporting(true);
    //     notifyInfo('Coupon import started...');

    //     const reader = new FileReader();
    //     reader.onload = async (e) => {
    //         const data = new Uint8Array(e.target.result);
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         const sheetName = workbook.SheetNames[0];
    //         const worksheet = workbook.Sheets[sheetName];
    //         const jsonData = XLSX.utils.sheet_to_json(worksheet);

    //         const expiryTimestamp = new Date(endDate).getTime();
    //         const convertedTime = expiryTimestamp.toString();

    //         try {
    //             for (const row of jsonData) {
    //                 const { coupon_code, amount, couponMRP } = row;

    //                 // Calculate the 25% discount
    //                 const discountedAmount = parseFloat(amount) * 0.75;

    //                 console.log('this is type of code ===', typeof discountedAmount)

    //                 await addCoupon(
    //                     brand.value,
    //                     parseFloat(coupon_code),
    //                     parseFloat(amount),  // Apply the discounted amount here
    //                     status.value,
    //                     brand.image,
    //                     couponCount,
    //                     discountedAmount,
    //                     convertedTime,
    //                     brand.description,
    //                 );
    //             }
    //             clearForm();
    //             notifySuccess('Coupons Imported Successfully!');
    //         } catch (error) {
    //             console.error('Failed to add coupons:', error);
    //             notifyWarning('Failed to import coupons');
    //         } finally {
    //             setIsImporting(false);
    //         }
    //     };
    //     reader.readAsArrayBuffer(file);
    // };

    const handleCouponCount = (e) => {
        setCouponCount(e.target.value);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Coupons</Card.Title>
                    <div className="form-group">
                        <div className="col-auto">
                            <Link to="/add-coupons">
                                <button type="button" className="btn btn-success">Download sample file from here!</button>
                            </Link>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6} md={12}>
                                <Form.Group>
                                    <Form.Label>Import File</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={handleFileChange}
                                        disabled={isImporting}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={12}>
                                <Form.Group>
                                    <Form.Label>Brand</Form.Label>
                                    <Select
                                        options={brands}
                                        className="custom-react-select"
                                        value={brand}
                                        onChange={setBrand}
                                        isDisabled={isImporting}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={6} md={12}>
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Select
                                        options={statuses}
                                        className="custom-react-select"
                                        value={status}
                                        onChange={setStatus}
                                        isDisabled={isImporting}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={12}>
                                <Form.Group>
                                    <Form.Label>Coupon Count</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Coupon Count"
                                        value={couponCount}
                                        onChange={handleCouponCount}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={6} md={12}>
                                <Form.Group>
                                    <Form.Label>Coupon Expiry Time</Form.Label>
                                    <div>
                                        <DatePicker
                                            selected={endDate} onChange={(date) => setEndDate(date)}
                                            placeholderText="Select Expiry Time"
                                            className="form-control"
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="success" disabled={isImporting}>
                                    {isImporting ? 'Importing...' : 'Import Coupons'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default CouponsCard;
