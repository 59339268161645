import CryptoJS from 'crypto-js';

// Encryption key (should be stored securely in a real-world scenario)
const ENCRYPTION_KEY = process.env.ENCRYPTION_KEY;

// Encryption function
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
};

// Decryption function
export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, ENCRYPTION_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
