import React, { useState } from "react";
import { Card, Form, Button, Table, Row, Col } from "react-bootstrap";
import { IoReturnDownForward } from "react-icons/io5";

const rowNames = ["Dashboard", "Coin Listing", "User Management", "Orders", "Withdraw", "Commision or Fee", "Airdrop"];

const subTabsContent = {
    "Dashboard": ["Sub-Tab 1A", "Sub-Tab 2A"],
    "Coin Listing": ["Add/Edit New Coins", "List Own Coins", "List Market Coins"],
    "User Management": ["Auto Approved", "Partial Filled", "Approved By Admin", "All Users"],
    "Orders": [],
    "Withdraw": [],
    "Commision or Fee": ["Add Tax", "List Tax"],
    "Airdrop": []
};

const ToggleRow = ({ label, isActive, onToggle }) => (
    <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>{label}</h4>
        <div>
            <button style={{ marginRight: '16px' }} className={`btn ${isActive ? 'btn-success' : 'btn-secondary'}`} onClick={onToggle}>
                {isActive ? 'On' : 'Off'}
            </button>
        </div>
    </div>
);


const AddRole = () => {
    const [roleName, setRoleName] = useState("");
    const [toggles, setToggles] = useState(Array(rowNames.length).fill(false));
    const [selectedTab, setSelectedTab] = useState(null);
    const [subTabToggles, setSubTabToggles] = useState({});

    const handleToggle = index => {
        const newToggles = [...toggles];
        newToggles[index] = !newToggles[index];
        setToggles(newToggles);
        if (newToggles[index]) {
            setSelectedTab(rowNames[index]);
        } else if (selectedTab === rowNames[index]) {
            setSelectedTab(null);
        }
    };

    const handleSubTabToggle = subTab => {
        setSubTabToggles(prevState => ({
            ...prevState,
            [subTab]: !prevState[subTab]
        }));
    };

    const handleSubmit = () => {
        const selectedTabs = toggles.reduce((selected, toggle, index) => {
            if (toggle) {
                const tabName = rowNames[index];
                const subTabs = subTabsContent[tabName];
                selected.push({
                    tabName,
                    subTabs,
                    isVisible: true
                });
            }
            return selected;
        }, []);
        console.log("Role Name:", roleName);
        console.log("Selected Tabs and Sub-Tabs:", selectedTabs);
    };
    

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <h4 className="card-title">Add Role</h4>
                    </Card.Header>
                    <Card.Body>
                        <div className="basic-form">
                            <form
                                className="d-flex align-items-center flex-wrap"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <div className="row mb-3">
                                    <label className="col-sm-5 col-form-label text-white">Role Name</label>
                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" placeholder="Enter Role Name" value={roleName} onChange={(e) => setRoleName(e.target.value)} />
                                    </div>
                                </div>

                            </form>
                        </div>
                    </Card.Body>
                    <Row>
                        <Col lg={6}>
                            <Card className="bg-gradient">
                                <div className="card-header border-0">
                                    <h4 className="heading">Select Tabs</h4>
                                </div>
                                <div className="card-body pt-0">
                                    {rowNames.map((name, index) => (
                                        <ToggleRow
                                            key={index}
                                            label={name}
                                            isActive={toggles[index]}
                                            onToggle={() => handleToggle(index)}
                                        />
                                    ))}
                                </div>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card className="bg-gradient">
                                <div className="card-header border-0">
                                    <h4 className="heading">Select Sub-Tabs of {selectedTab}</h4>
                                </div>
                                <div className="card-body pt-0">
                                    {selectedTab && subTabsContent[selectedTab].length > 0 ? (
                                        subTabsContent[selectedTab].map((subTab, index) => (
                                            <ToggleRow
                                                key={index}
                                                label={subTab}
                                                isActive={subTabToggles[subTab] || false}
                                                onToggle={() => handleSubTabToggle(subTab)}
                                            />
                                        ))
                                    ) : (
                                        <p className="text-center">No sub-tabs available{selectedTab ? ` for ${selectedTab}` : ''}</p>
                                    )}
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Button onClick={handleSubmit}>Submit</Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};


export default AddRole;
