import React, { useState } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import useToast from "../Toaster/Toaster";
import { addAppCharges } from "../../../apolloClientConnection/apolloClient";
import swal from "sweetalert";


const AddCharges = () => {
    // State variables for each input field
    const [brokerageFee, setBrokerageFee] = useState('');
    const [tds, setTds] = useState('');
    const [gst, setGst] = useState('');
    const { notifySuccess, notifyError, notifyWarning } = useToast();


    const handleClear = () => {
        setBrokerageFee('')
        setTds('')
        setGst('')
    }

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        if (!brokerageFee || !tds || !gst) {
            notifyWarning('All fields are required!');
            return;
        }

        // SweetAlert confirmation dialog
        const willSubmit = await swal({
            title: "Are you sure?",
            text: "Do you want to change the App Charges?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });
        if (willSubmit) {

            try {
                const data = await addAppCharges(parseFloat(brokerageFee), parseFloat(tds), parseFloat(gst));

                if (data?.addAppChargesPlans.success) {
                    notifySuccess('Added Token Successfully!');
                    handleClear()
                } else {
                    notifyError('Failed to add token');
                }
            } catch (error) {
                notifyError('An error occurred while adding charges');
                console.error('Error adding charges:', error);
            }
        } else {
            notifyWarning("ICO Text change canceled.");
        }
    }

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Add Brokerage Charges</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mt-3 mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Brokerage Fees</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Charges"
                                        value={brokerageFee}
                                        onChange={e => setBrokerageFee(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>TDS</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter TDS"
                                        value={tds}
                                        onChange={e => setTds(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter GST"
                                        value={gst}
                                        onChange={e => setGst(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Button type="submit" variant="success">Submit</Button>
                                <Button className="ms-2" variant="danger" onClick={handleClear}>Clear</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default AddCharges;
