import React, { useState } from "react";
import {
    Col,
    Card,
    Table,
    Dropdown,
} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Select from 'react-select';

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]


const ListOwnCoin = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);

    // Sample data
    const data = [
        {
            coinName: "ZCash",
            coinCode: "CCD",
            coinLTP: "77.00",
            status: "Live",
        },
        {
            coinName: "Monero",
            coinCode: "HLD",
            coinLTP: "34.00",
            status: "Off",
        },
    ];

    // SVG Icon
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill="#FFFFFF" d="M3,21.5 L3.5,17.5 L14.5,6.5 C14.8978,6.10218 15.4374,5.87868 16,5.87868 C16.2786,5.87868 16.5541,5.93355 16.8111,6.04016 C17.0681,6.14678 17.303,6.30356 17.5,6.5 C17.697,6.69644 17.8538,6.93131 17.9604,7.18832 C18.067,7.44534 18.1219,7.72077 18.1219,7.99935 C18.1219,8.56196 17.8984,9.10155 17.5006,9.49935 L6.5,20.5 L2.5,21 L3,21.5 Z M5.06066,19 L14.0607,10 L17,12.9393 L8,21.9393 L5.06066,19 Z M16.5,9.5 L19.5,6.5 L17,4 L14,7 L16.5,9.5 Z"/>
        </svg>
      );
      
      




    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = (filter === '') ? data : data.filter(item =>
        item.coinName.toString().includes(filter) ||
        item.coinCode.toString().includes(filter) ||
        item.coinLTP.includes(filter) ||
        item.status.includes(filter)

    );

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>List Own Coin</Card.Title>
                    <div className="form-group  col-md-1">
                                <Select
                                    options={optionValue}
                                    value={{ value: perPage, label: perPage.toString() }}
                                    onChange={handlePerPageChange}
                                    className="custom-react-select"
                                />
                            </div>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Coin Name</strong>
                                </th>
                                <th>
                                    <strong>Coin Code</strong>
                                </th>
                                <th>
                                    <strong>Coin LTP</strong>
                                </th>

                                <th>
                                    <strong>Status</strong>
                                </th>

                                <th> <strong>Action</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(item => (
                                <tr key={item.coinName}>
                                    <td>
                                        <strong>{item.coinName}</strong>
                                    </td>
                                    <td>{item.coinCode} </td>
                                    <td>{item.coinLTP}</td>
                                    <td>
                                        <span className={`badge light badge-${item.status === 'Live' ? 'success' : 'danger'}`}>{item.status}</span>
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Link to="/add-Coin">
                                                <Dropdown.Toggle
                                                    variant={item.status === 'Live' ? 'success' : 'danger'}
                                                    className="light sharp i-false"
                                                >
                                                    {svg1}
                                                </Dropdown.Toggle>
                                            </Link>
                                            {/* <Dropdown.Menu>
                                                <Dropdown.Item>Edit</Dropdown.Item>
                                                <Dropdown.Item>Delete</Dropdown.Item>
                                            </Dropdown.Menu> */}
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>
                      
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </Card.Body>
            </Card>
        </Col>
    );
}

export default ListOwnCoin;
