import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cancelOrder, fetchUserProfile } from "../../../apolloClientConnection/apolloClient";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import useToast from "../Toaster/Toaster";
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from "../UserManagement/apis";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
];

const selctTypeOption = [
    { value: 'PARTIAL_ORDER', label: 'Partially-Cancel' },
    { value: 'OPEN_ORDER', label: 'Canceled Order' },
    { value: 'All', label: 'All' },
];

const AllCancelled = () => {
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filter, setFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState('All');
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);
    const [userProfiles, setUserProfiles] = useState({});
    const { notifyError, notifySuccess } = useToast();
    const navigate = useNavigate();

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleTypeChange = (selectedOption) => {
        setTypeFilter(selectedOption.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
    };

    useEffect(() => {
        fetchUserBuySell();
    }, []);

    const fetchUserBuySell = async () => {
        setLoading(true);

        try {
            const data = await cancelOrder();
            console.log('Fetched orders:', data);
            if (data && data.deleteTradeOrderList) {
                setOrders(data.deleteTradeOrderList);
                await fetchUserProfileDetails(data.deleteTradeOrderList);
            } else {
                setOrders([]);
            }
            setHasError(false);
        } catch (error) {
            console.error("Error fetching orders:", error);
            setOrders([]);
            notifyError("Failed to fetch data");
            setHasError(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserProfileDetails = async (ordersList) => {
        const profileRequests = ordersList.map(async (order) => {
            const profileRes = await fetchUserProfile({ PK: order.PK });
            if (profileRes.success) {
                const userProfile = JSON.parse(profileRes.profile);
                setUserProfiles(prevState => ({
                    ...prevState,
                    [order.PK]: userProfile
                }));
                return { ...order, profile: userProfile };
            }
            return order;
        });

        Promise.all(profileRequests).then(completedOrders => {
            setOrders(completedOrders);
        });
    };

    const filteredOrders = orders.filter(order => {
        const matchesFilter = Object.values(order).some(
            value =>
                typeof value === "string" &&
                value.toLowerCase().includes(filter.toLowerCase())
        );
        const matchesType = typeFilter === 'All' || order.orderType === typeFilter;
        return matchesFilter && matchesType;
    });



    const handleRowClick = (pk, sk) => {
        localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
        navigate("/user-data");
    };

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    
                    <Card.Header >
                        <Card.Title>Cancel Orders</Card.Title>
                        <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col md={6} className="d-flex align-items-center">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                                <div>
                                    <Select
                                        className="custom-react-select ms-2"
                                        placeholder='Select Type'
                                        options={selctTypeOption}
                                        value={selctTypeOption.find(option => option.value === typeFilter)}
                                        onChange={handleTypeChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="d-flex align-items-center">
                                <div className="mr-2">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                </div>
                                <div className="ms-2">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control"
                                        placeholderText="End Date"
                                    />
                                </div>
                                <button className="btn btn-primary ms-2" onClick={handleSearch}>Search</button>
                            </Col>
                        </Row>
                        <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Mail/Phone</th>
                                    <th>Coin Name</th>
                                    <th>Pair</th>
                                    <th>OrderID</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                    <th>Date</th>
                                    <th><span className="text-success">Buy</span>/<span className="text-danger">Sell</span></th>
                                    <th>Trding Type</th>
                                    <th>Order Type</th>
                                    {/* <th>Status</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                ) : hasError ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) : !hasData || filteredOrders?.length === 0 ? (
                                    <tr>
                                        <td colSpan="12" className="text-center">
                                            <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                        </td>
                                    </tr>
                                ) : (filteredOrders?.map((order, index) => (
                                    <tr key={index}>
                                        <td>{order.profile?.full_name || 'Guest'}</td>
                                        <td>
                                            <div style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }}  onClick={() => handleRowClick(order.PK, userProfiles[order.PK]?.SK)}>
                                                {userProfiles[order.PK]?.phone || 'N/A'}
                                                {userProfiles[order.PK]?.email && <div>{userProfiles[order.PK]?.email}</div>}
                                            </div>
                                        </td>
                                        <td>{order.shareName}</td>
                                        <td>{order.coinPairWith}</td>
                                        <td className="color-primary">{order.orderId}</td>
                                        <td className="color-primary">{(order.quantity)?.toFixed(2)}</td>
                                        <td className="color-primary">₹ {order.price}</td>
                                        <td className="color-primary">₹ {(order.quantity * order.price)?.toFixed(2)}</td>
                                        <td>{order?.date ? order?.date : formatDateTime(order.createdAt)}</td>
                                        <td>
                                            <span className={`badge light badge-${order.beforeDeleteType === "BUY" ? "success" : "danger"}`}>
                                                {order.beforeDeleteType}
                                            </span>
                                        </td>
                                        <td>{order.tradingType}</td>
                                        <td className={`text-${order.orderType === 'OPEN_ORDER' ? 'success' : 'warning'}`}>{order.orderType === 'PARTIAL_ORDER' ? 'PARTIAL_CANCELLED' : order.orderType}</td>
                                    </tr>
                                )))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default AllCancelled;
