import React, { useState, useEffect } from "react";
import {
    Col,
    Card,
    Table,
    Button,
    Dropdown
} from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import { getAllPurchasedVoucher, fetchUserProfile, reverseVoucher } from "../../../apolloClientConnection/apolloClient";
import { formatDateTime } from "../UserManagement/apis";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import * as XLSX from "xlsx";
import { MdOutlineReplay } from "react-icons/md";
import useToast from "../Toaster/Toaster";


// const optionValue = [
//     { value: 10, label: '10' },
//     { value: 20, label: '20' },
//     { value: 25, label: '25' },
//     { value: 30, label: '30' },
// ];

const UserVoucherList = () => {
    const [filter, setFilter] = useState('');
    // const [perPage, setPerPage] = useState(25);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [noData, setNoData] = useState(false);
    const navigate = useNavigate();
    const { notifySuccess } = useToast();


    const fetchData = async () => {
        try {
            const voucherData = await getAllPurchasedVoucher();
            if (voucherData && voucherData.items) {
                const detailedData = await Promise.all(voucherData.items.map(async (item) => {
                    const userPK = item.PK.split("#").slice(0, 2).join("#");
                    const userProfile = await fetchUserProfile({ PK: userPK, SK: '' });
                    const userData = userProfile.profile ? JSON.parse(userProfile.profile) : 'N/A';
                    return {
                        ...item,
                        userData,
                    };
                }));

                setData(detailedData);
                setNoData(detailedData.length === 0);
            } else {
                setNoData(true);
            }
        } catch (error) {
            setNetworkError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    // const handlePerPageChange = (selectedOption) => {
    //     setPerPage(selectedOption.value);
    // };

    const handleRowClick = (pk, sk) => {
        localStorage.setItem('dataPKSK', JSON.stringify({ PK: pk, SK: sk }));
        navigate("/user-data");
    };

    const downloadExcel = () => {
        if (!data || data.length === 0) {
            return;
        }

        // Transform the data for Excel export
        const dataToDownload = data.map(user => {
            // Ensure that the coupon code is clean and consistent
            const cleanCouponCode = user.coupon_code ? String(user.coupon_code).trim() : 'N/A';

            return {
                Name: user.userData?.full_name || 'Guest',
                MobileNo: user.userData?.phone || 'N/A',
                Email: user.userData?.email || 'N/A',
                Coupon_Code: cleanCouponCode,
                MRP: user.CouponMRP || 'N/A',
                Amount: user.amount || 'N/A',
                Date_Time: formatDateTime(user.createdAt) || 'N/A',
            };
        });

        console.log('Data to be downloaded:', dataToDownload);

        // Create worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
        const workbook = XLSX.utils.book_new();

        // Set the column widths for the worksheet
        worksheet['!cols'] = [
            { wch: 20 }, // Name
            { wch: 15 }, // MobileNo
            { wch: 27 }, // Email
            { wch: 18 }, // Coupon Code
            { wch: 7 }, // MRP
            { wch: 7 }, // Amount
            { wch: 20 }, // Date Time
        ];

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Voucher");

        // Export the Excel file
        XLSX.writeFile(workbook, "UsersVoucherBuyData.xlsx");
    };



    const handleReverse = async (item) => {


        const reverseData = [{
            PK: item.PK,
            SK: item.SK,
            createdAt: item.createdAt
        }]

        try {
            const data = await reverseVoucher(reverseData)
            if (data) {
                notifySuccess('Reverse Data Successfull')
                fetchData();
            }
        } catch (error) {
            console.error('error reversing data', error)
        }
    }


    return (
        <Col lg={12}>
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title>User Voucher Buy List</Card.Title>
                    <div className="d-flex align-items-center">
                        <Button className="me-2" variant="success" onClick={downloadExcel}>
                            <span className="btn-icon-start text-success">
                                <i className="fa fa-file color-success" />
                            </span>
                            Download
                        </Button>
                    </div>
                </Card.Header>

                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th className="text-center">Phone/Email</th>
                                <th className="text-center">Coupon Code</th>
                                <th className="text-center">Coupon MRP</th>
                                <th className="text-center">Amount</th>
                                <th className="text-center">Date&Time</th>
                                <th className="text-center">Reverse</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            )}
                            {networkError && (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        <p>Connect To SuperAdmin</p>
                                    </td>
                                </tr>
                            )}
                            {noData && !loading && !networkError && (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            )}
                            {!loading && !networkError && data.length > 0 && data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td >
                                        {item?.userData?.full_name ? item.userData.full_name : 'Guest'}
                                    </td>
                                    <td className="text-center">
                                        <div
                                            style={{ cursor: 'pointer', color: 'rgb(113, 113, 255)' }}
                                            onClick={() => handleRowClick(item.userData.PK, item.userData.SK)}
                                        >
                                            {item?.userData.phone ? item.userData.phone : 'N/A'}
                                            {item?.userData.email && <div>{item.userData.email}</div>}
                                        </div>
                                    </td>
                                    <td className="text-center">{item.coupon_code}</td>
                                    <td className="text-center"><span>₹</span> {item.CouponMRP}</td>
                                    <td className="text-center"><span>₹</span> {item.amount}</td>
                                    <td className="text-center">{formatDateTime(item.createdAt)}</td>
                                    <td className="text-center">
                                        <Dropdown>
                                            <a onClick={() => handleReverse(item)}>
                                                <Dropdown.Toggle
                                                    variant={'success'}
                                                    className="light sharp i-false"
                                                >
                                                    <MdOutlineReplay />
                                                </Dropdown.Toggle>
                                            </a>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">Showing <span>1-4</span> from <span>30</span> data </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active">
                                    <Link to={"#"} className="page-link">1</Link>
                                </li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default UserVoucherList;
