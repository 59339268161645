import React, { useState, useEffect, useCallback } from "react";
import { Col, Card, Table, Dropdown, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CouponsCard from "./CouponsCard";
import { deleteCoupon, getAllCoupons, deletAllCoupoun } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { debounce } from "../UserManagement/debounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ListCoupons = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(300);
    const [coupons, setCoupons] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const { notifySuccess, notifyWarning, notifyError } = useToast();
    const [loading, setLoading] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [noData, setNoData] = useState(false);
    const [pageKeys, setPageKeys] = useState({});
    const [endDate, setEndDate] = useState(null);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [startDate, setStartDate] = useState(null);


    const debouncedFetchUsers = useCallback(debounce((filterValue) => {
        const { phone, email } = determineSearchType(filterValue);
        fetchCoupons(currentPage, phone, email, startDate, endDate);
    }, 500), [currentPage, startDate, endDate]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        debouncedFetchUsers(event.target.value);
    };


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };


    const formatTimestamp = (timestamp) => {
        return new Date(timestamp).getTime(); // Convert to timestamp
    };


    const fetchCoupons = async (page, phone = null, email = null, startDate = null, endDate = null) => {

        const lastKey = pageKeys[page - 1];

        try {
            setLoading(true); // Start loading
            setNetworkError(false); // Reset network error state
            const response = await getAllCoupons(perPage, lastKey?.PK, lastKey?.SK, lastKey?.createdAt, lastKey?.type, phone, email, startDate ? formatTimestamp(startDate) : null, endDate ? formatTimestamp(endDate) : null);
            console.log('Fetched coupons:', response.getAllcoupons);
            if (response.getAllcoupons.items.length === 0) {
                setNoData(true); // Show no data animation if response has no coupons
            } else {
                setCoupons(response.getAllcoupons.items);
                const newPageKeys = {
                    [page]: response.getAllcoupons.lastEvaluatedKey || null,
                };
                setPageKeys(newPageKeys);
                setPageKeys(response.getAllcoupons.lastEvaluatedKey);

            }
        } catch (error) {
            console.error('Error fetching coupons:', error);
            setNetworkError(true); // Show network error if there's an error
        } finally {
            setLoading(false); // Stop loading regardless of success or failure
        }
    };

    useEffect(() => {

        fetchCoupons(currentPage);
    }, [currentPage, perPage]);


    const goToPage = (number) => {
        setCurrentPage(number);
    };


    const determineSearchType = (filter) => {
        const phoneRegex = /^[0-9]+$/;
        if (phoneRegex.test(filter)) {
            return { phone: filter, email: null };
        } else {
            return { phone: null, email: filter };
        }
    };



    const handleDeleteCoupon = async (coupon) => {
        try {
            const deleteResponse = await deleteCoupon(coupon.PK, coupon.SK);
            console.log("Coupon deleted successfully", deleteResponse);
            if (deleteResponse.deleteFromCouponTbl === true) {
                const updatedCoupons = coupons.filter(c => c.PK !== coupon.PK);
                setCoupons(updatedCoupons);
                notifySuccess('Coupon deleted successfully!');
            } else {
                notifyWarning('Coupon not found or already deleted!');
            }
        } catch (error) {
            console.error('Error deleting coupon:', error);
            notifyError('Error deleting coupon. Please try again.');
        }
    };

    const handleDeleteAllCoupons = async () => {
        try {
            const deleteAllResponse = await deletAllCoupoun();
            console.log("All coupons deleted successfully", deleteAllResponse);
            if (deleteAllResponse && deleteAllResponse.deleteAllCoupons) {
                setCoupons([]);
                setNoData(true); // Show no data animation if response has no coupons
                notifySuccess('All coupons deleted successfully!');
            } else {
                notifyWarning('No coupons found or already deleted!');
            }
        } catch (error) {
            console.error('Error deleting all coupons:', error);
            notifyError('Error deleting all coupons. Please try again.');
        }
    };

    const handleEditCoupon = (coupon) => {
        console.log('this is edit button', coupon);
        navigate('/add-coupons', { state: { coupon } });
    };

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFFFFF" d="M3,21.5 L3.5,17.5 L14.5,6.5 C14.8978,6.10218 15.4374,5.87868 16,5.87868 C16.2786,5.87868 16.5541,5.93355 16.8111,6.04016 C17.0681,6.14678 17.303,6.30356 17.5,6.5 C17.697,6.69644 17.8538,6.93131 17.9604,7.18832 C18.067,7.44534 18.1219,7.72077 18.1219,7.99935 C18.1219,8.56196 17.8984,9.10155 17.5006,9.49935 L6.5,20.5 L2.5,21 L3,21.5 Z M5.06066,19 L14.0607,10 L17,12.9393 L8,21.9393 L5.06066,19 Z M16.5,9.5 L19.5,6.5 L17,4 L14,7 L16.5,9.5 Z" />
        </svg>
    );

    const svg2 = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    const optionValue = [
        { value: 50, label: "50" },
        { value: 100, label: "100" },
        { value: 200, label: "200" },
        { value: 300, label: "300" },
    ];


    const handleNextPage = () => {
        if (hasMoreData && pageKeys[currentPage]) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
        }
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        setCurrentPage(1);
        fetchCoupons(1);
    };

    const handleSearch = () => {

        fetchCoupons(1, filter ? determineSearchType(filter).phone : null, filter ? determineSearchType(filter).email : null, startDate, endDate);
    };


    const downloadExcel = () => {
        console.log('this is download excel')
    }




    return (
        <Col lg={12}>
            <CouponsCard />
            <Card>
                <Card.Header>
                    <Card.Title>Coupons</Card.Title>
                    <div className="d-flex justify-content-start align-items-center">
                        <Link to="/add-coupons">
                            <button type="submit" className="btn btn-success me-2">Add Coupon</button>
                        </Link>
                        <button type="button" className="btn btn-danger me-2" onClick={handleDeleteAllCoupons}>Delete All Coupons</button>
                        <Button className="me-2" variant="success" onClick={downloadExcel}>
                            <span className="btn-icon-start text-success">
                                <i className="fa fa-file color-success" />
                            </span>
                            Download
                        </Button>
                        <Select
                            options={optionValue}
                            value={{ value: perPage, label: perPage.toString() }}
                            onChange={handlePerPageChange}
                            className="custom-react-select"
                            style={{ width: '200px' }} // Adjust width as necessary
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="justify-content-between mb-4">
                        <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Filter..."
                                    value={filter}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </Col>
                        <Col
                            lg={6}
                            className="d-flex align-items-center justify-content-lg-end"
                        >
                            <div className="d-flex align-items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control"
                                    placeholderText="Start Date"
                                />
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control ms-2"
                                    placeholderText="End Date"
                                />
                                <button className="btn btn-primary ms-3" onClick={handleSearch}>
                                    Search
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>
                                    S.No
                                </th>
                                <th className="width80">
                                    <strong>Coupon Code</strong>
                                </th>
                                <th className="text-center">
                                    <strong>MRP</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Amount</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Status</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Brand</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Action</strong>
                                </th>
                                <th className="text-start d-none">
                                    <strong>ID</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            )}
                            {networkError && (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        <p>Connect To SuperAdmin</p>
                                    </td>
                                </tr>
                            )}
                            {noData && (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            )}
                            {!loading && !networkError && !noData && (
                                coupons?.map((coupon, index) => (
                                    <tr key={index}>
                                        <td>{(currentPage - 1) * perPage + index + 1}</td>
                                        <td>
                                            <strong>{coupon.coupon_code}</strong>
                                        </td>
                                        <td className="text-center"><span>₹</span> {coupon.CouponMRP}</td>
                                        <td className="text-center"><span>₹</span> {coupon.amount}</td>
                                        <td className="text-center">{coupon.isClaimed == false ? 'Unclaimed' : 'Claimed'}</td>
                                        <td className="text-center">{coupon.category_name}</td>
                                        <td className="text-center">
                                            <Dropdown>
                                                <a onClick={() => handleEditCoupon(coupon)}>
                                                    <Dropdown.Toggle
                                                        variant={'success'}
                                                        className="light sharp i-false"
                                                    >
                                                        {svg1}
                                                    </Dropdown.Toggle>
                                                </a>
                                                <Dropdown.Toggle
                                                    variant={'danger'}
                                                    className="light sharp i-false ms-3"
                                                    onClick={() => handleDeleteCoupon(coupon)} // Pass the correct coupon ID
                                                >
                                                    {svg2}
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </td>
                                        <td className="text-start d-none">{coupon.PK}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">
                            Showing <span> 1-4 </span> from <span> 30 </span> data{" "}
                        </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li
                                    className={`page-item page-indicator ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <Link
                                        to={"#"}
                                        className="page-link"
                                        onClick={(e) => {
                                            if (currentPage === 1) {
                                                e.preventDefault();
                                            } else {
                                                handlePreviousPage();
                                            }
                                        }}
                                    >
                                        <i
                                            className="fa-solid fa-angle-left"
                                            style={{
                                                color: currentPage === 1 ? "#cccccc" : "inherit",
                                            }}
                                        ></i>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link to={"#"} className="page-link">
                                        {currentPage}
                                    </Link>
                                </li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link" onClick={handleNextPage}>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ListCoupons;
