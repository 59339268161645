export const MenuList = [
    {
        title: 'Dashboard',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">grid_view</i>,
        content: [
            {
                title: 'Dashboard 1',
                to: 'dashboard-1',
            },
        ],
    },
    {
        title: 'User Management',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">account_circle</i>,
        content: [
            {
                title: 'Auto Approved',
                to: 'auto-approved',
            }, {
                title: 'Partial Filled',
                to: 'partial-filled',
            }, {
                title: 'Approved by Admin',
                to: 'approved-by-admin',
            },
            {
                title: 'All Users',
                to: 'all-Users',
            }
        ],
    },
    {
        title: 'Add/Edit New Coins',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">monetization_on</i>,
        content: [
            {
                title: 'Add/Edit New Coins',
                to: 'add-Coin',
            }, {
                title: 'List Own Coins',
                to: 'List-Coin',
            }, {
                title: 'List Market Coins',
                to: 'listMarketCoin',
            },
        ],
    },
    {
        title: 'Admin Wallet',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">account_balance_wallet</i>,
        content: [
            {
                title: 'Withdraw',
                to: 'with-draw',
            },
            // {
            //     title: 'INR Wallet',
            //     to: 'inr-wallet',
            // },
            {
                title: 'Brokerage Wallet',
                to: 'brokerage-wallet',
            },
            {
                title: 'GST Wallet',
                to: 'gst-wallet',
            },
            {
                title: 'TDS Wallet',
                to: 'tds-wallet',
            },
            {
                title: 'CCD Token Wallet',
                to: 'token-wallet',
            },
            // {
            //     title: 'TDS Deduction',
            //     to: 'tDS-deduction',
            // }
        ],
    },
    {
        title: 'Airdrop',
        iconStyle: <i><span class="material-symbols-outlined">cloud_upload</span></i>,
        to: 'airdrop',
    },
    {
        title: 'App Charges',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">percent</i>,
        content: [
            {
                title: 'Add Charges',
                to: 'add-charges',
            },
            {
                title: 'List Charges',
                to: 'list-charges',
            }
        ],
    },
    {
        title: 'Bank Details',
        iconStyle: <i><span class="material-symbols-outlined">account_balance</span></i>,
        to: 'all-bankDetails',
    },
    {
        title: 'Banner',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">tune</i>,
        content: [
            {
                title: 'Add Banner',
                to: 'add-banner',
            },
            {
                title: 'List Banner',
                to: 'banner-list',
            }
        ],
    },
    {
        title: 'Brand',
        iconStyle: <i><span class="material-symbols-outlined">loyalty</span></i>,
        to: 'list-brand',
    },
    {
        title: 'Change Password',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">vpn_key</i>,
        content: [
            {
                title: 'Admin',
                to: 'admin-password',
            },
            {
                title: 'Super Admin',
                to: 'superAdmin-password',
            }
        ],
    },
    // {
    //     title: 'Chat',
    //     iconStyle: <i><span class="material-symbols-outlined">chat</span></i>,
    //     to: 'chat-message',
    // },
  
    // {
    //     title: 'Coin Supply',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">repeat_on</i>,
    //     content: [
    //         {
    //             title: 'Add Supply',
    //             to: 'add-supply',
    //         },
    //         {
    //             title: 'Supply List',
    //             to: 'supply-list',
    //         }
    //     ],
    // },
    // {
    //     title: 'Commission or Fee',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="material-icons">attach_money</i>,
    //     content: [
    //         {
    //             title: 'Add Tax',
    //             to: 'add-tax',
    //         }, {
    //             title: 'List Tax',
    //             to: 'list-tax',
    //         }
    //     ],
    // },
    // {
    //     title: 'Control',
    //     iconStyle: <i><span class="material-symbols-outlined">toggle_on</span></i>,
    //     to: 'control',
    // },
    {
        title: 'Control Tabs',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">control_point</i>,
        content: [
            {
                title: 'Add Tabs/SubTabs',
                to: 'add-Tab',
            },
            {
                title: 'List Tabs/SubTabs',
                to: 'listTabs-Subtabs',
            }
        ],
    },
    {
        title: 'Create Admin',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">person_add</i>,
        content: [
            {
                title: 'Add Admin',
                to: 'create-admin',
            },
            {
                title: 'Admin List',
                to: 'admin-list',
            }
        ],
    },
    {
        title: 'Delete Items',
        iconStyle: <i><span class="material-symbols-outlined">delete</span></i>,
        to: 'delete-items',
    },
    // {
    //     title: 'Denominations',
    //     iconStyle: <i><span class="material-symbols-outlined">sort</span></i>,
    //     to: 'denomination',
    // },
    {
        title: 'Desktop',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">desktop_windows</i>,
        content: [
            {
                title: 'Contact User',
                to: 'contact-user',
            },
            {
                title: 'Web Blog',
                to: 'web-blog',
            },
            {
                title: 'Blog List',
                to: 'web-bloglist',
            }
        ],
    },
    // {
    //     title: 'Upload Deposit File',
    //     iconStyle: <i><span class="material-symbols-outlined">upload_file</span></i>,
    //     to: 'deposite-file',
    // },

    {
        title: 'Deposit',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">upload_file</i>,
        content: [
            {
                title: 'Upload',
                to: 'deposite-history',
            },
            {
                title: 'Rejected',
                to: 'rejected-history',
            },
            {
                title: 'All Data',
                to: 'all-history',
            },
        ],
    },

    {
        title: 'Download Excel',
        iconStyle: <i><span class="material-symbols-outlined">downloading</span></i>,
        to: 'client-wallet',
    },
    {
        title: 'Help & Support',
        iconStyle: <i><span class="material-symbols-outlined">support_agent</span></i>,
        to: 'help&support-list',
    },
    {
        title: 'ICO',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">style</i>,
        content: [
            {
                title: 'Mobile Data',
                to: 'ico-text',
            },
            {
                title: 'ICO List',
                to: 'ico-listing',
            },
            {
                title: 'ICO Coupon',
                to: 'ico-coupon',
            },
            {
                title: 'ICO Coupon List',
                to: 'ico-list',
            }
        ],
    },
    {
        title: 'Mobile App',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">phone_iphone</i>,
        content: [
            {
                title: 'Add Blog',
                to: 'add-blog',
            },
            {
                title: 'List Blog',
                to: 'blog-list',
            },
            {
                title: 'Header Text',
                to: 'list-header',
            },
            {
                title: 'Version',
                to: 'version',
            },
            {
                title: 'Maintenance',
                to: 'maintenance',
            },
        ],
    },
    {
        title: 'Merchent',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">desktop_windows</i>,
        content: [
            {
                title: 'Merchent Company',
                to: 'merchent-company',
            },
        ],
    },
    {
        title: 'Order',
        iconStyle: <i><span class="material-symbols-outlined">shopping_cart</span></i>,
        to: 'orders',
    },
    {
        title: 'Order Details',
        iconStyle: <i><span class="material-symbols-outlined">list_alt</span></i>,
        to: 'order-detail',
    },
    // {
    //     title: 'Order History',
    //     iconStyle: <i><span class="material-symbols-outlined">list_alt</span></i>,
    //     to: 'order-history',
    // },
    {
        title: 'Orders',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">style</i>,
        content: [
            {
                title: 'Open Orders',
                to: 'open-order',
            },
            {
                title: 'Executed Orders',
                to: 'executed-orders',
            },
            {
                title: 'Cancelled Orders',
                to: 'cancelled-orders',
            }
        ],
    },
    {
        title: 'Referral',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">change_circle</i>,
        content: [
            {
                title: 'Create Referral',
                to: 'create-referral',
            },
            {
                title: 'Referral List',
                to: 'referral-list',
            }
        ],
    },
    {
        title: 'Simplyfy Shark',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">change_circle</i>,
        content: [
            {
                title: 'All User',
                to: 'simplyfy-shark',
            },
           
        ],
    },
    {
        title: 'Template',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">pages</i>,
        content: [
            {
                title: 'Add Admin Template',
                to: 'admin-template',
            }, {
                title: 'List Admin Template',
                to: 'list-template',
            }
        ],
    },
    // {
    //     title: 'Test',
    //     iconStyle: <i><span class="material-symbols-outlined">bug_report</span></i>,
    //     to: 'test-trading',
    // },
    // {
    //     title: 'Token Holder List',
    //     iconStyle: <i><span class="material-symbols-outlined">generating_tokens</span></i>,
    //     to: 'token-holder',
    // },
    // {
    //     title: 'Token Request',
    //     iconStyle: <i><span class="material-symbols-outlined">token</span></i>,
    //     to: 'token-request',
    // },
    // {
    //     title: 'Transaction History',
    //     iconStyle: <i><span class="material-symbols-outlined">receipt_long</span></i>,
    //     to: 'transaction-history',
    // },
    {
        title: 'Voucher',
        classsChange: 'mm-collapse',
        iconStyle: <i className="material-icons">repeat_on</i>,
        content: [
            {
                title: 'Add Coupons',
                to: 'add-coupons',
            },
            {
                title: 'Coupons List',
                to: 'list-coupons',
            },
            {
                title: 'Flexible Voucher',
                to: 'flexible-list',
            },
            {
                title: 'Purchased Voucher',
                to: 'userVoucher-list',
            },
        ],
    },
    {
        title: 'Recharge',
        iconStyle: <i><span class="material-symbols-outlined">payments</span></i>,
        to: 'recharge',
    },
    // {
    //     title: 'Withdraw',
    //     iconStyle: <i><span class="material-symbols-outlined">account_balance_wallet</span></i>,
    //     to: 'with-draw',
    // },
];
