import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
} from "react-bootstrap";
import useToast from '../Toaster/Toaster';
import { fetchAllTabs } from '../../../apolloClientConnection/apolloClient';
import { addTemplate } from '../../../apolloClientConnection/apolloClient';


const ToggleRow = ({ label, isActive, onToggle }) => (
    <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>{label}</h4>
        <div>
            <button style={{ marginRight: '16px' }} className={`btn ${isActive ? 'btn-success' : 'btn-secondary'}`} onClick={onToggle}>
                {isActive ? 'On' : 'Off'}
            </button>
        </div>
    </div>
);


const AddAdminTemplate = () => {
    const [templateName, setTemplateName] = useState('');
    const [rowNames, setRowNames] = useState([]);
    const [subTabsContent, setSubTabsContent] = useState({});

    const [role, setRole] = useState('');
    const [toggles, setToggles] = useState(Array(rowNames.length).fill(false));
    const [selectedTab, setSelectedTab] = useState(null);
    const [subTabToggles, setSubTabToggles] = useState({});
    const { notifySuccess, notifyError, notifyWarning } = useToast();

    const GetAllTabs = async () => {
        try {
            const response = await fetchAllTabs();

            // Map through the response to extract and parse the tab information
            const tabsData = response.map(tab => {
                // Parse the JSON string stored in 'Tabs' to get an object with 'tab' and 'subtab'
                const tabDetails = JSON.parse(tab.Tabs);

                return {
                    PK: tab.PK,
                    SK: tab.SK,
                    tab: tabDetails.tab, // The name of the tab
                    subtab: tabDetails.subtab, // The array of sub-tabs
                };
            });

            // Set the row names (tab names) for displaying in the UI
            setRowNames(tabsData.map(tab => tab.tab));

            // Set the content of sub-tabs keyed by tab names
            const subTabsMapping = tabsData.reduce((acc, curr) => {
                acc[curr.tab] = curr.subtab;
                return acc;
            }, {});
            setSubTabsContent(subTabsMapping);

            // Reset toggles based on the new tabs data
            setToggles(Array(tabsData.length).fill(false));

        } catch (error) {
            notifyError("Failed to fetch tabs. Please try again.");
            console.error("Error fetching tabs:", error);
        }
    };


    useEffect(() => {
        GetAllTabs();
    }, []);

    const handleToggle = index => {
        const newToggles = [...toggles];
        newToggles[index] = !newToggles[index];
        setToggles(newToggles);
        if (newToggles[index]) {
            setSelectedTab(rowNames[index]);
        } else if (selectedTab === rowNames[index]) {
            setSelectedTab(null);
        }
    };

    const handleSubTabToggle = subTab => {
        setSubTabToggles(prevState => ({
            ...prevState,
            [subTab]: !prevState[subTab]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        if (!templateName || !role) {
            notifyWarning('Fill all fields!');
            return;
        }

        // Preparing the data structure according to your GraphQL mutation's needs
        const tabsData = toggles
            .map((isActive, index) => {
                if (isActive) {
                    const tabName = rowNames[index];
                    const activeSubTabs = subTabsContent[tabName]
                        .filter(subTab => subTabToggles[subTab]);
                    return { tab: tabName, subtab: activeSubTabs }; // Adjust this structure if necessary
                }
                return null;
            })
            .filter(Boolean);


        try {
            // Execute the addTemplate function with the structured data
            const result = await addTemplate(templateName, tabsData, role);

            if (result.data.AddTamplate.message == "Tamplate Added") {
                notifySuccess(`${templateName} Added Successfully!`);
            } else {
                notifyError("Something went wrong!");
            }
            // Reset form and related state after successful submission
            setTemplateName("");
            setRole("");
            setToggles(Array(rowNames.length).fill(false));
            setSelectedTab(null);
            setSubTabToggles({});
        } catch (error) {
            notifyError("Failed to add template. Please try again.");
        }
    };

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Body>
                        <form className="form-valide" >
                            <div className="row">
                                {/* Template Name Field */}
                                <div className="col-xl-6">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="templateName">
                                            Template Name
                                        </label>
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="templateName"
                                                placeholder="Enter Template Name"
                                                value={templateName}
                                                onChange={e => setTemplateName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Role Field */}
                                <div className="col-xl-6">
                                    <div className="form-group mb-3 row">
                                        <label className="col-lg-4 col-form-label" htmlFor="role">
                                            Role
                                        </label>
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="role"
                                                placeholder="Enter Role"
                                                value={role}
                                                onChange={e => setRole(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={12}>
                <Row>
                    <Col lg={6}>
                        <Card>
                            <div className="card-header border-0">
                                <h4 className="heading">Select Tabs</h4>
                            </div>
                            <div className="card-body pt-0">
                                {rowNames.map((name, index) => (
                                    <ToggleRow
                                        key={index}
                                        label={name}
                                        isActive={toggles[index]}
                                        onToggle={() => handleToggle(index)}
                                    />
                                ))}
                            </div>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <div className="card-header border-0">
                                <h4 className="heading">Select Sub-Tabs of {selectedTab}</h4>
                            </div>
                            <div className="card-body pt-0">
                                {selectedTab && subTabsContent[selectedTab].length > 0 ? (
                                    subTabsContent[selectedTab].map((subTab, index) => (
                                        <ToggleRow
                                            key={index}
                                            label={subTab}
                                            isActive={subTabToggles[subTab] || false}
                                            onToggle={() => handleSubTabToggle(subTab)}
                                        />
                                    ))
                                ) : (
                                    <p className="text-center">No sub-tabs available{selectedTab ? ` for ${selectedTab}` : ''}</p>
                                )}
                            </div>
                            <div className="card-footer d-flex justify-content-end">
                                <button type="submit" className="btn btn-success" onClick={handleSubmit}>Submit</button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default AddAdminTemplate;
