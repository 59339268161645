import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Dropdown,
    Nav
} from "react-bootstrap";
import { Link } from 'react-router-dom';
import UserNav from './UserNav';
import useToast from "../Toaster/Toaster";

import Select from 'react-select';


// Define options for the "Items Per Page" dropdown
const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

// Define the component for Deposit History
const WalletAddress = () => {
    // State variables
    const [filter, setFilter] = useState(''); // State for filter input
    const [perPage, setPerPage] = useState(25); // State for items per page
    const { notifySuccess, notifyError } = useToast();

    // Sample data for deposit history
    const data = [
        {
            S_No: 1,
            coin_name: 'BTC',
            wallet_address: 54765675657
        }
        , {
            S_No: 2,
            coin_name: 'CCD',
            wallet_address: 51765675657
        }

    ];

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            notifySuccess(`Copied to clipboard:${text}`);
        } catch (err) {
            notifyError('Failed to copy');
        }
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        console.log(event.target.value); // Add this to check what's being captured
    };


    // Filtered data based on search filter
    const filteredData = filter === '' ? data : data.filter(item =>
        item.S_No.toString().includes(filter) ||
        item.coin_name.toLowerCase().includes(filter.toLowerCase()) ||
        item.wallet_address.toString().includes(filter)
    );

    // SVG icon
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor" d="M16,1H4C2.9,1 2,1.9 2,3V17H4V3H16V1ZM20,5H8C6.9,5 6,5.9 6,7V21C6,22.1 6.9,23 8,23H20C21.1,23 22,22.1 22,21V7C22,5.9 21.1,5 20,5ZM20,21H8V7H20V21Z" />
        </svg>
    );

    // Handler for changing items per page
    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };


    // JSX return
    return (
        <div className="row">
            <div className="col-xl-12">
                <UserNav />
            </div>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>WalletAddress</Card.Title>
                        {/* Dropdown for items per page */}
                        <div className="form-group mb-3 col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col md={6} className="d-flex align-items-center ">
                                {/* Filter input */}
                                <div >
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>

                        </Row>

                        {/* Table to display deposit history */}
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>S_No.</th>
                                    <th>Coin Name</th>
                                    <th>WalletAddress</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr key={index}>
                                        <td><strong>{item.S_No}</strong></td>
                                        <td>{item.coin_name}</td>
                                        <td>{item.wallet_address}</td>
                                        <td>
                                            {/* Dropdown for actions */}
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className="light sharp i-false">
                                                    {/* This SVG icon now has an onClick handler */}
                                                    <div onClick={() => copyToClipboard(item.wallet_address)}>{svg1}</div>
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {/* Pagination */}
                        <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                            <p className="mb-0">Showing <span> 1-4 </span> from <span> 25 </span> data </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </div>
    );
}

export default WalletAddress;
