import React, { useState, useEffect } from "react";
import { Col, Card, Table, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { fetchAllBlogs, deleteItem } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json"


const optionValue = [
    { value: 15, label: "15" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
];

const BlogList = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(15);
    const [blogList, setBlogList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [pageKeys, setPageKeys] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);

    const { notifySuccess, notifyError } = useToast();
    const navigate = useNavigate();

    const fetchBlog = async (page) => {
        setIsLoading(true);
        setHasError(false);
        const lastKey = pageKeys[page - 1];


        try {
            const res = await fetchAllBlogs(perPage, lastKey?.PK, lastKey?.SK, String(lastKey?.createdAt), lastKey?.type);
            if (res.success) {
                const data = JSON.parse(res.blogs);


                const newPageKeys = {
                    ...pageKeys,
                    [page]: data.lastEvaluatedKey || null,
                };
                setPageKeys(newPageKeys);
                setBlogList(data.items);



                setHasMoreData(!!data.lastEvaluatedKey);
            } else {
                setHasMoreData(true);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setHasError(true);
        }
    };

    useEffect(() => {
        fetchBlog(currentPage);
    }, [currentPage, perPage]);

    const handleDeleteBlogList = async (PK, SK) => {
        try {
            await deleteItem('NewUser', PK, SK);
            notifySuccess("Blog deleted successfully!");
            setBlogList(prevBlogs => prevBlogs.filter(blog => !(blog.PK === PK && blog.SK === SK)));
        } catch (error) {
            console.error("Failed to delete Blog List: ", error);
            notifyError("Failed to delete Blog List!");
        }
    };

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFFFFF" d="M3,21.5 L3.5,17.5 L14.5,6.5 C14.8978,6.10218 15.4374,5.87868 16,5.87868 C16.2786,5.87868 16.5541,5.93355 16.8111,6.04016 C17.0681,6.14678 17.303,6.30356 17.5,6.5 C17.697,6.69644 17.8538,6.93131 17.9604,7.18832 C18.067,7.44534 18.1219,7.72077 18.1219,7.99935 C18.1219,8.56196 17.8984,9.10155 17.5006,9.49935 L6.5,20.5 L2.5,21 L3,21.5 Z M5.06066,19 L14.0607,10 L17,12.9393 L8,21.9393 L5.06066,19 Z M16.5,9.5 L19.5,6.5 L17,4 L14,7 L16.5,9.5 Z" />
        </svg>
    );

    const svg2 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 6h18M5 6l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M10 11v6M14 11v6" />
        </svg>
    );

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = (filter === '') ? blogList : blogList.filter(item =>
        item.title.toLowerCase().includes(filter.toLowerCase()) ||
        (item.image && item.image.toLowerCase().includes(filter.toLowerCase()))
    );

    const handleEditBlog = (PK, SK) => {
        navigate('/add-blog', { state: { blogDetails: { PK, SK } } });
    };

    const handleNextPage = () => {
        if (hasMoreData && pageKeys[currentPage]) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        setCurrentPage(1); // Reset to the first page when perPage changes
        setBlogList([]); // Clear the blog list to avoid concatenation issues
        setPageKeys({});
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Blog List</Card.Title>
                    <div className="form-group">
                        <div className="col-auto">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="mb-3" style={{ width: '200px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width80">
                                    <strong>Image</strong>
                                </th>
                                <th className="text-center">
                                    <strong>Title</strong>
                                </th>
                                <th className="d-none">
                                    PK
                                </th>
                                <th className="d-none">
                                    SK
                                </th>
                                <th className="text-center">
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? (
                                    <tr>
                                        <td colSpan="4" className="text-center"><Loader /></td>
                                    </tr>
                                ) : hasError ? (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                            Connect To SuperAdmin
                                        </td>
                                    </tr>
                                ) :
                                    hasMoreData ? (
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                            </td>
                                        </tr>
                                    )
                                        : (filteredData.map(item => (
                                            <tr key={item.id}>
                                                <td>
                                                    <strong>{<img src={item.Image} alt={item.name} style={{ width: '100%', height: '50px', borderRadius: '10px' }} />}</strong>
                                                </td>
                                                <td className="text-center">{item.title}</td>
                                                <td className="d-none">{item.PK}</td>
                                                <td className="d-none">{item.SK}</td>
                                                <td className="text-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle onClick={() => handleEditBlog(item.PK, item.SK)} variant={'success'} className="light sharp i-false">
                                                            {svg1}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Toggle
                                                            variant={'danger'}
                                                            className="light sharp i-false ms-3"
                                                            onClick={() => handleDeleteBlogList(item.PK, item.SK)}
                                                        >
                                                            {svg2}
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        )))}
                        </tbody>
                    </Table>
                    <div className="table-pagination d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0">
                            Showing <span> {(currentPage - 1) * perPage + 1} </span> to <span> {Math.min(currentPage * perPage, filteredData.length)} </span> of <span> {filteredData.length} </span> entries
                        </p>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li
                                    className={`page-item page-indicator ${currentPage === 1 ? "disabled" : ""
                                        }`}
                                >
                                    <Link
                                        to={"#"}
                                        className="page-link"
                                        onClick={(e) => {
                                            if (currentPage === 1) {
                                                e.preventDefault();
                                            } else {
                                                handlePreviousPage();
                                            }
                                        }}
                                    >
                                        <i
                                            className="fa-solid fa-angle-left"
                                            style={{
                                                color: currentPage === 1 ? "#cccccc" : "inherit",
                                            }}
                                        ></i>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link to={"#"} className="page-link">
                                        {currentPage}
                                    </Link>
                                </li>
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link" onClick={handleNextPage}>
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default BlogList;
