import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Badge
} from "react-bootstrap";

import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const data = [
    {
       name: 'Sarita',
       email: 'sarita@gmail.com',
       pair: 'CCD-INR',
       order_id: 'ORD100000342',
       volume: '4945.6436',
       price: '4.000000000',
       total: '19974645',
       date_time: '01-01-2024 09:18AM',
       buy_sell: 'Buy',
       status: 'Pending'
    },
    {
        name: 'Babita',
        email: 'babita@gmail.com',
        pair: 'CCD-INR',
        order_id: 'ORD105500342',
        volume: '3535.6436',
        price: '4.000000000',
        total: '19974645',
        date_time: '05-01-2024 09:18AM',
        buy_sell: 'Sell',
        status: 'Complete'
     },
];

const OrderHistory = () => {
    const [filter, setFilter] = useState('');
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        console.log(selectedOption.value)
    };


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };


    const handleEndDateChange = (date) => {
        setEndDate(date);
    };


    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
    };

    const filteredData = (filter === '') ? data : data.filter(item =>
        item.name.toString().includes(filter) ||
        item.email.toString().includes(filter) ||
        item.pair.includes(filter) ||
        item.order_id.includes(filter) ||
        item.volume.includes(filter) ||
        item.pair.includes(filter) ||
        item.date_time.includes(filter) ||
        item.buy_sell.includes(filter) ||
        item.status.includes(filter)
    );

    return (
        <div className="row">
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Order History</Card.Title>
                        <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-between mb-4">
                            <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Filter..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                                <div className="d-flex align-items-center">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                        placeholderText="Start Date"
                                    />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control ms-2"
                                        placeholderText="End Date"
                                    />
                                    <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                                </div>
                            </Col>
                        </Row>
                        <Table responsive className="header-border ">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className="text-center">Email</th>
                                    <th className="text-center">Pair</th>
                                    <th className="text-center">Order ID</th>
                                    <th className="text-center">Volume</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Total</th>
                                    <th className="text-center">Date/Time</th>
                                    <th className="text-center">Buy/Sell</th>
                                    <th className="text-end">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(item => (
                                    <tr key={item.name}>
                                        <td>
                                            <strong>{item.name}</strong>
                                        </td>
                                        <td className="text-center">{item.email}</td>
                                        <td className="text-center">{item.pair}</td>
                                        <td className="text-center">{item.order_id}</td>
                                        <td className="text-center">{item.volume}</td>
                                        <td className="text-center">{item.price}</td>
                                        <td className="text-center">{item.total}</td>
                                        <td className="text-center">{item.date_time}</td>
                                        <td className="text-center"><Badge bg={`${item.buy_sell === 'Buy' ? 'success' : 'danger'}`}>{item.buy_sell}</Badge></td>
                                        <td className="text-end">
                                            <span className={`badge light badge-${item.status === 'Pending' ? 'warning' : 'success'}`}>{item.status}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                            <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                    <li className="page-item "><Link to={"#"} className="page-link">2</Link></li>
                                    <li className="page-item"><Link to={"#"} className="page-link">3</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </Card.Body>
                </Card>
            </Col>
        </div>
    )
}

export default OrderHistory