import React, { useState } from "react";
import { Col, Card, Form, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { addHeaderText } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import swal from "sweetalert";

const HomeTextHeader = () => {
    const [text, setText] = useState('');
    const [description, setDescription] = useState('');
    const { notifySuccess, notifyWarning, notifyError } = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!text.trim() || !description.trim()) {
            notifyWarning("Please enter both text and description.");
            return;
        }

        // SweetAlert confirmation dialog
        const willSubmit = await swal({
            title: "Are you sure?",
            text: "Do you want to change the Header Text & Description?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (willSubmit) {
            try {
                const response = await addHeaderText(text, description, 'Dynamic_String'); // Updated to send description
                console.log('Mutation response:', response.DynamiInput);
                if (response.DynamiInput === true) {
                    notifySuccess("Header text and description added successfully.");
                    setText('');
                    setDescription('');
                } else {
                    notifyError("Failed to add header text and description. Please try again.");
                }
            } catch (error) {
                console.error('Error:', error);
                notifyError("An error occurred while adding header text and description.");
            }
        }else {
            notifyWarning("Header Text & Description change canceled.");
        }
    }

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Home Page Header Text</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Text</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Text"
                                        value={text}
                                        onChange={e => setText(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Description"
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button type="submit" variant="success">Save</Button>
                                <Link to='/list-header'>
                                    <Button className="ms-2" variant="danger">Go Back</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default HomeTextHeader;
