// Loader.js
import React from 'react';
import {
    HashLoader
} from 'react-spinners';

// Accepting a `color` prop
const Loader = ({ loading, color = '#fffc6a' }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <HashLoader loading={loading} size={80} color={color} />
    </div>
);

export default Loader;
