import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader/loader";
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";
import { getWebComplain } from "../../../apolloClientConnection/apolloClient";

const optionValue = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 30, label: '30' },
];

const ContactUser = () => {
  const [filter, setFilter] = useState('');
  const [perPage, setPerPage] = useState(25);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const [noData, setNoData] = useState(false);
  const [pageKeys, setPageKeys] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (page) => {
    setLoading(true);
    const lastKey = pageKeys[page - 1];

    try {
      const response = await getWebComplain(perPage, lastKey?.PK, lastKey?.SK, lastKey?.createdAt, lastKey?.type);

      if (response && response.data) {
        setData(response.data.items);
        setNoData(response.data.items.length === 0);
        const newPageKeys = {
          ...pageKeys,
          [page]: response.data.lastEvaluatedKey || null,
        };

        setPageKeys(newPageKeys);
      } else {
        setNoData(true);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching WEB details:', error);
      setNetworkError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, perPage]);

  const handlePerPageChange = (selectedOption) => {
    setPerPage(selectedOption.value);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (!noData) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSearch = () => {
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
  };

  return (
    <div className="row">
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>User Contact Details</Card.Title>
            <div className="form-group col-md-1">
              <Select
                options={optionValue}
                value={{ value: perPage, label: perPage.toString() }}
                onChange={handlePerPageChange}
                className="custom-react-select"
              />
            </div>
          </Card.Header>
          <Card.Body>
            <Row className="justify-content-between mb-4">
              <Col lg={6} className="d-flex align-items-center mb-2 mb-lg-0">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Filter..."
                    value={filter}
                    onChange={handleFilterChange}
                  />
                </div>
              </Col>
              <Col lg={6} className="d-flex align-items-center justify-content-lg-end">
                <div className="d-flex align-items-center">
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    className="form-control"
                    placeholderText="Start Date"
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    className="form-control ms-2"
                    placeholderText="End Date"
                  />
                  <button className="btn btn-primary ms-3" onClick={handleSearch}>Search</button>
                </div>
              </Col>
            </Row>
            <Table responsive className="header-border">
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th className="text-center">Name</th>
                  <th className="text-center">Last Name</th>
                  <th className="text-center">Contact</th>
                  <th className="text-center">Message</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <Loader />
                    </td>
                  </tr>
                )}
                {networkError && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                      <p>Connect To SuperAdmin</p>
                    </td>
                  </tr>
                )}
                {noData && !loading && !networkError && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                    </td>
                  </tr>
                )}
                {!loading && !networkError && !noData && data.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-center"><strong>{item.FirstName || ''}</strong></td>
                    <td className="text-center"><strong>{item.LastName || ''}</strong></td>
                    <td className="text-center text-primary fw-bold">
                      {item.phone}
                      {item.email && <div>{item.email}</div>}
                    </td>
                    <td className="text-center"><strong>{item.Massage || ''}</strong></td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
              <p className="mb-0">
                Showing <span>{(currentPage - 1) * perPage + 1}-{Math.min(currentPage * perPage, data.length)}</span> from <span> {data.length} </span> data{" "}
              </p>
              <nav>
                <ul className="pagination pagination-gutter pagination-primary no-bg">
                  <li className={`page-item page-indicator ${currentPage === 1 ? "disabled" : ""}`}>
                    <Link
                      to={"#"}
                      className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePreviousPage();
                      }}
                    >
                      <i className="fa-solid fa-angle-left" style={{ color: currentPage === 1 ? "#cccccc" : "inherit" }}></i>
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link to={"#"} className="page-link">
                      {currentPage}
                    </Link>
                  </li>
                  <li className={`page-item page-indicator ${noData && !pageKeys[currentPage] ? "disabled" : ""}`}>
                    <Link
                      to={"#"}
                      className="page-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNextPage();
                      }}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default ContactUser;
