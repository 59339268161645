import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Collapse from 'react-bootstrap/Collapse';
import { ThemeContext } from '../../../context/ThemeContext';
import { MenuList } from './Menu';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { fetchTabsByTemplate } from "../../../apolloClientConnection/apolloClient";

const initialState = {
  active: '',
  activeSubmenu: '',
  visibilitySettings: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE':
      return { ...state, active: state.active === action.payload ? '' : action.payload };
    case 'SET_ACTIVE_SUBMENU':
      return { ...state, activeSubmenu: state.activeSubmenu === action.payload ? '' : action.payload };
    case 'SET_VISIBILITY_SETTINGS':
      return { ...state, visibilitySettings: action.payload };
    default:
      return state;
  }
};

const SideBar = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);

  useEffect(() => {
    const fetchVisibilitySettings = async () => {
      const userTemplateData = localStorage.getItem("templateData");
      const data = JSON.parse(userTemplateData);
      const stringyfyData = JSON.parse(data);
      try {
        let fetchedSettings;

        // if (username === 'manas') {
          // If username is "manas", make all tabs and sub-tabs visible
          // fetchedSettings = MenuList.reduce((acc, item) => {
          //   acc[item.title] = { isVisible: true, subItems: {} };
          //   if (item.content) {
          //     item.content.forEach(subItem => {
          //       acc[item.title].subItems[subItem.title] = true;
          //     });
          //   }
          //   return acc;
          // }, {});
        // } else {
          // Otherwise, fetch visibility settings based on backend data
          const response = await fetchTabsByTemplate(stringyfyData?.PK, stringyfyData?.SK);
          const tabsData = JSON.parse(response.data.Tabs);

          // Initialize visibility settings with default visibility as false
          fetchedSettings = MenuList.reduce((acc, item) => {
            acc[item.title] = { isVisible: false, subItems: {} };
            return acc;
          }, {});

          // Set visibility based on backend data
          tabsData.forEach(tab => {
            if (fetchedSettings[tab.tab]) {
              fetchedSettings[tab.tab].isVisible = true;
              tab.subtab.forEach(subtab => {
                if (!fetchedSettings[tab.tab].subItems[subtab]) {
                  fetchedSettings[tab.tab].subItems[subtab] = true;
                }
              });
            }
          });
        // }

        dispatch({ type: 'SET_VISIBILITY_SETTINGS', payload: fetchedSettings });
      } catch (error) {
        console.error("Error fetching tabs:", error);
      }
    };

    fetchVisibilitySettings();
  }, []);

  // Handle scroll visibility
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y > prevPos.y;
    if (isShow !== hideOnScroll) setHideOnScroll(isShow);
  }, [hideOnScroll]);

  return (
    <div className={`deznav border-right ${iconHover} ${sidebarposition.value === "fixed" && sidebarLayout.value === "horizontal" && headerposition.value === "static" ? hideOnScroll ? "fixed" : "" : ""}`}>
      {/* <PerfectScrollbar className="deznav-scroll"> */}
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((item, index) => {
            const hasSubItems = item.content && Object.keys(state.visibilitySettings[item.title]?.subItems || {}).length > 0;
            return (
              <React.Fragment key={index}>
                {state.visibilitySettings[item.title] && state.visibilitySettings[item.title].isVisible ? (
                  hasSubItems ? (
                    <li className={state.active === item.title ? 'mm-active' : ''}>
                      <Link to="#" className="has-arrow" onClick={() => dispatch({ type: 'SET_ACTIVE', payload: item.title })}>
                        {item.iconStyle}
                        <span className="nav-text">{item.title}</span>
                      </Link>
                      <Collapse in={state.active === item.title}>
                        <ul>
                          {item.content.map((subItem, subIndex) => (
                            state.visibilitySettings[item.title].subItems[subItem.title] ? (
                              <li key={subIndex} className={state.activeSubmenu === subItem.title ? 'mm-active' : ''}>
                                <NavLink to={subItem.to}>{subItem.title}</NavLink>
                              </li>
                            ) : null
                          ))}
                        </ul>
                      </Collapse>
                    </li>
                  ) : (
                    <li>
                      <NavLink to={item.to}>
                        {item.iconStyle}
                        <span className="nav-text">{item.title}</span>
                      </NavLink>
                    </li>
                  )
                ) : null}
              </React.Fragment>
            );
          })}
        </ul>
      {/* </PerfectScrollbar> */}
      </div>
    </div>
  );
};

export default SideBar;
