const AadharURL = process.env.REACT_APP_AADHAR_URL
const LivePhotoURL = process.env.REACT_APP_LIVE_PHOTO_URL
const PanCardURL = process.env.REACT_APP_PAN_CARD



export const getProfileImage = async (url) => {
    try {
        // Extract the filename from the URL
        const filename = url.split('/').pop();

        // Construct the new URL
        let newUrl = `${LivePhotoURL}/${filename}`;
        
        // Remove any trailing quotes from the new URL
        newUrl = newUrl.replace(/"$/, '');
        
        return newUrl;
    } catch (error) {
        throw new Error(error);
    }
};

export const getPancardImage = async (url) => {
    try {
        // Extract the filename from the URL
        const filename = url.split('/').pop();



        // Construct the new URL
        let newUrl = `${PanCardURL}/${filename}`;
        
        // Remove any trailing quotes from the new URL
        newUrl = newUrl.replace(/"$/, '');
        
        return newUrl;
    } catch (error) {
        throw new Error(error);
    }
};

export const getAadharImage = async (url) => {
    try {
        // Extract the filename from the URL
        const filename = url.split('/').pop();


        // Construct the new URL
        let newUrl = `${AadharURL}/${filename}`;
        
        // Remove any trailing quotes from the new URL
        newUrl = newUrl.replace(/"$/, '');
        
        return newUrl;
    } catch (error) {
        throw new Error(error);
    }
};


export const getHelpSupportImage = async (url) => {
    try {
        // Extract the filename from the URL
        const filename = url.split('/').pop();

        // Construct the new URL
        let newUrl = `https://link.storjshare.io/raw/jwechh5qgh7no3276pk2a4wm22pa/simplyfy/Complain-file/${filename}`;
        
        // Remove any trailing quotes from the new URL
        newUrl = newUrl.replace(/"$/, '');
        
        return newUrl;
    } catch (error) {
        throw new Error(error);
    }
};



export const getImageLink = async (url) => {
    try {
        // Extract the filename from the URL
        const filename = url.split('/').pop();

        // Construct the new URL
        // let newUrl = `https://link.storjshare.io/raw/jwechh5qgh7no3276pk2a4wm22pa/simplyfy/Complain-file/${filename}`;
        let newUrl = `https://link.storjshare.io/raw/jw25isalkltlgo4nvkccxmii6dbq/simplyfy/BlogImage/${filename}`;
        
        
        // Remove any trailing quotes from the new URL
        newUrl = newUrl.replace(/"$/, '');
        
        return newUrl;
    } catch (error) {
        throw new Error(error);
    }
};

