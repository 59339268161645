import React, { useState } from "react";
import { Row, Col, Card, Table, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddTaxModel from "./AddTaxModel";
import EditTaxModel from "./EditTaxModel";
import Select from 'react-select';

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const ListTax = () => {

    const [addTaxModel, setAddTaxModel] = useState()
    const [editTaxModel, setEditTaxModel] = useState(false);
    const [currentTaxItem, setCurrentTaxItem] = useState(null);
    const [perPage, setPerPage] = useState(25);



    const data = [
        { taxCalculation: "Provider Fee", percentage: "0.075" },
        { taxCalculation: "TDS", percentage: "1.00" },
        { taxCalculation: "GST", percentage: "18" },
        { taxCalculation: "CGST", percentage: "9" },
        { taxCalculation: "SGST", percentage: "9" },
    ];

    // SVG Icon
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M3,17.25V21h3.75L17.81,9.94l-3.75-3.75L3,17.25zM20.71,7.04c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34c-0.39-0.39-1.02-0.39-1.41,0l-1.83,1.83l3.75,3.75L20.71,7.04z" fill="#000000" />
            </g>
        </svg>
    );

    const [filter, setFilter] = useState('');

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredData = !filter ? data : data.filter(item =>
        item.taxCalculation.toLowerCase().includes(filter.toLowerCase()) ||
        item.percentage.toLowerCase().includes(filter.toLowerCase())
    );

    const handleSaveEdit = (item) => {
        const newData = data.map(it => it.taxCalculation === item.taxCalculation ? item : it);
        // Update your data state here, or dispatch an action if you're using a state management library
        console.log(newData); // Replace this with actual state update logic
    };

    const handleSaveAdd = (item)=>{
        console.log(item)
    }

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        console.log(selectedOption.value)
    };
    

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>List Tax</Card.Title>
                    </Card.Header>
                    <Card.Body>
                    <Row className="mb-3 justify-content-between">
  <Col xs={12} md={7} lg={4} className="d-flex align-items-center mb-2 mb-md-0">
    <div style={{ width: '100%' }}> {/* Adjusted to 100% to use the full width of the column */}
      <input
        type="text"
        className="form-control"
        placeholder="Filter..."
        value={filter}
        onChange={handleFilterChange}
      />
    </div>
  </Col>
  <Col xs={5} md={5} lg={2} className="d-flex justify-content-md-end align-items-center">
    <Button variant="primary" className="light w-100 w-md-auto" onClick={() => setAddTaxModel(true)}> {/* w-100 for full width on smaller screens, auto width on md screens and up */}
      Add Tax
    </Button>
  </Col>
</Row>


                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="width80">
                                        <strong>Tax Calculation</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Percentage</strong>
                                    </th>

                                    <th className="text-center"> <strong>Action</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(item => (
                                    <tr key={item.taxCalculation}>
                                        <td>{item.taxCalculation}</td>
                                        <td className="text-center">
                                        {item.percentage.includes('%') ? item.percentage : `${item.percentage}%`}
                                        </td>

                                        <td className="text-center">
                                            <Dropdown>
                                                <Link>
                                                    <Dropdown.Toggle
                                                        onClick={() => { setEditTaxModel(true); setCurrentTaxItem(item); }}
                                                        variant={'success'}
                                                        className="light sharp i-false"
                                                    >
                                                        {svg1}
                                                    </Dropdown.Toggle>
                                                </Link>
                                                {/* <Dropdown.Menu>
                                                <Dropdown.Item>Edit</Dropdown.Item>
                                                <Dropdown.Item>Delete</Dropdown.Item>
                                            </Dropdown.Menu> */}
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="table-pagenation d-flex align-items-center justify-content-between ">
                            {/* <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p> */}
                            <div className="form-group col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                            <nav>
                                <ul className="pagination pagination-gutter pagination-primary no-bg">
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>
                                    <li className="page-item page-indicator">
                                        <Link to={"#"} className="page-link">
                                            <i className="fa-solid fa-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </Card.Body>
                </Card>
            </Col>
            <AddTaxModel show={addTaxModel} onHide={() => setAddTaxModel(false)} onSave={handleSaveAdd}/>
            <EditTaxModel show={editTaxModel} onHide={() => setEditTaxModel(false)} taxItem={currentTaxItem} onSave={handleSaveEdit} />
        </Row>
    )
}

export default ListTax;