import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]


const tabDataBlog = [
    { Date: "01/01/2024 09:18AM", CoinName: "BTC", Naration: "INR Deposit", BUY: "120", SELL: "80", Balance: "40" },
    { Date: "12/02/2001 07:36AM", CoinName: "CCD", Naration: "INR Withdrawal", BUY: "100", SELL: "80", Balance: "20" },
];

const Domestic = () => {
    const [data, setData] = useState(
        document.querySelectorAll("#futureorder_wrapper tbody tr")
    );
    const sort = 6;
    const activePag = useRef(0);
    const [test, settest] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filter, setFilter] = useState('');

    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove("d-none");
            } else {
                data[i].classList.add("d-none");
            }
        }
    };
    // use effect
    useEffect(() => {
        setData(document.querySelectorAll("#futureorder_wrapper tbody tr"));
        //chackboxFun();
    }, [test]);


    // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
    // paggination
    let paggination = Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1);

    // Active paggination & chage data
    const onClick = (i) => {
        activePag.current = i;
        chageData(activePag.current * sort, (activePag.current + 1) * sort);
        settest(i);
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value.toLowerCase());
    };


    return (
        <>
            <div className="table-responsive dataTabletrade ">
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-3 mt-3" style={{ maxWidth: '100%' }}>
    {/* Filter Input on the Left */}
    <div style={{ width: '200px', flex: '0 0 auto' }}>
        <input
            type="text"
            className="form-control"
            placeholder="Filter..."
            value={filter}
            onChange={handleFilterChange}
        />
    </div>

    {/* Date Pickers and Download Button on the Right */}
    <div className="d-flex justify-content-between justify-content-lg-end align-items-center mt-3 mt-lg-0">
        <div className="d-flex align-items-center">
            <div className="mr-2">
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    className="form-control"
                    placeholderText="Start Date"
                />
            </div>
            <div className="ms-2">
                <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    className="form-control"
                    placeholderText="End Date"
                />
            </div>
            <button className="btn btn-primary ms-2 me-3" onClick={handleSearch}>Search</button>
        </div>
        <Link to={"#"} className="btn btn-primary light btn-rounded me-3 mb-3 mb-lg-0"><i className="las la-download scale5 me-2"></i>Download</Link>
    </div>
</div>


                <div id="futureorder_wrapper" className="dataTables_wrapper no-footer">
                    <table id="example" className="table display dataTable no-footer" style={{ minWidth: "845px" }}>
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th>Coin Name</th>
                                <th>Narration</th>
                                <th className='text-success'>BUY</th>
                                <th className='text-danger'>SELL</th>
                                <th className="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tabDataBlog.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.Date}</td>
                                    <td>{item.CoinName}</td>
                                    <td><Badge bg={item.Naration === "INR Deposit" ? "success" : "danger"}>
                                        {item.Naration}
                                    </Badge></td>
                                    <td className='text-success'>{item.BUY}</td>
                                    <td className='text-danger'>{item.SELL}</td>
                                    <td className="text-end">{item.Balance}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        {/* <div className="dataTables_info">
                            Showing {activePag.current * sort + 1} to{" "}
                            {data.length > (activePag.current + 1) * sort
                                ? (activePag.current + 1) * sort
                                : data.length}{" "}
                            of {data.length} entries
                        </div> */}
                        <div className="form-group  col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                        <div
                            className="dataTables_paginate paging_simple_numbers mb-0"
                            id="application-tbl1_paginate"
                        >
                            <Link
                                className="paginate_button previous "
                                to="/future"
                                onClick={() =>
                                    activePag.current > 0 &&
                                    onClick(activePag.current - 1)
                                }
                            >
                                <i className="fa fa-angle-double-left" ></i>
                            </Link>
                            <span>
                                {paggination.map((number, i) => (
                                    <Link
                                        key={i}
                                        to="/future"
                                        className={`paginate_button  ${activePag.current === i ? "current" : ""
                                            } `}
                                        onClick={() => onClick(i)}
                                    >
                                        {number}
                                    </Link>
                                ))}
                            </span>

                            <Link
                                className="paginate_button next"
                                to="/future"
                                onClick={() =>
                                    activePag.current + 1 < paggination.length &&
                                    onClick(activePag.current + 1)
                                }
                            >
                                <i className="fa fa-angle-double-right" ></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Domestic;