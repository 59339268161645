import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Table, Dropdown } from "react-bootstrap";
import useToast from '../../Toaster/Toaster';
import Loader from "../../Loader/loader";
import LottieAnimation from "../../../../gif/LottieAnimation ";
import NetworkError from "../../../../gif/networkError.json";
import noDataAnimation from "../../../../gif/Data Error.json";
import { userBuySellOrdersData } from "../../../../apolloClientConnection/apolloClient";
import OrderDataModal from '../OrderDataModal';


const optionValue = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 25, label: '25' }, // Changed default value to 25
    { value: 30, label: '30' },
]

const tabDataBlog = [
    { Date: "01/01/2024 09:18AM", CoinName: "BTC", Naration: "INR Deposit", BUY: "120", SELL: "80", Balance: "40" },
    { Date: "12/02/2001 07:36AM", CoinName: "CCD", Naration: "INR Withdrawal", BUY: "90", SELL: "30", Balance: "0" },
];

const ExecutedOrders = () => {
    const [filter, setFilter] = useState(''); // State to keep track of the filter text
    const [data, setData] = useState(tabDataBlog); // Use the initial data as the default state
    const [perPage, setPerPage] = useState(25);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [orderDataModalShow, setOrderDataModalShow] = useState(false);
    const [userPK, setUserPK] = useState('');
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [hasData, setHasData] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(null); // State variable to store the selected order data
    const { notifyError } = useToast();

    useEffect(() => {
        const UserDocument = localStorage.getItem("UserDataInfoDocument");
        const ParseUserData = JSON.parse(UserDocument || '{}');

        if (ParseUserData.PK) {
            setUserPK(ParseUserData.PK);
        }
    }, []);


    // Function to filter data based on the filter text
    const filterData = (filterText) => {
        if (!filterText) {
            return tabDataBlog; // If no filter is applied, return the original data
        }

        return tabDataBlog.filter(item =>
            Object.values(item).some(value =>
                value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
        );
    };


    useEffect(() => {
        if (userPK) {
            fetchUserBuySell(userPK);
        }
    }, [userPK]);

    const fetchUserBuySell = async (userPK) => {
        setLoading(true);

        try {
            const data = await userBuySellOrdersData('CCD', 'INR', userPK);
            if (data && data.userOrders) {
                const allOrders = [...data.userOrders.tradeHistory];
                setOrders(allOrders);

            } else {
                setOrders([]);
            }
            setHasError(false);
        } catch (error) {
            console.error("Error fetching orders:", error);
            setOrders([]);
            notifyError("Failed to fetch data");
            setHasError(true);

        } finally {
            setLoading(false);
        }
    };

    const filteredOrders = orders.filter(order =>
        Object.values(order).some(
            value =>
                typeof value === "string" &&
                value.toLowerCase().includes(filter.toLowerCase())
        )
    ).sort((a, b) => b.createdAt - a.createdAt);


    // Update the displayed data when the filter changes
    const handleFilterChange = (e) => {
        const filterText = e.target.value;
        setFilter(filterText);
        setData(filterData(filterText));
    };

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSearch = () => {
        // Implement filtering based on selected date range
        // You can use startDate and endDate states to filter the data
        // console.log("Start Date:", startDate);
        // console.log("End Date:", endDate);
    };

    const handleRowClick = (order) => {
        setSelectedOrder(order);
        setOrderDataModalShow(true);
    };

    function formatDateTime(timestamp) {
        const date = new Date(Number(timestamp));
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        return date.toLocaleDateString('en-US', options);
    }

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M12,5C7,5 2.73,8.11 1,12C2.73,15.89 7,19 12,19C17,19 21.27,15.89 23,12C21.27,8.11 17,5 12,5ZM12,16C9.79,16 8,14.21 8,12C8,9.79 9.79,8 12,8C14.21,8 16,9.79 16,12C16,14.21 14.21,16 12,16ZM12,10C10.9,10 10,10.9 10,12C10,13.1 10.9,14 12,14C13.1,14 14,13.1 14,12C14,10.9 13.1,10 12,10Z" fill="#000000" />
            </g>
        </svg>
    );

    return (
        <>
            <div className="table-responsive dataTabletrade ">
                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mb-3 mt-3" style={{ maxWidth: '100%' }}>
                    {/* Filter Input on the Left */}
                    <div style={{ width: '200px', flex: '0 0 auto' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Filter..."
                            value={filter}
                            onChange={handleFilterChange}
                        />
                    </div>

                    {/* Date Pickers and Download Button on the Right */}
                    <div className="d-flex justify-content-between justify-content-lg-end align-items-center mt-3 mt-lg-0">
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    className="form-control"
                                    placeholderText="Start Date"
                                />
                            </div>
                            <div className="ms-2">
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    className="form-control"
                                    placeholderText="End Date"
                                />
                            </div>
                            <button className="btn btn-primary ms-2 me-3" onClick={handleSearch}>Search</button>
                        </div>
                        <Link to={"#"} className="btn btn-primary light btn-rounded me-3 mb-3 mb-lg-0"><i className="las la-download scale5 me-2"></i>Download</Link>
                    </div>
                </div>


                <div id="futureorder_wrapper" className="dataTables_wrapper no-footer">
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Pair</th>
                                <th>Order ID</th>
                                <th>QTY</th>
                                <th>Price</th>
                                <th>Total</th>
                                <th colSpan="2">Buyer Charges</th>
                                <th colSpan="3">Seller Charges</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            <tr>
                                {/* Sub-headers for charges */}
                                <th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                                <th>GST</th>
                                <th>Brokrage</th>
                                <th>TDS</th>
                                <th>GST</th>
                                <th>Brokrage</th>
                                <th></th><th></th><th></th><th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="16" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="16" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : !hasData || filteredOrders?.length === 0 ? (
                                <tr>
                                    <td colSpan="16" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (filteredOrders?.map((order, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td>{order.shareName}</td>
                                    <td>{order.coinPairWith}</td>
                                    <td className="color-primary">{order.orderId}</td>
                                    <td className="color-primary">{order.quantity} / {order.totalQuantity}</td>
                                    <td className="color-primary">{order.price}</td>
                                    <td className="color-primary">₹ {(order.quantity * order.price)?.toFixed(2)}</td>
                                    <td className="color-primary">₹ {order.buyCharges && order.buyCharges.GST ? Number(order.buyCharges.GST) : '0'}</td>
                                    <td className="color-primary">₹ {order.buyCharges && order.buyCharges.brokarage ? Number(order.buyCharges.brokarage) : '0'}</td>
                                    <td className="color-primary">₹ {order.sellCharges && order.sellCharges.TDS ? Number(order.sellCharges.TDS) : '0'}</td>
                                    <td className="color-primary">₹ {order.sellCharges && order.sellCharges.GST ? Number(order.sellCharges.GST) : '0'}</td>
                                    <td className="color-primary">₹ {order.sellCharges && order.sellCharges.brokarage ? Number(order.sellCharges.brokarage) : '0'}</td>
                                    <td>{order?.customDate ? order?.customDate : formatDateTime(order.createdAt)}</td>
                                    <td >
                                        <span className={`badge light badge-${order.orderExcutedType === "BUY" ? "success" : order.orderExcutedType === "SELL" ? "danger" : "warning"}`}>
                                            {order.orderExcutedType}
                                        </span>
                                    </td>
                                    {/* <td className="color-danger">{order.totalQuantity}</td> */}
                                    <td className={`text-${order.totalQuantity === order.quantity ? 'success' : 'warning'}`}>
                                        {order.totalQuantity === order.quantity ? (
                                            'Completed'
                                        ) : (
                                            <>
                                                Partially<br />Completed
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Link onClick={() => handleRowClick(order)}>
                                                <Dropdown.Toggle
                                                   variant={order.orderType === 'PARTIAL_ORDER' ? 'warning' : 'success'}
                                                    className="light sharp i-false"
                                                >
                                                    {svg1}
                                                </Dropdown.Toggle>
                                            </Link>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )))}
                        </tbody>
                    </Table>
                    <div className="table-pagenation d-flex align-items-center justify-content-between mb-3">
                        {/* <p className="mb-0">Showing <span> 1-4 </span> from <span> 30 </span> data </p> */}
                        <div className="form-group mb-3 col-md-1">
                            <Select
                                options={optionValue}
                                value={{ value: perPage, label: perPage.toString() }}
                                onChange={handlePerPageChange}
                                className="custom-react-select"
                            />
                        </div>
                        <nav>
                            <ul className="pagination pagination-gutter pagination-primary no-bg">
                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-left"></i>
                                    </Link>
                                </li>
                                <li className="page-item active"><Link to={"#"} className="page-link">1</Link></li>

                                <li className="page-item page-indicator">
                                    <Link to={"#"} className="page-link">
                                        <i className="fa-solid fa-angle-right"></i>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <OrderDataModal show={orderDataModalShow} onHide={() => setOrderDataModalShow(false)} order={selectedOrder} />

            </div>
        </>
    );
};

export default ExecutedOrders;
