import React, { useState, useEffect } from "react";
import { Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../Loader/loader";
import useToast from "../Toaster/Toaster";
import { AiOutlineDownload } from "react-icons/ai"; // Import download icon
import LottieAnimation from "../../../gif/LottieAnimation ";
import NetworkError from "../../../gif/networkError.json";
import noDataAnimation from "../../../gif/Data Error.json";

const DepositeHistory = () => {
    const [depositHistory, setDepositHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false); // New state for network error
    const { notifyError } = useToast();
    const SERVER_LINK = process.env.REACT_APP_SERVER_LINK; // Ensure this is defined in your environment variables

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${SERVER_LINK}/api/v1/file/getDepositeFileHistroy`, {
                    method: 'GET',
                });

                if (response.ok) {
                    const result = await response.json(); // Parse the JSON response
                    if (result && result.data && result.data.data) {
                        setDepositHistory(result?.data?.data); // Set deposit history from response data
                    } else {
                        notifyError("No deposit history available");
                    }
                } else {
                    notifyError("Failed to fetch deposit history");
                }
                setHasError(false);
            } catch (error) {
                console.error("Failed to fetch deposit history: ", error);
                notifyError("Failed to fetch deposit history");
                setHasError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, []);


    // Helper function to extract the file name after /Import_Deposite/
    const extractFileName = (fileUrl) => {
        const filePathParts = fileUrl.split("/Import_Deposite/");
        return filePathParts.length > 1 ? filePathParts[1] : fileUrl; // Return the file name if found, otherwise the full URL
    };

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Deposit File History</Card.Title>
                    <div className="form-group">
                        <div className="col-auto">
                            <Link to="/deposite-file">
                                <button type="submit" className="btn btn-success">Upload File</button>
                            </Link>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th><strong>S.No</strong></th>
                                <th><strong>File Name</strong></th>
                                <th><strong>Date&Time</strong></th>
                                <th className="text-center"><strong>Download</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <Loader />
                                    </td>
                                </tr>
                            ) : hasError ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={NetworkError} height={400} width={550} />
                                        Connect To SuperAdmin
                                    </td>
                                </tr>
                            ) : depositHistory.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <LottieAnimation lottieJson={noDataAnimation} height={400} width={550} />
                                    </td>
                                </tr>
                            ) : (
                                depositHistory?.map((record, index) => (
                                    <tr key={record.PK}>
                                        <td>{index + 1}</td>
                                        <td>{extractFileName(record.FILE)}</td>
                                        <td>{new Date(record.createdAt).toLocaleString()}</td>
                                        <td className="text-center">
                                            <a href={record.FILE} target="_blank" rel="noopener noreferrer" download>
                                                <AiOutlineDownload size={24} />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col >
    );
};

export default DepositeHistory;
