import React, { useState, useEffect } from "react";
import { changeAdminPassword } from "../../../apolloClientConnection/apolloClient";
import useToast from "../Toaster/Toaster";
import swal from "sweetalert";

const Password = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminPKSK, setAdminPKSK] = useState({ PK: "", SK: "" });
  const { notifyError, notifySuccess, notifyWarning } = useToast();

  useEffect(() => {
    const storedAdminPKSK = localStorage.getItem("adminPKSK");

    if (storedAdminPKSK) {
      try {
        const parsedPKSK = JSON.parse(storedAdminPKSK);
        setAdminPKSK({
          PK: parsedPKSK.PK.trim(),
          SK: parsedPKSK.SK.trim(),
        });
      } catch (error) {
        notifyError("Failed to parse admin data from localStorage");
      }
    } else {
      notifyWarning("Admin data not found. Please login again.");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!adminPKSK.PK || !adminPKSK.SK) {
      notifyWarning("Admin details are missing.");
      return;
    }

    if (password !== confirmPassword) {
      notifyWarning("Passwords do not match!");
      return;
    }

    if (!password || !confirmPassword) {
      notifyWarning("Please fill in all fields.");
      return;
    }

    // Show SweetAlert confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Do you want to change the password?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        try {
          // Call the changeAdminPassword function with PK, SK, and the new password
          await changeAdminPassword(adminPKSK.PK, adminPKSK.SK, password);
          notifySuccess("Password changed successfully!");
          // Reset form
          setPassword("");
          setConfirmPassword("");
        } catch (error) {
          notifyError("Failed to change password");
        }
      } else {
        notifyWarning("Password change was cancelled.");
      }
    });
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Change Admin Password</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Confirm New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm New Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <button type="submit" className="btn btn-success me-2">
                  Change Password
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    setPassword("");
                    setConfirmPassword("");
                  }}
                >
                  Clear
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Password;
