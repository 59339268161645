import React, { useState } from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { addReferral } from '../../../apolloClientConnection/apolloClient'; // Adjust the path
import useToast from '../Toaster/Toaster';


const CreateReferralProgram = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [rewardAmount, setRewardAmount] = useState('');
    const [rewardType, setRewardType] = useState(null);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { notifySuccess, notifyWarning, notifyError } = useToast();


    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const input = {
                name,
                description,
                RefferalAmount: parseFloat(rewardAmount),
                RewardType: rewardType.value,
                IsActive: active,
            };
            const success = await addReferral(input);
            if (success) {
                // Reset form or show success message
                setName('');
                setDescription('');
                setRewardAmount('');
                setRewardType(null);
                setActive(false);
                notifySuccess('Create Referral Sucucessfully!')
            }
        } catch (err) {
            notifyError('Failed to create referral')
            console.error('Error creating Referral:', err);
        } finally {
            setLoading(false);
        }
    };

    // Options for Reward Type dropdown
    const rewardTypeOptions = [
        { value: 'coins', label: 'Coins' },
        { value: 'inr', label: 'INR' }
    ];

    return (
        <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Create Referral Program</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={6}>
                                <Form.Group controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group controlId="rewardAmount">
                                    <Form.Label>Referral Reward Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter reward amount"
                                        value={rewardAmount}
                                        onChange={(e) => setRewardAmount(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col lg={6}>
                                <Form.Group controlId="rewardType">
                                    <Form.Label>Reward Type</Form.Label>
                                    <Select
                                        className='custom-react-select'
                                        options={rewardTypeOptions}
                                        value={rewardType}
                                        onChange={(selectedOption) => setRewardType(selectedOption)}
                                        placeholder="Select reward type"
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <Form.Group controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ minHeight: "150px", resize: "vertical" }}
                                        placeholder="Enter description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group controlId="active">
                                <Form.Check
                                    type="switch"
                                    id="active-switch"
                                    label="Active"
                                    checked={active}
                                    onChange={(e) => setActive(e.target.checked)}
                                />
                            </Form.Group>
                            {/* <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="active-switch" checked />
                                    <label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>
                            </div> */}
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="success" type="submit" disabled={loading}>
                                    {loading ? 'Creating...' : 'Create Program'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default CreateReferralProgram;
